.resend-container.reminder-container {
  position: absolute !important;
  z-index: 20 !important;
  .corners {
    @include vw(padding-top, 35);
    .theme-button {
      margin-top: 0;
      @include vw(width, 393);
      @include vw(margin-bottom, 10);
      .theme-button-container div {
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
      }
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
    .btn-invalid {
      opacity: 0.51;
    }
    .btn-invalid:hover {
      cursor: not-allowed;
    }
    .reminder-title {
      @include vw(margin-bottom, 25);
      * {
        text-transform: uppercase;
        margin: 0;
        line-height: normal;
        opacity: 1;
      }
    }
    .reminder-desc {
      * {
        font-weight: normal;
        line-height: 1.5;
      }
    }
    .g-recaptcha {
      @include vw(margin-bottom, 10);
    }
  }
}
