.need-assistance-section {
  @include vw(min-height, 700);
  @include vw(margin-top, 100);
  background-color: $blueDark;

  background-image: url('https://images.prismic.io/novaquark/3c062ebb-6ee0-4a97-9390-5bb548ccf8ba_ServerStatusBanner%402x.jpg?auto=compress,format');
  background-size: cover;
  @include breakpoint(mobile) {
    background-image: none;
    @include vwMobile(min-height, 310);
    margin-bottom: 0;
  }
  .need-assistance-section-content {
    @extend .util-main-padding;
    @include breakpoint(mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .theme-button {
      @include vw(min-width, 250);
      @include vw(margin-top, 80);
      @include breakpoint(mobile) {
        @include vwMobile(min-width, 230);
        @include vwMobile(min-height, 65);
        @include vwMobile(margin-bottom, 32);
        @include vwMobile(margin-top, 30);
      }
      .theme-button-container {
        div {
          @extend .icon-support;
          @include breakpoint(mobile) {
            @include vwMobile(margin-right, 10);
            @include vwMobile(background-size, 20);
            @include vwMobile(height, 20);
            @include vwMobile(width, 20);
          }
        }
        span {
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            line-height: 1.38;
          }
        }
      }
    }
  }
  .main-title-need-assistance {
    @include vw(padding-top, 200);
    @include vw(margin-bottom, 50);
    @extend .header_main_small;
    @include breakpoint(mobile) {
      text-align: center;
    }
  }

  .sub-title-need-assistance {
    @include vw(max-width, 480);
    @include vw(margin-top, 50);
    @extend .paragraph_regular;
    @include breakpoint(mobile) {
      max-width: 100%;
      @include vwMobile(font-size, 16);
      line-height: 2;
      text-align: center;
    }
  }
}
