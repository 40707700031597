/* theme settings */

// @import url('https://fonts.cdnfonts.com/css/eurostile');
@import url('https://use.typekit.net/tph3ywc.css');
// // Fonts

// @font-face {
//     font-family: 'Eurostile';
//     font-style: normal;
//     font-weight: normal;
//     src: url('/../fonts/eurostile.woff') format('woff');
// }

// @font-face {
//     font-family: 'EuroStyle Normal';
//     font-style: normal;
//     font-weight: normal;
//     src: url('/../fonts/EuroStyle Normal.woff') format('woff');
// }

// Settings ( from the Design Specs )
$white: #ffffff;
$white01: rgba(255, 255, 255, 0.1);
$white02: rgba(255, 255, 255, 0.2);
$gray: #dbdbdb;
$gray-box-media: #a2a2a2;
$gray-light: #9ea1a1;
$gray-home: #999999;
$gray-medium: #7b7b7b;
$gray-dark: #a8a8a8;
$grayer: #353b3d;
$gray-psw: #707070;
$gray-playlist: #3c4245;
$grayButton: rgba(255, 255, 255, 0.2);
$gray-oblique: #3d4244;
$border-nav: #282e30;
$blueDeep: #0c1315;
$blueSoft: #98cedd;
$blueLight: #162024;
$blueMedium: #0b1214;
$blueDark: #141f23;
$yellowMild: #e5b675;
$yellowIg: #ffd255;
$yellowHeader: #ffdf57;
$almostBlack: #0c1315;
$darkBlack: rgba(255, 255, 255, 0.02);
$black: black;
$whiteTransparent: rgba(255, 255, 255, 0.1);
$whiteLessTransparent: rgba(255, 255, 255, 0.2);
$reddish: #d13e3e;
$blacker: #232b2d;
$button-continue: #3dac79;
$maintenance-color: #3d70ac;
$blue: #08bdff;
$bestPriceBG: #243a43;
$graybox: rgb(128, 128, 128);
$headerMainColor: $white;
$headerSecondaryColor: $yellowIg;
$textMainColor: $gray;
$linksMainColor: $yellowMild;

$green: #3dac79;
$red: #d13e3e;

$gray-carousel: #484b4b;
$blue-patch: #8fc5c9;
$gray-patch: #727272;
$gray-patch-nav: #4c5051;
$black-news-detail: #0b1314;
$gray-content-border: #2c2c2c;
$gray-content: #797979;
$gray-scroll: #3e4345;
$gray-pillar: #575757;
// Semantics Classes to extends if needed

$corners-transition: 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6);

$white-to-yellow-filter: invert(6%) sepia(38%) saturate(3782%) hue-rotate(317deg) brightness(105%) contrast(103%);
$white-to-black-filter: invert(100%);
$black-to-yellow-filter: invert(86%) sepia(94%) saturate(7308%) hue-rotate(315deg) brightness(104%) contrast(103%);
.util-main-padding {
  @include vw(padding-right, 160);
  @include vw(padding-left, 160);
  @include breakpoint(mobile) {
    @include vwMobile(padding-right, 20);
    @include vwMobile(padding-left, 20);
  }
}
.buypage-container {
  position: relative;
  @include vw(padding-left, 156);
  @include vw(padding-right, 156);
  @include vw(padding-top, 80);
  @include breakpoint(mobile) {
    @include vwMobile(padding-right, 20);
    @include vwMobile(padding-left, 20);
  }
}
.buypage-notice {
  width: 100%;
  background-color: $white;
  color: $black;
  @include vw(height, 65);
  @include vw(padding-left, 156);
  @include vw(padding-right, 156);
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoint(mobile) {
    @include vwMobile(height, 65);
    @include vwMobile(padding-left, 20);
    @include vwMobile(padding-right, 20);
  }
}
.background-buy-page {
  background-clip: border-box;
  background-image: url('../images/buypage/bg-myDu-add.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  @include vw(padding-top, 25);
  @include vw(height, 1500);
  width: 100%;
  z-index: -1;
  @include breakpoint(mobile) {
    // @include vwMobile(padding-top, 70);
  }
}

.text_font_eurostile {
  font-family: 'Eurostile', sans-serif;
}

.text_font_eurostyle {
  font-family: 'EuroStyle', sans-serif;
}

.text_header_main_color {
  color: $white;
}

.text_header_secondary_color {
  color: $yellowIg;
}

.text_main_color {
  color: $gray;
}

.text_link_color {
  color: $yellowMild;
}

.header_main_shared {
  @extend .text_header_main_color;
  text-transform: uppercase;
}
//reset a few stuff

p,
h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
  opacity: 0.95;
}
a {
  margin-top: 0;
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
}
// H1
.header_main_big {
  @extend .header_main_shared;
  @include vw(font-size, 70);
  @include vw(line-height, 98);

  @include breakpoint(mobile) {
    @include vwMobile(font-size, 55);
    @include vwMobile(line-height, 77);
  }

  @include vw(margin-bottom, 90);
}

// H2
.header_main_medium {
  @extend .header_main_shared;
  @include vw(font-size, 50);
  @include vw(line-height, 70);

  @include breakpoint(mobile) {
    @include vwMobile(font-size, 35);
    @include vwMobile(line-height, 49);
  }
}

// H3
.header_main_small {
  @extend .header_main_shared;
  @include vw(font-size, 35);
  @include vw(line-height, 49);

  @include breakpoint(mobile) {
    @include vwMobile(font-size, 25);
    @include vwMobile(line-height, 35);
  }
}

.header_secondary_shared {
  @extend .text_header_secondary_color;
}

// H4
.header_secondary_big {
  @extend .header_secondary_shared;
  @include vw(font-size, 26);
  @include vw(line-height, 36);

  @include breakpoint(mobile) {
    @include vwMobile(font-size, 22);
    @include vwMobile(line-height, 30);
  }
}

.button_text_base {
  @extend .header_main_shared;
  @include vw(font-size, 26);
  @include vw(line-height, 36);
}

// H5
.header_secondary_medium {
  @extend .header_secondary_shared;
  @include vw(font-size, 22);
  @include vw(line-height, 30);

  @include breakpoint(mobile) {
    @include vwMobile(font-size, 18);
    @include vwMobile(line-height, 26);
  }
}

// Paragraph Under H1
.paragraph_under_main_header {
  @extend .text_header_secondary_color;
  @include vw(font-size, 32);
  @include vw(line-height, 44);

  @include breakpoint(tablet) {
    @include vw(font-size, 24);
    @include vw(line-height, 34);
  }
}

// Important Paragraph
.paragraph_important {
  @extend .text_main_color;
  @include vw(font-size, 22);
  // @include vw(line-height, 30);

  @include breakpoint(mobile) {
    @include vwMobile(font-size, 18);
  }
}

.paragraph_regular {
  @extend .text_main_color;
  @include vw(font-size, 20);
  @include vw(line-height, 40);

  @include breakpoint(tablet) {
    @include vw(font-size, 16);
    @include vw(line-height, 32);
  }
}

.paragraph_base {
  @include vw(font-size, 16);
  @include vw(line-height, 22);

  @include breakpoint(mobile) {
    @include vwMobile(font-size, 20);
    @include vwMobile(line-height, 22);
  }
}

.paragraph_disclaimer {
  @extend .paragraph_base;
  color: $gray-dark;
}

.header_main_list {
  @extend .text_main_color;
  @include vw(font-size, 20);
  @include vw(line-height, 28);

  @include breakpoint(tablet) {
    //No equivalent on mobile
  }
}

.list_item {
  @extend .text_main_color;
  @include vw(font-size, 18);
  @include vw(line-height, 26);

  @include breakpoint(tablet) {
    @include vw(font-size, 16);
    @include vw(line-height, 22);
  }
}

.list_item_secondary {
  color: $gray-dark;
  @include vw(font-size, 15);
  @include vw(line-height, 21);
}

.link_main {
  color: $yellowMild;
  @include vw(font-size, 20);
  @include vw(line-height, 28);
  &.active {
    text-decoration: underline;
  }
  @include breakpoint(mobile) {
    @include vwMobile(font-size, 20);
  }
}

// Structure of the basic article in the website ( from the Design Specs )

section {
  // @include vw(margin-bottom, 185);
  @include breakpoint(mobile) {
    @include vwMobile(margin-bottom, 0);
  }

  h1 {
    @extend .header_main_big;
    @include vw(margin-bottom, 90);
    opacity: 0.9;
    @include breakpoint(tablet) {
      // @include vw(margin-bottom, 50);
    }
  }

  .box {
    @include vw(margin-bottom, 36);

    h1 {
      margin-bottom: initial;
    }
  }

  h2 {
    @extend .header_main_medium;
  }

  h3 {
    @extend .header_main_small;
  }

  h4 {
    @extend .header_secondary_big;
  }

  h5 {
    @extend .header_secondary_medium;
  }

  li {
    @extend .list_item;
  }

  a {
    @extend .link_main;
    text-decoration: none;
  }

  p {
    @extend .paragraph_important;
  }

  .mobile-tobenamed1 {
    @include breakpoint(tablet) {
      //@include vw(margin-bottom, 50);
    }
  }

  .mobile-tobenamed2 {
    @include breakpoint(tablet) {
      // @include vw(margin-bottom, 20);
    }
  }
}

.sub-section {
  margin-bottom: 0;
}
