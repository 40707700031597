.buypage-aboutyou {
  height: auto;
  border: solid 1px rgba(255, 255, 255, 0.1);
  .container {
    height: 100%;
    background: $blueDark;
    @include vw(padding, 10);
    @include buildCorners(18, 0);
    @include breakpoint(mobile) {
      @include buildCornersMobile(22, 0);
    }
    h5 {
      color: $white;
      font-weight: normal;
      line-height: 1.42;
      @include vw(margin-bottom, 40);
      @include vw(font-size, 24);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 22);
        font-weight: bold;
        @include vwMobile(margin-bottom, 40);
      }
    }
    .title {
      * {
        @include vw(font-size, 30);
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 30);
        }
      }
    }
    .tooltip-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 20);
      }
      .input-title {
        display: inline-block;
        @include vw(font-size, 20);
        color: $yellowMild;
        line-height: 2;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
        }
      }
    }
    .checkbox {
      display: flex;
      align-items: flex-start;
      @include vwAll(padding, 20, 0, 20, 0);
      @include breakpoint(mobile) {
        @include vwMobileAll(padding, 20, 0, 30, 0);
        @include vwMobile(margin-top, 10);
        @include vwMobile(margin-bottom, 0);
      }
    }
    .checkbox-label {
      @include vw(max-width, 363);
      @include vw(margin-top, -5);
      @include breakpoint(mobile) {
        @include vwMobile(max-width, 320);
      }
      * {
        @include vw(font-size, 20);
        color: $gray;
        line-height: 1.2;
        text-align: left;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
        }
      }
      a {
        color: $yellowMild;
        text-decoration: underline;
      }
    }
    .move-left {
      @include vw(margin-right, 16);
      @include breakpoint(mobile) {
        margin-right: 0;
      }
    }
    .checkboxcontainer {
      color: $white;
      @include vw(margin-top, 15);
      input[type='checkbox'] {
        box-sizing: border-box;
        border-style: solid;
        border-color: $white;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background-color: $blueDeep;
        cursor: pointer;
        outline: none;
        @include vw(width, 20);
        @include vw(height, 20);
        @include vwAll(margin, 0, 30, 0, 0);
        @include vw(border-width, 1);
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 15);
          @include vwMobile(margin-right, 30);
          @include vwMobile(width, 25);
          @include vwMobile(height, 25);
        }
      }
      input[type='checkbox']:checked {
        background-clip: content-box;
        background-color: $yellowIg;
        @include vwAll(padding, 5, 5, 5, 5);
        @include breakpoint(mobile) {
          @include vwMobileAll(padding, 7, 7, 7, 7);
        }
      }
    }
    .second {
      margin-top: 0;
      padding-top: 0;
    }
    .util-input {
      width: auto;
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(height, 65);
      }
      div:first-of-type {
        border-style: none;
        margin: auto 0;
        background-image: url('../../../../assets/images/icons/no-border/Icon-Email.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(width, 25);
        @include vw(height, 25);
        @include vw(margin-right, 25);
        @include vw(margin-left, 25);
        @include breakpoint(mobile) {
          @include vwMobile(width, 25);
          @include vwMobile(height, 25);
          @include vwMobile(margin-right, 25);
          @include vwMobile(margin-left, 25);
        }
      }
      div:last-of-type {
        border-style: none;
        margin: auto 0;
        background-image: url('../../../../assets/images/icons/no-border/Icon-Loading.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(width, 25);
        @include vw(height, 25);
        @include vw(margin-right, 25);
        @include vw(margin-left, 25);
        @include breakpoint(mobile) {
          @include vwMobile(width, 25);
          @include vwMobile(height, 25);
          @include vwMobile(margin-right, 25);
        }
      }
    }
    .desc {
      @extend .list_item;
      text-align: right;
      @include vw(margin-top, 20);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 27);
        @include vwMobile(font-size, 16);
      }
    }

    div {
      &.centerButton {
        text-align: center;
        button {
          &.continue {
            @include vw(height, 65);
            @include vw(width, 228);

            @include breakpoint(mobile) {
              @include vwMobile(height, 65);
              @include vwMobile(width, 200);
            }
            margin-top: 0.18167vw !important;

            &:hover {
              background-color: $color-positive;
            }
            .theme-button-container {
              div {
                background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
                @include vw(height, 17.1);
                @include vw(width, 17.3);
                @include vw(background-size, 17);
                @include breakpoint(mobile) {
                  @include vwMobile(height, 25);
                  @include vwMobile(width, 20);
                  @include vwMobile(background-size, 18);
                  @include vwMobile(margin-right, 10);
                }
              }
            }

            &:hover .theme-button-container div {
              background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
            }
            &:hover .theme-button-container span {
              color: $black;
            }
            @include breakpoint(mobile) {
              @include vwMobile(height, 65);
              @include vwMobile(width, 205);
              @include vwMobile(font-size, 16);
              @include vwMobile(margin-top, 60);
            }
          }
        }
        button:disabled {
          opacity: 0.5;
        }
      }
    }

    .signin {
      color: #a8a8a8;
      line-height: 1.4;
      text-align: center !important;
      text-transform: none;
      @include vw(margin-top, 57);
      @include vw(margin-bottom, 57);
      @include vw(font-size, 20);
      link {
        @include vw(font-size, 20);
        @include vw(margin-left, 10);
      }
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }

    .signinbutton {
      @include vw(margin-top, 30);
      @include breakpoint(mobile) {
        @include vwMobile(height, 65);
        @include vwMobile(width, 180);
        @include vwMobile(font-size, 16);
        @include vwMobile(margin-top, 30);
      }
    }

    .errorMessage {
      background-color: $reddish;
      color: $white;
      line-height: 2.35;
      @include vw(font-size, 17);
      @include vw(margin-bottom, 20);
      @include vw(padding, 10);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 17);
        @include vwMobile(margin-bottom, 20);
      }
      &:before {
        border: 1px solid $white;
        background-image: url('../../../../assets/images/icons/Icon-Close.svg');
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        @include vw(background-size, 18);
        @include vwAll(padding, 3, 15, 3, 15);
        @include vw(margin-right, 15);
        @include breakpoint(mobile) {
          @include vwMobileAll(padding, 3, 15, 3, 15);
          @include vwMobile(margin-right, 15);
          @include vwMobile(background-size, 18);
        }
      }
    }
  }
  .signin-box {
    width: 100%;
    background-color: #011117;
    @include vw(padding, 22);
    @include vw(margin-top, 20);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 20);
    }
    * {
      @include vw(font-size, 22);
      font-weight: bold;
      text-align: center;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 22);
      }
    }
    span {
      color: #f4911e;
    }
  }
}
.buypage-aboutyou.convert-gt {
  @include vw(margin-top, 50);
  @include breakpoint(mobile) {
    @include vwMobile(margin-top, 20);
  }
  .convert-desc {
    @include vw(font-size, 22);
    color: #fff;
    text-align: center;
    @include vw(margin-top, 18);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 22);
      @include vwMobile(margin-top, 18);
    }
  }
}
.tooltip-box {
  @include vw(width, 25);
  @include vw(height, 25);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1px solid $white;
  cursor: pointer;
  opacity: 0.8;
  span {
    color: $white;
    opacity: 0.8;
  }
  @include breakpoint(mobile) {
    display: none;
  }
}
.popup-content {
  .popup-content-tooltip {
    @include vw(padding, 10);
    border: 0;
    background-color: $yellowIg;
    @include vw(width, 354);
    span {
      @include vw(font-size, 16);
      color: $almostBlack;
      line-height: 1.38;
    }
  }
}
.convert-popup-container-content {
  @include breakpoint(mobile) {
    width: 90vw !important;
    height: 90vh !important;
    overflow: auto;
    border: 1px solid $white02;
  }
}
.convert-popup-container-overlay {
  overflow-y: scroll;
}
