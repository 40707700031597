.updates-container {
  .navbar-container {
    @include vw(padding-left, 156);
    @include vw(padding-right, 156);
    @include vw(height, 65);
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    z-index: 9;
    border-bottom: 1px solid $white02;
    background-color: $almostBlack;

    transition: 0.5s ease-in-out;

    @include breakpoint(mobile) {
      @include vwMobile(height, 50);
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      border-top: 1px solid $white02;
      justify-content: center;
    }

    .navbar-left {
      position: absolute;
      @include vw(left, 156);
      display: flex;
      align-items: center;
      cursor: pointer;
      & div {
        background-image: url('../../../assets/images/icons-buttons/Icon-Continue-White.svg');
        transform: rotate(180deg);
        @include vw(background-size, 8);
        background-position: center;
        background-repeat: no-repeat;
        @include vw(margin-right, 18);
        @include vw(margin-top, 3);
        @include vw(height, 16);
        @include vw(width, 16);
      }
      @include breakpoint(mobile) {
        display: none;
      }
    }
    .navbar-right {
      display: flex;
    }
    a {
      color: $white;
      height: 100%;
      width: 100%;
      @include vw(font-size, 20);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 18);
      }
    }
    .nav-link {
      @include vw(height, 65);
      display: flex;
      align-items: center;
      border-bottom: solid transparent;
      border-top: solid transparent;
      @include vw(border-width, 3);
      position: relative;
      p.notice {
        position: absolute;
        @include vw(top, 8);
        left: 0;
        right: 0;
        color: $yellowIg;
        text-transform: uppercase;
        @include vw(font-size, 8);
        text-align: center;
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        @include breakpoint(mobile) {
          @include vwMobile(top, 5);
          @include vwMobile(font-size, 8);
        }
      }
      a {
        @include vw(padding-top, 15);
        @include vw(padding-right, 25);
        @include vw(padding-left, 25);
        @include breakpoint(mobile) {
          @include vwMobile(padding-right, 20);
          @include vwMobile(padding-left, 20);
          @include vwMobile(padding-top, 19);
        }
      }
      @include breakpoint(mobile) {
        @include vwMobile(height, 50);
        @include vwMobile(border-width, 3);
      }
    }
    .selected {
      border-bottom: solid $yellowIg;
      @include vw(border-width, 3);
      @include breakpoint(mobile) {
        @include vwMobile(border-width, 3);
      }
    }
  }
  .updates-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include vw(margin-bottom, 147);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 63);
    }
    .updates-intro-title,
    .updates-intro-desc,
    span {
      font-weight: 900;
      font-style: normal;
      margin: 0;
    }
    .updates-intro-number {
      z-index: 3;
      animation-duration: 2s;
      animation-name: versionUpdate;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      @include breakpoint(mobile) {
        animation-name: titleUpdateMobile;
      }
      * {
        @include vw(font-size, 30);
        line-height: 1;
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 1.75;
        }
      }
    }
    .updates-intro-title {
      z-index: 3;
      text-transform: uppercase;
      animation-duration: 2.5s;
      animation-name: titleUpdate;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      * {
        @include vw(font-size, 160);
        line-height: 0.8;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 70);
          line-height: 0.8;
        }
      }
    }
    @keyframes titleUpdate {
      0% {
        @include vw(margin-top, 300);
      }
      100% {
        @include vw(margin-top, 0);
      }
    }
    @keyframes versionUpdate {
      0% {
        @include vw(margin-top, 0);
      }
      100% {
        @include vw(margin-top, -580);
      }
    }
    @keyframes titleUpdateMobile {
      0% {
        @include vw(margin-top, 0);
      }
      100% {
        @include vw(margin-top, -2420);
      }
    }
    @keyframes subtitleUpdate {
      0% {
        @include vw(margin-top, 300);
      }

      100% {
        @include vw(margin-top, 150);
      }
    }
    @keyframes buttonLeft {
      0% {
        @include vw(margin-top, 300);
      }
      100% {
        @include vw(margin-top, 0);
      }
    }
    @keyframes buttonRight {
      0% {
        @include vw(margin-top, 300);
      }
      100% {
        @include vw(margin-top, 0);
      }
    }
    .updates-intro-desc {
      animation-duration: 3s;
      animation-name: subtitleUpdate;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      z-index: 3;
      @include vw(margin-top, 150);
      text-align: center;
      * {
        @include vw(font-size, 28);
        line-height: 1.36;
        font-weight: normal;
        @include breakpoint(mobile) {
          display: none;
          @include vwMobile(font-size, 21);
          text-align: center;
        }
      }
    }
    .button-group {
      display: flex;
      @include vw(margin-top, 34);
      @include breakpoint(mobile) {
        flex-direction: column;
        @include vwMobile(margin-top, 310);
      }
      button:first-of-type {
        background-image: linear-gradient(to right, $white 50%, $white 50%);
        @include vw(margin-right, 40);
        animation-duration: 2s;
        animation-name: buttonRight;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        @include breakpoint(mobile) {
          margin-right: 0;
          @include vwMobile(margin-bottom, 10);
        }
      }
      button:last-of-type {
        background-image: linear-gradient(to right, $yellowIg 50%, $yellowIg 50%);
        animation-duration: 3s;
        animation-name: buttonLeft;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        .theme-button-container {
          div {
            background-image: url('../../../assets/images/icons/no-border/Icon-Game-Black.svg');
            @include vw(height, 15);
            @include vw(width, 23);
            background-size: cover;
            @include breakpoint(mobile) {
              @include vwMobile(height, 15);
              @include vwMobile(width, 23);
            }
          }
        }
      }
      button {
        @include vw(min-width, 300);
        @include vw(height, 65);
        z-index: 3;
        @include breakpoint(mobile) {
          @include vwMobile(height, 65);
          // @include vwMobile(margin-top, 25);
          @include vwMobile(min-width, 300);
        }
        .theme-button-container {
          div {
            background-image: url('../../../assets/images/icons/Icon-Play.svg');
            background-size: 70%;
            @include vw(margin-right, 20);
            margin-left: 0;
            @include breakpoint(mobile) {
              @include vwMobile(margin-right, 13);
              @include vwMobile(height, 15);
              @include vwMobile(width, 15);
            }
          }
          span {
            @include vw(font-size, 16);
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            margin-right: 0;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }
        }
      }
    }
    .background-video {
      position: relative;
      width: 100%;
      object-fit: cover;
      height: 39.95vw;
      z-index: 1;
      @include breakpoint(mobile) {
        @include vwMobile(height, 646);
      }
    }
    .background-image {
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 39.95vw;
      @include breakpoint(mobile) {
        @include vwMobile(height, 646);
      }
    }
    .opacity {
      z-index: 2;
      position: absolute;
      left: 0;
      width: 100%;
      height: 39.95vw;
      background-image: linear-gradient(180deg, rgba(12, 19, 21, 0) 30%, #0c1315);
      @include breakpoint(mobile) {
        @include vwMobile(height, 648);
      }
    }
  }
  .section-title,
  .section-subtitle {
    @include vw(padding-left, 155);
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
    }
  }
  .section-title {
    @include vw(margin-top, 100);
    * {
      @include vw(font-size, 50);
      font-weight: 900;
      color: $white;
      line-height: 1.2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
      }
    }
  }
  .section-subtitle {
    @include vw(margin-bottom, 40);
    * {
      @include vw(font-size, 16);
      font-family: eurostile-extended, sans-serif;
      font-weight: 900;
      font-style: normal;
      color: $yellowIg;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
  }
  .box-container {
    position: relative;
    margin: auto;
    @include vw(margin-bottom, 61);
    @include vw(width, 1600);
    @include vw(height, 737);
    height: auto;
    border: 1px solid $white02;
    @include breakpoint(mobile) {
      width: 100vw - 4.16667vw * 2;
      @include vwMobile(margin-bottom, 40);
    }
    .box-corners {
      position: absolute;
      width: 100%;
      height: 100%;
      @include buildCorners(18, 0);
      @include breakpoint(mobile) {
        @include buildCornersMobile(18, 0);
      }
    }
    .box-grid {
      position: relative;
      width: auto;
      height: auto;
      @include vw(padding-left, 30);
      @include vw(padding-right, 30);
      @include vw(padding-top, 30);
      @include vw(padding-bottom, 30);
      display: flex;
      align-items: center;
      @include breakpoint(mobile) {
        flex-direction: column;
        @include vwMobileAll(padding, 25, 20, 40, 20);
        align-items: flex-start;
      }
      .box-grid-image {
        @include vw(width, 694);
        @include vw(height, 657);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include breakpoint(mobile) {
          width: 100%;
          @include vwMobile(height, 224);
        }
      }
      .box-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 100%;
        @include vw(padding-left, 117);
        @include vw(padding-right, 70);
        @include breakpoint(mobile) {
          height: auto;
          align-items: flex-start;
          padding-left: 0;
          padding-right: 0;
          width: 100%;
        }
        .box-content-title {
          @include vw(margin-bottom, 10);
          @include breakpoint(mobile) {
            @include vwMobile(margin-bottom, 10);
          }
          * {
            @include vw(font-size, 50);
            font-weight: 900;
            color: $white;
            text-transform: uppercase;
            text-align: center;
            line-height: 1;

            @include breakpoint(mobile) {
              @include vwMobile(font-size, 20);
              font-weight: bold;
              @include vwMobile(margin-top, 20);
            }
          }
        }
        .box-content-subtitle {
          * {
            @include vw(font-size, 20);
            text-transform: uppercase;
            color: $yellowIg;
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            font-style: normal;
            text-align: center;
            line-height: 1.2;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              text-align: left;
            }
          }
        }
        .box-content-desc {
          @include vw(width, 650);
          @include vw(margin-top, 30);
          * {
            @include vw(font-size, 20);
            color: #a8a8a8;
            line-height: 1.5;
            text-align: center;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              line-height: 1.63;
              text-align: left;
              color: $white;
            }
          }
          @include breakpoint(mobile) {
            width: 100%;
            @include vwMobile(margin-top, 10);
          }
        }
        button.box-content-button {
          @include vw(height, 65);
          @include vw(min-width, 260);
          border: 1px solid $white02;
          background-color: $almostBlack;
          background-image: none;
          @include vw(margin-top, 40);
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 20);
            @include vwMobile(height, 65);
            width: 100%;
          }
          .theme-button-container {
            div {
              background-image: url('../../../assets/images/icons-buttons/Icon-Continue-White.svg');
              @include vw(height, 9);
              @include vw(width, 8);
              background-size: contain;
              @include breakpoint(mobile) {
                @include vwMobile(height, 9);
                @include vwMobile(width, 8);
                @include vwMobile(margin-right, 15);
              }
            }
            span {
              color: $white;
              font-family: eurostile-extended, sans-serif;
              @include vw(font-size, 13);
              font-weight: 900;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 13);
              }
            }
          }

          &:hover .theme-button-container {
            @include buildCornersYellow(13, 4);
            @include breakpoint(mobile) {
              @include buildCornersMobileYellow(13, 4);
            }
            div {
              background-image: url('../../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
            }
            span {
              color: $yellowIg;
            }
          }
        }
      }
    }
    .inverse {
      flex-direction: row-reverse;
      @include breakpoint(mobile) {
        flex-direction: column;
      }
      .box-content {
        @include vw(padding-left, 70);
        @include vw(padding-right, 112);
        @include breakpoint(mobile) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .box-container-two {
    display: flex;
    justify-content: space-between;
    @include vw(padding-left, 153);
    @include vw(padding-right, 153);
    @include vw(margin-bottom, 61);
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      flex-direction: column;
      @include vwMobile(margin-bottom, 40);
    }
    .box-container:first-of-type {
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 40);
      }
    }
    .box-container {
      @include vw(width, 1051);
      margin: 0;
      height: 100%;
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
    .box-corners {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .box-container-small {
      @include vw(width, 496);
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
    .box-grid {
      flex-direction: column;
      width: auto;
      height: auto;
      @include vwAll(padding, 30, 25, 40, 25);
      position: relative;
      @include breakpoint(mobile) {
        @include vwMobileAll(padding, 25, 20, 40, 20);
      }
      .box-content {
        height: auto;
        padding: 0;
        .box-content-title {
          * {
            @include vw(font-size, 35);
            @include vw(margin-top, 40);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 26);
              @include vwMobile(margin-top, 20);
            }
          }
        }
        .box-content-desc {
          @include vw(width, 926);
          @include vw(margin-top, 15);
          @include breakpoint(mobile) {
            width: 100%;
            @include vwMobile(margin-top, 10);
          }
          * {
            color: #7b7b7b;
            @include breakpoint(mobile) {
              color: $white;
            }
          }
        }
        .box-content-desc-small {
          @include vw(width, 401);
          @include breakpoint(mobile) {
            width: 100%;
          }
        }
      }
      .box-grid-image {
        width: 100%;
        @include vw(height, 391);
        @include breakpoint(mobile) {
          @include vwMobile(height, 224);
        }
      }
      .box-grid-image-small {
        width: 100%;
        @include vw(height, 391);
        @include breakpoint(mobile) {
          @include vwMobile(height, 224);
        }
      }
    }
  }
  .box-container-three {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include vw(gap, 60);
    @include breakpoint(mobile) {
      grid-template-columns: 1fr;
      @include vwMobile(gap, 40);
    }
    .box-container {
      width: auto;
    }
  }
  .box-container-two-equals {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include vw(gap, 31);
    @include vw(padding-left, 31);
    @include vw(padding-right, 31);
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      grid-template-columns: 1fr;
      @include vwMobile(gap, 0);
    }
    .box-container {
      width: auto;
      @include vw(height, 912);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-clip: content-box;
      @include breakpoint(mobile) {
        @include vwMobile(height, 373);
      }
      .box-grid {
        position: absolute;
        left: 0;
        right: 0;
        @include vwMobile(bottom, 10);
        @include breakpoint(mobile) {
        }
        .box-image-small {
          width: 100%;
        }
        @include breakpoint(mobile) {
          right: 0;
        }
        .box-content {
          @include breakpoint(mobile) {
            align-items: center;
          }
        }
        .box-content-title {
          * {
            @include vw(font-size, 50);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 26);
            }
          }
        }
        .box-content-subtitle {
          @include breakpoint(mobile) {
            * {
              text-align: center;
            }
          }
        }
        button.box-content-button {
          @include vw(min-width, 300);
          @include vw(margin-top, 10);
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 10);
          }
        }
      }
    }
  }
  .carousel-container {
    @include vw(height, 720);
    @include vw(margin-bottom, 100);
    @include breakpoint(mobile) {
      @include vwMobile(height, 290);
      height: auto;
      width: 100%;
    }
    .react-multiple-carousel__arrow {
      z-index: 2;
      border: solid 1px $white;
      background: transparent;
      border-radius: 0;
      opacity: 0.95;
      @include vw(height, 60);
      @include vw(width, 60);
      &::before {
        opacity: 0.95;
      }
      @include breakpoint(mobile) {
        @include vwMobile(height, 30);
        @include vwMobile(width, 30);
      }
    }
    .react-multiple-carousel__arrow--right {
      position: absolute;
      @include vw(right, 181);
      @include breakpoint(mobile) {
        @include vwMobile(right, 20);
      }
    }
    .react-multiple-carousel__arrow--left {
      position: absolute;
      @include vw(left, 223);
      @include breakpoint(mobile) {
        @include vwMobile(left, 20);
      }
    }
    .image-container :active {
      cursor: grabbing;
    }
    .image-container {
      @include breakpoint(from-phablet) {
        @include vw(width, 1280);
        @include vw(height, 720);
        @include vw(margin-right, 60);
        position: relative;
        @include vw(left, 60);
        cursor: grab;
      }
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(height, 258);
      }
      .image {
        border-style: solid;
        border-color: $blueDark;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include vw(width, 1280);
        @include vw(height, 720);
        @include breakpoint(mobile) {
          @include vwMobile(height, 258);
          width: 100%;
        }
        @include vw(border-width, 1);
      }
    }
    .image-container-complete {
      width: 100%;
      left: 0;
      .image {
        width: 100%;
      }
    }
  }
  .carousel-container-complete {
    @include vw(margin-bottom, 61);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 60);
    }
    .box-container:active {
      cursor: grabbing;
    }
    .box-container {
      width: 100%;
      margin: 0;
      border: 0;
      background-color: $blueDark;
      cursor: grab;
      @include breakpoint(mobile) {
        background-color: transparent;
      }
      .box-grid {
        width: auto;
        @include vw(right, 183);
        @include breakpoint(mobile) {
          right: 0;
          @include vwMobile(padding-bottom, 80);
        }
        .box-content {
          align-items: flex-start;
        }
        .box-content-title {
          * {
            @include vw(font-size, 50);
            font-weight: 900;
            color: $white;
            text-transform: uppercase;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 26);
              line-height: 0.8;
            }
          }
        }
        .box-content-subtitle {
          @include vw(margin-top, 15);
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 20);
          }
        }
        .box-content-desc {
          * {
            text-align: left;
          }
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 15);
          }
        }
      }
    }
    .react-multiple-carousel__arrow--right {
      position: absolute;
      @include vw(right, 57);

      @include breakpoint(mobile) {
        @include vwMobile(right, 20);
        bottom: 0;
      }
    }
    .react-multiple-carousel__arrow--left {
      position: absolute;
      @include vw(left, 57);

      @include breakpoint(mobile) {
        @include vwMobile(right, 90);
        left: auto;
        bottom: 0;
      }
    }
    .react-multi-carousel-dot button {
      @include vw(height, 10);
      @include vw(width, 10);
      border: 0;
      background: transparent;
      border: 1px solid #7b7b7c;
      border-radius: 0;
      @include vw(margin-right, 20);
      @include breakpoint(mobile) {
        @include vwMobile(height, 10);
        @include vwMobile(width, 10);
        @include vwMobile(margin-right, 20);
      }
    }
    .react-multi-carousel-dot--active button {
      background: $white;
      border: 1px solid transparent;
      box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.62);
    }
    .react-multi-carousel-dot-list {
      @include vw(bottom, 40);
      @include breakpoint(mobile) {
        @include vw(left, -1397);
      }
    }
  }
  .statement {
    @include vw(margin-left, 61); //330
    @include vw(margin-top, 20);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 10);
      @include vwMobile(margin-left, 17);
    }
    div {
      &.line {
        position: relative;
        display: inline-block;
        border-color: $white;
        border-top-style: solid;
        @include vw(width, 40);
        @include vw(height, 1);
        @include vw(border-width, 1);
        @include vw(margin-right, 40);
        @include vw(bottom, 5);
        @include breakpoint(mobile) {
          @include vwMobile(margin-right, 20);
          @include vwMobile(bottom, 5);
        }
      }
    }
    p {
      display: inline-block;
      color: $white;
      @include vw(font-size, 20);

      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        line-height: 1.2;
      }
    }
  }
  .update-video-container {
    @include vw(padding-left, 155);
    @include vw(padding-right, 155);
    @include vw(margin-bottom, 60);
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      @include vwMobile(margin-bottom, 100);
    }
    .video-youtube,
    .video-front {
      @include vw(height, 800);
      @include breakpoint(mobile) {
        @include vwMobile(height, 267);
      }
    }
    .video-front {
      background-color: $blueDark;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      border: 1px solid $white02;
      background-clip: content-box;
      .corners {
        position: absolute;
        width: 100%;
        height: 100%;
        @include buildCorners(18, 0);
        @include breakpoint(mobile) {
          @include buildCornersMobile(18, 0);
        }
      }
      .video-icon-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        @include vw(height, 150);
        @include vw(width, 150);
        border: 4px solid $white;
        @include vw(border-width, 4);
        background-image: url('../../../assets/images/icons/Icon-Play-White.svg');
        background-position-x: 55%;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: 40%;
        cursor: pointer;
        @include breakpoint(mobile) {
          @include vwMobile(height, 75);
          @include vwMobile(width, 75);
        }
      }
      .video-text {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
      }
      .video-title {
        @include vw(bottom, 110);
        @include breakpoint(mobile) {
          @include vwMobile(bottom, -45);
        }
        * {
          @include vw(font-size, 50);
          color: $white;
          font-weight: 900;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 26);
          }
        }
      }
      .video-subtitle {
        @include vw(bottom, 80);
        @include breakpoint(mobile) {
          @include vwMobile(bottom, -65);
        }
        * {
          @include vw(font-size, 20);
          color: $yellowIg;
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          font-style: normal;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 12);
          }
        }
      }
    }
  }
}
.nav-scroll-down {
  @include vw(top, 80);
  @include breakpoint(mobile) {
    @include vwMobile(top, 65);
  }
}
.nav-scroll-up {
  @include vw(top, -50);
  @include breakpoint(mobile) {
    @include vwMobile(top, -50);
  }
}
