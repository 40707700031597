// class to be extended

$common-button-side: 65;
$common-brand-side: 40;

.flex-block {
  display: flex;
}

.flex-center-all {
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
}

.common-input-border {
  border-style: solid;
  border-color: $white;
  border-radius: 0;
}

.common-button {
  @include vw(width, 250);
  @include vw(height, $common-button-side);
  display: flex;
  padding: 0;
  border-style: inset;
  color: $white;
  cursor: pointer;

  font-weight: bold;
  text-align: center;
  @extend .common-input-border;
  @extend .button_text_base;
  @include vw(border-width, 1);

  // on click style
  &:focus {
    @include vw(outline-offset, -3);
    @include vw(outline-width, 2);
    outline-color: $white;
    outline-style: solid;
  }

  // text of the button
  span {
    @extend .flex-center-all;
    margin: auto;
  }

  // image block / icon
  div {
    @extend .common-input-border;
    @extend .flex-center-all;
    border-width: 0;
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    float: left;
    @include vw(width, $common-button-side);
    @include vw(border-right-width, 1);
  }
}

.brand-button-common {
  @extend .common-button;

  div {
    border-style: none;
  }

  span {
    text-transform: none;
    @extend .paragraph_base;
  }
}

.x-button {
  display: inline;
  border: 1px solid $gray;
  border-style: inset;
  background-color: transparent;
  background-image: url('../../assets/images/icons-buttons/Icon-Close.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  @include vw(background-size, 20);
  @include vw(width, 40);
  @include vw(height, 40);
}

.util-button {
  &-cancel {
    @extend .common-button;
    background-color: transparent;

    div {
      background-image: url('../../assets/images/icons-buttons/Icon-Close.svg');
      @include vw(background-size, 20);
    }
  }

  &-steam {
    @extend .brand-button-common;
    background-color: transparent;

    div {
      background-image: url('../../assets/images/icons-buttons/Icon-Steam.svg');
      @include vw(background-size, 26);
    }
  }

  &-facebook {
    @extend .brand-button-common;
    @include vw(height, $common-brand-side);
    background-color: #1778f2;

    div {
      background-image: url('../../assets/images/icons-buttons/Icon-Facebook.svg');
      @include vw(background-size, 22);

      @include breakpoint(mobile) {
        @include vwMobile(background-size, 20);
      }
    }
  }

  &-google {
    @extend .brand-button-common;
    @include vw(height, $common-brand-side);
    background-color: #ffffff;
    color: #000000;

    div {
      background-image: url('../../assets/images/icons-buttons/Icon-Google.svg');
      @include vw(background-size, 22);

      @include breakpoint(mobile) {
        @include vwMobile(background-size, 20);
      }
    }
  }

  util-button-text,
  &-continue {
    @extend .common-button;
    background-color: $button-continue;

    div {
      background-image: url('../../assets/images/icons-buttons/Icon-Continue.svg');
      @include vw(background-size, 20);
    }
  }

  &-text {
    @extend .common-button;
    @extend .flex-center-all;
    width: max-content;
    background-color: transparent;
    @include vw(padding-left, 40);
    @include vw(padding-right, 40);
  }
}

.util-input {
  @extend .common-input-border;
  display: flex;
  flex-direction: row;
  border-color: $gray-home;
  @include vw(border-width, 1);

  @include vw(width, 375);

  @include vw(height, 65);

  &:focus-within {
    border-color: $white;
  }

  &-text {
    width: 100%;
  }

  div {
    @include vw(width, 65);
    height: inherit;
    flex-shrink: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    content: '';

    // Exemple svg for the pic around the input : change that however you want in extended components
    &:first-of-type {
      background-image: url('../../assets/images/icons-buttons/Icon-Continue.svg');
    }

    &:last-of-type {
      background-image: url('../../assets/images/icons-buttons/Icon-Continue.svg');
    }
  }

  input {
    overflow: hidden;
    flex-grow: 1;
    border: none;
    background-color: transparent;
    color: $gray;
    @include vw(padding-right, 15);

    &:focus {
      outline: none;

      &::placeholder {
        color: $gray;
      }
    }

    @extend .paragraph_base;
  }
}

.checkboxcontainer {
  display: absolute;
  color: white;
  input[type='checkbox'] {
    box-sizing: border-box;
    border-style: solid;
    border-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: $blueDeep;
    cursor: pointer;
    outline: none;
    @include vw(width, 20);
    @include vw(height, 20);
    @include vwAll(margin, 3, 17, 0, 0);
    @include vw(border, 1);
  }

  input[type='checkbox']:checked {
    background-clip: content-box;
    background-color: $yellowIg;
    @include vwAll(padding, 7, 7, 7, 7);
  }
}
