div {
  &.buypage-specs-landingone {
    p {
      &.specsHeader {
        @include vw(margin-top, 20);
        @include vw(margin-bottom, 40);
        color: $white;
        font-weight: bold;
        @include vw(font-size, 35);

        @include breakpoint(mobile) {
          @include vwMobile(font-size, 25);
          @include vwMobile(margin-top, 93);
          @include vwMobile(margin-bottom, 40);
        }
      }
    }

    div {
      &.specContainer {
        display: grid;
        height: auto;
        grid-template-columns: 1fr 1fr;
        @include vw(grid-column-gap, 60);

        @include breakpoint(mobile) {
          grid-template-columns: 1fr;
          @include vwMobile(grid-column-gap, 40);
        }

        div {
          @include vw(padding-top, 40);
          @include vw(padding-right, 98);
          @include vw(padding-left, 40);
          @include vw(padding-bottom, 40);
          height: auto;
          border-width: 0.1px;
          border-style: solid;
          border-color: $gray;

          @include breakpoint(mobile) {
            @include vwAllMobile(padding, 40, 40, 40, 40);
          }

          p {
            &.childHeader {
              color: $yellowIg;
              text-transform: uppercase;
              @include vw(font-size, 22);
              @include vw(margin-bottom, 40);

              @include breakpoint(mobile) {
                @include vwMobile(font-size, 22);
              }
            }
          }
          &.minimal,
          &.recommended {
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-color: $almostBlack;
            div {
              &.gridContainer {
                display: grid;
                width: 100%;
                padding: 0;
                border-style: none;
                grid-template-columns: 8.5fr 91.5fr;
                @include vw(grid-column-gap, 16);
                @include vw(grid-row-gap, 20);

                @include breakpoint(mobile) {
                  @include vwMobile(grid-column-gap, 16);
                  @include vwMobile(grid-row-gap, 20);
                }
                p {
                  &.heading {
                    color: $white;
                    font-weight: bold;
                    @include vw(font-size, 18);
                    @include breakpoint(mobile) {
                      @include vwMobile(font-size, 16);
                      font-weight: bold;
                    }
                  }
                  &.description {
                    color: #a8a8a8;
                    @include vw(font-size, 18);
                    @include breakpoint(mobile) {
                      @include vwMobile(font-size, 15);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
