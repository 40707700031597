div {
  &.consentDetails {
    position: fixed;
    z-index: 10;

    @include vw(bottom, 20);
    @include vw(right, 20);

    @include vw(width, 536);

    border: solid 1px $white02;
    background-color: $almostBlack;

    @include vw(height, 820);
    overflow-y: auto;
    @include breakpoint(to-desktop-mid) {
      @include vw(height, 740);
    }
    @include breakpoint(mobile) {
      height: 100vh;
      @include vwMobile(padding-bottom, 110);
    }

    scrollbar-color: $gray-scroll $almostBlack;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 7.5px;
    }
    &::-webkit-scrollbar-track {
      background: $almostBlack;
    }
    &::-webkit-scrollbar-thumb {
      border-right: 1px solid $almostBlack;
      background: $gray-scroll;
    }
    @include breakpoint(mobile) {
      @include vwMobile(bottom, 0);
      @include vwMobile(top, 65);
      @include vwMobile(left, 0);
      @include vwMobile(right, 0);
      width: 100%;
    }
    .cookieParent {
      @include buildCorners(15, 10);
      height: auto;
      @include breakpoint(mobile) {
        @include vwMobile(margin, 10);
        @include vwMobile(border-width, 10);
        @include buildCorners(0, 0);
        @include vwMobileAll(padding, 20, 20, 20, 20);
      }
      @include vw(padding, 8);
      padding-bottom: 0;
      div {
        &.introduction {
          * {
            @include vw(font-size, 18);
            line-height: 1.44;
            @include vw(margin-bottom, 10);
            color: $gray-dark;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 18);
              @include vwMobile(margin-bottom, 10);
            }
          }
          a {
            @include vw(font-size, 18);
            text-decoration: underline;
            color: $yellowMild;

            @include breakpoint(mobile) {
              @include vwMobile(font-size, 18);
            }
          }
        }
      }
      .selection-group {
        display: flex;
        justify-content: flex-end;
        .selection {
          @include vw(font-size, 16);
          @include vw(margin-left, 20);
          @include vw(margin-bottom, 20);
          text-decoration: underline;
          cursor: pointer;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            @include vwMobile(margin-bottom, 25);
            @include vwMobile(margin-left, 20);
          }
        }
      }
      form {
        @include vw(margin-bottom, 50);
        @include vw(padding-bottom, 30);
      }
      .alternate {
        background-color: $blueDark;
      }
      .option {
        display: flex;
        align-items: center;
        @include vwAll(padding, 12, 20, 20, 20);
        @include breakpoint(mobile) {
          @include vwMobileAll(padding, 12, 20, 20, 20);
        }
        .content {
          h5 {
            @include vw(font-size, 18);
            line-height: 2.22;
            color: $white;
            text-transform: uppercase;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 18);
            }
          }
          p,
          li {
            @include vw(font-size, 16);
            line-height: normal;
            color: $gray-dark;
            @include vw(width, 356);
            @include vw(margin-bottom, 5);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              @include vwMobile(width, 310);
            }
          }
          ul {
            margin: 0;
            list-style-type: none;
            padding-left: 0;
            li::before {
              content: '+';
              @include vw(margin-right, 10);
            }
          }

          a {
            cursor: pointer;
            text-decoration: underline;
            line-height: normal;
            @include vw(font-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
            &.down,
            &.up {
              line-height: 2;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
              }
              &::after {
                @include vw(margin-left, 10);
                @include breakpoint(mobile) {
                  @include vwMobile(margin-left, 10);
                }
              }
            }
            &.down::after {
              content: url('../../../../assets/images/icons/arrow-yellow-down.svg');
            }
            &.up::after {
              content: url('../../../../assets/images/icons/arrow-yellow-up.svg');
            }
          }

          .details {
            @include vw(font-size, 16);
            @include vw(width, 356);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              @include vwMobile(width, 310);
            }
          }
          .long-content {
            @include vw(width, 410);
            @include breakpoint(mobile) {
              @include vwMobile(width, 350);
            }
          }
        }
        .checkbox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.03);
          @include vwAll(padding, 20, 40, 30, 40);
          @include breakpoint(mobile) {
            @include vwMobileAll(padding, 20, 30, 30, 20);
            @include vwMobile(margin-top, 10);
            @include vwMobile(margin-bottom, 40);
          }
        }
        .checkbox-transparent {
          @extend .checkbox;
          background-color: transparent;
        }
        .checkboxcontainer {
          color: $white;
          @include vw(margin-top, 15);
          @include vw(margin-left, 39);
          @include breakpoint(mobile) {
            @include vwMobile(margin-left, 45);
          }
          input[type='checkbox'] {
            box-sizing: border-box;
            border-style: solid;
            border-color: $white;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            background-color: $blueDeep;
            cursor: pointer;
            outline: none;
            @include vw(width, 20);
            @include vw(height, 20);
            @include vwAll(margin, 0, 0, 0, 0);
            @include vw(border-width, 1);
            @include breakpoint(mobile) {
              @include vwMobile(margin-top, 15);
              @include vwMobile(width, 20);
              @include vwMobile(height, 20);
            }
          }
          input[type='checkbox']:checked {
            background-clip: content-box;
            background-color: $yellowIg;
            @include vwAll(padding, 5, 5, 5, 5);
            @include breakpoint(mobile) {
              @include vwMobileAll(padding, 5, 5, 5, 5);
            }
          }
        }
      }
      .gradient {
        position: fixed;
        @include vw(width, 456);
        @include vw(height, 127);
        @include vw(bottom, 21);
        background-image: linear-gradient(to bottom, rgba(12, 19, 21, 0), $almostBlack);
        z-index: 10;
        @include breakpoint(mobile) {
          @include vwMobile(width, 456);
          @include vwMobile(height, 100);
          @include vwMobile(bottom, 0);
        }
      }
      button {
        cursor: pointer;
        font-weight: bold;
        position: fixed;
        @include vw(bottom, 60);
        @include vw(right, 178);
        margin: auto;
        @include vw(margin-top, 20);

        @include vwDimensions(218, 50);
        @include vw(font-size, 16);
        z-index: 11;
        span {
          margin-right: 0;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          @include vwDimensionsMobile(218, 50);
          right: 0;
          left: 0;
        }
        &:hover {
          background-color: $green;
        }
        &:hover span {
          color: $black;
        }
      }
    }
  }
}
