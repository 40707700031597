div {
  &.homePageMiddleLandingOne {
    div.popup-content {
      @include vw(width, 1608);
      width: 80% !important;
      @include vw(height, 700);
      padding: 0 !important;
      border: 0 !important;
      background-color: $almostBlack !important;
    }
    div.popUpContainer {
      position: relative;
      width: 100%;
      height: 100%;
      .exitIcon {
        position: absolute;
        border-width: 0.1px;
        border-style: solid;
        border-color: $gray;
        background-clip: content-box;
        background-image: url('../../../../../assets/images/icons/no-border/Icon-Close.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        @include vw(top, -25);
        @include vw(right, -55);
        @include vw(width, 40);
        @include vw(height, 40);
        @include vw(padding, 5);
        // @include vw(margin-left, 1820);

        @include breakpoint(mobile) {
          @include vwMobile(width, 40);
          @include vwMobile(height, 40);
          @include vwMobile(margin-left, 437);
        }
      }
      iframe,
      video {
        position: relative;
        z-index: 20;
        width: 100%;
        // @include vw(width, 1608);
        // @include vw(height, 700);
        height: 100%;
        object-fit: cover;
      }
    }

    .partOne.part-one-bottom {
      bottom: 0;
      height: auto;
      padding: 0;
      margin: 0;
      background: none;
      @include vw(margin-top, 120);
      &:after {
        display: none;
      }
    }

    div {
      &.spacer {
        width: 100%;
        @include vw(height, 240);
        content: '';
        @include breakpoint(mobile) {
          @include vwMobile(height, 65);
        }
      }
      .video-link {
        position: absolute;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        @include vw(bottom, 120);
        .theme-button {
          @include vw(width, 260);
          @include vw(height, 65);
          z-index: 9;
          div.icon {
            @include vw(height, 20);
            @include vw(width, 20);
            background-image: url('../../../../../assets/images/icons/Icon-Play.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include vw(margin-right, 15);
          }
        }
      }
      &.partOne {
        position: relative;
        width: 100%;
        @include breakpoint(from-phablet) {
          @include vw(height, 1192);
        }

        @include breakpoint(from-phablet) {
          background-image: url('../../../../../assets/images/homepage-actual/spacewalker.jpg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }

        div {
          &.scrollSection {
            .react-multi-carousel-item {
              @include vw(height, 310);
            }

            @include breakpoint(mobile) {
              display: flex;
            }
            position: relative;
            // @include vw(bottom, 103);

            @include breakpoint(mobile) {
              flex-wrap: wrap;
              justify-content: center;

              @include vw(bottom, 0);
              @include vw(margin-bottom, 50);
              @include vw(padding-left, 80);
              @include vw(padding-right, 80);
            }
            .react-multiple-carousel__arrow {
              z-index: 9;
              border: solid 0.1px $white;
              background: transparent;
              border-radius: 0;
              opacity: 0.8;
              @include vw(min-height, 40);
              @include vw(min-width, 40);
              @include vw(top, 253);
            }

            .react-multiple-carousel__arrow--right {
              @include vw(left, 125);
            }
            .react-multiple-carousel__arrow--left {
              @include vw(left, 52);
            }
            .contentbox {
              @include vw(width, 530);
              @include vw(height, 233);
              // @include vwAll(margin, 0, 30, 0, 30);
              position: relative;

              flex: 0 0 auto;
              border: solid 0.1px rgba(255, 255, 255, 0.2);
              margin: 0 auto;
              background-color: $almostBlack;
              // @include vw(right, 410);

              @include breakpoint(mobile) {
                width: 100%;
                @include vwMobile(min-height, 203);
                @include vwMobile(margin-bottom, 20);
              }

              .squarebox {
                @include vw(padding, 0);
                position: relative;
                width: 100%;
                height: 100%;
                cursor: pointer;
                transition: $corners-transition;
                &:hover {
                  @include vw(padding, 15);
                  transition: $corners-transition;
                }
                .realcontent {
                  width: 100%;
                  height: 100%;
                  margin: 0;

                  @include buildCorners(18, 0);
                  @include breakpoint(mobile) {
                    @include buildCorners(45, 0);
                  }
                }
                p {
                  position: absolute;
                  right: 0;
                  left: 0;
                  text-align: center;
                  text-transform: uppercase;
                  &.title {
                    @include vw(top, 35);
                    @include vw(font-size, 26);
                    color: $yellowIg;

                    @include breakpoint(mobile) {
                      @include vwMobile(top, 35);
                      @include vwMobile(font-size, 22);
                    }
                  }

                  &.description {
                    @include vw(width, 415);
                    @include vw(top, 87);
                    margin-right: auto;
                    margin-left: auto;
                    color: $white;
                    @include vw(margin-bottom, 45);
                    @include vw(font-size, 21);
                    @include vw(line-height, 40);
                    @include breakpoint(mobile) {
                      @include vwMobile(top, 87);
                      width: 100%;
                      @include vwMobile(font-size, 18);
                      @include vwMobile(line-height, 26);
                    }
                  }
                }
              }
            }
          }

          &.square {
            display: inline-block;
            div {
              &.squareLineOne {
                @include vw(width, 156);
                @include vw(height, 124);
                border-width: 0.1px;
                border-color: $white;
                border-right-style: solid;
                border-bottom-style: solid;
                content: '';
              }
              &.squareLineTwo {
                @include vw(width, 156);
                @include vw(height, 124);
                border-width: 0.1px;
                border-color: $white;
                border-right-style: solid;
                content: '';
              }
            }
            @include breakpoint(mobile) {
              display: none;
            }
          }

          &.content {
            position: relative;
            display: inline-block;
            @include vw(margin-left, 156);
            @include vw(bottom, 50);
            @include vw(margin-top, 600);
            @include breakpoint(mobile) {
              display: none;
            }

            div.title {
              @include vw(margin-bottom, 23);
              h2 {
                color: $yellowIg;
                font-weight: bold;
                text-transform: uppercase;
                @include vw(font-size, 50);
              }
            }

            div.description {
              @include vw(width, 600);
              p {
                display: block;
                color: $white;
                line-height: 1.4;
                text-align: justify;
                @include vw(font-size, 24);
              }
            }
          }

          &.actionButton {
            @include vw(margin-top, 234);
            text-align: center;
            button {
              text-transform: uppercase;
              @include vw(width, 260);
              @include vw(height, 65);
            }
          }
          &.identifier {
            position: absolute;

            transform: rotate(90deg);
            @include vw(top, 573);
            @include vw(right, -250);
            h3 {
              color: $white;
              font-weight: normal;
              line-height: 1.4;
              opacity: 1;
              @include vw(font-size, 100);
            }
          }
          &.identifier-fr {
            // @include vw(top, 590);
            @include vw(right, -220);
          }
          &.identifier-de {
            // @include vw(top, 590);
            @include vw(right, -340);
          }
        }
        &:after {
          position: absolute;
          z-index: 1;

          border-left-width: 1px;
          border-left-style: solid;
          border-left-color: $gray-oblique;
          content: ' ';
          transform: skew(-36deg, 0deg);
          @include vw(top, 130);
          @include vw(left, 980);
          @include vw(height, 1062);
          @include breakpoint(mobile) {
            display: none;
          }
        }
      }

      &.partMobileHome {
        .imageData {
          width: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          @include vwMobile(height, 233);
        }
        div {
          &.imageOne {
            @extend .imageData;
            background-image: url('../../../../../assets/images/homepage-actual/spacewalker.jpg');
          }

          &.imageTwo {
            @extend .imageData;
            background-image: url('../../../../../assets/images/homepage-actual/homepage-build.jpg');
          }
          &.imageThree {
            @extend .imageData;
            background-image: url('../../../../../assets/images/homepage-actual/homepage-economy.jpg');
          }
          &.imageFour {
            @extend .imageData;
            background-image: url('../../../../../assets/images/homepage-actual/homepage-pvp.jpg');
          }
          &.imageFive {
            @extend .imageData;
            background-image: url('../../../../../assets/images/homepage-actual/homepage-character.jpg');
          }

          &.content {
            @include vwMobile(min-height, 238);
            width: 100%;
            @include vwMobileAll(padding, 40, 20, 40, 20);

            p {
              &.title {
                color: $yellowIg;
                font-weight: bold;
                text-transform: uppercase;
                @include vwMobile(font-size, 22);
                @include vwMobile(margin-bottom, 20);
                @include vwMobileAll(line-height, 34);
              }

              &.description {
                color: $white;
                @include vwMobile(font-size, 16);
                @include vwMobile(line-height, 32);
              }
            }
          }
        }
      }

      &.partTwo {
        position: relative;

        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        align-items: center;
        background-image: url('../../../../../assets/images/homepage-actual/homepage-build.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include vw(height, 1080);

        div {
          &.square {
            // display: inline-block;
            position: relative;
            @include vw(top, 33);
            @include vw(height, 249);

            div {
              // margin-left: auto;
              &.squareLineOne {
                @include vw(width, 156);
                @include vw(height, 124);
                border-width: 0.1px;
                border-color: $white;
                border-bottom-style: solid;
                border-left-style: solid;
                content: '';
              }
              &.squareLineTwo {
                @include vw(width, 156);
                @include vw(height, 124);
                border-width: 0.1px;
                border-color: $white;
                border-left-style: solid;
                content: '';
              }
            }

            @include breakpoint(mobile) {
              display: none;
            }
          }

          &.content {
            @include vw(margin-left, 1164);
            position: relative;
            //  @include vw(margin-right, 150);
            display: inline-block;
            @include vw(bottom, 110);
            div {
              &.title {
                h2 {
                  @include vw(margin-bottom, 23);
                  color: $yellowIg;
                  font-weight: bold;
                  text-transform: uppercase;
                  @include vw(font-size, 50);
                }
              }

              &.description {
                @include vw(width, 600);
                p {
                  display: block;

                  color: $white;
                  @include vw(font-size, 24);
                  @include vw(line-height, 34);
                }
              }
            }
          }
          &.identifier {
            position: absolute;

            transform: rotate(270deg);
            @include vw(top, 490);
            @include vw(left, -25);
            h3 {
              color: $white;
              font-weight: normal;
              line-height: 1.4;
              opacity: 1;
              @include vw(font-size, 100);
            }
            @include breakpoint(mobile) {
              display: none;
            }
          }
          &.identifier-fr {
            // @include vw(top, 590);
            @include vw(left, -200);
          }
          &.identifier-de {
            // @include vw(top, 590);
            @include vw(left, 0);
          }
        }
        &:after {
          position: absolute;
          z-index: 9;
          top: 0;
          height: 100%;

          border-left-width: 1px;
          border-left-style: solid;
          border-left-color: $gray-oblique;
          content: ' ';
          transform: skew(-36deg, 0deg);
          @include vw(left, 920);
          @include breakpoint(mobile) {
            display: none;
          }
        }
      }

      &.partThree {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        background-image: url('../../../../../assets/images/homepage-actual/homepage-economy.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include vw(height, 1080);
        div {
          &.square {
            position: relative;
            display: inline-block;
            @include vw(top, 33);
            @include vw(margin-top, 428);
            div {
              &.squareLineOne {
                @include vw(width, 156);
                @include vw(height, 124);
                border-width: 0.1px;
                border-color: $white;
                border-right-style: solid;
                border-bottom-style: solid;
                content: '';
              }
              &.squareLineTwo {
                @include vw(width, 156);
                @include vw(height, 124);
                border-width: 0.1px;
                border-color: $white;
                border-right-style: solid;
                content: '';
              }
            }
            @include breakpoint(mobile) {
              display: none;
            }
          }

          &.content {
            @include vw(margin-left, 156);
            position: relative;
            display: inline-block;

            div.title {
              @include vw(margin-bottom, 23);
              h2 {
                color: $yellowIg;
                font-weight: bold;
                text-transform: uppercase;
                @include vw(font-size, 50);
              }
            }

            div.description {
              @include vw(width, 667);
              p {
                display: block;
                color: $white;
                @include vw(font-size, 24);
                @include vw(line-height, 34);
              }
            }
          }
          &.identifier {
            position: absolute;

            transform: rotate(90deg);
            @include vw(top, 470);
            @include vw(right, -180);
            h3 {
              color: $white;
              font-weight: normal;
              line-height: 1.4;
              opacity: 1;
              @include vw(font-size, 100);
            }
          }
          &.identifier-fr {
            // @include vw(top, 590);
            @include vw(right, -270);
          }
          &.identifier-de {
            // @include vw(top, 590);
            @include vw(right, -320);
          }
        }
        &:after {
          position: absolute;
          z-index: 9;
          top: 0;
          height: 100%;

          border-left-width: 1px;
          border-left-style: solid;
          border-left-color: $gray-oblique;
          content: ' ';
          transform: skew(-36deg, 0deg);
          @include vw(left, 920);
          @include breakpoint(mobile) {
            display: none;
          }
        }
      }

      &.partFour {
        position: relative;
        background-image: url('../../../../../assets/images/homepage-actual/homepage-pvp.jpg');
        div {
          &.identifier {
            position: absolute;
            @include vw(top, 474);
            @include vw(left, -236);
          }
          &.identifier-fr {
            // @include vw(top, 590);
            @include vw(top, 443);
            @include vw(left, -300);
          }
          &.identifier-de {
            // @include vw(top, 590);
            @include vw(left, -360);
          }
        }
      }
      &.partFive {
        position: relative;
        // z-index: 4;
        display: flex;
        align-items: center;
        background-image: url('../../../../../assets/images/homepage-actual/homepage-character.jpg');
        div {
          &.identifier {
            position: absolute;
            @include vw(top, 470);
            @include vw(right, -280);
          }
          &.identifier-fr {
            // @include vw(top, 590);
            @include vw(top, 470);
            @include vw(right, -200);
          }
          &.identifier-de {
            @include vw(top, 470);
            @include vw(right, -280);
          }
        }
      }
    }
  }
}
