.simplesubmenucontainer-1 {
  @extend .submenutemplate;
  // @include vw(left, 288);
  @include vw(left, 0);
  display: flex;
  z-index: 10;
  .menu-update-image:hover {
    background-size: 105%;
  }
  .menu-update-image {
    @include vw(height, 350);
    height: 100%;
    @include vw(width, 816);
    // background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    transition: background-size 0.6s linear;
    transition-delay: 0.12;
    background-size: 100%;
    flex-grow: 1.5;
    animation-name: parallaximg;
    animation-timing-function: cubic-bezier (0.65, 0, 0.35, 1);
    animation-duration: 0.6s;
    animation-delay: 0.12s;
    animation-fill-mode: forwards;
    right: 0;
    top: 0;
    bottom: 0;
    clip-path: polygon(28% 0, 100% 0%, 100% 100%, 0% 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .update-logo {
      @include vw(width, 219);
      @include vw(height, 162);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      // @include vw(margin-left, 395);
      // @include vw(margin-top, 73);
    }
    .update-button:hover {
      border-color: $yellowIg;
      div {
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
      }
      span {
        color: $yellowIg;
      }
    }
    a.update-button-container {
      @include vw(margin-top, 20);
      height: auto;
    }
    .update-button {
      // @include vw(margin-left, 395);
      @include vw(width, 170);
      @include vw(height, 50);
      background-color: $almostBlack;
      border: 1px solid $white02;
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      div {
        @include vw(width, 8);
        @include vw(height, 10);
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include vw(margin-right, 15);
      }
      span {
        color: $white;
        @include vw(font-size, 10);
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        text-transform: uppercase;
      }
    }
  }
  @keyframes parallaximg {
    from {
      background-position: 100% 0%;
    }
    to {
      background-position: 100% 100%;
    }
  }
  .simplesubmenu {
    @extend .simplesubmenutemplate;
    @extend .bigsubmenutemplate;
    display: flex;
    // @include vw(padding-left, 10);
    //z-index: 1;
    @include vw(width, 1545);
    @include vw(height, 350);
    @include vw(padding-left, 50);
    @include vw(padding-top, 20);
    .submenublock {
      @include vw(width, 200);
      @include vw(min-width, 200);
      @include vw(margin-right, 50);
      animation-duration: 0.5s;
      animation-name: menublock;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      animation-delay: 0;
      opacity: 0;
      .submenuheader {
        @extend .submenuheadertemplate;
      }
      .submenucontent {
        @extend .submenucontenttemplate;
        a {
          display: inline-block;
          width: 100%;
          text-align: left;
        }
      }
    }
    .submenublock:nth-child(1) {
      animation-delay: 0.42s;
    }
    .submenublock:nth-child(2) {
      animation-delay: 0.52s;
    }
    .submenublock:nth-child(3) {
      animation-delay: 0.62s;
    }
    @keyframes menublock {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

.simplesubmenucontainer-2 {
  z-index: 10;
  @extend .submenutemplate;
  // @include vw(left, 764);
  @include vw(left, 0);
  .simplesubmenu {
    @extend .simplesubmenutemplate;
    // @include vw(padding-left, 10);
    @include vw(min-height, 218);
    .submenublock {
      .submenuheader {
        @extend .submenuheadertemplate;
      }

      .submenucontent {
        @extend .submenucontenttemplate;
        a {
          display: inline-block;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}

.simplesubmenucontainer-3 {
  z-index: 10;
  @extend .submenutemplate;

  @include vw(left, 0);
  .simplesubmenu {
    @extend .simplesubmenutemplate;
    //  @include vw(padding-left, 10);

    .submenublock {
      .submenuheader {
        @extend .submenuheadertemplate;
      }

      .submenucontent {
        @extend .submenucontenttemplate;
        a {
          display: inline-block;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}
