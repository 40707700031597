@import './account-upload/account-upload.styles';
@import './account-upload/upload.modal.styles';
@import './account-recruit/account-recruit.scss';
@import './account-products/account-products.scss';
@import './account-creation/account-creation.component.scss';
@import './account-kickstarter/account-kickstarter.styles';
@import './account-management/account-management.component.scss';
@import './account-resend-confirmation/account.resend-confirmation.styles';
@import './account-login-page/account.login.page.styles';
@import './account-pts/account-pts.scss';
@import './account-email-preferences/account-email-preferences.style.scss';
@import './account-forgot-psw/forgot-psw.styles.scss';
@import './account-security/account-security.styles.scss';
@import './account-linked/account-linked.styles.scss';
@import './account-dacs/account-dacs.styles.scss';
@import './account-payments/account-payments.styles.scss';
@import './account-download/account-download.scss';

.notice_expiration {
  position: absolute;
  z-index: 8;
  display: flex;
  align-items: center;
  background-color: $white;
  @include vw(top, 274);
  @include vw(left, 470);
  @include vw(height, 65);
  @include breakpoint(mobile) {
    position: relative;
    @include vwMobile(height, 65);
    @include vwMobile(top, 0);
    @include vwMobile(left, 0);
  }
  p,
  a {
    color: $black;
    font-weight: bold;
    line-height: 1.4;
    text-transform: uppercase;
    @include vw(font-size, 20);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 14);
    }
  }
  a {
    color: $yellowMild;
    text-decoration: underline;
  }
  .notice-icon-close,
  .notice-icon-attention {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .notice-icon-close {
    @include vw(height, 20);
    @include vw(width, 20);
    background-image: url('../../../assets/images/icons/Icon-Close-Black.svg');
    cursor: pointer;
    @include vw(margin-left, 83);
    @include vw(margin-right, 22);
    @include breakpoint(mobile) {
      @include vwMobile(height, 20);
      @include vwMobile(width, 20);
      @include vwMobile(margin-left, 23);
      @include vwMobile(margin-right, 22);
    }
  }
  .notice-icon-attention {
    background-image: url('../../../assets/images/icons/Icon-Warning.svg');
    @include vw(height, 30);
    @include vw(width, 30);
    @include vw(margin-left, 18);
    @include vw(margin-right, 77);
    @include breakpoint(mobile) {
      @include vwMobile(height, 24);
      @include vwMobile(width, 24);
      @include vwMobile(margin-left, 20);
      @include vwMobile(margin-right, 20);
    }
  }
}
