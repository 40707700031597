.newsletter-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include vw(width, 462);
  h2 {
    @include vw(font-size, 35);
    line-height: 1.4;
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 25);
      @include vwMobile(margin-bottom, 20);
    }
  }
  p {
    @include vw(margin-bottom, 60);
    text-align: center;
    @include breakpoint(mobile) {
      @include vwMobile(width, 318);
      @include vwMobile(font-size, 18);
      @include vwMobile(margin-bottom, 40);
      line-height: 1.44;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #999999;
    background-color: $almostBlack;
    color: $white;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    @include vw(width, 200);
    @include vw(height, 65);
    @include breakpoint(mobile) {
      @include vwMobile(width, 200);
      @include vwMobile(height, 65);
    }

    .wrapper-signup {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      i.icon-email {
        background-image: url('../../../../assets/images/icons/no-border/Icon-Email.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(width, 20);
        @include vw(height, 20);
        @include vw(margin-right, 10);
        @include breakpoint(mobile) {
          @include vwMobile(width, 20);
          @include vwMobile(height, 20);
          @include vwMobile(margin-right, 10);
        }
      }
      span {
        @include vw(font-size, 16);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 15);
          line-height: 1.44;
        }
      }
    }
  }
}
