div {
  &.buypage-specs {
    @include vw(margin-bottom, 200);
    .specsHeader-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include vw(margin-top, 90);
      @include vw(margin-bottom, 40);
      @include breakpoint(mobile) {
        flex-direction: column;
      }
      .specsHeader {
        color: $white;
        font-weight: bold;
        @include vw(font-size, 35);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 25);
          @include vwMobile(margin-bottom, 20);
          @include vwMobile(margin-top, 20);
        }
      }
      .nvidia-spec {
        @include vw(font-size, 16);
        line-height: 1.38;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          @include vwMobile(margin-bottom, 20);
          text-align: center;
        }
        span {
          @include vw(font-size, 16);
          line-height: 1.38;
          color: $yellowIg;
          text-decoration: underline;
          cursor: pointer;
          @include vw(margin-left, 5);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            @include vwMobile(margin-left, 5);
          }
        }
      }
    }
    div {
      &.specContainer {
        display: grid;
        height: auto;
        grid-template-columns: 1fr 1fr;
        @include vw(grid-column-gap, 60);

        @include breakpoint(mobile) {
          grid-template-columns: 1fr;
          @include vwMobile(grid-column-gap, 40);
        }

        div {
          @include vw(padding-top, 40);
          @include vw(padding-right, 98);
          @include vw(padding-left, 40);
          @include vw(padding-bottom, 40);
          height: auto;
          border-width: 0.1px;
          border-style: solid;
          border-color: $gray;

          @include breakpoint(mobile) {
            @include vwAllMobile(padding, 40, 40, 40, 40);
          }

          p {
            &.childHeader {
              color: $yellowIg;
              text-transform: uppercase;
              @include vw(font-size, 22);
              @include vw(margin-bottom, 40);

              @include breakpoint(mobile) {
                @include vwMobile(font-size, 22);
              }
            }
          }
          &.minimal,
          &.recommended {
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-color: $almostBlack;
            div {
              &.gridContainer {
                display: grid;
                width: 100%;
                padding: 0;
                border-style: none;
                grid-template-columns: 8.5fr 91.5fr;
                @include vw(grid-column-gap, 16);
                @include vw(grid-row-gap, 20);

                @include breakpoint(mobile) {
                  @include vwMobile(grid-column-gap, 16);
                  @include vwMobile(grid-row-gap, 20);
                }
                p {
                  &.heading {
                    color: $white;
                    font-weight: bold;
                    @include vw(font-size, 18);
                    @include breakpoint(mobile) {
                      @include vwMobile(font-size, 16);
                      font-weight: bold;
                    }
                  }
                  &.description {
                    color: #a8a8a8;
                    @include vw(font-size, 18);
                    @include breakpoint(mobile) {
                      @include vwMobile(font-size, 15);
                    }
                  }
                  &.windows {
                    @include vw(margin-top, -10);
                    @include breakpoint(mobile) {
                      @include vwMobile(margin-top, -10);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.popup-desc {
  cursor: pointer;
  @include vw(font-size, 18);
  color: $yellowMild;
  text-decoration: underline;
  @include breakpoint(mobile) {
    @include vwMobile(font-size, 16);
  }
}
.popup-content-spec {
  * {
    @include vw(font-size, 16);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 16);
    }
  }
}
