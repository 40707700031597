$height-buypage-info: 700;
$height-buypage-info-mobile: 500;

.buypage-block {
  //@include vw(margin-top, 80);
  @include vw(margin-bottom, 80);
  @include vw(padding-left, 160);
  @include columns(1);
  @include container-align(center);
  overflow: hidden;
  height: auto;
  background-color: $blueDark;
  text-align: left;
  @include breakpoint(mobile) {
    @include vwMobile(padding-right, 20);
    @include vwMobile(padding-left, 20);

    height: auto;
  }
  .content-container {
    @include vw(height, 700);
    display: inline-block;
    width: 100%;
    display: flex;
    position: relative;
    @include breakpoint(mobile) {
      height: auto;
    }
    .content-block {
      position: relative;
      z-index: 2;
      display: inline-block;
      @include vw(width, 800);

      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(padding-top, 240);
        @include vwMobile(padding-bottom, 40);
      }
      h3 {
        @include vw(margin-top, 163);
        @include vw(margin-bottom, 30);
        position: relative;
        z-index: 2;
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 35);
          @include vwMobile(margin-bottom, 15);
          width: 100%;
        }
      }
      p {
        position: relative;
        z-index: 2;
        color: $gray;
        @include vw(line-height, 40);
        @include vw(font-size, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 2;
          width: 100%;
          margin-bottom: 0;
        }
      }
    }
    .content-image {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      display: inline-block;

      background-image: linear-gradient(
          to right,
          #162024 5%,
          rgba(22, 32, 36, 0.84) 16%,
          rgba(22, 32, 36, 0.67) 28%,
          rgba(22, 32, 36, 0) 90%
        ),
        url('../../../../assets/images/buypage/why.png');
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;

      @include vw(border-left-width, 2);

      @include vw(width, 1180);
      @include vw(height, 700);
      @include breakpoint(mobile) {
        z-index: 2;
        top: 0px;
        bottom: 0px;
        width: 100%;
        background-image: url('../../../../assets/images/buypage/why.png');
        @include vwMobile(height, 217);
        @include vwMobile(margin-top, 20);
      }
    }
  }
}
