@mixin image-props($position, $repeat, $size, $width: null, $height: null) {
  @if $height != null {
    @include vw(height, $height);
  }
  @if $width != null {
    @include vw(width, $width);
  }
  background-position: $position;
  background-repeat: $repeat;
  background-size: $size;
}
.export-blueprint-container {
  height: auto;
  @include vw(padding-left, 156);
  @include vw(padding-right, 156);
  background-image: linear-gradient(rgba(12, 19, 21, 0) 58%, rgba(12, 19, 21, 0.75) 70%, rgb(12, 19, 21) 85%),
    url('../../../assets/images/recruit-a-friend/Image\ 48.png');
  background-position-x: center;
  @include vw(background-position-y, -150);
  background-repeat: no-repeat;
  background-size: cover;
  .fw-900 {
    font-weight: 900;
  }
  .ff-ext {
    font-family: eurostile-extended, sans-serif;
  }
  .export-blueprint__intro {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $white02;
    @include vw(padding-bottom, 10);
    .export-blueprint__intro-title {
      * {
        @include vw(font-size, 80);
        @extend .fw-900;
        text-transform: uppercase;
        margin: 0;
      }
    }
    .export-blueprint__intro-desc {
      * {
        @include vw(font-size, 18);
        @extend .ff-ext, .fw-900;
        text-transform: uppercase;
      }
    }
  }
  .export-blueprint__grid-container {
    display: flex;
    justify-content: space-between;
    @include vw(margin-top, 31);
    .export-blueprint__corners {
      height: 100%;
      width: 100%;
      @include vw(padding-top, 0);
      @include vw(padding-left, 15);
      @include buildCorners(20, 0);
      @include breakpoint(mobile) {
        @include buildCornersMobile(20, 0);
      }
      .section-padding {
        @include vwAll(padding, 7, 13, 13, 13);
      }
    }
    .export-blueprint__grid {
      @include vw(width, 774);
      @include vw(height, 850);
      background-color: $almostBlack;
      border: 1px solid $white02;
      .export-blueprint__title {
        @include vw(font-size, 50);
        text-transform: uppercase;
        color: #eba60b;
        @extend .fw-900;
      }
      .export-blueprint__desc {
        * {
          @include vw(font-size, 22);
          color: #dbdbdb;
          @extend .fw-900;
          line-height: 1.36;
        }
      }
      .export-blueprint__desc--right {
        * {
          font-weight: normal;
        }
      }
      .export-blueprint__items {
        @include vw(margin-top, 20);
        @include vw(height, 650);
        @include vw(padding-right, 20);
        overflow-y: scroll;
        scrollbar-color: #7f7f7f $almostBlack;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 7.5px;
        }
        &::-webkit-scrollbar-track {
          background: $almostBlack;
        }
        &::-webkit-scrollbar-thumb {
          border-right: 1px solid $almostBlack;
          background: #7f7f7f;
        }
        @include breakpoint(mobile) {
          overflow-y: hidden;
        }
      }
      .export-blueprint__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $white02;
        @include vw(padding-bottom, 20);
        @include vw(padding-top, 20);

        .export-blueprint__item-image-info {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .export-blueprint__item-image {
          @include vw(width, 129);
          @include vw(height, 73);
          background-color: $black;
          background-image: url('../../../assets/images/Ship-Placeholder.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .export-blueprint__item-image--loading {
          background-image: url('../../../assets/images/icons/Icon\ -\ Loading.svg');
          background-size: 30%;
        }
        .export-blueprint__item-image--time {
          background-image: url('../../../assets/images/icons/Icon\ -\ Time.svg');
          background-size: 30%;
        }
        .export-blueprint__item-image--attention {
          background-image: url('../../../assets/images/icons/Icon\ -\ Attention.svg');
          background-size: 30%;
        }
        .export-blueprint__item-info {
          @include vw(margin-left, 20);
          .export-blueprint__item-name {
            @include vw(font-size, 22);
            color: #dbdbdb;
            font-weight: bold;
            @include vw(margin-bottom, 5);
          }
          .export-blueprint__item-desc {
            @include vw(font-size, 18);
            color: #dbdbdb;
            font-weight: normal;
            line-height: 1;
            @include vw(margin-bottom, 5);
          }
        }
        .export-blueprint__item-btn-group {
          display: flex;
        }
        .export-blueprint__item-btn {
          @include vw(width, 200);
          @include vw(height, 50);
          background-color: transparent;
          border: 1px solid $white02;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .export-blueprint__btn-label {
            color: $white;
            @include vw(font-size, 16);
            font-weight: bold;
            text-transform: uppercase;
          }
          .export-blueprint__btn-state {
            @include vw(font-size, 16);
            @include vw(margin-left, 10);
            color: $green;
            text-transform: uppercase;
            font-weight: 900;
          }
          .export-blueprint__btn-state--off {
            color: $red;
          }
          &:hover {
            border-color: $white;
            // filter: $white-to-yellow-filter;
            .export-blueprint__btn-label {
              color: $white;
            }
          }
        }
        .export-blueprint__item-btn--close {
          @include vw(width, 50);
          @include vw(margin-left, 10);
          .export-blueprint__btn-label {
            font-weight: normal;
          }
        }
        .export-blueprint__item-btn--bin {
          @include vw(margin-left, 10);
          background-image: url('../../../assets/images/icons/Icon\ -\ Bin.svg');
          @include image-props(center, no-repeat, 30%, 50);
        }
        .export-blueprint__item-btn--download {
          @include vw(margin-left, 10);
          background-image: url('../../../assets/images/icons/no-border/Icon-Download-White.svg');
          @include image-props(center, no-repeat, 30%, 50);
        }
      }
      .export-blueprint__items--right {
        @include vw(max-height, 350);
        margin-top: 0;
      }
    }
    .export-blueprint__grid--right {
      -webkit-backdrop-filter: blur(26px);
      backdrop-filter: blur(26px);
      border: solid 1px $white02;
      background-color: #11191c;
    }
    .export-blueprint__desc {
      @include vw(margin-top, 20);
    }
  }
}
.export-blueprint__carousel,
.text-centered-component {
  display: flex;
  @include vw(margin-top, 80);
  @include vw(margin-bottom, 80);
  .section-text {
    width: 45%;
    @include vw(min-height, 720);
    @include vw(padding-bottom, 180);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    z-index: 6;
    @include breakpoint(mobile) {
      width: 96%;
      justify-content: flex-start;
      height: auto;
      @include vw(padding-bottom, 0);
    }
    .slice-title {
      * {
        color: $white;
        @include vw(font-size, 50);
        line-height: 1.2;
        font-weight: 900;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 50);
          line-height: normal;
        }
      }
    }
    .slice-subtitle {
      * {
        color: $yellowIg;
        @include vw(font-size, 20);
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        line-height: 3.75;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 14);
          @include vwMobile(margin-bottom, 10);
          line-height: 1.43;
        }
      }
      em {
        font-style: normal;
        color: $white;
        display: inline-block;
        @include vw(font-size, 16);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 14);
          line-height: 1.43;
        }
      }
    }
    .slice-talents-title {
      @include vw(font-size, 16);
      font-family: eurostile-extended, sans-serif;
      font-weight: 900;
      font-style: normal;
      color: $yellowIg;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 14);
      }
    }
    p {
      @include vw(margin-right, 94);
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        @include vwMobile(margin-right, 0);
      }
    }
  }
  .section-carousel {
    width: 55%;
    z-index: 6;
    position: relative;
    @include breakpoint(mobile) {
      width: 100%;
      display: none;
    }
    .react-multiple-carousel__arrow {
      z-index: 9;
      border: solid 1px $white02;
      background: $almostBlack;
      border-radius: 0;
      opacity: 0.8;
      @include vw(min-height, 65);
      @include vw(min-width, 65);
      @include vw(bottom, 0);
    }
    .react-multiple-carousel__arrow::before {
      @include vw(font-size, 18);
    }
    .react-multiple-carousel__arrow--right {
      @include vw(left, 74);
      right: auto;
    }
    .react-multiple-carousel__arrow--left {
      @include vw(left, 0);
    }
    .carousel-box:active {
      cursor: grabbing;
    }
    .carousel-box {
      @include vw(width, 910);
      @include vw(height, 580);

      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
      position: relative;
      cursor: grab;
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(height, 374);
      }
    }
    .carousel-info-content {
      @include vw(height, 120);
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .carousel-box-title-author {
      z-index: 8;
      position: relative;
      @include vw(width, 316);
      @include vw(height, 65);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $almostBlack;
      border: 1px solid $white02;
      p,
      span {
        color: #a8a8a8;
        @include vw(font-size, 16);
      }
      span {
        @include vw(margin-left, 5);
      }
    }
    .carousel-box-coordinates {
      @include vw(width, 65);
      @include vw(height, 65);
      z-index: 8;
      position: relative;
      @include vw(margin-left, 10);
      background-color: $almostBlack;
      border: 1px solid $white02;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .carousel-box-coordinates-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .icon {
          @include vw(width, 20);
          @include vw(height, 20);
          background-image: url('../../../assets/images/icons/Icon\ -\ Location.svg');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .carousel-swipe {
    display: none;
    @include breakpoint(mobile) {
      display: inline;
      text-align: right;
      @include vwMobile(margin-right, 20);
      @include vwMobile(margin-bottom, 10);
      color: #a8a8a8;
      @include vwMobile(font-size, 12);
      font-weight: bold;
    }
  }
  .section-carousel-mobile {
    display: none;
    @include breakpoint(mobile) {
      width: 100%;
      display: grid;
      @include vwMobile(gap, 10);
      // grid-template-columns: repeat(2, 1fr);
      -ms-overflow-style: none; /* IE and Edge */
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none; /* Firefox */
      @include vwMobile(height, 420);
      .carousel-box {
        @include vwMobile(height, 374);
        @include vwMobile(width, 440);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
    .carousel-author-mobile {
      display: none;
      @include breakpoint(mobile) {
        display: block;
        position: relative;
        @include vwMobile(margin-top, 10);
        @include vwMobile(font-size, 16);
        color: #a8a8a8;
        * {
          color: #a8a8a8;
          @include vwMobile(font-size, 16);
          @include vwMobile(margin-left, 5);
        }
      }
    }
  }
}
