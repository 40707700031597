.buypage-yourselection-landingone {
  .corners-up {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    border-bottom-width: 0;
    @include buildCorners(18, 0);
    @include vw(padding-bottom, 18);
    @include breakpoint(mobile) {
      @include buildCornersMobile(22, 0);
      border-bottom-width: 0;
    }
    .image-container {
      .image {
        @extend .util-image-container;
        @include vw(width, 190);
        @include vw(height, 115);
        background-color: $almostBlack;

        background-image: none;
        @include breakpoint(mobile) {
          @include vwMobile(height, 95);
        }
        .visibleBorder {
          // @extend .util-image-placeholder;
          // @include buildCorners(25, 15);

          display: inline-block;
          width: 100%;
          background-position: center;
          background-repeat: no-repeat;
          @include vw(background-size, 250);
          @include breakpoint(mobile) {
            @include vwMobile(background-size, 200);
          }
          // background-size: contain;
        }

        .buyPage-logo-1 {
          background-image: url('../../../../assets/images/buypage/3months.png');
        }
        .buyPage-logo-2 {
          background-image: url('../../../../assets/images/buypage/6months.png');
        }
        .buyPage-logo-3 {
          background-image: url('../../../../assets/images/buypage/12months.png');
        }

        .visibleBorderBest {
          @extend .visibleBorder;
          background-image: url('../../../../assets/images/buypage/buypagetwologo.png');
        }

        @include breakpoint(mobile) {
          @include vwMobile(width, 106);
          @include vwMobile(height, 134);
          @include vwMobile(margin-right, 22);

          .visibleBorder {
            //display: none;
          }
        }
      }
    }
  }
  .text-container {
    p {
      @include vw(font-size, 20);
      color: #a8a8a8;
      @include vw(margin-bottom, 10);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        @include vwMobile(line-height, 22);
      }
    }
  }
  // .ext-border {
  //     background-color: $almostBlack;
  //     position: relative;
  //     .corners {
  //         @include buildCorners(18, 0);
  //     }
  // }

  .box-container {
    border: solid 0.1px rgba(255, 255, 255, 0.2);
    border-bottom: 0;
    background-color: $almostBlack;
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 25);
    }
  }
  .pr-0 {
    padding-right: 0 !important;
  }
  .box {
    @include breakpoint(from-phablet) {
      @include vw(padding-left, 20);
      @include vw(padding-right, 40);
      margin-bottom: 0;

      &:first-child {
        padding-left: 0;
      }
    }

    .buypagetwobutton {
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }
  .box-with-border {
    border-width: 0;
    // border-style: solid;
    // border-color: $gray-dark;
    // @include vw(border-right-width, 0.1);

    @include breakpoint(mobile) {
      @include vw(border-right-width, 0);
    }
  }

  h3 {
    @include vw(font-size, 28);
    @include vw(margin-bottom, 0);
    line-height: 1.43;
    @include breakpoint(mobile) {
      font-weight: bold;
      @include vwMobile(margin-top, 0);
      @include vwMobile(margin-bottom, 0);
      @include vwMobile(font-size, 35);
    }
    &.title {
      @include vw(margin-bottom, 24);
      line-height: 0.7;
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }
  h3 {
    @include vw(font-size, 22);
    // @include vw(margin-bottom, 16);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 18);
      font-weight: bold;
      &.yellow {
        //  color: $yellowIg;
      }
    }
    &.currency,
    &.save {
      display: inline-block;
      color: $yellowIg;
    }
    &.currency {
      @include vw(margin-right, 10);
    }
  }
  h4,
  h5 {
    @include vw(font-size, 26);
    @include vw(line-height, 26);
    text-transform: uppercase;
  }

  h4 {
    color: $gray;
  }
  h5 {
    color: $white;
  }

  .total-container {
    display: flex;
    justify-content: space-between;
    border-width: 0;
    border-style: solid;

    border-color: $gray-dark;
    @include vw(border-top-width, 0.1);
    @include vw(padding-top, 50);

    @include breakpoint(mobile) {
      @include vw(border-top-width, 0);
      @include vwMobile(height, 33);
      @include vw(border-bottom-width, 1);
    }
    h4,
    h5 {
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }
}
