.news-container-intro {
  width: 100%;
  //  background-image: url('../../../../assets/images/news/cover@3x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include vw(height, 700);
  @include vw(margin-bottom, 0);
  @include breakpoint(mobile) {
    @include vwMobile(height, 690);
    background-position-x: 75%;
    background-position-y: 0;
  }
  .section-type {
    @include vw(margin-left, 156);
    @include vw(margin-top, 148);
    background-color: $blueLight;
    display: inline-block;
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 290);
      @include vwMobile(margin-left, 20);
    }
    p {
      @include vw(min-width, 169);
      text-align: center;
      text-transform: uppercase;
      @include vw(font-size, 16);
      color: $white;
      @include vwAll(padding, 10, 20, 10, 20);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        @include vwMobileAll(padding, 10, 45, 10, 45);
        @include vwMobile(min-width, 100);
      }
    }
  }
  h1 {
    @include vw(width, 852);
    @include vw(font-size, 50);
    @include vw(margin-left, 156);
    @include vw(margin-bottom, 45);
    @include vw(margin-top, 30);
    margin-right: auto;
    line-height: 1.4;
    text-transform: bold;
    @include breakpoint(mobile) {
      @include vwMobile(width, 374);
      @include vwMobile(font-size, 35);
      @include vwMobile(margin-left, 20);
      line-height: 1.4;
      text-transform: bold;
      @include vwMobile(margin-top, 40);
      @include vwMobile(margin-bottom, 40);
    }
  }
  button {
    @extend .util-navbar-link;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: $white;
    color: $almostBlack;
    cursor: pointer;
    transition: 0.1s;

    @include vw(margin-left, 156);
    @include vw(min-width, 216);
    @include vw(height, 65);
    @include breakpoint(mobile) {
      @include vwMobile(width, 206);
      @include vwMobile(height, 65);
      @include vwMobile(margin-left, 20);
    }
    &:hover {
      // background-color: linear-gradient(to right, $white 50%, transparent 50%);
      // background-color: transparent;
      // border: solid 1px $white;
      // color: $white;
    }
    .theme-button-container {
      span {
        font-weight: bolder;
        @include vw(font-size, 16);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 2;
        }
      }
    }
    span {
      @include vw(font-size, 16);
      line-height: 1.36;
      text-transform: uppercase;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        line-height: 1.36;
      }
    }
    div.icon {
      background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include vw(height, 16);
      @include vw(width, 16);
      // content: '>';
      @include vw(margin-right, 15);
      @include breakpoint(mobile) {
        @include vwMobile(margin-right, 15);
        @include vwMobile(height, 16);
        @include vwMobile(width, 16);
      }
      &:hover {
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue.svg');
      }
    }
  }
}
