.resend-confirm-container {
  width: 100%;
  height: auto;
  border: 0.1px solid $grayButton;
  background-color: $almostBlack;
  @include vw(padding, 30);

  .corners {
    width: 100%;
    height: 100%;
    @include buildCorners(30, 0);
    @include breakpoint(mobile) {
      @include buildCorners(80, 0);
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .error-message {
      @include vw(font-size, 17);
      color: $reddish;
      line-height: 1.35;
      @include vw(margin-top, 20);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 17);
        @include vwMobile(margin-top, 20);
      }
    }
  }
  .resend-confirm-title {
    color: $white;
    font-weight: bold;
    text-align: center;
    @include vw(font-size, 50);
    line-height: 1.4;
    @include vw(margin-bottom, 10);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 22);
      @include vwMobile(padding-top, 45);
    }
  }
  .resend-input-box {
    display: flex;
    border: 1px solid $gray-home;
    @include vw(height, 65);
    @include vw(width, 510);
    @include vw(margin-top, 60);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 50);
      @include vwMobile(height, 65);
      @include vwMobile(width, 334);
    }
    .email-icon,
    .valid-icon {
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      @include vw(width, 65);
      @include breakpoint(mobile) {
        @include vwMobile(width, 65);
      }
    }
    .email-icon {
      background-image: url('../../../../../assets/images/icons/no-border/Icon-Email.svg');
      @include vw(background-size, 25);
      @include breakpoint(mobile) {
        @include vwMobile(background-size, 25);
      }
    }
    .valid-icon {
      background-image: url('../../../../../assets/images/icons/no-border/Icon-Validate-Green.svg');
      @include vw(background-size, 25);
      @include breakpoint(mobile) {
        @include vwMobile(background-size, 25);
      }
    }
    input {
      @include vw(width, 380);
      border: 0;
      background-color: $almostBlack;
      color: $white;
      outline: none;
      @include breakpoint(mobile) {
        @include vwMobile(width, 200);
      }
    }
  }
  .btn-resend {
    @include vw(margin-top, 20);
    @extend .util-navbar-link;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    margin-right: auto;
    margin-left: auto;
    background-color: $white;
    color: $almostBlack;
    cursor: pointer;
    transition: 0.1s;
    @include vw(min-width, 200);
    @include vw(height, 65);
    @include breakpoint(mobile) {
      @include vwMobile(width, 206);
      @include vwMobile(height, 65);
      // @include vwMobile(margin-left, 20);
    }
    .theme-button-container {
      width: 100%;
      div {
        background-image: url('../../../../../assets/images/icons/icon-send-black.svg');
        @include vw(background-size, 40);
        @include breakpoint(mobile) {
          @include vwMobile(width, 30);
          @include vwMobile(height, 30);
          @include vwMobile(background-size, 30);
        }
      }
      span {
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
  }

  .resend-recaptcha {
    @include vw(margin-top, 20);
    @include vw(min-width, 200);
    @include breakpoint(from-tablet) {
      transform: scale(0.5);
    }
    @include breakpoint(desktop) {
      transform: scale(0.8);
    }
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 30);
      transform: scale(0.7);
    }
  }
  .resend-notice {
    @include vw(font-size, 17);
    color: $button-continue;
    line-height: 1.35;
    @include vw(margin-top, 20);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 17);
      @include vwMobile(margin-top, 20);
    }
  }
  .resend-privacy-policy {
    @include vw(margin-top, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 45);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    p,
    a {
      @include vw(font-size, 16);
      color: $gray-medium;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        line-height: 1.88;
      }
    }
    a {
      color: $yellowMild;
      text-decoration: underline;
    }
  }
}
