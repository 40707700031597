@import './press-sections/press-navigation.scss';
.press-content-container {
  .overflowHidder {
    @include breakpoint(from-phablet) {
      @include vw(height, 1670);
      overflow: hidden;
    }
  }

  .content-navigation {
    position: sticky;
    @include vw(top, 80);
    z-index: 9;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px $grayButton;
    background-color: $almostBlack;
    @include vw(top, 80);
    @include vw(height, 65);
    .content-wrapper {
      @include vw(min-width, 342);
      display: flex;
      height: 100%;
      justify-content: space-between;
      ul {
        display: flex;
        height: 100%;
        align-items: center;
        margin: 0;
        a {
          display: flex;
          height: 100%;
          align-items: center;
          border-bottom-width: 3px;
          border-bottom-style: solid;
          border-bottom-color: transparent;
          color: $white;
          cursor: pointer;
          line-height: 1.4;
          text-decoration: none;
          @include vw(font-size, 20);
          @include vw(margin-right, 20);
          &:hover {
            // color: $yellowMild;
            // border-bottom: 1px solid $yellowMild;
            // text-decoration: underline;
          }
        }
      }
      .active {
        border-bottom-width: 3px;
        border-bottom-style: solid;

        border-bottom-color: $yellowIg;
      }
    }
    @include breakpoint(mobile) {
      display: none;
    }
  }
  // @include vw(padding-top, 44);
  .intro-form {
    @include vw(height, 849);
    display: flex;
    // background-color: #000000;
    background-image: url('../../../assets/images/presscontact/contact.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(mobile) {
      display: flex;
      flex-direction: column;
      @include vwMobile(height, 1580);
      background-image: none;
    }
    &:after {
      position: absolute;
      z-index: 0;
      @include vw(top, 145);
      border-left-style: solid;
      border-left-color: rgba(255, 255, 255, 0.6);
      content: ' ';
      transform: skew(-36deg, 0deg);
      @include vw(border-left-width, 1);
      @include vw(left, 1365);
      @include vw(height, 849);
      @include breakpoint(mobile) {
        display: none;
      }
    }
    div.column-one {
      @include vw(width, 990);
      @include breakpoint(mobile) {
        width: 100%;
      }
      h1 {
        @include vw(padding-left, 156);
        @include vw(padding-top, 338);
        margin-bottom: 0;
        @include breakpoint(mobile) {
          @include vwMobile(padding-top, 74);
          @include vw(padding-left, 0);
          @include vwMobile(font-size, 35);
          line-height: 1.4;
          text-align: center;
        }
      }
      .subtitle {
        @include vw(padding-left, 156);
        @include vw(margin-top, 40);
        p,
        a {
          @include vw(font-size, 26);
          color: $yellowIg;
          line-height: 1.38;
        }
        a {
          text-decoration: underline;
        }
        @include breakpoint(mobile) {
          p,
          a {
            @include vwMobile(font-size, 22);
            line-height: 1.36;
            text-align: center;
          }
          margin-right: auto;
          margin-left: auto;

          @include vwMobile(width, 374);
          @include vwMobile(padding-left, 0);
          @include vwMobile(margin-top, 40);
        }
      }
      .sublink {
        text-decoration: underline;
        text-transform: lowercase;
        @include vw(font-size, 26);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 22);
        }
      }
      .knowledge {
        @include vw(padding-left, 156);
        @include vw(margin-top, 140);
        p,
        a {
          @include vw(font-size, 20);
          line-height: 1.4;
          @include vw(width, 700);
        }
        a {
          text-decoration: underline;
        }
        @include breakpoint(mobile) {
          display: none;
        }
      }
    }
    div.column-two {
      @include vw(width, 774);
      z-index: 3;
      @include breakpoint(mobile) {
        display: flex;
        width: 100%;
        flex-direction: column;
      }
      form {
        @include vw(margin-top, 88);
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 88);
          @include vwMobile(margin-left, 20);
          @include vwMobile(margin-right, 20);
        }
        .row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          @include vw(gap, 20);
          @include breakpoint(mobile) {
            @include vwMobile(gap, 20);
            grid-template-columns: 1fr;
          }
          .input-group {
            position: relative;
            display: flex;
            flex-direction: column;
            @include breakpoint(mobile) {
            }
            .arrow {
              position: absolute;
              z-index: 1;
              right: 0;
              left: 0;
              margin-right: auto;
              margin-left: auto;
              @include vw(bottom, 28);
              @include vw(height, 12);
              @include vw(width, 26);
              @include vw(border-left-width, 26);
              @include vw(border-right-width, 26);
              @include vw(border-bottom-width, 26);
              border-right-style: solid; /* stylelint-disable-line */
              border-right-color: transparent; /* stylelint-disable-line */
              border-bottom-style: solid; /* stylelint-disable-line */
              border-bottom-color: $yellowIg; /* stylelint-disable-line */
              border-left-style: solid; /* stylelint-disable-line */
              border-left-color: transparent; /* stylelint-disable-line */

              @include breakpoint(mobile) {
                @include vwMobile(bottom, 32);
                @include vwMobile(height, 14);
                @include vwMobile(width, 32);
                @include vwMobile(border-left, 26);
                @include vwMobile(border-right, 26);
                @include vwMobile(border-bottom, 26);
                border-right-style: solid;
                border-right-color: transparent;
                border-bottom-style: solid;
                border-bottom-color: $yellowIg;
                border-left-style: solid;
                border-left-color: transparent;
              }
            }
            .yellow-notice {
              position: absolute;
              right: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: auto;
              margin-left: auto;
              background-color: $yellowIg;
              @include vw(bottom, -10);
              @include vw(min-height, 52);
              @include vw(width, 315);
              @include breakpoint(mobile) {
                @include vwMobile(width, 315);
                @include vwMobile(min-height, 52);
                @include vwMobile(bottom, -10);
              }
              p {
                @include vw(font-size, 16);

                color: $almostBlack;
                line-height: 1.38;
                @include breakpoint(mobile) {
                  @include vwMobile(font-size, 16);
                }
              }
            }
            label {
              color: $yellowIg;
              line-height: 2;
              @include vw(font-size, 20);
              @include vw(margin-bottom, 20);
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 20);
                @include vwMobile(margin-bottom, 12);
              }
            }
            .input-with-icons {
              display: flex;
              align-items: center;
              border: solid 1px #999999;
              background-color: $almostBlack;
              @include vw(margin-bottom, 30);
              @include vw(height, 65);
              @include breakpoint(mobile) {
                @include vwMobile(margin-bottom, 40);
                @include vwMobile(height, 65);
              }
              .icon {
                @include vw(height, 20);
                @include vw(width, 20);
                background-color: $almostBlack;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                @include vw(padding, 10);
                @include breakpoint(mobile) {
                  @include vwMobile(height, 20);
                  @include vwMobile(width, 20);
                  @include vwMobile(padding, 10);
                }
              }
              .icon-comment {
                background-image: url('../../../assets/images/icons/no-border/Icon-Comment.svg');
                @include vw(margin-left, 15);
                @include breakpoint(mobile) {
                  @include vwMobile(margin-left, 15);
                }
              }
              .icon-deploy {
                background-image: url('../../../assets/images/icons/Icon-DropArrow.svg');
                @include vw(margin-right, 15);
                @include breakpoint(mobile) {
                  @include vwMobile(margin-right, 15);
                }
              }
              .icon-dots {
                background-image: url('../../../assets/images/icons/Icon-Options.svg');
                @include vw(margin-left, 15);
                @include breakpoint(mobile) {
                  @include vwMobile(margin-left, 15);
                }
              }
              .icon-reload {
                background-image: url('../../../assets/images/icons/no-border/Icon-Loading.svg');
                @include vw(margin-right, 15);
                @include breakpoint(mobile) {
                  @include vwMobile(margin-right, 15);
                }
              }

              .icon-email {
                background-image: url('../../../assets/images/icons/no-border/Icon-Email.svg');
                @include vw(margin-left, 15);
                @include breakpoint(mobile) {
                  @include vwMobile(margin-left, 15);
                }
              }
              .icon-user {
                background-image: url('../../../assets/images/icons/no-border/Icon-Profile.svg');
                @include vw(margin-left, 15);
                @include breakpoint(mobile) {
                  @include vwMobile(margin-left, 15);
                }
              }
              .icon-error {
                background-image: url('../../../assets/images/icons/no-border/Icon-Close-Red.svg');
              }
              input,
              select {
                width: 100%;
                height: 100%;
                border: 0;
                background-color: $almostBlack;
                color: $white;
                outline: none;

                @include vw(padding, 15);
                @include breakpoint(mobile) {
                  @include vwMobile(padding, 15);
                }
              }
              input:invalid {
                box-shadow: none;
                outline: none;
              }
              select {
                -webkit-appearance: none;
                -moz-appearance: none;
                background: transparent
                  url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='20' height='20' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>")
                  no-repeat;
                background-position: right 3/1920 * 100vw top 55%;
                background-repeat: no-repeat;
                cursor: pointer;
                @include vw(background-size, 20);
                @include vw(margin-right, 10);
                @include breakpoint(mobile) {
                  @include vwMobile(margin-right, 10);
                }
                option {
                  color: $almostBlack;
                }
              }
            }
            .error {
              border: $reddish 1px solid;
            }
          }
        }

        .complete {
          grid-template-columns: 1fr;
          textarea {
            @include vw(height, 400);
            width: 100%;
            border: solid 1px #9ea1a1;

            @include vw(height, 200);

            background-color: $almostBlack;
            @include vw(padding, 15);
            color: $white;
            outline: none;
            resize: none;
            @include breakpoint(mobile) {
              @include vwMobile(height, 220);

              @include vwMobile(padding, 15);
            }
          }
          .error {
            border: $reddish 1px solid;
          }
        }
        button {
          float: right;
          @include vw(min-width, 240);
          @include vw(margin-top, 45);
          @include breakpoint(mobile) {
            @include vwMobile(height, 65);
            @include vwMobile(width, 200);
            @include vwMobile(margin-top, 40);
          }
          .theme-button-container {
            div {
              &.icon-send {
                @include vw(height, 30);
                @include vw(width, 30);
                background-image: url('../../../assets/images/icons/icon-send-black.svg');
                background-position: center;
                background-repeat: no-repeat;
                @include vw(background-size, 35);
                @include breakpoint(mobile) {
                  @include vwMobile(height, 30);
                  @include vwMobile(width, 30);
                  @include vwMobile(background-size, 35);
                }
              }

              &.success {
                background-image: url('../../../assets/images/icons/no-border/Icon-Validate-Black.svg');
                @include vw(background-size, 20);
              }
              &.loading {
                animation-duration: 1000ms;
                animation-iteration-count: infinite;
                animation-name: spin;
                animation-timing-function: linear;
                background-image: url('../../../assets/images/icons/loading-icon.svg') !important;
              }
              &.error {
                background-image: url('../../../assets/images/icons/Icon-Close-Black.svg');
                @include vw(background-size, 15);
              }
            }
            span {
              @include vw(font-size, 16);
              -ms-animation: topToBottom 5s infinite;
              -webkit-animation: topToBottom 5s infinite;
              animation: topToBottom 5s infinite;
              font-weight: 800;
              line-height: 1.38;
              text-transform: uppercase;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
              }
            }
            @keyframes spin {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
      .column-one-visible {
        display: none;
        @include breakpoint(mobile) {
          display: block;
          @include vwMobile(padding-left, 20);
          @include vwMobile(padding-right, 20);
          @include vwMobile(margin-top, 40);
          width: 100%;
          p {
            @include vwMobile(font-size, 20);
            line-height: 1.44;
          }
        }
      }
    }
  }
  .list-social {
    .footerIcon {
      @include vw(height, 90);
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 1px;
      border-color: $grayButton;
      border-top-style: solid;
      border-bottom-style: solid;
      @include breakpoint(mobile) {
        width: 100%;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        @include vwMobile(height, 300);
        @include vwMobile(padding-right, 81.5);
        @include vwMobile(padding-left, 82.5);
      }
      .iconLogo {
        @include vw(width, 40);
        @include vw(margin-right, 37);
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(mobile) {
          @include vwMobile(width, 40);
          @include vwMobile(margin-right, 30);
          @include vwMobile(margin-bottom, 20);
          @include vwMobile(height, 40);
        }
      }
      h3 {
        @include vw(margin-right, 60);
        @include vw(font-size, 20);
        line-height: 1.4;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 25);
          @include vwMobile(margin-bottom, 40);
          @include vwMobile(margin-top, 20);
          @include vwMobile(margin-right, 30);
        }
      }
      div {
        height: 50%;
        @include breakpoint(mobile) {
          @include vwMobile(padding-left, 35);
        }
        a {
          @include breakpoint(from-phablet) {
            height: 100%;
          }

          span {
            height: 100%;
            &.iconLogoTwitter {
              @extend .iconLogo;
              background-image: url('../../../assets/images/icons/Twitter.svg');
              &:hover {
                background-image: url('../../../assets/images/icons/yellow-withBorder/icon-twitter.svg');
              }
            }

            &.iconLogoFacebook {
              @extend .iconLogo;
              background-image: url('../../../assets/images/icons/Facebook.svg');
              &:hover {
                background-image: url('../../../assets/images/icons/yellow-withBorder/icon-facebook.svg');
              }
            }

            &.iconLogoInstagram {
              @extend .iconLogo;
              background-image: url('../../../assets/images/icons/Instagram.svg');
              &:hover {
                background-image: url('../../../assets/images/icons/yellow-withBorder/icon-instagram.svg');
              }
            }

            &.iconLogoYoutube {
              @extend .iconLogo;
              background-image: url('../../../assets/images/icons/Youtube.svg');
              &:hover {
                background-image: url('../../../assets/images/icons/yellow-withBorder/icon-youtube.svg');
              }
            }

            &.iconLogoTwitch {
              @extend .iconLogo;
              background-image: url('../../../assets/images/icons/Twitch.svg');
              &:hover {
                background-image: url('../../../assets/images/icons/yellow-withBorder/icon-twitch.svg');
              }
            }
            &.iconLogoDiscord {
              @extend .iconLogo;
              background-image: url('../../../assets/images/icons/Discord.svg');
              &:hover {
                background-image: url('../../../assets/images/icons/yellow-withBorder/icon-discord.svg');
              }
            }
            &.iconLogoReddit {
              @extend .iconLogo;
              background-image: url('../../../assets/images/icons/Reddit.svg');
              &:hover {
                background-image: url('../../../assets/images/icons/yellow-withBorder/icon-reddit.svg');
              }
            }
            &.iconLogoCloud {
              @extend .iconLogo;
              background-image: url('../../../assets/images/icons/Soundcloud.svg');
              &:hover {
                background-image: url('../../../assets/images/icons/yellow-withBorder/icon-soundcloud.svg');
              }
            }
          }
        }
      }
    }
  }
  .list-emails {
    background-color: $almostBlack;
    // @include vw(height, 500);
    @include breakpoint(mobile) {
      @include vwMobile(height, 950);
    }
    h2 {
      @include vw(font-size, 35);
      line-height: 1.4;
      @include vw(padding-top, 68);
      @include vw(margin-bottom, 60);
      @include vw(padding-left, 156);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
        @include vwMobile(padding-top, 71);
        @include vwMobile(margin-bottom, 40);
        @include vwMobile(padding-left, 20);
      }
    }
    .emails-blocks {
      @include vw(padding-left, 156);
      @include vw(padding-right, 156);
      display: grid;
      @include vw(gap, 60);
      grid-template-columns: repeat(4, 1fr);
      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
        @include vwMobile(padding-left, 20);
        @include vwMobile(padding-right, 20);
        @include vwMobile(gap, 20);
      }
      .block {
        position: relative;
        z-index: 1;
        border: solid 1px #999999;
        background-color: #162024;
        @include vw(height, 185);
        @include breakpoint(mobile) {
          @include vwMobile(height, 185);
        }
        h5 {
          color: $white;
          line-height: 1.4;
          text-transform: uppercase;
          @include vw(font-size, 20);
          @include vw(padding-left, 30);
          @include vw(padding-top, 25);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 14);
            line-height: 1.44;
            @include vwMobile(padding-left, 30);
            @include vwMobile(padding-top, 30);
          }
        }
        p {
          @include vw(padding-left, 30);
          @include vw(margin-top, 10);
          color: #7b7b7b;
          line-height: 1.4;
          @include vw(font-size, 20);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);

            line-height: 1.38;
            @include vwMobile(margin-top, 10);
            @include vwMobile(padding-left, 30);
            @include vwMobile(padding-right, 50);
          }
        }
        a {
          display: inline-block;
          line-height: 1.4;
          text-decoration: none;
          @include vw(padding-left, 30);
          @include vw(margin-top, 10);
          @include vw(font-size, 20);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            line-height: 1.38;
            @include vwMobile(margin-top, 20);
            @include vwMobile(padding-left, 30);
          }
        }
      }
    }
  }
  .download-press-kit {
    @include vw(height, 700);
    position: relative;
    background-color: #162024;
    background-image: url('../../../assets/images/presscontact/presskit.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &:after {
      position: absolute;
      z-index: 0;
      top: 0;
      border-left-style: solid;
      border-left-color: rgba(255, 255, 255, 0.6);
      content: ' ';
      transform: skew(-36deg, 0deg);
      @include vw(border-left-width, 1);
      @include vw(left, 950);
      @include vw(height, 700);
      @include breakpoint(mobile) {
        display: none;
      }
    }
    @include breakpoint(mobile) {
      background-image: none;
      @include vwMobile(height, 560);
      @include vwMobile(padding-top, 20);
    }
    .black-box {
      display: none;
      @include breakpoint(mobile) {
        display: block;
        width: 100%;
        background-image: url('../../../assets/images/presscontact/presskit.png');
        background-repeat: no-repeat;
        background-size: cover;
        @include vwMobile(background-position-x, -170);
        @include vwMobile(height, 217);
      }
    }
    .download-content {
      @include vw(padding-left, 156);
      position: relative;
      @include breakpoint(mobile) {
        @include vwMobile(padding-left, 20);
        @include vwMobile(padding-right, 20);
      }
      #download {
        position: absolute;
        @include vw(height, 400);
        @include vw(top, 60);
      }
      h2 {
        @include vw(font-size, 35);
        line-height: 1.4;
        @include vw(padding-top, 204);
        @include vw(margin-bottom, 50);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 25);
          @include vwMobile(margin-bottom, 30);
          // @include vwMobile(padding-top, 204);
          // @include vwMobile(margin-bottom, 50);
        }
      }
      p {
        @include vw(font-size, 20);
        color: #dbdbdb;
        line-height: 2;
        //  @include vw(margin-bottom, 80);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          // @include vwMobile(margin-bottom, 40);
        }
      }
      button {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // border: 0;
        // background-color: $white;
        // cursor: pointer;
        // outline: none;
        // @include vw(height, 65);
        // @include vw(width, 233);
        @include vw(margin-top, 80);
        @include breakpoint(mobile) {
          @include vwMobile(height, 65);
          @include vwMobile(margin-top, 39);
          width: 100%;
        }
        .icon-download {
          @include vw(height, 20);
          @include vw(width, 20);
          background-image: url('../../../assets/images/icons/no-border/Icon-Download.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          @include vw(margin-right, 15);
          @include breakpoint(mobile) {
            @include vwMobile(height, 65);
            @include vwMobile(height, 20);
            @include vwMobile(width, 20);
            @include vwMobile(margin-right, 20);
          }
        }
        span {
          @include vw(font-size, 16);
          font-weight: 800;
          line-height: 1.38;
          text-transform: uppercase;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
      }
    }
    .download-box-line {
    }
  }

  .press-release-image {
    position: relative;
    z-index: 0;
    width: 100%;
    background-image: url('../../../assets/images/presscontact/pressrelease.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include vw(height, 1500);
    @include vw(bottom, 310);
  }

  .carousel-press-releases {
    position: relative;
    // @include vw(bottom, 1080+450);
    background-image: url('../../../assets/images/presscontact/pressrelease.png');
    @include vw(background-position-y, -120);
    background-repeat: no-repeat;
    background-size: cover;

    .carousel-item-padding {
      @include vw(padding-left, 35);
      @include vw(padding-right, 35);
    }
    //@include vw(height, 830);

    @include vw(padding-left, 121);
    @include vw(padding-right, 121);
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      // @include vwMobile(height, 700);
      @include vwMobile(margin-bottom, 60);
    }
    .message {
      @include vw(font-size, 22);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 22);
      }
    }
    @include vw(padding-bottom, 120);
    h2 {
      @include vw(padding-left, 35);
      @include vw(font-size, 35);
      line-height: 1.4;
      @include vw(padding-top, 120);
      @include vw(margin-bottom, 60);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
        @include vwMobile(margin-bottom, 40);
        @include vwMobile(margin-top, 60);
      }
    }
    .react-multi-carousel-item {
      @include vw(width, 449);
    }
    .news-wapper-contact {
      @include vw(height, 620);
      width: 100%;
      border: solid 1px rgba(255, 255, 255, 0.2);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      // transition-delay: 1s ease-in-out; /* delays for 1 second */
      // -webkit-transition-delay: 1s ease-in-out; /* for Safari & Chrome */

      @include breakpoint(mobile) {
        @include vwMobile(height, 500);
      }
      &:hover {
        transition-duration: $animations-duration;
      }
      .news-block {
        position: relative;
        width: 100%;
        height: 100%;
        border: 0;
        @extend .util-image-placeholder;
        @include vw(padding, 0);
        transition: $corners-transition;
        &:hover {
          @include vw(padding, 15);
          transition: $corners-transition;
        }
        .news-header {
          position: absolute;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background-color: $blueLight;
          color: $white;
          text-transform: uppercase;
          transform: translate(-50%, -50%);
          @include vw(top, 60);
          @include vw(height, 41);
          @include vw(width, 169);
          @include breakpoint(mobile) {
            @include vwMobile(height, 39);
            @include vwMobile(width, 169);
            @include vwMobile(top, 40);
          }
          p {
            @include vw(font-size, 16);
            color: $white;
            line-height: 1.38;
            text-emphasis: bold;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }
        }
        .news-content-detail {
          @include vw(min-height, 250);
          position: relative;
          width: 100%;
          padding-bottom: 0;
          transition: $corners-transition;
          @include breakpoint(mobile) {
            @include vwMobile(min-height, 300);
          }
          .content {
            @include vw(padding-bottom, 50);
            @include breakpoint(mobile) {
              @include vwMobile(padding-bottom, 150);
            }
          }
          .social-media-group {
            position: absolute;
            bottom: 0;
            width: 88%;
          }
        }
        &:hover .news-content-detail {
          p.content {
            color: $yellowIg;
            opacity: none;
          }
          transition: $corners-transition;
          @include vw(margin-bottom, 30);
        }
        &:hover .linebefore {
          background: $yellowIg;
          opacity: none;
        }
        &:hover .news-content::before {
          background: $yellowIg;
        }
        .real-content {
          width: 100%;
          height: 100%;
          @include buildCorners(20, 0);
          @include breakpoint(mobile) {
            @include buildCorners(65, 0);
          }
        }
      }
    }
    .news-content {
      @include vw(right, 0);
      .news-content-detail {
        width: 100%;
        .list-social-media {
          @include vw(right, 0);
        }
      }
    }
    .carousel-item-padding {
      @include vw(padding-left, 25);
      @include vw(padding-right, 25);
    }
    .react-multiple-carousel__arrow {
      z-index: 0;
      border: solid 1px $white;
      background: transparent;
      border-radius: 0;
      opacity: 0.9;
      @include vw(border-width, 2);
      @include vw(height, 80);
      @include vw(width, 80);
      &::before {
        @include vw(font-size, 40);
      }
    }
    .react-multiple-carousel__arrow--right {
      @include vw(right, -0.4);
    }
    .react-multiple-carousel__arrow--left {
      @include vw(left, -0.5);
    }
  }
  .press-contact-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../assets/images/presscontact/pressrelease.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include vw(height, 450);
    @include breakpoint(mobile) {
      @include vwMobile(height, 434);
      flex-direction: column;
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
    }
    .logo-image {
      @include vw(height, 50);
      @include vw(width, 267);
      background-image: url('../../../assets/images/icons/Novaquark-Logo3x.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include breakpoint(mobile) {
        @include vwMobile(height, 50);
        @include vwMobile(width, 267);
        @include vwMobile(margin-bottom, 74);
        @include vwMobile(margin-top, 40);
      }
    }
    a {
      @include vw(margin-left, 173);
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(margin-left, 0);
      }
      .connect-comunity {
        @include vw(height, 160);
        @include vw(width, 496);
        position: relative;
        border: 0.1px solid $grayButton;
        background-color: $almostBlack;
        transition: $corners-transition;
        @include breakpoint(mobile) {
          @include vwMobile(height, 160);
          width: 100%;
          @include vwMobile(margin-left, 0);
        }
        @include vw(padding, 0);
        &:hover {
          // @include vw(padding, 10);
          cursor: pointer;
          transition: $corners-transition;
          @include vw(padding, 15);
        }
        .corners {
          width: 100%;
          height: 100%;
          @include buildCorners(20, 0);
          @include breakpoint(mobile) {
            @include buildCorners(65, 0);
          }
        }
        .real-content {
          position: absolute;
          top: 0;
          left: 40px;
          display: flex;

          height: 100%;
          align-items: center;
          justify-content: space-between;
          @include breakpoint(mobile) {
            justify-content: start;
          }
          div.image-users {
            @include vw(height, 80);
            @include vw(width, 80);
            background-image: url('../../../assets/images/icons/Icon-Comunity.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include breakpoint(mobile) {
              @include vwMobile(height, 80);
              @include vwMobile(width, 80);
            }
          }
          div.text-wrapper {
            @include vw(margin-left, 40);
            @include breakpoint(mobile) {
              @include vwMobile(margin-left, 20);
            }
            .title {
              line-height: 1.36;
              text-transform: uppercase;
              @include vw(font-size, 22);
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
                line-height: 1.38;
              }
            }
            .subtitle {
              @include vw(font-size, 20);
              line-height: 2;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
                line-height: 2.5;
              }
            }
          }
        }
      }
    }
  }
}
