div {
  &.homepagestart {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include vw(height, 850);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 20);
      @include vwMobile(height, 700);
    }
    .lottie-container {
      @include vw(width, 1600);
      animation-delay: 1s;
      animation-duration: 1s;
      animation-name: line;
      animation-timing-function: ease-in-out cubic;
      animation-fill-mode: forwards;
      @keyframes line {
        0% {
          @include vw(margin-top, 0);
        }
        50% {
          @include vw(margin-top, 0);
        }
        100% {
          @include vw(margin-top, -250);
          transform: scale(50%);
        }
      }
      @include breakpoint(mobile) {
        display: none;
        @include vwMobile(margin-top, 400);
        animation-name: none;
      }
    }
    div {
      &.cityImage {
        @include vw(height, 1000);
        position: absolute;
        z-index: -1;
        top: 0px;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(12, 19, 21, 0), $almostBlack),
          url('../../../../assets/images/homepage/city.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include vw(height, 1000);
      }

      &.dualUniverseLogo {
        @include vw(width, 589);
        @include vw(height, 261);
        margin: 0 auto;
        background-image: url('../../../../assets/images/du-logo-updated.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: none;
        @include vw(margin-bottom, 240);
        @include breakpoint(mobile) {
          display: flex;
          @include vwMobile(width, 310);
          @include vwMobile(height, 131);
          @include vwMobile(margin-top, 105);
          @include vwMobile(margin-bottom, 170);
        }
      }

      &.actionButtons {
        text-align: center;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        @include vw(margin-top, 15);
        //@include vw(margin-bottom, 140);

        @include breakpoint(mobile) {
          flex-direction: column;
          text-align: center;
          @include vwMobile(padding-left, 20);
          @include vwMobile(padding-right, 20);
        }
        button,
        a {
          @include vw(min-width, 305);
          @include vw(height, 65);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            width: 100%;

            @include vwMobile(height, 65);
            @include vwMobile(margin-bottom, 15);
          }
          animation-delay: 1.9s;
          animation-duration: 0.2s;
          animation-name: fadeInBtn;
          animation-timing-function: ease-in-out cubic;
          animation-fill-mode: forwards;
          @include vw(margin-top, 30);
          opacity: 0;
          @keyframes fadeInBtn {
            from {
              opacity: 0;
              @include vw(margin-top, 30);
            }
            to {
              opacity: 1;
              @include vw(margin-top, 0);
            }
          }
          .theme-button-container {
            width: 95%;
            margin-right: auto;
            margin-left: auto;
            div {
              background-image: url('../../../../assets/images/icons/Icon-Play.svg');
              @include vw(width, 20);
              @include vw(height, 20);
              background-size: contain;
              @include breakpoint(mobile) {
                @include vwMobile(width, 20);
                @include vwMobile(height, 20);
                @include vwMobile(margin-right, 10);
              }
            }
            span {
              font-family: eurostile-extended, sans-serif;
              font-weight: 900;
              font-style: normal;
              @include vw(font-size, 15);
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 15);
              }
            }
          }
          &.joinBeta {
            background-color: $yellowIg;
            background-image: linear-gradient(to right, #ffd255 50%, transparent 50%);
            &:hover {
              background-color: $yellowIg;
              border-color: $yellowIg;
            }
            .theme-button-container {
              div {
                background-image: url('../../../../assets/images/icons/no-border/Icon-Game.svg');
                @include vw(width, 9);
                @include vw(height, 12);
                @include breakpoint(mobile) {
                  @include vwMobile(width, 9);
                  @include vwMobile(height, 12);
                  @include vwMobile(margin-right, 15);
                }
              }
            }
            &:hover .theme-button-container div {
              background-image: url('../../../../assets/images/icons/no-border/Icon-Game-Green.svg');
            }
            &:hover .theme-button-container span {
              color: $black;
            }
            @include breakpoint(from-phablet) {
              // @include vw(margin-right, 155);
              @include vw(margin-left, 40);
            }
          }
          &.tryFree {
            animation-delay: 2s;
            animation-duration: 0.3s;
            background-image: linear-gradient(to bottom, #0027ff, #221772);
            color: $white;
            border: none;
            .theme-button-container {
              div {
                background-image: url('../../../../assets/images/icons/Icon-Wishlist.svg');
                @include vw(height, 25);
                @include vw(width, 25);
                @include vw(margin-right, 10);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                @include breakpoint(mobile) {
                  @include vwMobile(height, 25);
                  @include vwMobile(width, 25);
                  @include vwMobile(margin-right, 10);
                }
              }
            }
            &:hover {
              border: none;
              .theme-button-container {
                border-image-source: url('../../../../assets/images/utils/border-image-corners-white.svg');
                div {
                  background-image: url('../../../../assets/images/icons/Icon-Wishlist.svg');
                }
                span {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }

    p {
      &.title {
        margin: 0 auto;
        color: $white;
        line-height: 0.86;
        opacity: 1;
        text-transform: uppercase;
        @include vw(font-size, 70);
        @include vw(width, 1090);
        font-weight: 900;
        @include breakpoint(mobile) {
          @include vwMobile(width, 400);
          @include vwMobile(font-size, 28);
          @include vwMobile(margin-bottom, 10);
        }
        animation-delay: 1.8s;
        animation-duration: 0.5s;
        animation-name: fadeInText;
        animation-timing-function: ease-in-out cubic;
        animation-fill-mode: forwards;
        opacity: 0;
        @keyframes fadeInText {
          from {
            opacity: 0;
            @include vw(margin-top, 0);
          }
          to {
            opacity: 1;
            @include vw(margin-top, -80);
          }
        }
      }
      &.title-fr {
        @include vw(width, 1400);
      }
      &.title-de {
        @include vw(width, 1400);
      }
      &.explanation {
        margin: 0 auto;
        color: $white;
        font-weight: bold;
        line-height: 1;
        opacity: 1;
        text-transform: uppercase;
        font-family: eurostile-extended, sans-serif;
        @include vw(font-size, 30);
        font-weight: 900;
        @include vw(margin-top, 15);
        @include vw(margin-bottom, 30);
        // @include vw(line-height, 42);
        animation-delay: 1.8s;
        animation-duration: 0.5s;
        animation-name: fadeInTextTwo;
        animation-timing-function: ease-in-out cubic;
        animation-fill-mode: forwards;
        opacity: 0;
        @keyframes fadeInTextTwo {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @include breakpoint(mobile) {
          @include vwMobile(width, 373);
          @include vwMobile(font-size, 14);
          @include vwMobile(margin-top, 15);
          @include vwMobile(margin-bottom, 20);
          line-height: 1.43;
          color: $white;
        }
      }
      &.explanation-fr {
        @include vw(width, 1400);
      }
      &.explanation-de {
        @include vw(width, 1400);
      }
    }
  }
}
.modal-open {
  overflow: hidden;
}
