.buypage-thankyou {
  background-image: url('../../../../assets/images/thankyou.png');
  background-position-x: 0;
  background-repeat: no-repeat;
  background-size: cover;
  // @include vw(background-position-y, 80);
  @include vw(padding-left, 156);
  @include vw(padding-right, 156);
  @include breakpoint(mobile) {
    @include vwMobile(background-position-y, 0);
    @include vwMobile(padding-left, 20);
    @include vwMobile(padding-right, 20);
  }
  .notice {
    position: sticky;
    z-index: 8;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    @include vw(top, 80);
    @include vw(right, 0);
    @include vw(height, 70);
    @include breakpoint(mobile) {
      @include vwMobile(top, 60);
      @include vwMobile(height, 40);
    }
    .notice-title {
      @include vw(padding-left, 156);
      color: $almostBlack;
      @include vw(font-size, 22);
      @include vw(line-height, 22);
      @include breakpoint(mobile) {
        @include vwMobile(padding-left, 20);
        @include vwMobile(font-size, 16);
        @include vwMobile(line-height, 16);
      }
    }
    .error-title {
      color: $white;
    }
    .notice-close {
      @include vw(width, 20);
      @include vw(height, 20);
      @include vw(margin-right, 156);
      background-image: url('../../../../assets/images/icons/Icon-Close-Black.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      @include breakpoint(mobile) {
        @include vwMobile(width, 16);
        @include vwMobile(height, 16);
        @include vwMobile(margin-right, 20);
      }
    }
    .error-notice-close {
      color: $white;
      background-image: url('../../../../assets/images/icons/Icon-Close.svg');
    }
  }
  .error {
    background-color: $red;
  }
  div.maintitle {
    @include vw(padding-top, 162);
    @include breakpoint(mobile) {
      @include vwMobile(padding-top, 100);
    }
    h2 {
      @include vw(font-size, 50);
      @include vw(width, 800);
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 35);
        margin-left: 0;
        line-height: 1;
        text-align: left;
        @include vwMobile(width, 385);
      }
    }
  }
  .thankyou-breacrumb {
    @include vw(margin-top, 10);
    @include vw(margin-bottom, 50);
    display: flex;
    align-items: center;
    justify-content: start;
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 10);
      @include vwMobile(margin-bottom, 40);
    }
    .active {
      color: $yellowIg;
    }
    p {
      color: $white;
      font-weight: bold;
      line-height: 1.4;
      text-transform: uppercase;
      @include vw(margin-right, 40);
      @include vw(font-size, 20);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 11);
        line-height: 1.36;
        @include vwMobile(margin-right, 10);
      }
      &:nth-child(1):before {
        color: $gray-medium;
        content: '1.';
        @include vw(margin-right, 10);
      }
      &:nth-child(2):before {
        color: $gray-medium;
        content: '2.';
        @include vw(margin-right, 10);
      }
      &:nth-child(3):before {
        color: $gray-medium;
        content: '3.';
        @include vw(margin-right, 10);
      }
      &:nth-child(4):before {
        content: '4.';
        @include vw(margin-right, 10);
      }
    }
  }
  .thankyou-main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    @include vw(margin-top, 40);
    @include vw(margin-bottom, 82);
    @include vw(width, 800);
    @include breakpoint(mobile) {
      width: 100%;
      @include vwMobile(margin-top, 20);
    }
    p {
      display: inline-block;
    }
    .content-description {
      @include vw(font-size, 24);
      line-height: 1.42;
      text-align: center;
      @include vw(margin-left, 5);
      @include breakpoint(mobile) {
        text-align: left;
      }
    }
    p {
      @include vw(margin-right, 6);
    }
    .second-content {
      line-height: 1.42;
      text-align: center;
      @include vw(font-size, 24);
      @include breakpoint(mobile) {
        text-align: left;
      }
    }
    .content-marked {
      @include vw(margin-top, 40);
      font-weight: bold;
      line-height: 1.42;
      text-align: center;
      @include vw(font-size, 24);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 20);
        line-height: 1.38;
        text-align: left;
        @include vwMobile(font-size, 16);
      }
    }
    .theme-button-spec-positive {
      @include vw(min-width, 325);
    }
    .btn-box {
      display: flex;
      justify-content: center;
      @include vw(margin-top, 62);
      @include breakpoint(mobile) {
        flex-direction: column;
        @include vwMobile(margin-top, 40);
      }
      .download-game {
        border-color: transparent;
        @include vw(margin-right, 20);
        &:hover {
          background-color: $color-positive;
        }

        .theme-button-container {
          div {
            border: 1px solid $white;
            background-image: url('../../../../assets/images/Logo-DU.svg');
            @include vw(padding, 12);
            @include vw(height, 20);
            @include vw(width, 20);
            @include vw(background-size, 18);
            @include breakpoint(mobile) {
              @include vwMobile(padding, 12);
              @include vwMobile(height, 25);
              @include vwMobile(width, 20);
              @include vwMobile(background-size, 18);
              @include vwMobile(margin-right, 10);
            }
          }
        }

        &:hover .theme-button-container div {
          border-color: $black;
          background-image: url('../../../../assets/images/Logo-DU-Black.svg');
        }
        &:hover .theme-button-container span {
          color: $black;
        }
        @include breakpoint(mobile) {
          @include vwMobile(height, 65);
          width: 100%;
          @include vwMobile(font-size, 16);
          @include vwMobile(margin-top, 0);
        }
      }
      .resend {
        border: 0.1px solid $gray-home;
        background-color: $blueDark;
        background-image: linear-gradient(to right, $blueDark 50%, transparent 50%);

        @include breakpoint(mobile) {
          @include vwMobile(height, 65);
          width: 100%;
          @include vwMobile(font-size, 16);
          @include vwMobile(margin-top, 20);
        }
        &:hover {
          border-color: $yellowIg;
          .theme-button-container {
            @include buildCornersYellow(13, 5);
            div {
              border-color: $yellowIg;
              background-image: url('../../../../assets/images/icons/no-border/Icon-Email-Yellow.svg');
            }
            span {
              color: $yellowIg;
            }
          }
        }
        .theme-button-container {
          // height: 100%;
          background-color: $blueDark;

          font-weight: bold;
          text-transform: uppercase;
          @include vw(font-size, 16);
          div {
            border: 1px solid $white;
            background-image: url('../../../../assets/images/icons/no-border/Icon-Email.svg');
            @include vw(padding, 12);
            @include vw(height, 16);
            @include vw(width, 16);
            @include vw(background-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(padding, 12);
              @include vwMobile(height, 25);
              @include vwMobile(width, 20);
              @include vwMobile(background-size, 18);
              @include vwMobile(margin-right, 10);
            }
          }
        }
      }
    }
  }
  .thankyou-icons-links-title {
    display: flex;
    @include vw(margin-bottom, 40);
    @include vw(margin-top, 100);
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .thankyou-icons-links {
    display: flex;
    justify-content: space-between;
    @include vw(padding-bottom, 158);
    @include breakpoint(mobile) {
      display: flex;
      flex-direction: column;
      @include vwMobile(margin-top, 40);
      @include vwMobile(margin-bottom, 0);
    }
    .box {
      @include vw(width, 496);
      @include vw(height, 160);
      position: relative;
      border: 1px solid $grayButton;
      margin-bottom: 0;
      background-color: $almostBlack;
      transition: $corners-transition;
      @include vw(padding, 0);
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(height, 160);
        @include vwMobile(margin-bottom, 20);
      }
      .corners {
        width: 100%;
        height: 100%;
        @include buildCorners(17, 0);
        @include breakpoint(mobile) {
          @include buildCorners(65, 0);
        }
      }
      &:hover {
        @include vw(padding, 15);
        cursor: pointer;
        transition: $corners-transition;
      }
      .real-content {
        position: absolute;
        display: flex;
        @include vw(top, 40);
        @include vw(left, 40);
        @include breakpoint(mobile) {
          @include vwMobile(top, 40);
          @include vwMobile(left, 40);
          align-items: center;
        }
        .box-image {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          @include vw(height, 80);
          @include vw(width, 80);
          @include breakpoint(mobile) {
            @include vwMobile(height, 80);
            @include vwMobile(width, 80);
          }
        }
        .box-image-support {
          background-image: url('../../../../assets/images/icons/Icon\ -\ KeyKeyboard.svg');
        }
        .box-image-chat {
          background-image: url('../../../../assets/images/icons/Icon\ -\ Chat.svg');
        }
        .box-image-discord {
          background-image: url('../../../../assets/images/icons/Icon\ -\ Discord.svg');
        }
        .box-text {
          @include vw(margin-left, 40);
          .box-text-title {
            @include vw(font-size, 22);
            font-weight: bold;
            line-height: 1.36;
            text-transform: uppercase;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }
          .box-text-desc {
            @include vw(font-size, 20);
            line-height: 2;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              line-height: 2.5;
            }
          }
        }
      }
    }
  }
  .popup-content {
    width: 35% !important;
    padding: 0 !important;
    border: 0 !important;
    @include breakpoint(mobile) {
      width: 90% !important;
    }
    .exitIcon {
      position: absolute;
      border-width: 0.1px;
      border-style: solid;
      border-color: $gray;
      background-clip: content-box;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Close.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      // @include vw(margin-left, 1820);
      opacity: 0.8;
      @include vw(top, 35);
      @include vw(right, 35);
      @include vw(width, 30);
      @include vw(height, 30);
      @include vw(padding, 5);
      @include breakpoint(mobile) {
        @include vwMobile(top, 20);
        @include vwMobile(right, 20);
        @include vwMobile(width, 30);
        @include vwMobile(height, 30);
        @include vwMobile(padding, 5);
        @include vwMobile(margin-left, 437);
      }
    }
  }
}
