.building-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  .opacity {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.87) 13%, rgba(0, 0, 0, 0) 51%);
    @include breakpoint(mobile) {
      @include vwMobile(top, 65);
      @include vwMobile(height, 400);
      display: none;
    }
  }
  .background-video {
    position: relative;
    z-index: -100;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include breakpoint(mobile) {
      opacity: 0.4;
      @include vwMobile(height, 400);
      display: none;
    }
  }
  .building-section-presentation {
    position: relative;
    @include vw(height, 400);
    width: 100%;
    @include breakpoint(mobile) {
      @include vwMobile(height, 460);
      @include vwMobile(padding-top, 65);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include vwMobile(padding-right, 20);
      @include vwMobile(padding-left, 20);
      background-image: url('../../../../assets/images/pillars/building/bg-mobile.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
    .building-presentation-title {
      margin-bottom: 0;
      @include vw(margin-top, -400);
      @include vw(font-size, 124);
      @include vw(padding-left, 156);
      animation-duration: 1s;
      animation-name: title;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      @include breakpoint(mobile) {
        margin-top: 0;
        @include vwMobile(padding-left, 0);
        @include vwMobile(font-size, 55);
        margin-bottom: 0;
        animation: none;
        @include vwMobile(margin-top, 15);
      }
    }
    @keyframes title {
      0% {
        opacity: 0;
        @include vw(padding-top, 130);
      }
      8% {
        opacity: 1;
      }
      12% {
        opacity: 0;
      }
      20% {
        opacity: 1;
      }
      28% {
        opacity: 0;
      }
      48% {
        opacity: 1;
      }
      100% {
        opacity: 1;
        @include vw(padding-top, 88);
      }
    }
    .building-presentation-description {
      @include vw(font-size, 63);
      font-weight: normal;
      line-height: 1.51;
      color: $yellowIg;
      @include vw(padding-left, 156);
      animation-duration: 1s;
      animation-name: show;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      @include breakpoint(mobile) {
        @include vwMobile(padding-left, 0);
        @include vwMobile(font-size, 35);
        @include vwMobile(margin-top, -10);
        line-height: 1.4;
        animation: none;
      }
    }

    @keyframes show {
      0% {
        opacity: 0;
      }
      8% {
        opacity: 1;
      }
      12% {
        opacity: 0;
      }
      20% {
        opacity: 1;
      }
      28% {
        opacity: 0;
      }
      48% {
        opacity: 1;
      }
    }

    .theme-button {
      @include vw(width, 260);
      @include vw(height, 65);
      position: absolute;
      @include vw(right, 20);
      @include vw(bottom, 25);
      @include breakpoint(mobile) {
        width: 100%;
        position: relative;
        right: 0;
        z-index: 3;
        @include vwMobile(height, 65);
        @include vwMobile(margin-top, 180);
      }
      .theme-button-container {
        div {
          background-image: url('../../../../assets/images/icons/Icon-Play.svg');
          @include breakpoint(mobile) {
            @include vwMobile(background-size, 14);
            @include vwMobile(width, 16);
            @include vwMobile(height, 22);
            @include vwMobile(margin-right, 14);
          }
          span {
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              line-height: 1.38;
            }
          }
        }
      }
    }
    &:after {
      position: absolute;
      z-index: 0;
      @include vw(top, 0);
      border-left-style: solid;
      border-left-color: $gray-pillar;
      content: ' ';
      transform: skew(-36deg, 0deg);
      @include vw(border-left-width, 1);
      @include vw(left, 1500);
      @include vw(height, 400);
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }
  .building-line {
    width: 0;
    position: relative;
    align-self: flex-start;
    @include vw(margin-top, 20);
    @include vw(margin-bottom, 60);
    @include vw(height, 1.5);
    background-color: $gray-pillar;
    animation-duration: 1s;
    animation-name: lineloading;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 20);
      @include vwMobile(margin-bottom, 30);
    }
  }
  @keyframes lineloading {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  .building-stars {
    @include vw(min-height, 2000);
    @include vw(width, 991);
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../../../assets/images/pillars/building/stars.png');
    background-size: cover;
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .building-sidebar {
    @include vw(padding-left, 991);
    @include vw(padding-right, 156);
    @include vw(min-height, 2000);
    position: relative;
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      width: 100%;
    }

    .side_bar_title {
      @include vw(margin-bottom, 40);
      * {
        @include vw(font-size, 50);
        line-height: 1.4;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 35);
        }
      }
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 25);
        position: relative;
        z-index: 6;
      }
    }
    .building-sidebar-items {
      .item-content {
        * {
          @include vw(font-size, 20);
          line-height: 2;
          color: $gray;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
        a {
          color: $yellowMild;
          text-decoration: underline;
        }
      }
      .item-image {
        width: 100%;
        @include vw(margin-top, 60);
        @include vw(margin-bottom, 60);
        @include vw(height, 279);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 40);
          @include vwMobile(margin-bottom, 40);
          @include vwMobile(height, 279);
          border-bottom: 1px solid $grayButton;
        }
        .item-header-bottom {
          position: absolute;
          @include vw(right, -25);
          bottom: 0;
          transform: skew(-36deg);
          @include vw(width, 600);
          @include vw(height, 65);
          @include vw(border-left-width, 12);
          border-left-style: solid;
          border-left-color: $yellowIg;
          background-color: $almostBlack;
          display: flex;
          align-items: center;
          @include breakpoint(mobile) {
            @include vwMobile(right, 0);
            width: 100%;
            transform: none;
            border-left: 0;
            @include vwMobile(height, 65);
          }
          cursor: pointer;
          &:hover p {
            color: $yellowIg;
          }
          .small-line {
            display: none;
            position: absolute;
            @include vw(left, 38);
            @include vw(width, 15);
            @include vw(height, 1);
            transform: skew(36deg);
            background-color: $gray-psw;
            @include breakpoint(mobile) {
              display: none;
            }
          }
          &:hover .small-line {
            display: block;
          }
          &:hover .arrow {
            background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
            @include vw(background-size, 12);
            @include breakpoint(mobile) {
              @include vwMobile(background-size, 12);
            }
          }
          p {
            @include vw(margin-left, 70);
            @include vw(font-size, 19);
            transform: skew(36deg);
            font-weight: bold;
            @include breakpoint(mobile) {
              transform: none;
              @include vwMobile(font-size, 16);
            }
          }
          .arrow {
            position: absolute;
            @include vw(width, 20);
            @include vw(height, 20);
            background-image: url('../../../../assets/images/icons/Icon-DropArrow.svg');
            background-position: center;
            @include vw(background-size, 17);
            background-repeat: no-repeat;
            @include vw(right, 40);
            transform: skew(36deg);
            transition: background-image $corners-transition;
            @include breakpoint(mobile) {
              @include vwMobile(width, 20);
              @include vwMobile(height, 20);
              @include vwMobile(background-size, 17);
              @include vwMobile(right, 25);
              transform: skew(0deg);
            }
          }
        }
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      .theme-button-spec-positive {
        position: relative;
        @include vw(min-width, 260);
        @include vw(margin-top, 70);
        @include vw(margin-bottom, 80);
        @include breakpoint(mobile) {
          width: 100%;
          @include vwMobile(height, 65);
          @include vwMobile(margin-top, 60);
          @include vwMobile(margin-bottom, 49);
        }
        .theme-button-container {
          div {
            background-image: url('../../../../assets/images/icons/no-border/Icon-Game.svg');
            @include vw(width, 27);
            @include vw(height, 17);
            @include vw(background-size, 27);
            @include breakpoint(mobile) {
              @include vwMobile(width, 27);
              @include vwMobile(height, 17);
              @include vwMobile(background-size, 27);
              @include vwMobile(margin-right, 15);
            }
          }
          span {
            color: $white;
          }
        }
        &:hover {
          background-color: $green;
          .theme-button-container {
            div {
              background-image: url('../../../../assets/images/icons/no-border/Icon-Game-Green.svg');
            }
            span {
              color: $black;
            }
          }
        }
      }
    }
  }
  .building-form-container {
    width: 100%;
    @include vw(padding-right, 156);
    @include vw(padding-left, 156);
    @include breakpoint(mobile) {
      padding: 0;
    }
    .building-form {
      @include vw(margin-bottom, 113);
      @include vw(min-height, 400);
      background-color: $blueDark;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      @include vw(padding-left, 60);
      @include breakpoint(mobile) {
        @include vw(min-height, 619);
        background-image: none !important;
        width: 100%;
        @include vwMobile(padding-left, 20);
        @include vwMobile(padding-right, 20);
        @include vwMobile(padding-top, 20);
        margin-bottom: 0;
      }
      .building-form-img-mobile {
        display: none;
        @include breakpoint(mobile) {
          display: block;
          width: 100%;
          @include vwMobile(height, 217);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .building-form-title {
        * {
          @include vw(font-size, 35);
          font-weight: bold;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 25);
            font-weight: bold;
            line-height: 1.4;
          }
        }
        @include vw(padding-top, 50);
        @include breakpoint(mobile) {
          @include vwMobile(padding-top, 40);
        }
      }
      .building-form-description {
        * {
          line-height: 2;
          @include vw(font-size, 20);
          color: $gray;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
        @include vw(width, 552);
        @include vw(margin-top, 15);
        @include breakpoint(mobile) {
          width: 100%;
          @include vwMobile(margin-top, 20);
        }
      }
    }
  }
  form.newsletter {
    @include vw(width, 520);
    @include breakpoint(mobile) {
      width: 100%;
    }
    .disclaimer {
      * {
        margin-top: 0;
        display: inline-block;
      }
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 20);
        @include vwMobile(padding-bottom, 50);
        margin-bottom: 0;
      }
    }
    .util-input {
      background-color: $almostBlack;
      @include vw(width, 496);
      @include vw(margin-top, -15);
      @include vw(margin-bottom, 23);
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(margin-top, 0);
      }
    }
  }
}
.image-planet {
  @include vw(left, -15);
  @include vw(width, 889);
  @include vw(height, 967);
  background-image: url('../../../../assets/images/pillars/building/planet-big.png');
  animation-duration: 2000ms;
  animation-name: planet;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  @include breakpoint(mobile) {
    @include vwMobile(width, 340);
    @include vwMobile(height, 377);
    // @include vwMobile(top, -310);
    // @include vwMobile(left, -20);
    @include vwMobile(top, 200);
    @include vwMobile(left, -20);
    animation: none;
  }
}

@keyframes planet {
  from {
    @include vw(top, 288);
  }
  to {
    @include vw(top, -390+540);
  }
}
.image-pillar {
  position: absolute;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2;
  transition: transform 500ms linear;
}
.image-ship-one {
  @include vw(width, 900);
  @include vw(height, 376);
  background-image: url('../../../../assets/images/pillars/building/ship-one.png');
  left: 0;
  @include vw(top, 50);
  z-index: 3;
  animation-duration: 2300ms;
  animation-name: ship;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  cursor: pointer;
  @include breakpoint(mobile) {
    // @include vwMobile(top, -312);
    // @include vwMobile(left, -40);
    @include vwMobile(top, 200);
    @include vwMobile(left, -40);
    @include vwMobile(width, 460);
    @include vwMobile(height, 167);
    animation: none;
  }
}
@keyframes ship {
  from {
    @include vw(top, 20+540);
  }
  to {
    @include vw(top, 50+540);
  }
}
.image-ship-one:hover + .ship-label {
  display: flex;
  align-items: center;
  @include vw(top, 50+830);
  @include vw(left, 350);
}
.image-sat:hover + .ship-label {
  display: flex;
  align-items: center;
  @include vw(left, 430);
  @include vw(top, 500+870);
}
.image-ship-two:hover + .ship-label {
  display: flex;
  align-items: center;
  @include vw(left, 350);
  @include vw(top, 865+870);
}
.image-ship-three:hover + .ship-label {
  display: flex;
  align-items: center;
  @include vw(left, 750);
  @include vw(top, 1210+990);
}
.image-ship-multiple:hover + .ship-label {
  display: flex;
  align-items: center;
  @include vw(left, 70);
  @include vw(top, 1600+1070);
}
.ship-label {
  display: none;
  position: absolute;
  z-index: 2;
  animation-duration: 1s;
  //animation-name: ship-label;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  p {
    @include vw(font-size, 16);
    line-height: 1.81;
    @include vw(margin-left, 16);
  }
  div {
    background-image: url('../../../../assets/images/icons/Icon-Ship-Bar.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @include vw(width, 40);
    @include vw(height, 10);
  }
}
@keyframes ship-label {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  12% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  28% {
    opacity: 0;
  }
  48% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.image-sat {
  background-image: url('../../../../assets/images/pillars/building/sat.png');
  @include vw(width, 379);
  @include vw(height, 253);
  @include vw(left, 430);
  @include vw(top, 500+540);
  z-index: 5;
  cursor: pointer;
  @include breakpoint(mobile) {
    display: none;
  }
}
.image-planet-medium {
  background-image: url('../../../../assets/images/pillars/building/planet-medium.png');
  @include vw(width, 400);
  @include vw(height, 400);
  @include vw(left, 540);
  @include vw(top, 820+540);
  @include breakpoint(mobile) {
    display: none;
  }
}
.image-ship-two {
  @include vw(width, 760);
  @include vw(height, 250);
  background-image: url('../../../../assets/images/pillars/building/ship-two.png');
  @include vw(left, 100);
  @include vw(top, 865+540);
  cursor: pointer;
  z-index: 3;
  @include breakpoint(mobile) {
    display: none;
  }
}
.image-planet-small {
  background-image: url('../../../../assets/images/pillars/building/planet-small.png');
  @include vw(width, 99);
  @include vw(height, 104);
  @include vw(left, 140);
  @include vw(top, 1210+500);
  @include breakpoint(mobile) {
    display: none;
  }
}
.image-ship-three {
  @include vw(width, 612);
  @include vw(height, 347);
  background-image: url('../../../../assets/images/pillars/building/ship-three.png');
  @include vw(left, 340);
  @include vw(top, 1210+720);
  cursor: pointer;
  z-index: 3;
  @include breakpoint(mobile) {
    display: none;
  }
}
.image-ship-multiple {
  @include vw(width, 736);
  @include vw(height, 487);
  background-image: url('../../../../assets/images/pillars/building/ship-multiple.png');
  @include vw(left, 140);
  @include vw(top, 1600+450);
  cursor: pointer;
  z-index: 3;
  @include breakpoint(mobile) {
    display: none;
  }
}
