.nvidia-container {
  @include vw(margin-top, 80);
  @include vw(margin-bottom, 80);
  position: relative;
  width: 100%;
  @include vw(min-height, 400);
  background-color: $blueDark;
  @include vw(padding-top, 63);
  @include vw(padding-left, 80);
  @include breakpoint(mobile) {
    min-height: auto;
    @include vwMobile(padding-top, 20);
    @include vwMobile(padding-bottom, 50);
    @include vwMobile(padding-left, 20);
    @include vwMobile(padding-right, 20);
  }
  .nvidia-title,
  .nvidia-description,
  .theme-button {
    position: relative;
    z-index: 8;
  }
  .nvidia-title {
    * {
      @include vw(font-size, 35);
      line-height: 1.4;
      font-weight: bold;
      z-index: 10;
      text-transform: uppercase;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
        line-height: 1.4;
      }
    }
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 30);
    }
  }
  .nvidia-description {
    @include vw(margin-top, 30);
    @include vw(max-width, 680);
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      max-width: 100%;
      @include vwMobile(margin-top, 20);
      @include vwMobile(margin-bottom, 40);
    }
    * {
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        line-height: 2;
      }
    }
  }
  .theme-button {
    @include vw(min-width, 205);
    @include breakpoint(mobile) {
      width: 100%;
      @include vwMobile(height, 65);
    }
    .theme-button-container {
      div {
        @include vw(width, 10);
        @include vw(height, 13);
        @include vw(margin-right, 18);
        @include vw(background-size, 10);
        @include breakpoint(mobile) {
          @include vwMobile(width, 13);
          @include vwMobile(height, 13);
          @include vwMobile(margin-right, 18);
          @include vwMobile(background-size, 13);
        }
      }
      span {
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 1.38;
        }
      }
    }
  }
  .nvidia-logo {
    @include vw(width, 378);
    @include vw(height, 95);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    @include vw(top, 233);
    @include vw(right, 213);
    z-index: 7;
    @include breakpoint(mobile) {
      background-size: cover;
      @include vwMobile(width, 227);
      @include vwMobile(height, 57);
      @include vwMobile(top, 105);
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .nvidia-logo-large {
    @extend .nvidia-logo;
    @include vw(width, 450);
    @include vw(height, 112);
    top: 0;
    bottom: 0;
    margin: auto;

    @include breakpoint(mobile) {
      @include vwMobile(width, 227);
      @include vwMobile(height, 57);
      @include vwMobile(top, 105);
      bottom: unset;
    }
  }

  .nvidia-image {
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    position: absolute;
    top: 0;
    right: 0;
    @include vw(width, 960);
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .nvidia-image-large {
    @extend .nvidia-image;
    @include vw(width, 1140);
  }
  .nvidia-image-mobile {
    @include breakpoint(mobile) {
      width: 100%;
      @include vwMobile(height, 217);
    }
  }
}
.nvidia-small-p {
  @include vw(padding-left, 156);
  @include vw(padding-right, 156);
  @include breakpoint(mobile) {
    @include vwMobile(padding-left, 20);
    @include vwMobile(padding-right, 20);
  }
}
.nvidia-large {
  @extend .nvidia-container;
  @include vw(min-height, 700);
  @include vw(padding-top, 135);
  @include vw(padding-left, 156);
  .nvidia-description {
    @include vw(margin-top, 40);
    @include vw(margin-bottom, 60);
    @include vw(max-width, 560);
    @include breakpoint(mobile) {
      max-width: 100%;
      @include vwMobile(margin-top, 20);
      @include vwMobile(margin-bottom, 40);
    }
  }
  @include breakpoint(mobile) {
    @include vwMobile(padding-left, 20);
    @include vwMobile(padding-top, 20);
  }
}
.nvidia-large-reduced {
  @extend .nvidia-large;
  @include vw(padding-left, 100);
}
.nvidia-logo-footer {
  @include vw(width, 200);
  @include vw(height, 50);
  margin-left: auto;
  margin-right: auto;
  @include vw(margin-top, 80);
  @include vw(margin-bottom, 63);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @include breakpoint(mobile) {
    @include vwMobile(width, 180);
    @include vwMobile(height, 57);
    @include vwMobile(margin-top, 60);
    @include vwMobile(margin-bottom, 43);
  }
}
.nvidia-banner-footer {
  width: 100%;
  @include vw(height, 120);
  @include vw(margin-top, 80);
  @include vw(margin-bottom, 55);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @include breakpoint(mobile) {
    @include vwMobile(height, 65);
    @include vwMobile(margin-top, 60);
    @include vwMobile(margin-bottom, 43);
  }
}
