div {
  &.homepagestartlandingone {
    text-align: center;
    @include vw(margin-top, 90);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 20);
    }
    div {
      //width: 100%;
      &.popup-content {
        @include vw(width, 1608);
        width: 80% !important;
        @include vw(height, 700);
        padding: 0 !important;
        border: 0 !important;
        background-color: $almostBlack !important;
      }
      &.popUpContainer {
        position: relative;
        width: 100%;
        height: 100%;
        .exitIcon {
          position: absolute;
          border-width: 0.1px;
          border-style: solid;
          border-color: $gray;
          background-clip: content-box;
          background-image: url('../../../../../assets/images/icons/no-border/Icon-Close.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          cursor: pointer;
          @include vw(top, -25);
          @include vw(right, -55);
          @include vw(width, 40);
          @include vw(height, 40);
          @include vw(padding, 5);
          // @include vw(margin-left, 1820);

          @include breakpoint(mobile) {
            @include vwMobile(width, 40);
            @include vwMobile(height, 40);
            @include vwMobile(margin-left, 437);
          }
        }
        video,
        iframe {
          position: relative;
          z-index: 20;
          width: 100%;
          // @include vw(width, 1608);
          // @include vw(height, 700);
          height: 100%;
          object-fit: cover;
        }
      }
    }

    div {
      &.cityImage {
        @include vw(height, 1000);
        position: absolute;
        z-index: -1;
        top: 0px;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(12, 19, 21, 0), $almostBlack),
          url('../../../../../assets/images/homepage/city.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include vw(height, 1000);
      }

      &.dualUniverseLogo {
        @include vw(width, 589);
        @include vw(height, 261);
        margin: 0 auto;
        background-image: url('../../../../../assets/images/du-logo-updated.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(margin-bottom, 240);
        @include breakpoint(mobile) {
          @include vwMobile(width, 310);
          @include vwMobile(height, 131);
          // @include vwMobile(width, 310);
          // @include vwMobile(height, 131);
          @include vwMobile(margin-bottom, 210);
        }
      }

      &.actionButtons {
        text-align: center;
        @include vw(margin-top, 30);
        //@include vw(margin-bottom, 140);

        @include breakpoint(mobile) {
          text-align: center;
          @include vwMobile(padding-left, 20);
          @include vwMobile(padding-right, 20);
        }
        button {
          @include vw(width, 300);
          @include vw(height, 65);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            width: 100%;

            @include vwMobile(height, 65);
            @include vwMobile(margin-bottom, 15);
          }
          .theme-button-container {
            width: 95%;
            margin-right: auto;
            margin-left: auto;
            div {
              background-image: url('../../../../../assets/images/icons/Icon-Play.svg');
              @include vw(width, 20);
              @include vw(height, 20);
              background-size: contain;
              @include breakpoint(mobile) {
                @include vwMobile(width, 20);
                @include vwMobile(height, 20);
              }
            }
          }
          &.joinBeta {
            &:hover {
              background-color: $color-positive;
            }
            .theme-button-container {
              div {
                background-image: url('../../../../../assets/images/icons/no-border/Icon-Game.svg');
              }
            }
            &:hover .theme-button-container div {
              background-image: url('../../../../../assets/images/icons/no-border/Icon-Game-Green.svg');
            }
            &:hover .theme-button-container span {
              color: #000;
            }
            @include breakpoint(from-phablet) {
              // @include vw(margin-right, 155);
              @include vw(margin-left, 40);
            }
          }
        }
      }
    }

    p {
      &.title {
        display: none;
        color: $yellowIg;
        font-weight: bold;

        text-transform: uppercase;
        @include vw(font-size, 70);
        @include vw(margin-bottom, 30);
        @include vw(line-height, 100);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 35);
          @include vwMobile(margin-bottom, 20);
          color: $white;
        }
      }

      &.explanation {
        margin: 0 auto;
        color: $white;
        font-weight: bold;
        line-height: 1.2;
        opacity: 1;
        text-transform: uppercase;
        @include vw(font-size, 50);
        @include vw(margin-bottom, 45);
        @include vw(width, 958);
        // @include vw(line-height, 42);
        @include breakpoint(mobile) {
          @include vwMobile(width, 373);
          @include vwMobile(font-size, 22);
          @include vwMobile(margin-bottom, 40);
          @include vwMobile(line-height, 30);
          color: $yellowIg;
        }
      }
      &.explanation-fr {
        @include vw(width, 1400);
      }
      &.explanation-de {
        @include vw(width, 1400);
      }
    }
  }
}
.modal-open {
  overflow: hidden;
}
