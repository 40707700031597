.megasubmenucontainer {
  @extend .submenutemplate;
  z-index: 100;

  @include breakpoint(from-phablet) {
    @include vw(width, 1030);
    @include vw(min-height, 285);
    @include vw(left, 170);
  }

  .megasubmenu {
    @extend .simplesubmenutemplate;

    @include breakpoint(from-phablet) {
      @include vwAll(padding, 0, 60, 40, 60);
      @include vwColumns(3, 30, true);
      @include vw(min-height, 220);
      @include vw(width, 960);
    }

    .submenublock {
      @include vw(margin-top, 60);

      .submenuheader {
        @extend .submenuheadertemplate;
      }
      .submenucontent {
        @extend .submenucontenttemplate;
      }
    }
  }
}
