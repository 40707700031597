.launcher-detail-container {
  height: 100vh;
  overflow-y: scroll;
  scrollbar-color: $gray-scroll $almostBlack;
  scrollbar-width: thin;
  @include vw(margin-right, 10);
  @include breakpoint(mobile) {
    @include vwMobile(height, 1080);
  }
  &::-webkit-scrollbar {
    width: 7.5px;
  }
  &::-webkit-scrollbar-track {
    background: $almostBlack;
  }
  &::-webkit-scrollbar-thumb {
    border-right: 1px solid $almostBlack;
    background: $gray-scroll;
  }
  .launcher-intro {
    @include vw(height, 280);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include vw(padding-top, 20);
    a.launcher-top-btn {
      position: fixed;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      border-style: solid;
      border-color: $gray-patch-nav;
      background-color: $black-news-detail;
      outline: none;
      @include vw(height, 60);
      @include vw(width, 121);
      @include vw(border-width, 1);
      @include vw(margin-left, 40);
      span {
        @include vw(font-size, 16);
        color: $white;
        line-height: 1;
        opacity: 0.9;
      }
      .icon-back {
        @include vw(height, 16);
        @include vw(width, 16);
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
        background-position: center;
        background-repeat: no-repeat;
        transform: rotate(180deg);
        @include vw(background-size, 8);
        @include vw(margin-right, 15);
      }
    }
  }
  .launcher-content {
    @include vw(padding-left, 260);
    @include vw(padding-right, 260);
    .launcher-content-title {
      h1,
      h3 {
        font-weight: bold;
        line-height: 1.4;
        @include vw(font-size, 45);
        @include vw(margin-top, 50);
        @include vw(margin-bottom, 0);
      }
    }
    .launcher-content-desc {
      p {
        @include vw(font-size, 26);
        color: $gray-dark;
        line-height: 1.54;
        @include vw(margin-top, 30);
      }
    }
    .launcher-content-date {
      color: $yellowIg;
      font-weight: bold;
      line-height: 1.44;
      text-transform: uppercase;
      @include vw(margin-top, 40);
      @include vw(font-size, 18);
    }
    .launcher-content-detail {
      @include vw(margin-top, 25);
      border-top: 1px solid $gray-content-border;
      @include vw(border-width, 1);
      @include vw(padding-top, 50);
      * {
        @include vw(font-size, 20);
        @include vw(margin-bottom, 20);
        color: $gray-content;
        line-height: normal;
      }
      img {
        margin-bottom: 0;
      }
      iframe,
      img {
        width: 100%;
        @include vw(margin-top, 14);
      }
      iframe {
        @include vw(height, 650);
      }
      p,
      li {
        line-height: 1.75;
      }
      a {
        color: $yellowIg;
        text-decoration: underline;
      }
      .list {
        margin-bottom: 0;
      }
      h1 {
        @include vw(font-size, 35);
      }
      h2 {
        @include vw(font-size, 30);
      }
      h3 {
        @include vw(font-size, 25);
      }
      ul {
        padding-left: 0;
        li {
          @include vw(padding-left, 0);
          @include vw(margin-bottom, 8);
          @include vw(margin-left, 28);
          @include vw(font-size, 20);

          @include breakpoint(mobile) {
            @include vwMobile(font-size, 18);
          }
          list-style-type: none;
          &::before {
            display: inline-block;
            color: $gray-psw;
            content: '+';
            font-weight: normal;
            @include vw(width, 20);
            @include vw(margin-left, -33);
            @include vw(margin-right, 20);
            @include breakpoint(mobile) {
              @include vwMobile(width, 20);
              @include vwMobile(margin-left, -33);
              @include vwMobile(margin-right, 10);
            }
          }
        }
      }
    }
    .launcher-navigation {
      display: flex;
      justify-content: space-between;
      @include vw(margin-top, 96);
      @include vw(margin-bottom, 103);
      p {
        @include vw(font-size, 18);
        line-height: 1.39;
        &:hover {
          text-decoration: underline;
        }
      }
      .nav-before,
      .nav-after {
        display: flex;
        align-items: center;
      }
      .nav-before-box,
      .nav-after-box {
        @include vw(height, 25);
        @include vw(width, 25);
        border: 1px solid $gray-psw;
      }

      .nav-after-box,
      .nav-before-box {
        @include vw(margin-left, 25);
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
        background-position: center;
        background-repeat: no-repeat;
        @include vw(background-size, 8);
      }
      .nav-before-box {
        @include vw(margin-right, 25);
        margin-left: 0;
        transform: rotate(180deg);
      }
    }
  }
}
