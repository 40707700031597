.tutorials-other {
  $corners-width: 499;
  $corners-height: 160;
  $corners-over: 10;

  h3 {
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 40);
    }
  }
  .links {
    @include vw(grid-column-gap, 40);
    @include vw(margin-top, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 40);
    }
    .item {
      position: relative;
      border-style: solid;
      border-color: $whiteLessTransparent;
      background-color: $almostBlack;
      @include vw(border-width, 1);

      @include vw(width, 500);
      @include vw(height, $corners-height);
      padding: 0;
      transition: $corners-transition;
      &:hover {
        @include vw(padding, 15);
        @include breakpoint(mobile) {
          @include vwMobile(padding, 15);
        }
      }
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(height, $corners-height);
      }
      .corners {
        height: 100%;
        width: 100%;
        @include buildCorners(20, 0);
        @include breakpoint(mobile) {
          @include buildCornersMobile(20, 0);
          width: 100%;
          height: 100%;
        }
      }
      .square {
        position: absolute;
        @include vw(left, 43);
        top: 0;
        min-width: 80%;
        height: 100%;
        display: flex;

        @include breakpoint(mobile) {
          @include vwMobile(left, 20);
        }
      }
    }
    .icon {
      @include vw(width, 80);
      @include vw(margin-right, 35);

      @include breakpoint(mobile) {
        @include vwMobile(width, 80);
        @include vwMobile(margin-right, 20);
      }
    }
    .side {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .label,
    .description {
      p {
        color: $white;
      }
    }
    .label {
      * {
        font-weight: bold;
        line-height: normal;
        text-transform: uppercase;
        @include vw(font-size, 22);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
    .description {
      * {
        line-height: normal;
        @include vw(font-size, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
  }
}
