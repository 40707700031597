.common-icons {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.95;
}

.icon-get-code {
  background-image: url('../../assets/images/icons-ref/Icon-Code-Ref.svg');
  @extend .common-icons;
}

.icon-recruit-novean {
  background-image: url('../../assets/images/icons-ref/Icon-Recruit-Ref.svg');
  @extend .common-icons;
}

.icon-get-reward {
  background-image: url('../../assets/images/icons-ref/Icon-Rewards-Ref.svg');
  @extend .common-icons;
}

.icon-check-progress {
  background-image: url('../../assets/images/icons-ref/Icon-Progress-Ref.svg');
  @extend .common-icons;
}

.icon-du {
  background-image: url('../../assets/images/Logo-DU.svg');
  @extend .common-icons;
}
.icon-maintenance {
  background-image: url('../../assets/images/Maintenance.svg');
  @extend .common-icons;
}
.icon-steam {
  @include vw('width', 26);
  @include vw('height', 26);
  background-image: url('../../assets/images/icons/steam.svg');
  @extend .common-icons;
}

.icon-support {
  background-image: url('../../assets/images/status-page/Icon-Support.svg');
  @extend .common-icons;
}
