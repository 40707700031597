.server-status-section {
  .img-mobile {
    display: none;
    @include breakpoint(mobile) {
      display: block;
      @include vwMobile(min-height, 220);
      background-image: url('../../../assets/images/status-page/status-page-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      @include vwMobile(background-position-x, -320);
    }
    border-bottom: 1px solid $grayButton;
  }
  .content {
    @extend .util-main-padding;

    @include vw(min-height, 449);
    @include vw(padding-top, 85);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $gray-medium;

    background-image: url('../../../assets/images/status-page/status-page-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    @include breakpoint(mobile) {
      background-image: none;
      @include vwMobile(padding-top, 27);
      @include vwMobile(margin-bottom, 45);
      border: 0;
    }
    .title {
      h1 {
        @extend .header_main_big;
        @include vw(margin-top, 0);
        @include vw(margin-bottom, 0);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 35);
          @include vwMobile(margin-bottom, 10);
          line-height: 1.4;
          text-align: center;
        }
      }
    }

    .description {
      @extend .header_secondary_big;
      @extend .text_header_secondary_color;
      @include vw(max-width, 800);
      @include breakpoint(mobile) {
        max-width: 100%;
        text-align: justify;
      }
    }
  }

  .server-status-subsection {
    @extend .util-main-padding;
    display: grid;
    grid-template-columns: 0.72fr 0.29fr;

    @include vw(gap, 61);
    @include vw(margin-top, -100);
    @include breakpoint(mobile) {
      @include vwMobile(gap, 42);
      grid-template-columns: 1fr;
      @include vwMobile(margin-top, 0);
      @include vwMobile(margin-bottom, 30);
    }
  }
  .navbar-mobile {
    display: none;
    @include breakpoint(mobile) {
      display: flex;
      @include vwMobile(padding-top, 65);
      @include vwMobile(height, 130);
      border-top: 1px solid $grayButton;
      border-bottom: 1px solid $grayButton;
    }
    .navbar-mobile-current-content {
      display: flex;
      align-items: center;
    }
    a {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @include vwMobile(padding-right, 20);
      @include vwMobile(padding-left, 20);
      border-top: 1px solid $grayButton;
      span {
        color: $white;
        opacity: 0.8;
        @include vwMobile(font-size, 18);
      }
      p {
        @include vwMobile(font-size, 16);
        line-height: 2.5;
        text-transform: uppercase;
      }
      p:before {
        color: $yellowIg;
        content: '\002B'; //    Icon-NavDropArrow.41c5672c.svg)
        @include vwMobile(padding-right, 20);
      }
      div.navbar-mobile-left-icon {
        background-image: url('../../../assets/images/icons-navbar/Icon-NavDropArrowYellow.svg');
        background-position: center;
        background-repeat: no-repeat;

        @include vwMobile(background-size, 15);
        @include vwMobile(width, 20);
        @include vwMobile(height, 20);
      }
      .navbar-mobile-right-icon {
        background-image: url('../../../assets/images/icons-navbar/icon-closemenu.svg');
        background-position: center;
        background-repeat: no-repeat;
        @include vwMobile(background-size, 23);
        @include vwMobile(width, 18);
        @include vwMobile(height, 21);
      }
    }
  }
  .navbar-mobile-options {
    @include breakpoint(from-phablet) {
      display: none;
    }
    height: auto;
    @include vwMobile(padding-right, 20);
    @include vwMobile(padding-left, 20);
    @include vwMobile(padding-bottom, 40);
    background-color: $blueDark;
    border-bottom: 1px solid $grayButton;
    .d-flex {
      display: flex;
    }
    a {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $grayButton;
      color: $white;
      line-height: 1.44;
      text-decoration: none;
      @include vwMobile(font-size, 18);
      @include vwMobile(padding-top, 20);
      @include vwMobile(padding-bottom, 20);
    }
  }
  .main-line-status-txt {
    &:before {
      @include vw(margin-right, 18);
      content: '\25CF';
      @include breakpoint(mobile) {
        @include vwMobile(margin-right, 8);
        @include vwMobile(margin-left, 18);
      }
    }

    &.status-up {
      color: $green;

      &:before {
        color: $green;
      }
    }

    &.status-issue {
      color: $maintenance-color;

      &:before {
        color: $maintenance-color;
      }
    }

    &.status-down {
      color: $red;

      &:before {
        color: $red;
      }
    }
    &.status-maintenance {
      color: $maintenance-color;

      &:before {
        color: $maintenance-color;
      }
    }

    text-align: left;
    @include vw(border-right-width, 1);
    @include breakpoint(mobile) {
      border: 0;
    }
  }
}

@import './server-status-blocks/need-assistance.styles';
@import './server-status-blocks/news-status.styles';
@import './server-status-blocks/services-status.styles';
@import './server-status-blocks/server-shard-status.styles';
@import './server-status-blocks/server-maintenance-logs.styles';
