@import './components/medianavbar.styles';
@import './components/categories.component';
.center-x {
  display: flex;
  justify-content: center;
}
.media-container {
  .mediaHeader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 36);
    }
    p {
      &.left {
        display: inline-block;
        color: $white;
        font-weight: bold;
        text-transform: uppercase;
        @include vw(font-size, 35);

        @include breakpoint(mobile) {
          @include vwMobile(font-size, 25);
        }
      }
    }
    div {
      &.rightSection {
        display: flex;
        align-items: center;
        float: right;
        p {
          &.right {
            display: inline-block;
            width: 100px;

            // @include vw(min-width, 165);
            width: auto;
            margin-left: auto;
            color: $yellowIg;
            cursor: pointer;
            font-weight: bold;
            text-transform: uppercase;
            @include vw(font-size, 26);
            @include vw(padding-bottom, 2);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 15);
              text-align: right;
              @include vwMobile(padding-top, 4);
              @include vwMobile(padding-bottom, 4);
            }
          }
        }
        a {
          @include vw(height, 40);
          display: flex;
          align-items: center;
          &:hover p {
            border-top: 2px solid transparent;
            border-bottom: 2px solid $yellowIg;
          }
        }
        div {
          display: inline-block;
          border: solid 1px $yellow;
          background-clip: content-box;
          background-image: url('../../../assets/images/media/Icon\ -\ Continue.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          @include vw(width, 40);
          @include vw(height, 40);
          @include vw(margin-left, 30);
          @include vw(padding, 8);
          @include breakpoint(mobile) {
            display: none;
          }
        }
      }
    }
  }

  .mediaCarousel {
    // @include vw(margin-top, 80);
    @include vw(padding-top, 30);
    @include vw(height, 740);
    width: 100%;

    background-image: url('../../../assets/images/media/space.png');
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(mobile) {
      @include vwMobile(height, 367);
    }

    h1 {
      &.mediaHeader {
        @include vw(font-size, 70);
        justify-content: center;
        color: $white;
        font-weight: bold;
        text-align: center;
        @include vw(margin-bottom, 40);

        @include breakpoint(mobile) {
          @include vwMobile(font-size, 35);
          @include vwMobile(margin-bottom, 40);
        }
      }
    }

    div {
      &.carouselSection {
        position: relative;
        width: 100%;
        @include vw(height, 506);
        .image {
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        @include breakpoint(mobile) {
          @include vwMobile(height, 260);
        }
        .react-multiple-carousel__arrow {
          z-index: 2;
          border: solid 1px $white;
          background: transparent;
          border-radius: 0;
          opacity: 0.95;
          @include vw(height, 60);
          @include vw(width, 60);
          &::before {
            opacity: 0.95;
          }

          @include breakpoint(mobile) {
            display: none;
          }
        }

        .react-multiple-carousel__arrow--right {
          position: absolute;
          @include vw(right, 400);
        }

        .react-multiple-carousel__arrow--left {
          position: absolute;
          @include vw(left, 400);
        }

        div {
          &.videoCarousel {
            @include breakpoint(from-phablet) {
              @include vw(width, 900);
              @include vw(height, 506);
              position: relative;
              border: solid 1px $grayButton;
              margin: 0 auto;
              @include vw(right, 510);
              @include vw(margin-left, 60);
            }

            @include breakpoint(mobile) {
              @include vwMobile(height, 260);
            }

            video {
              position: relative;
              z-index: 20;
              width: 100%;
              height: 100%;
              background-color: $almostBlack;
              object-fit: cover;
              outline: none;
            }

            iframe {
              width: 100%;
              height: 100%;
              border: 0;
            }
          }
        }
      }
    }
  }

  .screenShotSection {
    width: 100%;
    // @include vw(height, 1967);
    height: auto;
    background-color: $blueDark;
    @include vwAll(padding, 73, 156, 10, 156);

    @include breakpoint(mobile) {
      @include vwAllMobile(padding, 40, 21, 0, 21);
      // @include vwMobile(height, 678);
      height: auto;
    }
    .screenshots-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      @include vw(grid-column-gap, 46);
      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
      }
      .screenshoots-three {
        @include breakpoint(from-phablet) {
          height: auto;
          @include vw(margin-bottom, 46);
          @include vw(border-width, 1);
        }

        @include breakpoint(mobile) {
          width: 100%;
          @include vwMobile(margin-bottom, 21);
        }

        div {
          &.imageContainer {
            border-style: solid;
            border-color: $grayButton;
            @include vw(border-width, 1);
            @include breakpoint(mobile) {
              @include vwMobile(border-width, 1);
            }
            &:hover {
              border-color: $gray-box-media;
              cursor: pointer;
            }
            .image {
              width: 100%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              @include vw(height, 283);

              @include breakpoint(mobile) {
                @include vwMobile(height, 209);
              }
            }

            div {
              &.header {
                @include vw(height, 60);
                @include vw(padding-left, 30);
                display: flex;
                align-items: center;
                background-color: $almostBlack;
                p {
                  @include vw(font-size, 20);
                  color: $white;
                  line-height: 1.4;
                  @include breakpoint(mobile) {
                    @include vwMobile(font-size, 16);
                  }
                }
                @include breakpoint(mobile) {
                  @include vwMobile(height, 49);
                }
              }
            }
          }
        }
      }

      .screenshoots-complete {
        //    width: 100%;
        border-style: solid;
        border-color: $grayButton;
        background-color: $almostBlack;
        content: '';
        // display: grid;
        grid-column: 1/4;
        // @include vw(height, 968);
        @include vw(border-width, 1);
        @include vw(margin-bottom, 46);

        @include breakpoint(mobile) {
          display: none;
          width: 100%;
          border-style: thin;
          @include vwMobile(height, 259);
        }
        &:hover {
          border-color: $gray-box-media;
          cursor: pointer;
        }
        .image {
          width: 100%;
          background-image: url('https://i1.wp.com/fullsync.co.uk/wp-content/uploads/2019/01/dual-universe-header.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          @include vw(height, 900);
          @include breakpoint(mobile) {
            @include vwMobile(height, 209);
          }
        }

        div {
          &.header {
            @include vw(height, 60);
            @include vw(padding-left, 30);
            // height: 100%;
            display: flex;
            align-items: center;
            p {
              @include vw(font-size, 20);
              color: $white;
            }
            @include breakpoint(mobile) {
              @include vwMobile(height, 49);

              @include vwMobile(font-size, 16);
            }
          }
        }
      }
    }
  }
  .videoSection {
    width: 100%;
    // @include vw(height, 1050);
    height: auto;
    background-color: $blueDeep;
    @include vwAll(padding, 73, 156, 60, 156);
    @include breakpoint(mobile) {
      // @include vwMobile(height, 990);
      @include vwAllMobile(padding, 40, 20, 0, 20);
    }

    div {
      &.videoBar {
        @include breakpoint(from-phablet) {
          display: grid;
          width: 100%;
          height: auto;
          border: solid 1px $grayButton;
          grid-template-columns: 0.3fr 0.7fr;
          @include vw(height, 635);
          @include vw(margin-bottom, 60);
        }

        @include breakpoint(mobile) {
          //  @include vwMobile(height, 260);
          @include vwMobile(margin-bottom, 35);
        }

        div {
          &.videoPlayList {
            height: 100%;
            overflow-y: scroll;
            scrollbar-color: #3e4345 $almostBlack;
            scrollbar-width: thin;
            @include breakpoint(mobile) {
              // display: none;
            }
            &::-webkit-scrollbar {
              width: 7.5px;
            }

            &::-webkit-scrollbar-track {
              background: $almostBlack;
            }

            &::-webkit-scrollbar-thumb {
              border-right: 1px solid $almostBlack;
              background: #3e4345;
            }
            @include breakpoint(mobile) {
              display: none;
            }

            .image {
              position: relative;
              width: 100%;
              border-style: solid;
              border-color: $gray-playlist;
              border-left: 0;
              background-color: $black;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              cursor: pointer;
              @include vw(border-width, 1);
              @include vw(margin-bottom, 20);
              @include vw(height, 234);
              &:hover {
                border-color: $gray-box-media;
              }
              .playlist-count {
                position: absolute;
                color: $white;
                font-weight: bold;
                line-height: 1.44;
                text-transform: uppercase;
                @include vw(top, 30);
                @include vw(left, 30);
                @include vw(font-size, 18);
              }
              .playlist-title {
                position: absolute;
                display: flex;
                @include vw(left, 30);
                @include vw(bottom, 30);
                p {
                  @include vw(font-size, 22);
                  line-height: 1.36;
                  text-transform: capitalize;
                }
                .playlist-playing {
                  font-weight: bold;
                  @include vw(margin-right, 5);
                }
                .playlist-playing-icon {
                  @include vw(height, 30);
                  @include vw(width, 30);
                  border: 1px solid $yellowMild;
                  background-image: url('../../../assets/images/icons/Icon-Play-Yellow.svg');
                  background-position: center;
                  background-repeat: no-repeat;
                  opacity: 0.8;
                  @include vw(background-size, 12);
                  @include vw(margin-right, 20);
                  @include breakpoint(mobile) {
                    @include vwMobile(height, 30);
                    @include vwMobile(width, 30);
                    @include vwMobile(background-size, 12);
                    @include vwMobile(margin-right, 20);
                  }
                }
              }
            }
            .selected {
              border-color: $gray-box-media;
            }
          }
          .image:last-of-type {
            @include vw(margin-bottom, 0);
          }

          &.videoContainer {
            video {
              width: 100%;
              height: 100%;
              border: 0;
              background-color: $almostBlack;
              object-fit: cover;
              outline: none;
            }

            @include breakpoint(mobile) {
              @include vwMobile(height, 260);
              @include vwMobile(margin-bottom, 35);
            }
          }
        }
      }

      &.carouselVideoSection {
        width: 100%;
        @include vw(height, 160);

        @include breakpoint(mobile) {
          display: none;
        }

        .carousel-item-padding {
          @include vw(margin-right, 45);
          @include vw(margin-left, 0);
        }
        .react-multiple-carousel__arrow {
          z-index: 2;
          border: solid 1px $white;
          background: transparent;
          border-radius: 0;
          opacity: 0.95;
          @include vw(height, 20);
          @include vw(width, 15);
          &::before {
            opacity: 0.95;
          }
        }

        .react-multiple-carousel__arrow--right {
          position: absolute;
          @include vw(right, 0);
          z-index: 100;
        }

        .react-multiple-carousel__arrow--left {
          position: absolute;
          @include vw(left, 0);
        }
        .selected {
          border: 1px solid $gray;
        }
        div {
          &.videoCarouselSmall {
            @include vw(height, 160);
            width: 100%;
            // @include vw(width, 284);
            @include vw(margin-right, 47);
            @include vw(margin-left, 0);
            li {
              // @include vw(width, 284);
              @include vw(margin-right, 47);
              @include vw(margin-left, 0);
            }
            .carousel-image {
              width: 100%;
              height: 100%;
              background-color: $black;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              cursor: pointer;
            }
            video {
              width: 100%;
              height: 100%;
              border: none;
              object-fit: cover;
              outline: none;
            }
          }
        }
      }
    }
  }

  .wallPaperConceptArt {
    width: 100%;
    @include vw(height, 992);
    // height: auto;
    @include vw(padding-top, 73);
    background-color: $blueDark;

    @include breakpoint(mobile) {
      @include vwMobile(height, 432);
      @include vwMobile(padding-top, 40);
    }

    div {
      &.padding {
        @include vw(margin-right, 155);
        @include vw(margin-left, 155);

        @include breakpoint(mobile) {
          @include vwMobile(margin-right, 20);
          @include vwMobile(margin-left, 20);
        }
      }

      &.carouselScroll {
        @include vw(height, 720);

        @include breakpoint(mobile) {
          @include vwMobile(height, 258);
          width: 100%;
        }

        .react-multiple-carousel__arrow {
          z-index: 2;
          border: solid 1px $white;
          background: transparent;
          border-radius: 0;
          opacity: 0.95;
          @include vw(height, 60);
          @include vw(width, 60);
          &::before {
            opacity: 0.95;
          }

          @include breakpoint(mobile) {
            @include vwMobile(height, 30);
            @include vwMobile(width, 30);
          }
        }

        .react-multiple-carousel__arrow--right {
          position: absolute;
          @include vw(right, 181);

          @include breakpoint(mobile) {
            @include vw(right, 20);
          }
        }

        .react-multiple-carousel__arrow--left {
          position: absolute;
          @include vw(left, 223);

          @include breakpoint(mobile) {
            @include vw(left, 20);
          }
        }

        div {
          &.imageContainer {
            @include breakpoint(from-phablet) {
              @include vw(width, 1280);
              @include vw(height, 720);
              @include vw(margin-right, 60);
              position: relative;
              @include vw(left, 60);
            }
            @include breakpoint(mobile) {
              width: 100%;
              height: 100%;
            }
            .image {
              border-style: solid;
              border-color: $blueDark;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              cursor: pointer;
              @include vw(width, 1280);
              @include vw(height, 720);
              @include breakpoint(mobile) {
                @include vwMobile(height, 258);
                width: 100%;
              }
              @include vw(border-width, 1);
              &:hover {
                @include vw(border-width, 1);
                border-style: solid;
                border-color: $gray-box-media;
              }
            }
          }
        }
      }

      &.statement {
        @include vw(margin-left, 61); //330
        @include vw(margin-top, 20);

        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 10);
          @include vwMobile(margin-left, 17);
        }

        div {
          &.line {
            position: relative;
            display: inline-block;
            border-color: $white;
            border-top-style: solid;
            @include vw(width, 40);
            @include vw(height, 1);
            @include vw(border-width, 1);
            @include vw(margin-right, 40);
            @include vw(bottom, 5);
            @include breakpoint(mobile) {
              @include vwMobile(margin-right, 20);
              @include vwMobile(bottom, 5);
            }
          }
        }
        p {
          display: inline-block;
          color: $white;
          @include vw(font-size, 20);

          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
      }
    }
  }

  .conceptArt {
    background-color: $blueDeep;
    .image-concept {
      border-style: solid;
      border-color: $almostBlack;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      @include vw(width, 1280);
      @include vw(height, 720);
      @include breakpoint(mobile) {
        @include vwMobile(height, 258);
        width: 100%;
      }
      @include vw(border-width, 1);
      &:hover {
        @include vw(border-width, 1);
        border-style: solid;
        border-color: $gray-box-media;
      }
    }
  }

  .documents {
    height: auto;
    @include vwAll(padding, 73, 156, 60, 156);
    background-color: $blueDark;
    @include breakpoint(mobile) {
      @include vwAllMobile(padding, 40, 20, 0, 20);
    }

    div {
      &.documentGrid {
        @include breakpoint(from-phablet) {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;

          @include vw(grid-column-gap, 47);
          @include vw(grid-row-gap, 46);
        }

        div {
          &.document {
            width: 100%;
            border-style: solid;
            border-color: $grayButton;
            background-color: $blueDeep;
            color: $white;
            @include vw(height, 350);
            cursor: pointer;
            @include vw(border-width, 1);
            @include breakpoint(mobile) {
              @include vwMobile(height, 264);
              @include vwMobile(margin-bottom, 20);
              @include vwMobile(border-width, 1);
            }
            &:hover {
              @include vw(border-width, 1);
              border-style: solid;
              border-color: $gray-box-media;
            }
            div {
              &.container {
                background-color: $black;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                content: '';
                @include vw(height, 283);

                @include breakpoint(mobile) {
                  @include vwMobile(height, 209);
                }
              }

              &.header {
                display: flex;
                align-items: center;
                @include vw(height, 60);
                @include vw(padding-left, 30);
                // @include vw(padding-top, 10);
                @include vw(font-size, 20);
                @include breakpoint(mobile) {
                  @include vwMobile(height, 49);
                  @include vwMobile(padding-left, 20);
                  // @include vwMobile(padding-top, 17);
                  @include vwMobile(font-size, 16);
                }
              }
            }
          }
        }
      }
    }
  }
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0% 0%;
}
.popup-component-download,
.popup-component-preview {
  .popup-exit-icon {
    position: absolute;
    z-index: 100;
    border-width: 1px;
    border-style: solid;
    border-color: $white;
    background-clip: content-box;
    background-image: url('../../../assets/images/icons/no-border/Icon-Close.svg');
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    @include vw(background-size, 30);
    @include vw(top, -70);
    @include vw(right, 0);
    @include vw(width, 50);
    @include vw(height, 50);

    @include breakpoint(mobile) {
      position: fixed;
      @include vwMobile(top, 20);
      @include vwMobile(right, 20);
      @include vwMobile(width, 30);
      @include vwMobile(height, 30);
      @include vwMobile(padding, 5);
      @include vwMobile(margin-left, 437);
      @include vwMobile(background-size, 20);
    }
  }
}
.popup-component-preview {
  .popup-container {
    @include vw(width, 1376);
    @include vw(height, 757);
    @include breakpoint(mobile) {
      width: 100%;
      @include vwMobile(height, 227);
    }
    .popup-image-name,
    .popup-image-position,
    .popup-arrow-left,
    .popup-arrow-right {
      position: absolute;
      color: $white;
    }
    .popup-image-name {
      @include vw(bottom, -50);
      display: flex;
      align-items: center;
      @include breakpoint(mobile) {
        @include vwMobile(bottom, -50);
        @include vwMobile(left, 20);
      }
      .popup-image-name-line {
        @include vw(height, 2);
        @include vw(width, 40);
        @include vw(margin-right, 40);
        background-color: $white;
        background-image: url('../../../assets/images/icons/icon-line.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.8;
        @include breakpoint(mobile) {
          @include vwMobile(height, 2);
          @include vwMobile(margin-right, 15);
          @include vwMobile(width, 20);
        }
      }
      p {
        @include vw(font-size, 20);
        color: $white;
        line-height: 2;
        opacity: 1;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 2.5;
        }
      }
    }
    .popup-image-position {
      @include vw(bottom, -55);
      @include vw(right, 0);
      @include vw(font-size, 35);
      font-weight: bold;
      line-height: 1.4;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
        @include vwMobile(top, 490);
        @include vwMobile(right, 381);
      }
    }
    .popup-arrow-left,
    .popup-arrow-right {
      top: 45%;
      border: 1px solid $white;
      background-image: url('../../../assets/images/icons-buttons/Icon-Continue-White.svg');
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      transform: rotate(180deg);
      @include vw(width, 70);
      @include vw(height, 70);
      @include vw(background-size, 25);
      &:hover {
        border: 2px solid $yellowIg;
        background-image: url('../../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
      }
      @include breakpoint(mobile) {
        @include vwMobile(top, 490);
        @include vwMobile(width, 40);
        @include vwMobile(height, 40);
        @include vwMobile(background-size, 15);
      }
    }
    .popup-arrow-left {
      @include vw(left, -100);
      @include breakpoint(mobile) {
        @include vwMobile(left, 355);
      }
    }
    .popup-arrow-right {
      @include vw(right, -100);
      transform: none;
      @include breakpoint(mobile) {
        @include vwMobile(right, 20);
      }
    }
    .popup-image {
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include vw(height, 757);
      @include breakpoint(mobile) {
        @include vwMobile(height, 227);
      }
    }
    .react-player {
      height: 100%;
    }
    video {
      width: 100%;
      background-color: $almostBlack;
      object-fit: cover;
      outline: none;
      @include vw(height, 757);
      @include breakpoint(mobile) {
        @include vwMobile(height, 227);
      }
    }
    .popup-image-desc {
      width: 100%;
      @include vw(height, 80);
    }
  }
}
.popup-component-download {
  .popup-exit-icon {
    @include vw(width, 40);
    @include vw(height, 40);
    @include vw(top, -60);
  }
  .popup-container {
    position: relative;
    @include vw(width, 600);
    border: 1px solid $grayButton;
    @include breakpoint(mobile) {
      width: 100%;
      @include vwMobile(height, 227);
    }
  }
  .popup-container-corners {
    position: absolute;
    width: 100%;
    height: 100%;
    @include buildCorners(25, 0);
  }
  .popup-image {
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include vw(height, 300);
    @include breakpoint(mobile) {
      @include vwMobile(height, 227);
    }
  }
  .popup-download-options {
    // @include vw(height, 408);
    height: auto;
    .download-options-title {
      color: $white;
      line-height: 1.36;
      text-align: center;
      text-transform: uppercase;
      @include vw(font-size, 22);
      @include vw(margin-top, 40);
    }
  }
  .download-options-list {
    @include vw(margin-top, 20);
    @include vw(margin-left, 40);
    @include vw(margin-right, 40);
    @include vw(padding-bottom, 54);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      position: relative;
      display: block;
      width: 100%;
      border-bottom: 1px solid $grayButton;
      color: $gray-medium;
      cursor: pointer;
      line-height: 1.4;
      text-decoration: none;
      @include vw(font-size, 20);
      @include vw(padding-bottom, 20);
      @include vw(padding-top, 20);
      &::before {
        content: '+';
        @include vw(margin-right, 50);
      }
      &:hover {
        color: $yellowMild;
      }
    }
  }
}
