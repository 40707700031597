.full {
  height: 100vh; //previous 100%
}
.navbar-mobile-main {
  width: 100%;
  margin-bottom: 0px;
  position: sticky;
  top: 0;
  background-color: $almostBlack;
  z-index: 10;

  a:focus {
    outline: none;
  }

  &:focus-within .navbar-content-menu {
    display: block;
  }

  .mobile-bar-one {
    @extend .util-main-padding;
    box-sizing: content-box;
    @include vwMobile(height, 45);
    @include vwMobile(padding-top, 10);
    @include vwMobile(padding-bottom, 10);
    @include columns((5, 15, 5), $order: (2, 3, 1));

    .navbar-item-logo-mobile {
      display: flex;
      height: inherit;
      flex-direction: row-reverse;
      align-items: center;

      .navbar-logo-mobile {
        @include vwMobile(width, 40);
        @include vwMobile(height, 30);
        @extend .navbarimage;
      }
    }

    .navbar-item-icon {
      @extend .navbar-center;
      justify-content: left;

      .navbar-icon {
        background-image: url('../../../../assets/images/icons-navbar/icon-openmenu.svg');
        @extend .navbarimage;
        @include vwMobile(width, 40);
        @include vwMobile(height, 30);
      }

      .navbar-icon-close {
        background-image: url('../../../../assets/images/icons-navbar/icon-closemenu.svg');
      }
    }
  }

  .navbar-content-menu {
    width: 100%;
    height: 100%;
    @include vwMobile(margin-top, 50);
    &:hover {
      display: block;
    }
    .navbar-content-one {
      @extend .util-main-padding;
      width: 100%;

      text-align: left;

      &:hover {
        display: block;
      }
      .navbar-item-play {
        @include vwMobile(margin-top, 40);
        @include vwMobile(padding-bottom, 40);
        .navbar-play {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          color: $black;
          @include vwMobile(height, 65);
          background-color: $yellowIg;
          border: 1px solid $white02;
          text-transform: uppercase;
          @include vwMobile(font-size, 13);
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          font-style: normal;
          cursor: pointer;
        }
        .navbar-play::before {
          content: '>';
          @include vwMobile(font-size, 18);
          font-weight: normal;
          @include vwMobile(margin-right, 15);
        }
      }
      .navbar-item {
        display: block;
        width: 100%;
        align-items: center;
        border-width: 0.1px;
        border-color: $white01;
        border-bottom-style: solid;
        @include vwMobile(min-height, 65);
        @include vwMobile(padding-top, 35);
        @include vwMobile(padding-bottom, 10);
        a {
          display: block;
          @extend .util-navbar-link;
          @extend .text_font_eurostile;
          @include vwMobile(margin-bottom, 25);

          &.navbar-SubMenuMegaGame,
          &.navbar-SubMenuGame,
          &.navbar-SubMenu {
            font-weight: bold;
            text-transform: uppercase;

            .mobileSubIcon {
              position: relative;
              background-image: url('../../../../assets/images/icons-navbar/arrow-down.svg');
              content: '';
              float: right;
              @extend .navbarimage;
              @include vwMobile(width, 15);
              @include vwMobile(height, 15);
              @include vwMobile(top, -5);
            }

            .mobileSubIconClose {
              position: relative;
              background-image: url('../../../../assets/images/icons-navbar/icon-closemenu.svg');
              content: '';
              float: right;
              @extend .navbarimage;
              @include vwMobile(width, 15);
              @include vwMobile(height, 15);
              @include vwMobile(top, -5);
            }
          }

          &.navbar-SubMenuMegaGame:focus + .mobilesubmenucontainer,
          &.navbar-SubMenuGame:focus + .mobilesubmenucontainer {
            position: relative;
            display: block;
            width: 100%;
          }

          &.current,
          &:hover,
          &:focus,
          &:active {
            @extend .util-navbar-link-secondary;
            display: block;
            cursor: pointer;
          }
        }
      }
    }

    .mobile-bar {
      position: fixed;
      bottom: 0;
      width: 100%;
      border-top-style: solid;
      border-top-color: $white02;
      background-image: linear-gradient(to bottom, #162024, $almostBlack);
      border-width: 1px;
      @include vwMobileAll(margin, 75, 0, 0, 0);
      // @include vwMobile(padding-top, 30);
      @include vwMobile(height, 70);
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      // @include vwColumns(4, 35, true);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nav-item {
        @include vwMobile(width, 103);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
      .selected {
        border-bottom: 3px solid $yellowIg;
        border-top: 3px solid transparent;
      }
      a {
        display: block;
        @include vwMobile(width, 35);
        @include vwMobile(height, 35);
        border: 1px solid $gray-home;
        &.main {
          background-image: url('../../../../assets/images/icons-navbar/icon-openmenu.svg');
          @extend .navbarimage;
          @include vwMobile(background-size, 23);
          // -webkit-filter: blur(15px);
          // filter: blur(15px);
          // background-color: $yellowIg;
        }
        &.language {
          background-image: url('../../../../assets/images/icons-navbar/Icon-Language.svg');
          @extend .navbarimage;
          @include vwMobile(background-size, 22);
        }
        &.userlogin {
          background-image: url('../../../../assets/images/icons-navbar/Icon-Profile.svg');
          @extend .navbarimage;
          @include vwMobile(background-size, 20);
          @include vwMobile(background-position-y, 7);
          //  background-position: bottom;
        }

        &.search {
          background-image: url('../../../../assets/images/icons-navbar/Icon-Search.svg');

          @extend .navbarimage;
        }
      }
    }
  }
}
