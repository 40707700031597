@mixin image-display() {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.news-content {
  position: absolute;
  @include vw(bottom, 40);
  @include vw(left, 0);
  @include breakpoint(mobile) {
    @include vwMobile(bottom, 0);
    @include vwMobile(left, 0);
  }
}
.news-content-detail {
  @include vw(margin-top, 40);
  @include vw(margin-left, 0);
  @include vw(width, 416);
  @include vw(padding-left, 30);
  @include vw(padding-right, 30);
  @include breakpoint(mobile) {
    @include vwMobile(margin-top, 40);
    @include vwMobile(margin-left, 0);
    @include vwMobile(padding-left, 15);
    @include vwMobile(padding-right, 15);
    @include vwMobile(width, 313);
  }
  p.content {
    color: $white;
    @include vw(font-size, 22);
    text-transform: uppercase;
    line-height: 1.38;
    // word-break: break-all;
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 18);
      width: 100%;
      line-height: 1.44;
      text-align: center;
    }
  }
}
.linebefore {
  display: block;
  width: 100%;
  height: 1px;
  margin: 0.5em auto 1.5em;
  background: $white;
  content: '';
  opacity: 0.15;
}
