.homePageMiddle {
  @keyframes FadeInUp {
    0% {
      opacity: 0;
      @include vw(margin-top, 100);
    }
    100% {
      opacity: 1;
      @include vw(margin-top, 0);
    }
  }
  .moveBlade {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    display: flex;
    align-items: center;
    opacity: 0;
  }

  .spacer {
    width: 100%;
    @include vw(height, 170);
    content: '';
    @include breakpoint(mobile) {
      @include vwMobile(height, 25);
    }
  }
  .video-link {
    display: flex;
    justify-content: flex-start;
    @include vw(margin-top, 40);

    .theme-button {
      @include vw(min-width, 260);
      @include vw(height, 65);
      @include vw(margin-right, 40);
      @include breakpoint(mobile) {
        @include vwMobile(min-width, 65);
        @include vwMobile(height, 65);
      }
      div.icon {
        @include vw(height, 20);
        @include vw(width, 20);
        background-image: url('../../../../assets/images/icons/Icon-Play.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(margin-right, 15);
        @include breakpoint(mobile) {
          @include vwMobile(height, 18);
          @include vwMobile(width, 13);
        }
      }
      span {
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        @include vw(font-size, 13);
        @include breakpoint(mobile) {
          display: none;
        }
      }
    }
    @include breakpoint(mobile) {
      justify-content: space-between;
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      @include vwMobile(padding-bottom, 20);
      .joinBeta {
        @include vwMobile(width, 340);
        @include vwMobile(height, 65);
        span {
          display: flex;
          @include vwMobile(font-size, 13);
        }
      }
    }
  }
  .scrollSection {
    z-index: 8;
    padding-left: 6.25vw;
    padding-right: 6.25vw;
    .react-multi-carousel-item {
      @include vw(height, 310);
    }

    @include breakpoint(mobile) {
      display: flex;
    }
    position: relative;

    @include breakpoint(mobile) {
      flex-wrap: wrap;
      justify-content: center;

      @include vw(bottom, 0);
      @include vw(margin-bottom, 50);
      @include vw(padding-left, 80);
      @include vw(padding-right, 80);
    }
    .react-multiple-carousel__arrow {
      z-index: 9;
      border: solid 0.1px $white;
      background: transparent;
      border-radius: 0;
      opacity: 0.8;
      @include vw(min-height, 40);
      @include vw(min-width, 40);
      @include vw(top, 253);
    }
    .react-multi-carousel-list {
      overflow: inherit;
    }
    .react-multi-carousel-item[aria-hidden='true'] {
      opacity: 0.4;
    }
    .react-multi-carousel-track {
      will-change: unset;
    }
    .react-multiple-carousel__arrow--right {
      @include vw(left, 115);
      right: unset;
    }
    .react-multiple-carousel__arrow--left {
      @include vw(left, 41);
    }
    .carousel-item-padding {
      padding-left: 1.927vw;
      padding-right: 1.5625vw;
    }

    .contentbox {
      @include vw(width, 483);
      @include vw(height, 205);
      position: relative;
      flex: 0 0 auto;
      border: solid 0.1px $white02;
      margin: 0 auto;
      background-color: $almostBlack;
      // @include vw(right, 410);

      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(min-height, 180);
        @include vwMobile(margin-bottom, 20);
      }

      .squarebox {
        @include vw(padding, 0);
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: $corners-transition;
        &:hover {
          @include vw(padding, 15);
          transition: $corners-transition;
          background-color: $blueDark;
          @include breakpoint(mobile) {
            @include vwMobile(padding, 15);
          }
        }
        .realcontent {
          width: 100%;
          height: 100%;
          margin: 0;

          @include buildCorners(18, 0);
          @include breakpoint(mobile) {
            @include buildCornersMobile(22, 0);
          }
        }
        p {
          position: absolute;
          right: 0;
          left: 0;
          text-align: center;
          text-transform: uppercase;
          font-style: normal;
          &.title {
            @include vw(top, 45);
            @include vw(font-size, 20);
            font-weight: 900;
            font-family: eurostile-extended, sans-serif;
            color: $yellowIg;
            font-weight: bold;
            @include breakpoint(mobile) {
              @include vwMobile(top, 35);

              @include vwMobile(font-size, 22);
            }
          }

          &.description {
            @include vw(width, 415);
            @include vw(top, 80);
            margin-right: auto;
            margin-left: auto;
            color: $white;
            @include vw(margin-bottom, 45);
            @include vw(font-size, 21);
            @include vw(line-height, 40);
            color: #dbdbdb;
            line-height: 1.43;
            font-weight: 500;
            @include breakpoint(mobile) {
              @include vwMobile(top, 77);
              width: 90%;
              @include vwMobile(font-size, 18);
              line-height: 1.44;
              @include vwMobile(line-height, 26);
            }
          }
        }
      }
    }
  }
  .reboot-section {
    background-image: url('../../../../assets/images/homepage-actual/reboot.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    @include vw(height, 1400);
    @include vw(margin-top, -450);
    @include vw(margin-bottom, -200);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 200);
      @include vwMobile(margin-top, -160);
      // @include vwMobile(height, 500);
    }
    .reboot-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include vw(margin-top, 250);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 380);
        @include vwMobile(padding-left, 20);
        @include vwMobile(padding-right, 20);
      }
    }
    .reboot-title,
    .reboot-desc {
      @include vw(min-width, 819);
      text-align: center;
      display: block;
      @include breakpoint(mobile) {
        @include vwMobile(min-width, 374);
      }
    }

    .reboot-title {
      * {
        line-height: 1.25;
        @include vw(font-size, 40);
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        line-height: 1.25;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 22);
          line-height: 1.36;
        }
      }
      @include vw(margin-bottom, 15);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 18);
        @include vwMobile(width, 374);
        display: none;
      }
    }
    .reboot-desc {
      * {
        @include vw(font-size, 28);
        line-height: 1.43;
        @include breakpoint(mobile) {
          text-align: left;
          @include vwMobile(font-size, 16);
          line-height: 1.63;
        }
      }
      @include breakpoint(mobile) {
        display: none;
      }
    }
    .mobile {
      display: none;
      @include breakpoint(mobile) {
        display: flex;
      }
    }
    /* Button section */
    .theme-button {
      background-image: none;
      background-color: $blueDark;
      border: 1px solid $white02;
      border-image: none;
      span {
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        @include vw(font-size, 13);
      }
    }
    .btn-watch {
      @include vw(height, 65);
      @include vw(min-width, 337);
      @include vw(margin-top, 40);
      @include vw(margin-right, 30);
      @include vw(margin-bottom, 80);
      background-color: $white;
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(margin-top, 20);
        @include vwMobile(height, 65);
        //  @include vwMobile(padding-left, 20);
        //   @include vwMobile(padding-right, 20);
      }
      .theme-button-container {
        div {
          background-image: url('../../../../assets/images/icons/Icon-Play.svg');
          background-size: contain;
          @include vw(height, 17);
          @include vw(width, 12);
          @include breakpoint(mobile) {
            @include vwMobile(height, 17);
            @include vwMobile(width, 12);
            @include vwMobile(margin-right, 12);
          }
        }
        span {
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 13);
          }
        }
      }
      &:hover {
        background-color: $yellowIg;
        .theme-button-container {
          @include breakpoint(mobile) {
            @include buildCornersMobileBlack(13, 4);
          }
          span {
            color: $black;
          }
        }
      }
    }
    .btn-group-pillars {
      @include breakpoint(mobile) {
        display: none;
      }
      display: flex;
      a {
        @include vw(margin-right, 30);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &:nth-child(4) {
          margin-right: 0;
        }
      }
      .theme-button {
        @include vw(height, 65);
        @include vw(min-width, 300);
        .theme-button-container {
          width: 100%;
          span {
            color: $white;
            @include vw(margin-right, 1);
          }
        }
        &:hover {
          background-image: none;
          .theme-button-container {
            @include buildCornersYellow(13, 4.3);
            span {
              color: $yellowIg;
            }
          }
        }
      }
    }

    /*End button section */
  }
  .bg-partOne {
    @include vw(height, 1080);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain !important;
  }
  .partOne {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    @include breakpoint(from-phablet) {
      @include vw(height, 1080);
      @include vw(margin-top, -1080);
      background-color: transparent;
    }
    @include breakpoint(mobile) {
      display: inline-block;
    }
    div {
      &.square {
        display: inline-block;
        div {
          &.squareLineOne {
            @include vw(width, 156);
            @include vw(height, 124);
            border-width: 0.1px;
            border-color: $white;
            border-right-style: solid;
            border-bottom-style: solid;
            content: '';
          }
          &.squareLineTwo {
            @include vw(width, 156);
            @include vw(height, 124);
            border-width: 0.1px;
            border-color: $white;
            border-right-style: solid;
            content: '';
          }
        }
        @include breakpoint(mobile) {
          display: none;
        }
      }
      &.content {
        // position: relative;
        display: inline-block;
        @include vw(margin-left, 1068);
        z-index: 1;
        @include breakpoint(mobile) {
          display: none;
        }
        .title {
          @include vw(margin-bottom, 23);
          h2 {
            color: $yellowIg;
            font-weight: bold;
            text-transform: uppercase;
            font-weight: 900;
            line-height: 0.86;
            @include vw(font-size, 70);
          }
        }
        .description {
          @include vw(width, 700);
          p {
            display: block;
            color: $white;
            line-height: 1.4;
            text-align: justify;
            @include vw(font-size, 28);
          }
        }
      }

      &.actionButton {
        @include vw(margin-top, 234);
        text-align: center;
        button {
          text-transform: uppercase;
          @include vw(min-width, 260);
          @include vw(height, 65);
        }
      }
      &.identifier {
        position: absolute;
        transform: rotate(180deg);
        writing-mode: vertical-lr;
        h3 {
          color: $white;
          font-weight: 900;
          line-height: 1.4;
          opacity: 1;
          font-family: eurostile-extended, sans-serif;
          @include vw(font-size, 100);
        }
      }
      &.identifier-fr {
        @include vw(right, -220);
      }
      &.identifier-de {
        @include vw(right, -340);
      }
    }
  }
  .none {
    display: none;
  }
  .partMobileHome {
    .imageData {
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include vwMobile(height, 233);
    }
    div {
      &.imageOne {
        @extend .imageData;
        background-image: url('../../../../assets/images/homepage-actual/spacewalker.jpg');
      }

      &.imageTwo {
        @extend .imageData;
        background-image: url('../../../../assets/images/homepage-actual/homepage-build.jpg');
      }
      &.imageThree {
        @extend .imageData;
        background-image: url('../../../../assets/images/homepage-actual/homepage-economy.jpg');
      }
      &.imageFour {
        @extend .imageData;
        background-image: url('../../../../assets/images/homepage-actual/homepage-pvp.png');
      }
      &.imageFive {
        @extend .imageData;
        background-image: url('../../../../assets/images/homepage-actual/homepage-character.jpg');
      }

      &.content {
        @include vwMobile(min-height, 238);
        width: 100%;
        @include vwMobileAll(padding, 30, 20, 20, 20);
        z-index: 1;
        p {
          &.identifier {
            font-family: eurostile-extended, sans-serif;
            @include vwMobile(font-size, 16);
            font-weight: 900;
          }
          &.title {
            color: $yellowIg;
            font-weight: bold;
            text-transform: uppercase;
            @include vwMobile(font-size, 22);
            @include vwMobile(margin-bottom, 15);
            @include vwMobileAll(line-height, 34);
          }

          &.description {
            color: $white;
            @include vwMobile(font-size, 16);
            @include vwMobile(line-height, 32);
          }
        }
      }
    }
  }
  .partTwo {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include vw(height, 1080);

    div {
      &.square {
        position: relative;
        @include vw(top, 33);
        @include vw(height, 249);
        div {
          &.squareLineOne {
            @include vw(width, 156);
            @include vw(height, 124);
            border-width: 0.1px;
            border-color: $white;
            border-bottom-style: solid;
            border-left-style: solid;
            content: '';
          }
          &.squareLineTwo {
            @include vw(width, 156);
            @include vw(height, 124);
            border-width: 0.1px;
            border-color: $white;
            border-left-style: solid;
            content: '';
          }
        }
        @include breakpoint(mobile) {
          display: none;
        }
      }

      &.content {
        @include vw(margin-left, 156);
        //  position: relative;
        display: inline-block;
        z-index: 1;
        @include vw(bottom, 110);
        div {
          &.title {
            @include vw(margin-bottom, 23);
            h2 {
              color: $yellowIg;
              font-weight: 900;
              line-height: 0.86;
              text-transform: uppercase;
              @include vw(font-size, 70);
            }
          }

          &.description {
            @include vw(width, 700);
            p {
              display: block;
              color: $white;
              @include vw(font-size, 28);
              line-height: normal;
              // @include vw(line-height, 34);
            }
          }
        }
      }
      &.identifier {
        position: absolute;
        transform: rotate(180deg);
        right: 0;
        writing-mode: vertical-lr;
        h3 {
          color: $white;
          font-weight: 900;
          line-height: 1.4;
          opacity: 1;
          font-family: eurostile-extended, sans-serif;
          @include vw(font-size, 100);
        }
        @include breakpoint(mobile) {
          display: none;
        }
      }
      &.identifier-fr {
        @include vw(left, -200);
      }
      &.identifier-de {
        @include vw(left, 0);
      }
    }
  }
  .partThree {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    background-image: url('../../../../assets/images/homepage-actual/homepage-economy.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include vw(height, 1080);
    div {
      &.square {
        position: relative;
        display: inline-block;
        @include vw(top, 33);
        @include vw(margin-top, 428);
        div {
          &.squareLineOne {
            @include vw(width, 156);
            @include vw(height, 124);
            border-width: 0.1px;
            border-color: $white;
            border-right-style: solid;
            border-bottom-style: solid;
            content: '';
          }
          &.squareLineTwo {
            @include vw(width, 156);
            @include vw(height, 124);
            border-width: 0.1px;
            border-color: $white;
            border-right-style: solid;
            content: '';
          }
        }
        @include breakpoint(mobile) {
          display: none;
        }
      }

      &.content {
        @include vw(margin-left, 1084);
        // position: relative;
        display: inline-block;
        z-index: 1;
        div.title {
          @include vw(margin-bottom, 23);
          h2 {
            color: $yellowIg;
            font-weight: 900;
            text-transform: uppercase;
            line-height: 0.86;
            @include vw(font-size, 70);
          }
        }

        div.description {
          @include vw(width, 667);
          p {
            display: block;
            color: $white;
            @include vw(font-size, 28);
            line-height: normal;
            // @include vw(line-height, 34);
          }
        }
      }
      &.identifier {
        position: absolute;
        @include vw(left, 0);
        transform: rotate(180deg);
        writing-mode: vertical-lr;
        h3 {
          color: $white;
          font-weight: 900;
          line-height: 1.4;
          opacity: 1;
          font-family: eurostile-extended, sans-serif;
          @include vw(font-size, 100);
        }
      }
      &.identifier-fr {
        @include vw(right, -270);
      }
      &.identifier-de {
        @include vw(right, -320);
      }
    }
  }
  .partFour {
    position: relative;
    // background-image: url('../../../../assets/images/homepage-actual/homepage-pvp.png');
    div {
      &.identifier {
        position: absolute;
        @include vw(top, 474);
        @include vw(left, -236);
      }
      &.identifier-fr {
        @include vw(top, 443);
        @include vw(left, -300);
      }
      &.identifier-de {
        @include vw(left, -360);
      }
    }
  }
  .partFive {
    position: relative;
    display: flex;
    align-items: center;
    background-image: url('../../../../assets/images/homepage-actual/homepage-character.jpg');
    div {
      &.identifier {
        position: absolute;
        @include vw(top, 470);
        @include vw(right, -280);
      }
      &.identifier-fr {
        @include vw(top, 470);
        @include vw(right, -200);
      }
      &.identifier-de {
        @include vw(top, 470);
        @include vw(right, -280);
      }
    }
  }
}
.popUpContainer {
  position: relative;
  @include vw(width, 1608);
  @include vw(height, 700);
  @include breakpoint(mobile) {
    width: 100%;
  }
  .exitIcon {
    position: absolute;
    border-width: 0.1px;
    border-style: solid;
    border-color: $gray;
    background-clip: content-box;
    background-image: url('../../../../assets/images/icons/no-border/Icon-Close.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    @include vw(top, -25);
    @include vw(right, -55);
    @include vw(width, 40);
    @include vw(height, 40);
    @include vw(padding, 5);
    // @include vw(margin-left, 1820);

    @include breakpoint(mobile) {
      @include vwMobile(width, 40);
      @include vwMobile(height, 40);
      @include vwMobile(margin-left, 437);
      @include vwMobile(top, -50);
      @include vwMobile(right, 15);
    }
  }
  iframe,
  video {
    position: relative;
    z-index: 20;
    width: 100%;
    // @include vw(width, 1608);
    // @include vw(height, 700);
    height: 100%;
    object-fit: cover;
    @include breakpoint(mobile) {
      @include vwMobile(height, 300);
    }
  }
}
