.account-container {
  @include vw(height, 1500);
  background-image: linear-gradient(rgba(12, 19, 21, 0) 58%, rgba(12, 19, 21, 0.75) 70%, rgb(12, 19, 21) 85%),
    url('../../../../assets/images/recruit-a-friend/Image\ 48.png');
  background-position-x: center;
  @include vw(background-position-y, -150);
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint(mobile) {
    display: none;
  }
}

.upload-disabled {
  pointer-events: none;
}

div {
  &.accountupload {
    @include vw(margin-top, -1530);

    @include breakpoint(mobile) {
      margin-top: 0;
    }

    div {
      &.header {
        @include vwAll(padding, 137, 156, 0, 156);
        position: relative;
        z-index: 1;
        width: 100%;
        background-clip: border-box;
        @include vw(height, 506);

        @include breakpoint(mobile) {
          @include vwMobile(height, 230);
          @include vwMobileAll(padding, 100, 20, 50, 20);
        }

        p {
          &.title {
            display: inline-block;
            color: $white;
            font-weight: bold;
            text-transform: uppercase;
            @include vw(font-size, 50);
            @include vw(margin-right, 830);
            @include vw(margin-bottom, 72);

            @include breakpoint(mobile) {
              display: none;
            }
          }
        }

        button {
          box-sizing: border-box;
          border-width: 1px;
          border-style: solid;
          border-color: $white;
          @include vw(width, 268);
          @include vw(height, 65);

          @include breakpoint(mobile) {
            display: none;
          }

          &.theme-button {
            div {
              &.theme-button-container {
                height: 100%;
                border-width: 1px;
                border-style: solid;
                border-color: $white;
                background-color: $black;
                color: $black;

                span,
                div {
                  color: $white;
                }
              }
            }
          }
        }

        div {
          &.profileImageSectionMobile {
            @include vwMobile(height, 80);
            width: 100%;

            @include breakpoint(from-phablet) {
              display: none;
            }

            div {
              &.image {
                display: inline-block;
                background-image: url('../../../../assets/images/placeholderavatar.jpg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                @include vwMobile(width, 80);
                @include vwMobile(height, 80);
              }

              &.contents {
                position: relative;
                display: inline-block;
                // margin-bottom: auto;
                // height: 100%;
                @include vwMobile(margin-left, 40);
                @include vwMobile(bottom, 17);

                p {
                  text-transform: uppercase;

                  &.name {
                    @include vwMobile(font-size, 22);
                    color: $white;
                    font-weight: bold;
                  }

                  &.titleMobile {
                    @include vwMobile(font-size, 20);
                    color: $white;
                    @include vwMobile(margin-bottom, 17);
                  }
                }
              }
            }
          }
        }
      }

      &.box {
        position: relative;
        z-index: 2;
        border-width: 0.1px;
        border-style: solid;
        border-color: $gray-oblique;
        background-color: $blueDeep;
        @include vw(width, 1608);
        @include vwAll(margin, 0, 156, 0, 156);
        @include vw(bottom, 249);

        @include breakpoint(mobile) {
          @include vw(bottom, 0);
          width: 100%;
          border-style: none;
          margin: 0px;
        }

        div {
          &.profileBorder {
            @include breakpoint(from-phablet) {
              //@include buildCorners(30, 40);
              @include vwAll(padding, 0, 0, 0, 0);
              grid-template-columns: 0.34fr 0.66fr;
            }

            // @include vw(height, 1781);
            display: grid;

            height: auto;

            @include breakpoint(mobile) {
              width: 100%;
              border-style: none;
              grid-template-columns: 1fr;
            }

            div {
              &.userSelection {
                height: 100%;
                border-width: 0.1px;
                border-color: $gray-oblique;
                border-right-style: solid;
                background-color: $blueDark;
                @include vwAll(padding, 48, 0, 80, 61);

                //@include vw(width, 485);
                @include breakpoint(mobile) {
                  width: 100%;
                  border-style: none;
                  @include vwAllMobile(padding, 0, 0, 0, 0);
                }

                .line {
                  @include vw(height, 1);
                  width: 100%;
                  background-color: $gray-oblique;
                  @include vw(margin-bottom, 50);

                  @include breakpoint(mobile) {
                    @include vwMobile(height, 1);
                    @include vwMobile(margin-bottom, 0);
                  }
                }

                div {
                  &.profileImageSection {
                    display: flex;
                    align-items: center;
                    @include vw(height, 80);
                    @include vw(margin-bottom, 40);

                    @include breakpoint(mobile) {
                      display: none;
                    }

                    div {
                      &.image {
                        display: inline-block;
                        height: 100%;
                        background-image: url('../../../../assets/images/placeholderavatar.jpg');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        @include vw(width, 80);
                      }

                      &.icon-tool {
                        display: inline-block;
                        background-image: url('../../../../assets/images/Maintenance.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        @include vw(width, 60);
                        @include vw(height, 60);
                      }

                      &.contents {
                        display: inline-block;
                        // height: 100%;
                        // margin-bottom: auto;
                        @include vw(width, 330);
                        @include vw(margin-left, 20);

                        p {
                          margin-right: 0px;
                          text-transform: uppercase;
                          @include vw(max-width, 300);

                          &.name {
                            @include vw(font-size, 32);
                            color: $white;
                            font-weight: bold;
                            line-height: 1.1;
                          }

                          &.tag {
                            @include vw(font-size, 14);
                            font-family: eurostile-extended, sans-serif;
                            font-weight: 900;
                            font-style: normal;
                            color: $yellowIg;
                            font-weight: bold;
                            line-height: 1;
                          }

                          &.title {
                            @include vw(font-size, 20);
                            color: $white;
                          }
                        }
                      }
                    }
                  }
                }

                button {
                  @include vw(height, 61);
                  @include vw(margin-top, 60);
                  @include vw(margin-left, 79);
                  background-color: $almostBlack;
                  background-image: none;
                  display: flex;
                  @include vw(width, 352);
                  align-items: center;
                  justify-content: center;
                  border: 1px solid $white02;
                  text-align: center;

                  @include breakpoint(mobile) {
                    display: none;
                    justify-content: start;
                    border-top-style: none;
                    @include vwMobile(height, 50);
                    @include vwMobile(font-size, 16);
                    @include vwMobile(padding-bottom, 15);
                    @include vwMobile(padding-top, 18);
                  }

                  .theme-button-container {
                    width: 100%;

                    div {
                      background-image: url('../../../../assets/images/icons/no-border/Icon-Download.svg');
                      -webkit-filter: invert(100%); // Safari/Chrome
                      filter: invert(100%);
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: contain;
                      @include vwDimensions(25, 20);
                      @include vw(margin-right, 15);

                      @include breakpoint(mobile) {
                        @include vwMobile(width, 20);
                        @include vwMobile(height, 20);
                        @include vwMobile(margin-right, 15);
                        @include vwMobile(margin-left, 13);
                      }
                    }

                    span {
                      color: $white;
                      text-decoration: none;
                      text-transform: uppercase;
                      @include vw(font-size, 13);
                      font-family: eurostile-extended, sans-serif;
                      font-weight: 900;
                      font-style: normal;

                      @include breakpoint(mobile) {
                        @include vwMobile(font-size, 16);
                        color: $gray;
                        text-transform: none;
                      }
                    }
                  }
                }

                button:hover .theme-button-container {
                  @include buildCornersYellow(13, 4);

                  div {
                    background-image: url('../../../../assets/images/icons/no-border/yellow/Icon-Download.svg');
                    filter: none;
                  }

                  span {
                    color: $yellowIg;
                  }
                }

                .container {
                  display: flex;
                  align-items: center;
                  border-bottom-width: 1px;
                  border-bottom-style: solid;
                  border-bottom-color: $gray-oblique;
                  // border-top: 1px solid $gray-oblique;
                  @include vw(padding-bottom, 20);
                  @include vw(padding-top, 20);
                  // @include vw(margin-bottom, 20);
                  @include vw(padding-left, 35);
                  border-left: 5px solid transparent;
                }

                .container-link {
                  display: block;

                  @include breakpoint(mobile) {
                    display: none;
                  }
                }

                .container-link:nth-child(5) {
                  @include vw(margin-bottom, 40);
                }

                .icon {
                  @include vw(width, 40);
                  @include vw(height, 40);
                  //border: 1px solid $white;
                  @include vw(margin-right, 20);
                  background-image: url('../../../../assets/images/icons/Icon\ -\ Profile.svg');
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                }

                .icon-preferences {
                  background-image: url('../../../../assets/images/icons/Icon\ -\ Email.svg');
                }

                .icon-code {
                  background-image: url('../../../../assets/images/icons/Icon-Code.svg');
                }

                .icon-pts {
                  background-image: url('../../../../assets/images/icons/Icon-DU.svg');
                }

                .icon-raf {
                  background-image: url('../../../../assets/images/icons/Icon-RAF.svg');
                }

                .icon-upload {
                  background-image: url('../../../../assets/images/icons/Icon-Library.svg');
                }

                .icon-dacs {
                  background-image: url('../../../../assets/images/icons/Icon-Dacs.svg');
                }

                .icon-myDU {
                  background-image: url('../../../../assets/images/icons/Icon-myDU.svg');
                  border: 1px solid $black;
                  background-size: 50%;
                  filter: none !important;
                }
                .icon-DU {
                  background-image: url('../../../../assets/images/icons/Icon-DU.svg');
                }

                span,
                a {
                  &.underlineText {
                    color: $gray-medium;
                    text-decoration: none;
                    @include vw(font-size, 20);

                    @include breakpoint(mobile) {
                      display: none;
                      @include vwMobile(font-size, 16);
                      @include vwMobile(padding-bottom, 20);
                      @include vwMobile(padding-top, 20);
                    }

                    &.current::before {
                      @include breakpoint(mobile) {
                        @include vwMobile(margin-right, 70);
                      }
                    }

                    &::before {
                      @include breakpoint(mobile) {
                        content: '+';
                        @include vwMobile(margin-right, 50);
                        @include vwMobile(margin-right, 19);
                        @include vwMobile(margin-left, 17);
                        @include vwMobile(font-size, 16);
                      }
                    }
                  }
                }
                .download-yellow {
                  background-color: $yellowIg;
                  span {
                    color: $black;
                  }
                  .icon {
                    filter: $white-to-black-filter;
                  }
                }
                .download-blue {
                  background-color: $blue;
                  span {
                    color: $black;
                  }
                  .icon {
                    filter: $white-to-black-filter;
                  }
                }
                .container:hover,
                .selected {
                  background-color: $almostBlack;
                  border-left: 5px solid $yellowIg;
                  border-right: 5px solid transparent;

                  .icon {
                    filter: $white-to-yellow-filter;
                  }
                  .icon-myDU {
                    filter: $black-to-yellow-filter !important;
                  }

                  span {
                    color: $yellowIg;
                  }
                }

                .menu-links-mobile {
                  display: none;

                  @include breakpoint(mobile) {
                    background-color: $almostBlack;
                    display: block;

                    .underlineText {
                      border-bottom: 0.1px solid #3d4244;
                      display: block;
                    }
                  }

                  .menu-links-mobile-box {
                    background-color: $blueDark;
                  }

                  .underlineText {
                    position: relative;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    @include vwMobile(font-size, 20);

                    p {
                      @include vwMobile(font-size, 20);
                    }

                    & {
                      margin-bottom: 0;
                    }

                    &::before {
                      color: $yellowIg;
                    }
                  }

                  .navbar-mobile-open-icon {
                    position: absolute;
                    @include vwMobile(right, 20);
                    background-image: url('../../../../assets/images/icons-navbar/Icon-NavDropArrowYellow.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    @include vwMobile(background-size, 15);
                    @include vwMobile(width, 20);
                    @include vwMobile(height, 20);
                  }

                  .navbar-mobile-close-icon {
                    position: absolute;
                    @include vwMobile(right, 20);
                    background-image: url('../../../../assets/images/icons-navbar/icon-closemenu.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    @include vwMobile(background-size, 23);
                    @include vwMobile(width, 18);
                    @include vwMobile(height, 21);
                  }

                  .sublink {
                    text-transform: capitalize;
                    @include vwMobile(font-size, 16);

                    &::before {
                      content: '';
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.userInfo {
    @include vw(padding-left, 80);
    @include vw(padding-right, 80);
    @include vw(padding-top, 48);
    @include vw(padding-bottom, 80);
    height: auto;

    div {
      &.mainHeading {
        width: 100%;
        @include vw(margin-bottom, 60);

        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 60);
          @include vwMobile(margin-bottom, 40);
        }

        p.imageHeader {
          color: $white;
          font-weight: bold;
          @include vw(font-size, 35);
          @include vw(margin-bottom, 75);

          @include breakpoint(mobile) {
            @include vwMobile(font-size, 25);
            @include vwMobile(margin-bottom, 40);
          }
        }
      }

      &.uploadHeading {
        @include vw(margin-bottom, 42);

        .uploadTitle {
          @include vw(height, 68);
          position: relative;
          width: 100%;

          border-width: 0.1px;
          border-color: $grayButton;
          border-top-style: solid;
          border-bottom-style: solid;

          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;

          .left {
            @include vw(font-size, 22);
            color: $white;
            font-weight: bold;
            text-transform: uppercase;

            @include breakpoint(mobile) {
              @include vwMobile(font-size, 18);
              @include vwMobile(margin-top, 15);
            }

            &:before {
              content: '+';
              @include vw(margin-right, 30);
            }
          }

          .right {
            color: #ffd255;
          }
        }

        .line {
          position: relative;
          width: 100%;
          height: 2px;
          background-color: $blueLight;

          .yellowline {
            position: absolute;
            height: 2px;
            background-color: $yellowIg;
          }
        }
      }

      &.uploadIcon {
        width: 100%;
        @include buildCorners(35, 1);
        @include vw(margin-bottom, 40);

        @include breakpoint(mobile) {
          @include vwMobile(border-width, 35);
          @include vwMobile(margin, 1);
        }

        label {
          input {
            width: 0;
            height: 0;
            visibility: hidden;
          }

          div {
            &.uploadIconLink {
              width: 100%;
              height: 100%;
              cursor: pointer;
              text-align: center;

              p {
                &.uploadMedia {
                  @include vw(margin-top, 27);
                  @include vw(margin-bottom, 101);
                  color: $white;
                  font-weight: bold;
                  @include vw(font-size, 22);

                  @include breakpoint(mobile) {
                    @include vwMobile(font-size, 18);
                    @include vwMobile(margin-top, 47);
                    @include vwMobile(margin-bottom, 91);
                  }
                }

                &.uploadExplanation {
                  @include vw(font-size, 20);
                  color: $white;
                  @include vw(margin-bottom, 20);

                  @include breakpoint(mobile) {
                    @include vwMobile(font-size, 18);
                    @include vwMobile(margin-bottom, 10);
                  }
                }

                &.uploadLimit {
                  color: $gray-dark;
                  @include vw(font-size, 18);

                  @include breakpoint(mobile) {
                    @include vwMobile(font-size, 16);
                  }
                }
              }

              div {
                &.uploadIconImage {
                  @include vwDimensions(130, 130);
                  margin: 0 auto;
                  background-image: url('../../../../assets/images/icons/Icon-Upload.svg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  @include vw(margin-bottom, 84);

                  @include breakpoint(mobile) {
                    @include vwMobile(height, 120);
                    @include vwMobile(width, 120);
                    @include vwMobile(margin-bottom, 75);
                  }
                }
              }
            }
          }
        }
      }

      &.reviewHeading {
        @include vw(height, 68);
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        border-width: 0.1px;
        border-color: $grayButton;
        border-top-style: solid;
        border-bottom-style: solid;
        @include vw(margin-bottom, 42);

        .loading {
          position: absolute;
          color: $yellowIg;
          @include vw(top, -50);
        }

        @include breakpoint(mobile) {
          @include vwMobile(height, 68);
          @include vwMobile(margin-bottom, 42);

          display: block;
        }

        p {
          &.pendingReview {
            &:before {
              content: '+';
              @include vw(margin-right, 30);
            }

            @include vw(font-size, 22);
            color: $white;
            font-weight: bold;
            text-transform: uppercase;

            @include breakpoint(mobile) {
              @include vwMobile(font-size, 18);
              @include vwMobile(margin-top, 15);
            }
          }

          &.see {
            margin-left: auto;
            color: $yellowIg;
            font-weight: bold;
            @include vw(font-size, 22);

            @include breakpoint(mobile) {
              @include vwMobile(margin-left, 20);
              @include vwMobile(margin-top, 5);
              @include vwMobile(font-size, 14);
            }
          }
        }
      }

      &.pendingImagesContainer {
        display: grid;
        @include vw(grid-column-gap, 38.8);
        @include vw(grid-row-gap, 40);
        grid-template-columns: 1fr 1fr 1fr;
        @include vw(margin-bottom, 80);

        @include breakpoint(mobile) {
          @include vwMobile(grid-column-gap, 20);
          @include vwMobile(grid-row-gap, 20);
          grid-template-columns: 1fr 1fr;
        }

        .imagecontainer {
          position: relative;
          @include vw(height, 200);
          display: flex;
          justify-content: center;
          align-items: start;
          &:hover .onhoverbox {
            display: block;
          }

          .onhoverbox {
            position: absolute;
            top: 0;
            display: none;
            width: 100%;
            height: 100%;
            background-color: rgba($almostBlack, 0.6);
            @include vw(padding, 20);

            p {
              @include vw(font-size, 16);
              line-height: 1.38;
            }

            .options {
              @include vw(margin-top, 71);
              display: flex;
              justify-content: space-between;

              .hide {
                display: none;
              }

              button {
                @include vw(height, 40);
                @include vw(width, 40);
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $white;
                background-color: transparent;
                border-radius: 0;

                cursor: pointer;
                outline: none;

                .copy {
                  @include vw(height, 14);
                  @include vw(width, 26);
                  background-image: url('../../../../assets/images/icons/copy.svg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                }

                .trash {
                  @include vw(height, 40);
                  @include vw(width, 40);
                  background-image: url('../../../../assets/images/icons/no-border/Icon-Trash.svg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                }
              }
            }
          }
        }

        div,
        img {
          &.pendingReviewImages {
            @include vw(max-width, 223);
            @include vw(max-height, 195);
            margin: 0 auto;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            @include breakpoint(mobile) {
              @include vw(max-width, 176);
              @include vw(max-height, 176);
            }
          }
        }
      }
    }

    p {
      &.imageExplanation {
        @include vw(font-size, 16);
        color: $gray;
        @include vw(margin-bottom, 80);

        @include breakpoint(mobile) {
          @include vwMobile(margin-bottom, 80);
          @include vwMobile(margin-top, 40);
          @include vwMobile(font-size, 16);
        }
      }
    }
  }
}

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  width: 100%;
  height: 100%;
}

.file-drop > .file-drop-target {
  /* basic styles */
  top: 0;
  left: 0;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  width: 100%;
  height: 100%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  flex-direction: column;
  -webkit-align-content: center;

  align-content: center;
  -webkit-align-items: center;

  align-items: center;
  -webkit-justify-content: center;

  justify-content: center;
  border-radius: 2px;
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
  -ms-flex-align: center;
  -ms-flex-line-pack: center;
  -ms-flex-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  z-index: 50;
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: $almostBlack;
  box-shadow: none;

  /* typography */
  color: $white;
  opacity: 1;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  box-shadow: 0 0 13px 3px $yellowMild;
  /* turn stuff orange when we are dragging over the target */
  color: $yellowMild;
}

.upload-warning {
  display: flex;
  width: 100%;
  align-items: center;
  border-style: solid;
  border-color: $whiteTransparent;
  @include vw(border-width, 1);
  @include vw(margin-top, 40);
  @include vw(margin-bottom, 40);
  @include vw(padding-top, 28);
  @include vw(padding-right, 20);
  @include vw(padding-bottom, 27);
  @include vw(padding-left, 30);

  p {
    flex: 10000;
    opacity: 1;
    @include vw(font-size, 16);
  }

  .error {
    color: $color-negative;
  }

  .picture-icon {
    @include vw(margin-right, 30);
  }

  .x-button {
    flex: 1;
    @include vw(min-width, 40);
  }
}

.uploadForbidden {
  display: flex;
  width: 100%;
  align-items: center;
  @include vw(border-width, 1);
  @include vw(margin-bottom, 40);
  @include vw(padding-right, 20);
  @include vw(padding-bottom, 27);

  p {
    flex: 10000;
    color: $gray-dark;
    opacity: 1;
    @include vw(font-size, 20);
    line-height: 2;
  }
}

.picture-icon {
  flex: 1;
  background-image: url('../../../../assets/images/icons/icon-image.svg');
  background-size: 100%;
  @include vw(min-width, 40);
  @include vw(height, 40);
}
