.account-login-page {
  .loginmenucontainer {
    position: relative;
    top: auto;
    left: auto;
    display: flex;
    align-content: center;
    justify-content: center;
    border: 0;
    margin: auto;
    margin-top: auto;
    transform: scale(2);
    @include vw(width, 500);
    @include vw(margin-top, 120);
    @include breakpoint(mobile) {
      margin: 0;
      width: 100%;
      transform: none;
      @include vwMobileAll(padding, 120, 20, 20, 20);
    }
    .simplesubmenu {
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
    .loginheader {
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }
  .login-modal-container {
    position: relative;
    z-index: 8;
    .close {
      display: none;
    }
  }
}
