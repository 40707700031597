.account-management-container {
  .account-management-intro {
    display: flex;
    justify-content: space-between;
    position: sticky;
    @include vw(top, 80);
    z-index: 10;
    background-color: $almostBlack;
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      flex-direction: column;
      position: relative;
      @include vwMobile(margin-bottom, 30);
    }
    h2 {
      @include vw(font-size, 35);
      line-height: 1.4;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
        @include vwMobile(margin-top, 40);
      }
    }
    .theme-button {
      @include breakpoint(mobile) {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        width: 100%;
        @include vwMobile(height, 65);
      }
    }
    .theme-button-container {
      div {
        background-image: url('../../../../assets/images/icons/no-border/Icon-Edit-White.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(mobile) {
          @include vwMobile(width, 18);
          @include vwMobile(height, 18);
          @include vwMobile(margin-right, 10);
        }
      }
    }

    button:hover {
      background-color: $green;
      .theme-button-container div {
        background-image: url('../../../../assets/images/icons/no-border/Icon-Edit-Black.svg');
      }
      span {
        color: $black;
      }
    }
  }
  .account-management-desc {
    * {
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
        @include vwMobile(margin-bottom, 40);
      }
    }
  }
  .account-notice {
    display: flex;
    align-items: center;
    background-color: $green;
    @include vw(height, 65);
    @include vw(margin-top, 39);
    animation-duration: 4s;
    animation-name: custom;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    position: sticky;
    @include vw(top, 160);
    z-index: 10;
    @include breakpoint(mobile) {
      @include vwMobile(height, 65);
      @include vwMobile(top, 65);
      @include vwMobile(margin-top, 39);
    }
    p {
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    div {
      background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-white-border.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include vw(height, 40);
      @include vw(width, 40);
      @include vw(margin-left, 15);
      @include vw(margin-right, 17);
      @include breakpoint(mobile) {
        @include vwMobile(height, 25);
        @include vwMobile(width, 25);
        @include vwMobile(margin-left, 15);
        @include vwMobile(margin-right, 17);
      }
    }
  }
  @keyframes custom {
    0% {
      opacity: 0;
    }
    8% {
      opacity: 0;
    }
    12% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    28% {
      opacity: 1;
    }
    48% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .error {
    background-color: $red;
    @include breakpoint(mobile) {
      @include vwMobile(padding, 5);
      height: auto;
    }
    div {
      background-image: url('../../../../assets/images/icons/Icon-Close.svg');
      @include vw(background-size, 20);
      @include breakpoint(mobile) {
        @include vwMobile(background-size, 18);
      }
    }
  }
  .registration-form {
    width: 100%;
    height: 100%;
    color: $yellowIg;
    @include vw(margin-left, 0);
    @include vw(margin-right, 0);
    @include vw(margin-top, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 0);
      @include vwMobile(padding-left, 0);
      @include vwMobile(padding-right, 0);
    }

    .row {
      display: flex;
      height: auto;
      justify-content: space-between;
      @include breakpoint(mobile) {
        flex-direction: column;
        @include vwMobile(margin-bottom, 0);
      }
      a {
        color: $yellowMild;
      }
    }
    .d-none {
      display: none;
    }
    .mb-0 {
      margin-bottom: 0;
    }
    .col {
      position: relative;
      .label {
        position: absolute;
        @include vw(font-size, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 2;
        }
        &::after {
          content: '*';
        }
        &.none {
          &::after {
            content: '';
          }
        }
      }
      input:invalid {
        box-shadow: none;
        outline: none;
      }

      input {
        width: 100%;
        border: solid 1px $gray-home;
        background-color: transparent;
        color: $gray;
        line-height: 1.38;
        outline: none;
        @include vw(font-size, 20);
        @include vw(height, 66);
        @include vw(border-left, 0);
        @include vw(border-right, 0);
        @include breakpoint(mobile) {
          @include vwMobile(height, 65);
          @include vwMobile(font-size, 16);
        }
      }
      .strenght-psw-container {
        @include vw(margin-top, 15);
        .strenght-psw-label {
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 15);
            @include vwMobile(font-size, 16);
          }
        }
      }
    }
    .tooltip-box {
      position: absolute;
      right: 0;
    }
    label {
      @include breakpoint(mobile) {
        width: 86%;
        word-break: break-word;
      }
    }
    .checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include vw(margin-top, 5);
      @include vw(margin-bottom, 40);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 10);
        @include vwMobile(margin-bottom, 40);
      }
    }
    .checkboxcontainer {
      color: $white;
      input[type='checkbox'] {
        box-sizing: border-box;
        border-style: solid;
        border-color: $white;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background-color: $blueDeep;
        cursor: pointer;
        outline: none;
        @include vw(width, 20);
        @include vw(height, 20);
        @include vwAll(margin, 0, 0, 0, 0);
        @include vw(border-width, 1);
        @include breakpoint(mobile) {
          @include vwMobile(width, 25);
          @include vwMobile(height, 25);
        }
      }
      input[type='checkbox']:checked {
        background-clip: content-box;
        background-color: $yellowIg;
        @include vwAll(padding, 5, 5, 5, 5);
        @include breakpoint(mobile) {
          @include vwMobileAll(padding, 7, 7, 7, 7);
        }
      }
    }
    select {
      width: 80%;
      border: solid 1px $gray-home;
      @include vw(border-left, 0);
      background-color: $almostBlack;
      color: $gray;
      outline: none;
      @include vw(font-size, 20);
      line-height: 1.38;
      @include vw(height, 66);
      -webkit-appearance: none;
      -moz-appearance: none;
      background: $almostBlack
        url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='20' height='20' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>")
        no-repeat;
      background-position: right 13/1920 * 100vw top 55%;
      background-repeat: no-repeat;
      cursor: pointer;
      @include vw(background-size, 20);
      @include breakpoint(mobile) {
        @include vwMobile(height, 65);
        @include vwMobile(font-size, 16);
      }
    }
    .col-6 {
      width: 48%;
      @include breakpoint(mobile) {
        width: 100%;
      }

      button {
        width: 100%;

        @include vw(margin-top, 48);
        .theme-button-container div {
          background-image: url('../../../../assets/images/icons/icon-send-black.svg');
          @include vw(background-size, 40);
          @include breakpoint(mobile) {
            @include vwMobile(background-size, 40);
            @include vwMobile(height, 40);
            @include vwMobile(width, 40);
          }
        }
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 0);
          @include vwMobile(margin-bottom, 35);
          @include vwMobile(height, 65);
        }
      }
    }
    .col-12 {
      width: 100%;
    }
    .input-container {
      display: -ms-flexbox; /* IE10 */
      display: flex;
      width: 100%;
      flex-direction: column;
      @include vw(margin-bottom, 40);
      @include vw(margin-top, 0);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 25);
        @include vwMobile(margin-top, 15);
      }
    }
    .input-container__row {
      display: flex;
      width: 100%;
      flex-direction: row;
      @include vw(margin-top, 48);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 30);
      }
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px $gray-home;
      background: $almostBlack;
      color: $white;
      text-align: center;
      @include vw(border-right, 0);
      @include vw(min-width, 50);
    }
    .icon-type {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include vw(width, 20);
      @include vw(height, 20);
      @include breakpoint(mobile) {
        @include vwMobile(width, 18);
        @include vwMobile(height, 18);
      }
    }
    .icon-profile {
      @extend .icon-type;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Profile.svg');
    }
    .icon-email {
      @extend .icon-type;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Email.svg');
    }
    .icon-code {
      @extend .icon-type;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Code.svg');
    }
    .icon-psw {
      @extend .icon-type;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Password.svg');
    }
    .icon-address {
      @extend .icon-type;
      background-image: url('../../../../assets/images/icons/Icon-Address.svg');
    }
    .icon-loading {
      @extend .icon-type;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Loading.svg');
    }
    .icon-validate {
      @extend .icon-type;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-Green.svg');
    }
    .icon-fail {
      @extend .icon-type;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Close-Red.svg');
    }
    .iconWapper {
      display: flex;
      height: inherit;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray-home;
      border-right: 0;
      @include vw(width, 75);
      @include breakpoint(mobile) {
        @include vwMobile(width, 65);
      }
    }
    .iconWapperRight {
      border-right: 1px solid $gray-home;
      border-left: 0;
    }
  }
  .account-management-policy {
    @include vw(margin-top, 60);
    @include vw(margin-bottom, 250);
    @include breakpoint(mobile) {
      position: relative;
      z-index: -1;
    }
    * {
      @include vw(font-size, 16);
      line-height: 1.38;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    p {
      color: $gray-dark;
    }
    a {
      color: $yellowMild;
      text-decoration: underline;
    }
  }
}
.DummyUsername,
.DummyPassword {
  display: none;
}
.modal-container-edition {
  background-color: $almostBlack;
  label {
    @include vw(font-size, 22);
    line-height: 2;
    color: $white;
    display: flex;
    justify-content: center;
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 22);
    }
  }
  input {
    width: 100%;
    border: solid 1px $gray-home;
    background-color: transparent;
    color: $gray;
    line-height: 1.38;
    @include vw(padding-left, 30);
    @include vw(padding-right, 30);
    outline: none;
    @include vw(margin-top, 35);
    @include vw(font-size, 20);
    @include vw(height, 66);
    @include breakpoint(mobile) {
      @include vwMobile(height, 65);
      @include vwMobile(font-size, 16);
      @include vwMobile(margin-top, 35);
      @include vwMobile(padding-left, 30);
      @include vwMobile(padding-right, 30);
    }
  }
  .group-button {
    @include vw(margin-top, 60);
    display: flex;
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 60);
    }
    .util-button-continue {
      border: 0;
      @include vw(padding-right, 30);
      @include vw(padding-left, 10);
      @include vw(margin-left, 20);
      @include vw(width, 200);
      @include breakpoint(mobile) {
        @include vwMobile(padding-right, 30);
        @include vwMobile(padding-left, 10);
        @include vwMobile(margin-left, 0);
        @include vwMobile(width, 200);
        @include vwMobile(height, 65);
      }
      div {
        background-image: url('../../../../assets/images/icons/no-border/Icon-Validate.svg');
        @include vw(height, 17);
        @include vw(width, 22);
        @include vw(margin-right, 19);
        @include breakpoint(mobile) {
          @include vwMobile(width, 15);
          @include vwMobile(height, 15);
          @include vwMobile(margin-right, 10);
        }
      }
    }
    .util-button-cancel {
      @include vw(width, 200);
      @include breakpoint(mobile) {
        @include vwMobile(width, 200);
        @include vwMobile(height, 65);
      }
    }
    span {
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
  }
}
