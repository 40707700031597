.partner-container {
  .partner-intro {
    @include vw(height, 347);
    border-bottom: 1px solid $grayButton;
    background-image: url('../../../assets/images/partners.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include vw(padding-top, 100);
    @include vw(padding-left, 156);
    @include breakpoint(mobile) {
      @include vwMobile(height, 850);
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 0;
      background-image: url('../../../assets/images/launcher-bg.jpg');
      @include vwMobile(padding-top, 70);
      @include vwMobile(padding-left, 0);
    }
    h1,
    .partner-intro-title {
      @include vw(font-size, 70);
      margin-bottom: 0;
      font-weight: bold;
      line-height: 1.4;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 55);
        line-height: 1.27;
        text-align: center;
        @include vwMobile(width, 300);
      }
    }
    .partner-intro-desc {
      @include vw(font-size, 26);
      color: $yellowIg;
      line-height: 1.38;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 26);
        @include vwMobile(margin-top, 20);
        @include vwMobile(width, 340);
        line-height: 1.38;
        text-align: center;
      }
    }
  }
  .partner-content {
    @include vwAll(padding, 0, 156, 122, 156);

    @include breakpoint(mobile) {
      @include vwMobileAll(padding, 0, 20, 50, 20);
      @include vwMobile(margin-top, -550);
    }
    .partner-content-category {
      @include vw(font-size, 50);
      line-height: 1.4;
      @include vw(margin-top, 61);
      @include vw(margin-bottom, 50);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 35);
        @include vwMobile(margin-top, 61);
        @include vwMobile(margin-bottom, 35);
      }
    }
    .partner-content-elements {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @include vw(gap, 59);
      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
        @include vwMobile(gap, 22);
      }
    }
    .partner-content-box {
      position: relative;
      display: flex;
      justify-content: center;
      border: 1px solid $grayButton;
      background-color: $blueDark;
      transition: $corners-transition;
      @include vw(height, 279);
      @include vw(padding, 0);
      @include breakpoint(mobile) {
        @include vwMobile(height, 273);
      }
      &:hover {
        @include vw(padding, 20);
        cursor: pointer;
        transition: $corners-transition;
      }
      &:hover .partner-name {
        color: $yellowIg;
      }
      &:hover .partner-link {
        text-decoration: underline;
      }
      .partner-content-box-corners {
        width: 100%;
        height: 100%;
        @include buildCorners(18, 0);
      }

      .partner-content-box-image {
        position: absolute;
        // border: 1px solid $grayButton;
        margin-right: auto;
        margin-left: auto;
        // background-color: $almostBlack;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(width, 327);
        @include vw(height, 138);
        @include vw(top, 36);
        @include vw(left, 0);
        @include vw(right, 0);
        @include breakpoint(mobile) {
          @include vwMobile(width, 370);
          @include vwMobile(height, 138);
          @include vwMobile(top, 33);
        }
      }
      .partner-info {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include vw(top, 193);
        @include breakpoint(mobile) {
          @include vwMobile(top, 193);
        }
      }
      .partner-name,
      .partner-link {
        color: $white;
        line-height: 1.2;
        @include vw(font-size, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
        }
      }
      .partner-link {
        color: $gray;
        line-height: 2;
        @include vw(font-size, 16);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
  }
}
