.content-creator-block-faq {
  // @extend .util-main-padding;
  @include vw(padding-left, 155);
  @include vw(padding-right, 155);
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include vw(padding-bottom, 140);
  @include breakpoint(mobile) {
    @include vwMobile(padding-left, 20);
    @include vwMobile(padding-right, 20);
  }
}
