.notice-notlogin-referal {
  p {
    color: $almostBlack;
    font-weight: bold;
    text-align: center;
    @include vw(font-size, 20);
  }
  position: sticky;
  z-index: 8;
  left: 0;
  display: none; //flex
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: $yellowIg;
  opacity: 0.95;
  // @include vw(top, 80);
  @include vw(height, 70);
  @include breakpoint(mobile) {
    display: none;
  }
}
.referral-block-presentation {
  @include vw(padding-top, 185);
  @include vw(margin-top, -70);
  .consent-required-message {
    height: 100% !important;
  }
  .content-container {
    @extend .util-main-padding;
    @include vw(padding-bottom, 362);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    @include breakpoint(mobile) {
      padding-bottom: 0;
      background-image: none;
    }
    h1,
    .description {
      // @include vw(margin-left, 115);
      @include breakpoint(mobile) {
        @include vwMobile(margin-left, 0);
        @include vwMobile(margin-right, 20);
      }
    }
    h1 {
      @include vw(margin-top, 120);
      @include vw(margin-bottom, 0);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 330);
      }
    }

    .description {
      .mobile-description {
        display: none;
        @include breakpoint(mobile) {
          display: block;
        }
      }
      * {
        margin: 0;
        @extend .paragraph_under_main_header;
        font-weight: bold;
        line-height: 1;
        text-transform: none;
        display: block;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 24);
          line-height: 1.42;
          display: none;
        }
      }
    }
    .theme-button {
      // @include vw(margin-left, 115);
      @include vw(margin-top, 40);
      @include vw(width, 260);
      @include vw(height, 65);
      @include breakpoint(mobile) {
        @include vwMobile(width, 260);
        @include vwMobile(height, 65);
        @include vwMobile(margin-left, 0);
        @include vwMobile(margin-top, 25);
      }
      .theme-button-container {
        div {
          background-image: url('../../../../assets/images/icons/Icon-Play.svg');
          @include vw(width, 20);
          @include vw(height, 20);
          background-size: contain;
          @include breakpoint(mobile) {
            @include vwMobile(width, 20);
            @include vwMobile(height, 20);
          }
        }
      }
    }
    .content-block {
      @include vw(max-width, 750);
      @include breakpoint(mobile) {
        @include vwMobile(max-width, 750);
      }
    }
  }
  @include vw(height, 850);
  background-image: url('../../../../assets/images/Background-Ref-Bottom.svg'),
    linear-gradient(180deg, rgba(12, 19, 21, 0) 90%, #0c1315),
    url('../../../../assets/images/recruit-a-friend/background-raf.jpg');
  background-position-x: center;
  background-position-y: 40.20833vw, 0, 3.38542vw, (65 / $viewport-desktop) * 100vw;
  background-repeat: no-repeat;
  background-size: contain, cover, cover;
  @include breakpoint(mobile) {
    //background-image: none;
    @include vwMobile(height, 800);
    @include vwMobile(margin-top, 0);
    background-image: linear-gradient(to bottom, rgba(12, 19, 21, 0), $almostBlack),
      url('../../../../assets/images/recruit-a-friend/background-raf.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
