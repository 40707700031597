.message-component {
  position: relative;
  z-index: 9;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  //@include vw(top, 80);
  @include vw(right, 0);
  @include vw(min-height, 70);
  @include vw(padding-top, 10);
  @include vw(padding-bottom, 10);
  @include breakpoint(mobile) {
    // position: absolute;
    // @include vwMobile(top, 60);
    @include vwMobile(min-height, 40);
    @include vwMobile(padding-top, 10);
    @include vwMobile(padding-bottom, 10);
  }
  .message-component-title {
    @include vw(padding-left, 156);
    width: 80%;
    p,
    a {
      color: $almostBlack;
      @include vw(font-size, 20);
      line-height: 1.4;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 14);
        line-height: 1.43;
      }
    }
    a {
      text-decoration: underline;
      &:hover {
        font-weight: bold;
      }
    }
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      width: 90%;
    }
  }
  .message-component-close {
    @include vw(width, 20);
    @include vw(height, 20);
    @include vw(margin-right, 156);
    background-image: url('../../../assets/images/icons/Icon-Close-Black.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    @include breakpoint(mobile) {
      @include vwMobile(width, 16);
      @include vwMobile(height, 16);
      @include vwMobile(margin-right, 20);
    }
  }
}
