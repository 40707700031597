.buypageparent {
  display: flex;
  justify-content: space-between;
  @include vw(margin-bottom, 70);
  @include breakpoint(mobile) {
    flex-direction: column;
  }
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    @include breakpoint(mobile) {
      flex-direction: column;
      @include vwMobile(margin-bottom, 30);
    }
  }
  .steam-btns {
    display: grid;
    align-items: center;
    @include vw(margin-top, 15);
    grid-template-columns: 1fr 1fr;
    @include vw(gap, 40);
    @include breakpoint(mobile) {
      grid-template-columns: 1fr;
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
    }
    button,
    a {
      @include vw(min-width, 280);
      @include vw(height, 65);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        width: 100%;
        @include vwMobile(height, 65);
        @include vwMobile(margin-bottom, 15);
      }
      .theme-button-container {
        width: 95%;
        margin-right: auto;
        margin-left: auto;
        div {
          background-image: url('../../../../assets/images/icons/Icon-Play.svg');
          @include vw(width, 20);
          @include vw(height, 20);
          background-size: contain;
          @include breakpoint(mobile) {
            @include vwMobile(width, 20);
            @include vwMobile(height, 20);
            @include vwMobile(margin-right, 10);
          }
        }
        span {
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          font-style: normal;
          @include vw(font-size, 15);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 15);
          }
        }
      }
      &.joinBeta {
        background-color: $yellowIg;
        background-image: linear-gradient(to bottom, #ffa700, #ffd55f);
        &:hover {
          background-color: $yellowIg;
          border-color: $yellowIg;
        }
        .theme-button-container {
          div {
            background-image: url('../../../../assets/images/icons/Icon-Wishlist.svg');
            @include vw(height, 25);
            @include vw(width, 25);
            filter: invert(100%);
            @include breakpoint(mobile) {
              @include vwMobile(height, 25);
              @include vwMobile(width, 25);
              @include vwMobile(margin-right, 15);
            }
          }
          span {
            color: $black;
          }
        }
        &:hover .theme-button-container div {
          background-image: url('../../../../assets/images/icons/Icon-Wishlist.svg');
        }
        &:hover .theme-button-container span {
          color: $black;
        }
      }
      .btn-wrapper {
        @include breakpoint(from-phablet) {
          @include vw(padding-left, 40);
        }
      }
      &.tryFree {
        @include vw(min-width, 280);
        background-image: linear-gradient(to bottom, #0027ff, #221772);
        color: $white;
        border: none;
        .theme-button-container {
          div {
            background-image: url('../../../../assets/images/icons/Icon-Wishlist.svg');
            @include vw(height, 25);
            @include vw(width, 25);
            @include vw(margin-right, 10);
            filter: none;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include breakpoint(mobile) {
              @include vwMobile(height, 25);
              @include vwMobile(width, 25);
              @include vwMobile(margin-right, 10);
            }
          }
          span {
            color: $white;
          }
        }
        &:hover {
          border: none;
          .theme-button-container {
            border-image-source: url('../../../../assets/images/utils/border-image-corners-white.svg');
            div {
              background-image: url('../../../../assets/images/icons/Icon-Wishlist.svg');
            }
            span {
              color: $white;
            }
          }
        }
      }
    }
  }
  h2 {
    @include vw(margin-top, 20);
    @include vw(font-size, 40);
    @include vw(max-width, 680);
    line-height: 1;
    text-align: center;
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 20);
      @include vwMobile(font-size, 20);
      font-weight: 900;
      line-height: 1;
      @include vwMobile(margin-bottom, 20);
      @include vwMobile(max-width, 363);
    }
  }
  .promotion-title {
    * {
      @include vw(margin-top, 20);
      @include vw(font-size, 70);
      @include vw(max-width, 1100);
      line-height: 0.86;
      color: $white;
      opacity: 1 !important;
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 20);
        line-height: 1;
        @include vwMobile(font-size, 35);
        @include vwMobile(margin-bottom, 20);
        max-width: 100%;
      }
    }
  }
  .notice-notlogin {
    position: absolute;
    left: 0;
    display: none;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #ffdf57;
    opacity: 0.95;
    p {
      color: $almostBlack;
      font-weight: bolder;
      @include vw(font-size, 22);
    }
    @include vw(top, 80);
    @include vw(height, 70);
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .buy-packages-logo {
    @include vw(width, 500);
    @include vw(height, 134);
    @include vw(margin-bottom, 15);
    @include breakpoint(mobile) {
      @include vwMobile(width, 266);
      @include vwMobile(height, 71);
    }
  }
  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .buypage-packages-planet1 {
    background-image: url(../../../../assets/images/buypage/planet1.png);
    @include vw(width, 310);
    @include vw(height, 539);
    position: absolute;
    top: 0;
    left: 0;
    @include breakpoint(mobile) {
      @include vwMobile(width, 266);
      display: none;
    }
  }
  .buypage-packages-planet2 {
    background-image: url(../../../../assets/images/buypage/planet2.png);
    @include vw(width, 148);
    @include vw(height, 148);
    position: absolute;
    @include vw(bottom, 28);
    @include vw(right, 67);
    @include breakpoint(mobile) {
      @include vwMobile(width, 266);
      display: none;
    }
  }
  .limited-offer {
    position: absolute;
    @include vw(top, 5);
    // top: 0;
    @include vw(left, -36);
    border-style: none;
    margin-right: auto;
    margin-left: auto;
    background-color: transparent;
    font-weight: bold;
    pointer-events: none;
    text-transform: uppercase;
    border-bottom: solid #ffd55f;
    @include vw(border-bottom-width, 50);
    border-left: solid transparent;
    @include vw(border-left-width, 43);
    border-right: solid transparent;
    @include vw(border-right-width, 59);
    height: 0;
    transform: rotate(-40deg);
    @include vw(width, 160);
    display: flex;
    justify-content: center;
    span {
      @include vw(font-size, 13);
      @include vw(margin-top, 18);
      font-family: eurostile-extended, sans-serif;
      line-height: 1;
      font-weight: 900;
      font-style: normal;
    }
    @include breakpoint(mobile) {
      @include vwMobile(width, 150);
      @include vwMobile(top, 3);
      @include vwMobile(left, -34);
      @include vwMobile(border-bottom-width, 50);
      @include vwMobile(border-left-width, 43);
      @include vwMobile(border-right-width, 60);
      span {
        @include vwMobile(font-size, 16);
        @include vwMobile(margin-top, 17);
      }
    }
  }
  .buypage-packages {
    position: relative;
    background-color: $blueDark;
    @include vw(width, 1050);
    @include vw(padding-top, 60);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(255, 210, 85, 0.2);
    @include breakpoint(mobile) {
      width: 100%;
      @include vwMobile(padding-top, 40);
    }
    .textButtonContainer {
      @include vw(margin-top, 10);
      @include vw(margin-bottom, 50);
      display: flex;
      align-items: center;
      justify-content: start;
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 10);
        @include vwMobile(margin-bottom, 40);
      }

      p {
        color: $white;
        font-weight: bold;
        @include vw(margin-right, 40);
        @include vw(font-size, 20);
        line-height: 1.4;
        text-transform: uppercase;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 11);
          line-height: 1.36;
          @include vwMobile(margin-right, 10);
        }
        &:nth-child(1):before {
          content: '1.';
          @include vw(margin-right, 10);
        }
        &:nth-child(2):before {
          color: $gray-medium;
          content: '2.';
          @include vw(margin-right, 10);
        }
        &:nth-child(3):before {
          color: $gray-medium;
          content: '3.';
          @include vw(margin-right, 10);
        }
      }
      .active {
        color: $yellowIg;
      }

      @include breakpoint(from-phablet) {
        //  @include vwColumnsOrders(3, 0, true, (3, 1, 2));
      }
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 40);
      }

      .headerModificationPackages {
        @include vw(margin-bottom, 15);
        display: inline-block;
      }

      .button-container {
        // @include columns(2, $order: (2, 1));
        margin-left: auto;
        @include breakpoint(mobile) {
          display: none;
        }
        p {
          display: none;
        }
      }

      .steam-buttonBuy {
        background-color: $almostBlack;
        @include vw('width', 250);
      }
    }
    .image-container {
      @include breakpoint(from-phablet) {
        display: flex;
        height: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        @include vw(margin-top, 40);
      }
      @include breakpoint(mobile) {
        width: 100%;
      }
      .priceBox.important {
        background-color: #44565d;
      }
      .delay-one {
        animation-delay: 250ms;
      }
      .delay-two {
        animation-delay: 550ms;
      }
      .delay-three {
        animation-delay: 750ms;
      }
      .priceBox-container {
        @include vw(margin-bottom, 20);
        border: solid 3px transparent;
        &:hover {
          cursor: pointer;
          //transition: $corners-transition;
          //@include vw('padding', 30);
          //  border: solid 3px #ffd255;
        }
        @include breakpoint(mobile) {
          margin-bottom: 0;
          display: flex;
          height: auto;
          border: none;
        }
        &.selected {
          border: solid 3px #ffd255;
        }
      }

      .priceBox {
        position: relative;
        animation-duration: 1s;
        animation-name: fadeInUp;
        animation-fill-mode: both;
        animation-delay: 0;
        opacity: 0;
        border: 1px solid rgba(255, 255, 255, 0.2);
        -webkit-backdrop-filter: blur(14px) brightness(1.4);
        backdrop-filter: blur(14px) brightness(1.4);
        background-color: rgba(22, 32, 36, 0.4);
        text-transform: uppercase;
        @include vw(height, 80);
        @include vw(width, 680);
        @include vw('padding', 0);
        // transition: $corners-transition;
        display: flex;
        align-items: center;
        @include breakpoint(mobile) {
          width: 100%;
          box-sizing: border-box;
          @include vwMobile('height', 100);
          border-left: none;
          border-right: none;
        }
        .real-content {
          position: absolute;
          right: 0;
          left: 0;
          margin-right: auto;
          margin-left: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          @include vw(padding-right, 20);
          @include breakpoint(mobile) {
          }
          .buyPage-logo-time {
            display: flex;
            align-items: center;
          }
          .buyPage-logo {
            @include vw(height, 60);
            @include vw(width, 144);
            display: inline-block;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include breakpoint(mobile) {
              display: none;
            }
          }
          .buyPage-logo-1 {
            background-image: url('../../../../assets/images/buypage/1month.png');
          }
          .buyPage-logo-2 {
            background-image: url('../../../../assets/images/buypage/3months.png');
          }
          .buyPage-logo-3 {
            background-image: url('../../../../assets/images/buypage/6months.png');
          }
          .buyPage-logo-4 {
            background-image: url('../../../../assets/images/buypage/12months.png');
          }
          .buyPage-time {
            color: $white;
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            letter-spacing: normal;
            text-align: center;
            @include vw(font-size, 24);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 25);
              @include vwMobile(margin-left, 10);
            }
          }
          .buyPage-time-mobile {
            @include breakpoint(mobile) {
              @include vwMobile(margin-bottom, 10);
            }
          }
          .buyPage-time-recommended {
            color: $yellowIg;
            @include vw(font-size, 12);
            font-weight: 900;
            text-align: left;
            line-height: 1.2;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 12);
              @include vwMobile(margin-left, 10);
              @include vwMobile(margin-bottom, 5);
            }
          }
          .discount {
            color: $white;
          }
          .hide {
            @include breakpoint(from-phablet) {
              visibility: hidden;
            }
          }
          .buyPage-price {
            text-align: right;
            color: $gray-dark;
            font-weight: bold;
            @include vw(font-size, 14);
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            font-style: normal;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 10);
            }
          }
          .buyPage-price-month {
            @include vw(font-size, 20);
            color: $yellowIg;
            font-weight: 900;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 20);
              @include vwMobile(margin-top, 5);
              @include vwMobile(margin-bottom, 15);
            }
          }

          .buyPage-free {
            @include vw('margin-top', 7);
            color: $white;
            @include vw(font-size, 10);
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            font-style: normal;
            text-align: right;
            line-height: 1.2;
            // @include vw(height, 20);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 10);
            }
          }
          .mobile {
            display: none;
            @include breakpoint(mobile) {
              display: flex;
            }
          }
          button {
            @include vw(margin-top, 50);
            @include vw(margin-bottom, 15);
            .theme-button-container {
              div {
                @include vw(width, 8);
                @include vw(height, 10);
                background-size: contain;
              }
              span {
                @include vw(font-size, 13);
                font-family: eurostile-extended, sans-serif;
                font-weight: 900;
                font-style: normal;
              }
            }
            &.buyButtonGray {
              background-color: $grayButton;
              background-image: none;
            }
          }
          // @include breakpoint(mobile) {
          //   top: 0;
          //   display: flex;
          //   height: 100%;
          //   align-items: center;
          //   justify-content: space-between;
          //   border-style: none;
          //   .buyPage-time {
          //     margin-top: 0;
          //     color: $white;
          //     font-stretch: normal;
          //     font-style: normal;
          //     font-weight: bold;
          //     letter-spacing: normal;
          //     @include vwMobile(font-size, 25);
          //     @include vwMobile('margin-left', 10);
          //   }
          //   .d-none {
          //     display: none;
          //   }
          //   .price-box {
          //     display: flex;
          //     width: auto;
          //     flex-direction: column;
          //     justify-content: center;
          //     @include vwMobile(margin-right, 10);
          //     align-items: flex-end;
          //     .buyPage-price {
          //       position: relative;
          //       color: $yellowIg;
          //       font-weight: bold;
          //       text-align: right;
          //       @include vwMobile(font-size, 10);
          //       @include vwMobile('margin-bottom', 10);
          //     }
          //     .buyPage-free {
          //       position: relative;
          //       color: $yellowIg;
          //       text-align: right;
          //       @include vwMobile(font-size, 15);
          //       @include vwMobile(height, 15);
          //       @include vwMobile('margin-top', 10);
          //     }
          //   }

          //   button {
          //     display: none;
          //   }
          // }
        }
        .visibleBorder {
          width: 100%;
          height: 100%;
          @include breakpoint(from-phablet) {
            @include buildCorners(20, 0);
          }
          @include breakpoint(mobile) {
            border-style: none;
          }
        }
      }
      .priceBox-mobile {
        @include breakpoint(mobile) {
          border-top: none;
        }
      }
      .buyPage-bestvalue {
        position: absolute;
        @include vw(top, -1);
        // top: 0;
        right: 0;
        left: 0;
        border-style: none;
        margin-right: auto;
        margin-left: auto;
        background-color: $yellowIg;
        font-weight: bold;
        pointer-events: none;
        text-transform: uppercase;
        // @include vwAll('padding', 20, 35, 20, 35);
        //@include vwDimensions(190, 60);
        @include vw(width, 210);
        @include vw(height, 50);
        @include vw('font-size', 13);
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        @include breakpoint(mobile) {
          display: none;
        }
      }
      .priceBoxBest {
        position: relative;
        border: 1px solid rgba(255, 255, 255, 0.2);
        -webkit-backdrop-filter: blur(14px) brightness(1.4);
        backdrop-filter: blur(14px) brightness(1.4);
        background-color: rgba(22, 32, 36, 0.4);
        text-transform: uppercase;
        opacity: 0;
        animation-duration: 1s;
        animation-name: fadeInUp;
        animation-fill-mode: both;
        @include vw(height, 80);
        @include vw(width, 680);
        // @include vw('padding', 0);
        // transition: $corners-transition;
        @include breakpoint(mobile) {
          width: 100%;
          box-sizing: border-box;
          // @include vwMobile('margin-bottom', 20);
          @include vwMobile('height', 100);
          border-left: none;
          border-right: none;
          border-top: none;
        }

        .visibleBorderBest {
          width: 100%;
          height: 100%;
          @include breakpoint(from-phablet) {
            @include buildCorners(20, 0);
          }
          @include breakpoint(mobile) {
            border-style: none;
          }
        }
        .real-content {
          position: absolute;
          right: 0;
          left: 0;
          margin-right: auto;
          margin-left: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          @include vw(padding-right, 20);
          .buyPage-logo-time {
            display: flex;
            align-items: center;
          }
          .buyPage-logo {
            @include vw(height, 60);
            @include vw(width, 144);
            display: inline-block;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include breakpoint(mobile) {
              display: none;
            }
          }
          .buyPage-logo-1 {
            background-image: url('../../../../assets/images/buypage/1month.png');
          }
          .buyPage-logo-2 {
            background-image: url('../../../../assets/images/buypage/3months.png');
          }
          .buyPage-logo-3 {
            background-image: url('../../../../assets/images/buypage/6months.png');
          }
          .buyPage-logo-4 {
            background-image: url('../../../../assets/images/buypage/12months.png');
          }
          .buyPage-time {
            // @include vwAll('margin', 25, 0, 0, 0);
            color: $white;
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            letter-spacing: normal;
            text-align: center;
            width: auto;
            // @include vw(height, 41);
            @include vw(font-size, 24);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 25);
              @include vwMobile(margin-left, 10);
            }
          }
          .buyPage-time-recommended {
            color: $yellowIg;
            @include vw(font-size, 12);
            font-weight: 900;
            text-align: left;
            line-height: 1.2;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 12);
              @include vwMobile(margin-left, 10);
              @include vwMobile(margin-bottom, 5);
            }
          }
          .discount {
            color: $white;
          }
          .buyPage-price-month {
            @include vw(font-size, 20);
            color: $yellowIg;
            font-weight: bold;
            text-align: right;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 20);
              @include vwMobile(margin-top, 5);
              @include vwMobile(margin-bottom, 15);
            }
          }
          .buyPage-price {
            // @include vw('margin-top', 13);
            color: $gray-dark;
            font-weight: bold;
            @include vw(font-size, 14);
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            font-style: normal;
            text-align: right;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 10);
            }
          }
          .buyPage-free {
            @include vw('margin-top', 7);
            color: $white;
            @include vw(font-size, 10);
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            font-style: normal;
            //@include vw(height, 20);
            line-height: 1.2;
            text-align: right;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 10);
            }
          }
          .mobile {
            display: none;
            @include breakpoint(mobile) {
              display: flex;
            }
          }
          button {
            @include vw(margin-top, 50);
            @include vw(margin-bottom, 15);
            .theme-button-container {
              div {
                @include vw(width, 8);
                @include vw(height, 10);
                background-size: contain;
              }
              span {
                @include vw(font-size, 13);
                font-family: eurostile-extended, sans-serif;
                font-weight: 900;
                font-style: normal;
              }
            }
          }
          // @include breakpoint(mobile) {
          //   top: 0;
          //   display: flex;
          //   height: 100%;
          //   align-items: center;
          //   justify-content: space-between;
          //   border-style: none;

          //   .buyPage-time {
          //     margin-top: 0;
          //     color: $white;
          //     font-stretch: normal;
          //     font-style: normal;
          //     font-weight: bold;
          //     letter-spacing: normal;
          //     @include vwMobile(font-size, 25);
          //     @include vwMobile('margin-left', 10);
          //   }
          //   .d-none {
          //     display: none;
          //   }
          //   .price-box {
          //     display: flex;
          //     width: auto;
          //     flex-direction: column;
          //     justify-content: center;
          //     align-items: flex-end;
          //     @include vwMobile(margin-right, 10);
          //     .buyPage-price {
          //       position: relative;
          //       // display: inline-block;
          //       color: $yellowIg;
          //       font-weight: bold;
          //       // @include vwMobile(bottom, 10);
          //       //@include vwMobile(left, 38);
          //       text-align: right;
          //       @include vwMobile(font-size, 20);
          //       @include vwMobile('margin-bottom', 10);
          //     }

          //     .buyPage-free {
          //       position: relative;
          //       color: $yellowIg;
          //       text-align: right;
          //       @include vwMobile(font-size, 15);
          //       @include vwMobile(height, 15);
          //       @include vwMobile('margin-top', 10);
          //       //@include vwMobile(bottom, 15);
          //     }
          //   }
          //   button {
          //     display: none;
          //   }
          // }
        }
      }
      .graybox {
        background-color: rgb(128, 128, 128);
      }
    }
    .buyPage-disclaimer {
      strong {
        color: $yellowIg;
        font-weight: normal;
        @include vw(font-size, 16);
      }
      margin: 0 auto;
      color: $gray-dark;
      @include vw('margin-top', 57);
      @include vw('margin-bottom', 20);
      p {
        @include vw(font-size, 16);
        text-align: center;
      }
      h4,
      a {
        @include vw(font-size, 20);
        font-weight: 900;
        text-align: center;
        @include vw(margin-top, 10);
      }
      a {
        color: $yellowIg;
        text-decoration: underline;
      }
      @include vw(min-width, 956);

      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile('margin-top', 52);
        @include vwMobile('margin-bottom', 30);
        p,
        strong,
        h4,
        a {
          @include vwMobile(font-size, 15);
        }
      }
    }

    .buyPage-price-before {
      @include vw(font-size, 18);
      color: $gray-dark;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 18);
      }
    }
  }
  .btn-wrapper {
    @include vw(margin-top, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 40);
    }
    .theme-button {
      background-color: $yellowIg;
      @include vw(width, 430);
      background-image: linear-gradient(to bottom, #ffa700, #ffd55f);
      @include breakpoint(mobile) {
        @include vwMobile(width, 350);
        @include vwMobile(height, 65);
      }
      &:hover {
        background-color: $yellowIg;
        border-color: $yellowIg;
      }
    }
    .theme-button-mydu {
      @include vw(width, 370);
      @include breakpoint(mobile) {
        @include vwMobile(width, 370);
      }
    }
    .theme-button-container {
      div {
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
        @include vw(height, 10);
        @include vw(width, 10);
        @include vw(margin-right, 10);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(mobile) {
          @include vwMobile(height, 10);
          @include vwMobile(width, 10);
          @include vwMobile(margin-right, 10);
        }
      }
      span {
        @include vw(font-size, 13);
        color: $black;
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 13);
        }
      }
    }
    &:hover {
      border: none;
      .theme-button-container {
        border-image-source: url('../../../../assets/images/utils/border-image-corners-black.svg');
      }
    }
  }
  .btn-wrapper-myDu {
    @include vw(margin-top, 15);
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 15);
      @include vwMobile(margin-bottom, 15);
    }
  }
  .buypage-mydu {
    @include vw(width, 496);
    border: 1px solid rgba(255, 210, 85, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0c1315;
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 20);
      width: 100%;
    }
    .mydu-image {
      background-image: url(../../../../assets/images/buypage/bg-myDu.png);
      width: 100%;
      background-size: contain;
      @include vw(height, 310);
      position: relative;
      @include breakpoint(mobile) {
        @include vwMobile(height, 310);
        background-size: cover;
      }
    }
    .mydu-logo {
      background-image: url(../../../../assets/images//icons/mydu-logo.svg);
      @include vw(width, 250);
      background-size: contain;
      @include vw(height, 62);
      @include vw(margin-top, -40);
      z-index: 1;
      position: relative;
      @include breakpoint(mobile) {
        @include vwMobile(width, 250);
        @include vwMobile(height, 62);
        @include vwMobile(margin-top, -40);
      }
    }
    .mydu-title {
      @include vw(margin-top, 15);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 15);
      }
      * {
        @include vw(font-size, 30);
        line-height: 1;
        font-weight: 900;
        text-align: center;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 30);
        }
      }
    }
    .mydu-description,
    .mydu-disclamer {
      @include vw(margin-left, 0);
      @include vw(margin-top, 25);
      @include vw(padding-left, 20);
      @include vw(padding-right, 20);
      @include vw(padding-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 25);
        @include vwMobile(margin-left, 30);
      }
      * {
        @include vw(font-size, 20);
        line-height: 1.5;
        color: #dbdbdb;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
        }
      }
      em {
        color: #ffb114;
        font-style: normal;
      }
      ul {
        list-style-type: none;
        padding-left: 0;
        @include vw(margin-top, 5);
      }
      li::before {
        content: '-';
        @include vw(margin-right, 5);
        @include breakpoint(mobile) {
          @include vwMobile(margin-right, 5);
        }
      }
    }
    button:disabled {
      background-color: $gray-dark !important;
      background-image: none;
      cursor: not-allowed;
    }
    button:disabled:hover .theme-button-container {
      border-style: none;
    }
    .mydu-message {
      @include vw(margin-top, 16);
      @include vw(margin-bottom, 20);
      * {
        @include vw(font-size, 20);
        text-align: center;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
    .mydu-disclamer {
      margin-left: 0;
      //@include vw(margin-top, 140);
      //@include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 38);
        @include vwMobile(margin-bottom, 20);
      }
      * {
        @include vw(font-size, 16);
        text-align: center;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
      em {
        color: #ffd255;
      }
    }
    .mydu-price {
      @include vw(margin-top, 10);
      text-align: center;
      color: #ffd255;
      font-weight: 900;
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 10);
      }
    }
    .before {
      color: $gray-dark;
    }
  }
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 20%, 0);
    }
    100% {
      opacity: 1;
      transform: translateZ(0);
    }
  }
}
