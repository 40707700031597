/* Utils Classes */

$navbar-height: 80;
$navbar-border-width: 1;

.util-navbar-oblique-border-base {
  position: absolute;
  top: 0;
  content: ' ';
  transform: skew(-36deg, 0deg);
  @include vw(height, 80);
}

.util-navbar-link {
  color: $white;
  outline-style: none;
}

.util-navbar-link-secondary {
  color: $yellowIg;
}

.navbarimage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.submenutemplate {
  @include breakpoint(from-phablet) {
    position: absolute;
    z-index: 2;
    //display: none;
    border-width: 0.1px;
    border-style: solid;
    //  border-top-style: none;
    border-color: $border-nav;
    background-image: linear-gradient(to bottom, $blueDark, $almostBlack);
    @include vw(top, $navbar-height - $navbar-border-width);
    // @include vw(border-width, $navbar-border-width);
    @include vwAll(padding, 20, 20, 20, 20);
    animation-duration: 0.6s;
    animation-name: fadeInUp;
    animation-timing-function: cubic-bezier (0.65, 0, 0.35, 1);
    animation-fill-mode: forwards;
    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translate3d(0, 20%, 0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
    &:hover {
      display: flex;
    }
  }
}

.simplesubmenutemplate {
  @extend .paragraph_base;
  box-sizing: border-box;
  text-align: left;
  @include breakpoint(from-phablet) {
    position: relative;
    @include buildCorners(15, 0);
    @include vw(width, 240);
    @include vwAll(padding, 10, 0, 20, 5);
    @include vw(min-height, 250);
  }
}
.bigsubmenutemplate {
  @include breakpoint(from-phablet) {
    @include vw(width, 600);
    @include vwAll(padding, 10, 0, 0, 0);
    @include vw(min-height, 230);
  }
}
.submenuheadertemplate {
  @extend .paragraph_base;
  color: $white;
  text-transform: uppercase;
  @include vw(padding-bottom, 14);
  border-bottom: 1px solid $white02;
  // @include vw(font-size, 16);
}

.submenucontenttemplate {
  @include vw(padding-top, 7);

  @include breakpoint(mobile) {
    @include vwMobile(padding-top, 55);
  }

  a,
  .confirm-link {
    @include vw(font-size, 16);
    display: block;
    color: $gray-dark;
    font-weight: normal;
    text-align: left;
    text-transform: none;
    @include vw(margin-top, 15);
    @extend .paragraph_disclaimer;

    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 20);
    }

    &.current,
    &:hover,
    &:focus,
    &:active {
      @extend .util-navbar-link-secondary;
      cursor: pointer;
    }
    &:before {
      display: inline-block;
      content: '+';
      @include vw(margin-right, 15);
      @include breakpoint(mobile) {
        @include vwMobile(margin-right, 15);
      }
    }
  }
}

.navbuttons {
  @include breakpoint(from-phablet) {
    @include vw(width, 210);
    @include vw(height, 40);
    @include vw(font-size, 15);
    @include vw(margin-bottom, 10);
    border-style: none;

    div {
      @include vw(width, 45);
    }
  }

  @include breakpoint(mobile) {
    @include vwMobile(height, 40);
    @include vwMobile(width, 250);
    @include vwMobile(margin-bottom, 10);
    border-style: none;

    div {
      @include vwMobile(width, 45);
    }
  }
}

.navhoverborder {
  border-color: $white;
  border-bottom: solid;
  @include vw(border-width, 2);
}

.nabarlinks {
  @extend .util-navbar-link;
  @extend .text_font_eurostile;
  @extend .link_main;
  box-sizing: content-box;
  border: initial;
  background-color: initial;
  cursor: pointer;
  line-height: normal;
  text-align: center;

  &.current,
  &:hover,
  &:focus,
  &:active {
    @extend .util-navbar-link-secondary;
  }
}
