.home-menu-component {
  overflow: hidden;
  border: 0;
  border-style: solid;
  border-color: $gray-psw;
  border-top-width: 1px;
  border-bottom-width: 1px;

  text-align: center;

  @include vw(padding-top, 30);

  &.alt,
  .alt & {
    .menu-item {
      .title {
        @include vw(font-size, 24);
        color: $yellowIg;
      }
      .sub-title {
        display: block;
        @include vw(font-size, 18);
      }
    }
  }

  .menu-item {
    @include vw(margin, 15);
    position: relative;
    display: inline-block;
    margin: 0 auto;

    color: $white;
    font-weight: bold;
    line-height: 1.2;
    opacity: 1;
    text-transform: uppercase;
    @include vw(padding-left, 30);
    @include vw(padding-right, 80);

    @include vw(margin-bottom, 30);
    @include breakpoint(mobile) {
      @include vwMobile(width, 373);
      @include vwMobile(font-size, 15.4);
      @include vwMobile(margin-bottom, 40);
      @include vwMobile(line-height, 30);
    }

    .title,
    .sub-title {
      display: block;
      color: inherit;
      line-height: 1;
    }

    .title {
      @include vw(font-size, 35);
    }

    .sub-title {
      display: none;
    }

    &:hover {
      color: $yellowIg;
    }

    &::before {
      left: -20px;
    }
    &::after {
      right: 20px;
    }
    &::before,
    &::after {
      @extend .util-navbar-oblique-border-base;
      @include vw(border-right-width, 2);
      height: 100px;
      border-right-style: solid;
      border-right-color: $gray-psw;
      margin-top: -30px;
    }
  }
}
