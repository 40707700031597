@import 'assets/neutron/neutron';
@import 'assets/scss/mixins/corner_builder';
@import 'assets/scss/utils';
@import 'assets/scss/theme.settings';
@import 'assets/scss/theme.icons';
@import 'assets/scss/theme.form';
@import 'assets/scss/theme.button';
@import 'assets/scss/theme.box';
@import 'assets/scss/navbar.utils';
@import 'assets/scss/blocks-pictures.utils';

body {
  @include vw(margin, 0);
  min-height: 100%;
  text-rendering: optimizeLegibility;

  * {
    @extend .text_font_eurostile;
    @include vw(font-size, 20);

    @include breakpoint(mobile) {
      @include vwMobile(font-size, 20);
    }
  }
}

html {
  height: 100%;
  background-color: $blueDeep;
  scroll-behavior: smooth;
}

@import './src/app/app.styles';

body.widgetHidden {
  .xpaystation-widget-lightbox {
    display: none;
  }
}
