.account-resend-confirmation {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    @include vw(height, 768);
    @include vw(width, 1000);
    border: 0;
    @include breakpoint(mobile) {
      width: 100%;
      border: 0;
      @include vwMobile(height, 768);
    }
  }
  h2 {
    @include vw(font-size, 50);
    line-height: 1.4;
    text-align: center;
    @include vw(margin-bottom, 60);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 35);
      @include vwMobile(margin-bottom, 40);
    }
  }

  .message-image-success {
    @include vw(height, 130);
    @include vw(width, 130);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include vw(margin-bottom, 80);
    @include breakpoint(mobile) {
      @include vwMobile(height, 80);
      @include vwMobile(width, 80);
      @include vwMobile(margin-bottom, 40);
      @include vwMobile(margin-top, 30);
    }
  }

  .message-image-resend {
    @extend .message-image-success;
    margin-right: auto;
    margin-left: auto;

    background-image: url('../../../../assets/images/icons/Icon\ -\ Email.svg');
  }
}
