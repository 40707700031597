.news-archives {
  // @include vw(padding-top, 65);
  @include vw(margin-top, -1000);
  @include breakpoint(mobile) {
    @include vwMobile(margin-top, -230);
  }
  .filter-by-categories {
    @include vw(height, 160);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $blueLight;
    @include vw(padding-left, 272);
    @include vw(padding-right, 272);
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      @include vwMobile(height, 150);
      @include vwMobile(margin-top, 49);
      @include vwMobile(padding-bottom, 100);
      @include vwMobile(padding-top, 100);
    }
    .selectors {
      display: flex;
      select {
        @include vw(font-size, 18);
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent
          url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='20' height='20' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>")
          no-repeat;
        background-position: right 3/1920 * 100vw top 55%;
        background-repeat: no-repeat;
        color: $white;
        cursor: pointer;
        line-height: 1.44;
        outline: none;
        @include vw(background-size, 15);
        @include vw(min-width, 145);
        @include vw(margin-right, 10);
        @include breakpoint(mobile) {
          @include vwMobile(margin-right, 10);
          @include vwMobile(font-size, 18);
          @include vwMobile(background-size, 15);
          @include vwMobile(min-width, 120);
        }
      }
      option {
        background-color: $almostBlack;
        @include vw(font-size, 18);
        line-height: 1.44;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 18);
        }
      }
      @include breakpoint(mobile) {
        flex-direction: column;
      }
    }
    .categories {
      position: relative;
      @include vw(width, 140);
      @include breakpoint(mobile) {
        @include vwMobile(width, 140);
      }
    }
    .categories-default-value {
      display: flex;
      align-items: center;
      cursor: pointer;
      p {
        @include vw(font-size, 18);
        text-transform: capitalize;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
      div {
        @include vw(width, 18);
        @include vw(height, 18);
        @include vw(margin-left, 14);
        background-image: url('../../../../assets/images/icons/Icon-DropArrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        @include vw(background-size, 14);
        @include breakpoint(mobile) {
          @include vwMobile(width, 18);
          @include vwMobile(height, 18);
          @include vwMobile(margin-left, 14);
          @include vwMobile(background-size, 14);
        }
      }
    }
    .categories-dropbox {
      position: absolute;
      z-index: 20;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $grayButton;
      background-color: $almostBlack;
      @include vw(top, 32);
      @include vw(width, 250);
      @include vw(min-height, 220);
      @include vw(padding, 20);
      @include breakpoint(mobile) {
        @include vwMobile(top, 35);
        @include vwMobile(width, 200);
        @include vwMobile(height, 150);
        @include vwMobile(padding, 10);
      }
      .corners {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        @include buildCorners(15, 0);
      }
      .content {
        @include vw(padding-top, 22);
      }
      p {
        @include vw(font-size, 18);
        text-transform: capitalize;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
      .selected-item {
        padding-bottom: 0.625vw;
        border-bottom: 1px solid $grayButton;
        margin-bottom: 1vw;
      }
      .box-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        @include vw(margin-bottom, 10);
        @include vw(padding-left, 15);
      }
      .box {
        @include vw(width, 15);
        @include vw(height, 15);
        display: inline-block;
        border-width: 0.1px;
        border-style: solid;
        border-color: rgba(255, 255, 255, 0.6);
        margin-bottom: 0;
        @include vw(padding, 4);
        @include vw(margin-right, 15);
        @include breakpoint(mobile) {
          @include vwMobile(width, 15);
          @include vwMobile(height, 15);
          @include vwMobile(padding, 4);
          @include vwMobile(margin-right, 15);
        }
      }

      .selected {
        background-clip: content-box;
        background-color: $yellowIg;
      }
    }
    .buttom-group {
      display: flex;
      @include breakpoint(mobile) {
        flex-direction: column;
      }
      .archiveButton--right {
        @include vw(margin-left, 20);
        .close {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Close.svg');
          @include vw(height, 20);
          @include vw(width, 20);
          @include vw(background-size, 16);
          @include breakpoint(mobile) {
            @include vwMobile(height, 20);
            @include vwMobile(width, 20);
            @include vwMobile(margin-right, 10);
            @include vwMobile(background-size, 16);
          }
        }
        @include breakpoint(mobile) {
          @include vwMobile(margin-left, 0);
          @include vwMobile(margin-top, 10);
        }
      }
      .archiveButton {
        @include vw(height, 65);
        @include vw(min-width, 200);
        border: 1px solid $gray-home;
        background-color: $blueDark;
        background-image: linear-gradient(to right, $blueDark 50%, transparent 50%);
        @include breakpoint(mobile) {
          @include vwMobile(height, 65);
          @include vwMobile(min-width, 200);
        }
        .theme-button-container {
          background-color: $blueDark;
          font-weight: bold;
          text-transform: uppercase;

          span {
            @include vw(font-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 14);
            }
          }
          .validate {
            background-image: url('../../../../assets/images/icons/no-border/Icon-Validate.svg');
            @include vw(height, 20);
            @include vw(width, 20);
            @include vw(background-size, 18);
            @include breakpoint(mobile) {
              @include vwMobile(height, 20);
              @include vwMobile(width, 20);
              @include vwMobile(margin-right, 10);
              @include vwMobile(background-size, 18);
            }
          }
        }
        &:hover span {
          color: $yellowIg;
        }
        &:hover .close {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Close-Yellow.svg');
        }
        &:hover .validate {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-Yellow.svg');
        }
        &:hover {
          border-color: $yellowIg;
        }
        &:hover .theme-button-container {
          @include buildCornersYellow(13, 5);
        }
      }
    }
  }

  .block-title-options {
    @include vw(margin-left, 272);
    @include vw(margin-right, 272);
    @include vw(height, 196);
    @include vw(margin-bottom, 60);
    @include breakpoint(mobile) {
      @include vwMobile(margin-left, 20);
      @include vwMobile(margin-right, 20);
      @include vwMobile(height, 80);
    }
    div {
      @include vw(padding-top, 100);
      @include vw(padding-bottom, 60);
      @include breakpoint(mobile) {
      }
      a {
        color: $white;
        font-weight: bold;
        line-height: 1.4;
        text-decoration: none;
        text-transform: uppercase;
        @include vw(font-size, 35);
        @include vw(margin-right, 40);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 18);
        }

        &.archive {
          color: $yellowIg;
        }
      }
    }
  }
}
.news-archiver-container {
  @include vw(height, 1000);

  background-image: linear-gradient(rgba(12, 19, 21, 0) 58%, rgba(12, 19, 21, 0.75) 70%, rgb(12, 19, 21) 85%),
    url('../../../../assets/images/recruit-a-friend/Image\ 48.png');
  background-position-x: center;
  background-position-y: 5%;
  background-repeat: no-repeat;
  background-size: cover;
}
.news-archivesBlock {
  @include vw(margin-bottom, 60);
  a {
    text-decoration: none;
  }
  .news-block {
    position: relative;
    @include vw(height, 342);
    @include vw(margin-bottom, 30);
    border: solid 1px $grayButton;
    background-color: $almostBlack;
    @include vw(margin-left, 272);
    @include vw(margin-right, 272);
    @include breakpoint(mobile) {
      @include vwMobile(margin-left, 20);
      @include vwMobile(margin-right, 20);
      @include vwMobile(height, 400);
      @include vwMobile(margin-bottom, 40);
    }
    @include vw(padding, 0);
    transition: $corners-transition;
    &:hover {
      @include vw(padding, 15);
      transition: $corners-transition;
    }
    .corners {
      width: 100%;
      height: 100%;
      @include buildCorners(25, 0);
    }
    .real-content {
      position: absolute;
      @include vw(top, 40);
      @include vw(left, 40);
      display: flex;

      align-items: center;

      @include breakpoint(mobile) {
        right: 0;
        left: 0;
        flex-direction: column;
        margin-right: 0;
        margin-left: 0;
      }

      .box-image {
        @include vw(height, 261);
        @include vw(width, 465);
        background-color: $black;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include breakpoint(mobile) {
          @include vwMobile(height, 200);
          @include vwMobile(margin-top, 10);
          @include vwMobile(width, 370);
          @include vwMobile(margin-bottom, 20);
        }
      }
      .content-archive {
        position: relative;
        @include vw(width, 700);
        @include vw(height, 261);
        @include vw(margin-left, 40);
        @include vw(margin-right, 40);
        @include breakpoint(mobile) {
          @include vwMobile(height, 150);
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
        .type {
          @include vw(height, 41);
          @include vw(width, 169);
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #162024;
          @include breakpoint(mobile) {
            @include vwMobile(height, 35);
            @include vwMobile(width, 140);
          }
          p {
            line-height: 1.38;
            text-transform: uppercase;
            @include vw(font-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 14);
            }
          }
          @include vw(margin-bottom, 40);
          @include breakpoint(mobile) {
            @include vwMobile(margin-bottom, 20);
          }
        }
        .description {
          line-height: 1.36;
          word-break: break-all;
          @include vw(margin-bottom, 70);
          @include vw(font-size, 22);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 20);
            @include vwMobile(margin-bottom, 20);
            @include vwMobile(font-size, 16);
          }
        }
        .date {
          position: absolute;
          bottom: 0;
          line-height: 1.38;
          @include vw(font-size, 16);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
      }
    }
  }
}
