.convert-modal-container {
  @include vw(width, 800);
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border: solid 1px $gray-oblique;
  background-image: linear-gradient(to bottom, $blueDark, $almostBlack);
  animation-duration: 0.6s;
  animation-name: fadeInUpModal;
  animation-timing-function: cubic-bezier (0.65, 0, 0.35, 1);
  animation-fill-mode: forwards;
  @include breakpoint(mobile) {
    @include vwMobile(height, 1500);
  }
  @keyframes fadeInUpModal {
    from {
      opacity: 0;
      transform: translate(-50%, -30%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  @include breakpoint(mobile) {
    width: 100%;
    height: auto;
    transform: none;
    @include vwMobile(top, 65);
    background-image: none;
    background-color: $almostBlack;
    left: 0;
    animation: none;
    border: none;
  }
  .corners {
    display: flex;
    @include buildCorners(20, 0);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .convert-modal-title {
      @include vw(margin-top, 30);
      @include vw(margin-bottom, 30);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 0);
        @include vwMobile(margin-bottom, 30);
      }
      * {
        color: #fff;
        @include vw(font-size, 35);
        font-weight: bold;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 25);
        }
      }
    }
    .convert-modal-desc {
      @include vw(margin-top, 20);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 0);
      }
      * {
        color: #a8a8a8;
        @include vw(font-size, 20);
        line-height: 1.5;
        text-align: center;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
        }
      }
      strong {
        color: #fff;
        font-weight: bold;
      }
      em {
        color: #ffa700;
        font-weight: bold;
        font-style: normal;
      }
    }
    .convert-columns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include vw(margin-top, 42);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 40);
        flex-direction: column;
      }
      .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include vw(width, 288);
        @include vw(height, 339);
        border: 1px solid $white02;
        background-color: #00090b;
        @include breakpoint(mobile) {
          @include vwMobile(width, 288);
          @include vwMobile(height, 339);
        }
        .column-1-title {
          @include vw(margin-top, 15);
          color: #ffd255;
          @include vw(font-size, 22);
          font-weight: bold;
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 15);
            @include vwMobile(font-size, 22);
          }
        }
      }
      .column-2 {
        background-image: linear-gradient(to bottom, #291f06, #000);
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 20);
        }
      }
      .column-1-logo {
        @include vw(height, 105);
        @include vw(width, 105);
        @include vw(margin-top, 48);
        background-image: url('../../../../assets/images/icons/Icon-DU-50.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(mobile) {
          @include vwMobile(height, 105);
          @include vwMobile(width, 105);
          @include vwMobile(margin-top, 48);
        }
      }
      .column-1-date-exp {
        color: #909090;
        @include vw(margin-top, 3);
      }
      .column-days {
        @include vw(margin-top, 31);
        @include vw(font-size, 22);
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 31);
          @include vwMobile(font-size, 22);
        }
      }
      .column-days-2 {
        color: #ffb100;
      }
    }
    .convert-buttons {
      @include vw(margin-top, 40);
      @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        @include vwMobile(margin-top, 40);
      }
      button {
        &.continue,
        &.cancel {
          @include vw(height, 65);
          @include vw(width, 263);
          margin-top: 0.18167vw !important;

          &:hover {
            background-color: $color-positive;
          }
          .theme-button-container {
            div {
              background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
              @include vw(height, 17.1);
              @include vw(width, 17.3);
              @include vw(background-size, 17);
              @include breakpoint(mobile) {
                @include vwMobile(height, 25);
                @include vwMobile(width, 20);
                @include vwMobile(background-size, 18);
                @include vwMobile(margin-right, 10);
              }
            }
          }

          &:hover .theme-button-container div {
            background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
          }
          &:hover .theme-button-container span {
            color: $black;
          }
          @include breakpoint(mobile) {
            @include vwMobile(height, 65);
            @include vwMobile(width, 263);
            @include vwMobile(font-size, 16);
            @include vwMobile(margin-top, 60);
            @include vwMobile(margin-bottom, 20);
          }
        }
        &.cancel {
          background-color: transparent;
          background-image: none;
          border: 1px solid $white02;
          @include vw(margin-left, 20);
          @include breakpoint(mobile) {
            margin-left: 0;
          }
          .theme-button-container {
            div {
              background-image: url('../../../../assets/images/icons-buttons/Icon-Close.svg');
            }
          }
          &:hover {
            background-color: transparent;
          }
          &:hover .theme-button-container span {
            color: #fff;
          }
          &:hover .theme-button-container div {
            background-image: url('../../../../assets/images/icons-buttons/Icon-Close.svg');
          }
          &:hover .theme-button-container {
            @include buildCornersImage($size-corners, $size-corners-margin, 'border-image-corners-white');
          }
        }
      }
      button:disabled {
        opacity: 0.5;
      }
    }
    .convert-arrow {
      @include vw(width, 51);
      @include vw(height, 23);
      @include vw(margin-left, 25);
      @include vw(margin-right, 25);
      background-image: url('../../../../assets/images/icons/Icon-Arrow.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @include breakpoint(mobile) {
        display: none;
      }
    }
    .convert-disclamer {
      @include vw(margin-top, 33);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 20);
        @include vwMobile(margin-bottom, 25);
      }
      * {
        @include vw(font-size, 16);
        color: #a8a8a8;
        text-align: center;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
  }
}
.popup-content {
  animation-duration: 0.6s;
  animation-name: fadeInUpModalCopy;
  animation-timing-function: cubic-bezier (0.65, 0, 0.35, 1);
  animation-fill-mode: forwards;
}
@keyframes fadeInUpModalCopy {
  from {
    opacity: 0;
    transform: translate(0, 40%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
