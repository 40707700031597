.snackbar-container {
  position: fixed;
  @include vw(top, 100);
  left: 50%;
  transform: translate(-50%, 0);
  @include vwAll(padding, 0, 30, 0, 30);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  @include vw(width, 877);
  @include vw(height, 65);
  background-color: $white;
  box-shadow: 0 3px 50px 0 rgba(255, 255, 255, 0.5);
  border: 1px solid $white02;
  animation-duration: 6s;
  animation-name: custom;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  @include breakpoint(mobile) {
    width: 100%;
    @include vwMobile(height, 65);
  }
  .icon-desc {
    @include vw(width, 30);
    @include vw(height, 20);
    background-image: url('../../../assets/images/icons/no-border/Icon-Validate.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include vw(margin-right, 25);
    @include breakpoint(mobile) {
      @include vwMobile(width, 27);
      @include vwMobile(height, 20);
    }
  }
  .icon-failure {
    background-image: url('../../../assets/images/icons/no-border/Icon-Close.svg');
  }
  .icon-close {
    @include vw(width, 30);
    @include vw(height, 20);
    background-image: url('../../../assets/images/icons/no-border/Icon-Close.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    @include breakpoint(mobile) {
      @include vwMobile(width, 27);
      @include vwMobile(height, 20);
    }
  }
  p {
    @include vw(font-size, 16);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 16);
      line-height: 1;
    }
  }
}
@keyframes custom {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 0;
  }
  12% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  28% {
    opacity: 1;
  }
  48% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.snackbar-success {
  background-color: $green;
  box-shadow: 0 3px 50px 0 rgba(17, 177, 103, 0.5);
}
.snackbar-failure {
  background-color: $red;
  box-shadow: 0 3px 50px 0 #c82827;
}
