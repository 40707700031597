.languagemenucontainer {
  position: absolute;
  z-index: 10;
  text-align: left;
  @extend .submenutemplate;
  //@include vw(width, 270);
  @include vw(min-height, 245);
  @include vw(right, 0);

  @include breakpoint(mobile) {
    position: relative;
    width: 100%;
  }

  .languagesubmenu {
    @extend .simplesubmenutemplate;
    @include vw(min-height, 180);
    @include vwAll(padding, 30, 8, 30, 8);
    @include breakpoint(mobile) {
      @include vwMobileAll(padding, 0, 0, 0, 0);
      border: 0;
    }
    .language-title-mobile {
      display: none;
      @include breakpoint(mobile) {
        display: flex;
        @include vwMobile(font-size, 20);
        line-height: 1.4;
        border-bottom: 1px solid $grayButton;
        @include vwMobile(padding-bottom, 18);
        @include vwMobile(margin-bottom, 44);
      }
    }
    // @include buildCorners(12, 0);
    input[type='checkbox'] {
      @include vw(width, 16);
      @include vw(height, 16);
      border-width: 0.1px;
      border-style: solid;
      border-color: $gray-dark;
      @include breakpoint(mobile) {
        @include vwMobile(width, 16);
        @include vwMobile(height, 16);
        @include vwMobile(margin-right, 20);
      }
    }

    .selected {
      @include vwAll(padding, 4, 4, 4, 4);
      background-clip: content-box;
      background-color: $yellowIg;
      @include breakpoint(mobile) {
        @include vwAllMobile(padding, 3, 3, 3, 3);
      }
    }

    .languageselected {
      a {
        border-width: 0.1px;
        border-bottom-style: solid;
        border-bottom-color: $grayButton;
        color: $gray-dark;
        font-weight: normal;
        text-transform: none;
        //@include vw(border-width, 1);
        @extend .paragraph_base;
        @include vw(padding-bottom, 13);
        @include breakpoint(mobile) {
          display: flex;
          align-items: center;
          width: 100%;
          @include vwMobile(padding-bottom, 18);
        }
      }
    }
    .languages {
      @include vw(margin-top, 20);

      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 20);
        display: flex;
        flex-direction: column;
        margin-top: 0;
      }

      a {
        border-width: 0.1px;
        border-bottom-style: solid;
        border-bottom-color: $grayButton;
        @include vw(padding-bottom, 15);
        @extend .paragraph_base;
        color: $gray-dark;

        font-weight: normal;
        text-transform: none;
        @include vw(margin-top, 15);
        @include breakpoint(mobile) {
          border-bottom: 1px solid $grayButton;
          @include vwMobile(margin-top, 15);
          @include vwMobile(padding-bottom, 18);
        }

        &.current,
        &:hover,
        &:focus,
        &:active {
          @extend .util-navbar-link-secondary;
          cursor: pointer;
        }
      }
    }
  }
}
