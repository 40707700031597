.legal-navigation-block {
  position: relative;
  @include vwMobile(top, -70);
  .news-detail-header {
    display: flex;
    // @include vw(height, 65);
    height: auto;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #9999;
    a {
      color: $white;
      line-height: 1.4;
      text-decoration: none;
      @include vw(font-size, 20);
      &.current,
      &:hover {
        color: $yellowMild;
        text-decoration: underline;
      }
    }
    .header-columnOne {
      text-transform: uppercase;
      @include vw(margin-left, 272);
    }
    .header-columnTwo {
      text-transform: capitalize;
      @include vw(margin-right, 272);
      a:nth-child(3),
      a:nth-child(2) {
        @include vw(margin-left, 40);
      }
    }

    @include breakpoint(mobile) {
      display: none;
    }
  }
  .navbar-mobile {
    display: none;
    @include breakpoint(mobile) {
      display: flex;
    }
    a {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-top: 0.1px solid #999999;
      border-bottom: 0.1px solid #999999;
      @include vwMobile(margin-right, 20);
      @include vwMobile(margin-left, 20);
      @include vwMobile(margin-top, 35);
      @include vwMobile(margin-bottom, 30);
      @include vwMobile(padding-top, 10);
      @include vwMobile(padding-bottom, 10);
      p {
        @include vwMobile(font-size, 16);
        line-height: 2.5;
        text-transform: uppercase;
      }
      p:before {
        color: $yellowMild;
        content: '\002B'; //    Icon-NavDropArrow.41c5672c.svg)
        @include vwMobile(padding-right, 20);
      }
      div {
        background-image: url('../../../../assets/images/icons-navbar/Icon-NavDropArrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vwMobile(width, 15);
        @include vwMobile(height, 15);
      }
    }
  }
  .navbar-mobile-options {
    height: auto;
    //  @include vwMobile(height, 160);
    @include vwMobile(margin-right, 20);
    @include vwMobile(margin-left, 20);

    a {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #9999;
      color: $white;
      line-height: 1.44;
      text-decoration: none;
      @include vwMobile(font-size, 18);
      @include vwMobile(margin-top, 20);
      @include vwMobile(padding-bottom, 20);
    }
  }
}
