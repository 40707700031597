.services-status-section {
  @extend .block-container;
  border: solid 1px rgba(255, 255, 255, 0.2);
  background-color: $blueDeep;
  @include breakpoint(mobile) {
    display: none;
  }
  .block-top {
    div {
      @extend .header_main_list;
      display: flex;
      height: 100%;
      align-items: center;
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
    }

    @include vw(max-height, 130);

    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    text-align: left;
  }

  .block-content {
    .block-footer {
    }

    .main-container-line {
      @include vw(padding-right, 20);
      @include vw(padding-left, 20);
      @include vw(margin-bottom, 40);
      cursor: pointer;
      &:hover .main-container-border:not(.border-yellow) {
        border-color: rgba(255, 255, 255, 0.45);
      }
      .main-container-line-content {
        // @include columns((1, 5, 1), $flush-padding: true);
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .content-icon-desc {
          display: flex;
          align-items: center;
        }
        .main-line-icon {
          @include vw(width, 50);
          @include vw(height, 50);
          @include vw(padding, 10);

          border: solid 2px $white;
          background-origin: content-box;
        }
        .icon-yellow {
          border: solid 2px $yellowIg;
          background-image: url('../../../../assets/images/Logo-DU-Yellow.svg');
        }
        .main-line-desc {
          @include vw(padding-right, 20);
          @include vw(padding-left, 20);
        }
        .yellow {
          color: $yellowIg;
        }
        .main-line-status {
          width: auto;
          .main-line-status-txt {
            color: $white;

            &:before {
              @include vw(margin-right, 18);
              content: '\25CF';
            }

            &.status-up {
              color: $green;

              &:before {
                color: $green;
              }
            }

            &.status-issue {
              color: $maintenance-color;

              &:before {
                color: $maintenance-color;
              }
            }

            &.status-down {
              color: $red;

              &:before {
                color: $red;
              }
            }

            &.status-maintenance {
              color: $maintenance-color;

              &:before {
                color: $maintenance-color;
              }
            }
          }
        }
      }
      .main-container-border {
        @include vw(border-bottom-width, 1);
        @include vw(margin-top, 20);
        border-bottom-style: solid;
        border-bottom-color: rgba(255, 255, 255, 0.2);
      }
      .border-yellow {
        border-color: $yellowIg;
      }
    }
  }
}
