.modal-content {
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  border: 1px solid $grayButton;
  margin-right: -50%;
  background-color: $almostBlack;
  text-align: center;
  transform: translate(-50%, -50%);
  // @include vw(padding, 30);
  @include vw(width, 600);

  @include breakpoint(mobile) {
    width: auto;
  }
  h1 {
    @include vw(font-size, 22);
    color: $white;
  }
  .corners {
    width: 100%;
    height: 100%;
    @include vw(padding-top, 40);
    @include vw(padding-bottom, 40);
    @include vw(padding-left, 70);
    @include vw(padding-right, 70);
    @include buildCorners(25, 0);
  }
  p {
    @include vw(font-size, 20);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 20);
    }
    color: $gray-dark;
    line-height: 2;
    @include vw(margin-top, 50);
    @include vw(margin-bottom, 60);
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    span {
      @include vw(font-size, 16);
      font-weight: bold;
      line-height: 1.38;
    }
    @include breakpoint(mobile) {
      width: auto;
    }
  }
  .x-button {
    z-index: 1;
    @include breakpoint(mobile) {
      @include vwMobile(width, 30);
      @include vwMobile(height, 30);
      @include vwMobile(background-size, 15);
    }
  }
  .util-button-continue,
  .util-button-cancel {
    @include breakpoint(mobile) {
      @include vwMobile(height, 40);
      span {
        @include vwMobile(font-size, 20);
        @include vwMobile(margin-top, -2);
      }
    }

    div {
      border-right: 0;
      @include breakpoint(mobile) {
        @include vwMobile(background-size, 13);
        @include vwMobile(margin-top, 2);
        @include vwMobile(margin-right, 8);
        @include vwMobile(margin-bottom, 0);
        @include vwMobile(margin-left, 8);
      }
    }
  }
  span {
    margin: 0;
  }
  .util-button-continue {
    border: 0;
    @include vw(padding-right, 30);
    @include vw(padding-left, 10);
    @include breakpoint(mobile) {
      @include vwMobile(padding-right, 30);
      @include vwMobile(padding-left, 10);
    }
    div {
      background-image: url('../../../../assets/images/icons/no-border/Icon-Validate.svg');
      @include vw(height, 17);
      @include vw(width, 22);
      @include vw(margin-right, 19);
      @include breakpoint(mobile) {
        @include vwMobile(width, 15);
        @include vwMobile(height, 15);
        @include vwMobile(margin-right, 10);
      }
    }
  }
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    @include vw(margin-top, 20);
    @include vw(margin-right, 27);
  }
  .width-auto {
    width: auto;
    @include vw(padding-right, 15);
    @include breakpoint(mobile) {
      @include vwMobile(padding-right, 20);
    }
  }
}
.modal-custom-pad {
  @include vwAll(padding, 40, 30, 40, 30);
}
.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
