div {
  &.popup-overlay {
    position: fixed;
    @include vw(top, 80);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8;
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0% 0%;
  }
  &.display-none {
    display: none;
  }
  &.boxParent {
    position: fixed;
    z-index: 9;
    height: auto;
    border: solid 1px $white02;
    background-color: $almostBlack;
    @include vw(width, 536);
    @include vw(bottom, 20);
    @include vw(right, 20);
    @include breakpoint(from-phablet) {
      // @include vw(right, 5);
    }

    @include breakpoint(mobile) {
      width: 100%;
      height: auto;
      @include vwMobile(left, 0);
    }

    div {
      &.cookieParent {
        @include buildCorners(15, 10);
        @include breakpoint(mobile) {
          @include vwMobile(margin, 10);
          @include vwMobile(border-width, 10);
          @include buildCorners(0, 0);
          @include vwMobileAll(padding, 20, 0, 20, 0);
        }

        div {
          &.cookiesDescription {
            @include vwAll(margin, 16, 15, 30, 15);
            * {
              @include vw(font-size, 16);
              line-height: 2;

              @include breakpoint(mobile) {
                @include vwAllMobile(margin, 20, 20, 20, 20);
                @include vwMobile(font-size, 16);
              }
            }
            a {
              @include vw(font-size, 16);
              text-decoration: underline;

              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
              }
            }
          }
        }
        .cookie-customize {
          @include vwAll(margin, 0, 15, 0, 15);
          @include vw(font-size, 18);
          text-decoration: underline;
          text-align: right;
          display: inherit;
          cursor: pointer;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            @include vwMobile(margin-right, 20);
          }
        }
        .cookie-group-button {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          button {
            @include vwDimensions(218, 50);
            @include vw(font-size, 16);
            cursor: pointer;
            font-weight: bold;
            @include vw(border-width, 1);
            border-color: $gray;
            border-style: solid;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              @include vwDimensionsMobile(182, 50);
            }

            &.decline-cookie-consent {
              @include vw(margin-left, 15);
              @include vw(margin-right, 20);
              background-color: $almostBlack;
              color: $white;
              @include breakpoint(mobile) {
                @include vwMobile(margin-left, 20);
                @include vwMobile(margin-right, 10);
              }

              div {
                &.flex-container {
                  display: flex;
                  align-content: center;
                  justify-content: center;
                  text-transform: uppercase;
                  span {
                    @include vw(font-size, 16);
                    font-weight: bold;
                    @include breakpoint(mobile) {
                      @include vwMobile(font-size, 16);
                    }
                  }
                }
              }
            }

            &.accept-cookie-consent {
              @include vw(margin-right, 20);
              @include breakpoint(mobile) {
                @include vwMobile(margin-right, 20);
              }
              div {
                &.flex-container {
                  display: flex;
                  align-content: center;
                  justify-content: center;
                  text-transform: uppercase;
                  span {
                    @include vw(font-size, 16);
                    font-weight: bold;
                    @include breakpoint(mobile) {
                      @include vwMobile(font-size, 16);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .consent-required-message {
    height: 100%;
    background-color: $blueDark;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      @include vw(font-size, 30);
      line-height: normal;
      color: $white;
      margin-bottom: 0;
      text-align: center;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 30);
      }
    }
    p {
      @include vw(font-size, 20);
      line-height: 1.5;
      color: $gray-dark;
      @include vw(margin-bottom, 30);
      text-align: center;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
        @include vwMobile(margin-bottom, 30);
      }
    }
    button {
      @include vw(min-width, 274);
      @include vw(height, 50);
      @include vw(margin-bottom, 10);
      @include vw(padding-left, 15);
      @include vw(padding-right, 15);
      border: 1px solid $gray-home;
      cursor: pointer;
      @include breakpoint(mobile) {
        @include vwMobile(min-width, 274);
        @include vwMobile(height, 50);
        @include vwMobile(margin-bottom, 10);
        @include vwMobile(padding-left, 15);
        @include vwMobile(padding-right, 15);
      }
      .flex-container {
        @include vw(margin-top, -3);
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, -3);
        }
        span {
          @include vw(font-size, 16);
          color: $black;
          font-weight: bold;
          text-transform: uppercase;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
      }
    }
    .decline {
      background-color: $almostBlack;
      .flex-container {
        span {
          color: $white;
        }
      }
    }
  }
  .consent-youtube {
    @include vw(height, 540);
    @include breakpoint(mobile) {
      @include vwMobile(height, 320);
    }
    h2 {
      margin: 0 !important;
    }
  }
}
