.util-block-base {
  display: block;
  @include vw(width, 67);
  @include vw(height, 67);
  border-style: solid;
  border-color: $white;
  @include vw(border-width, 1);
}

/*
usage :
  @extend .util-image-container;
  @include vw(height, 242);
*/

.util-image-container {
  display: flex;
  background-color: $yellowMild;

  // Definition of the background image to display
  background-image: url('../../assets/images/icons/Icon-PlaceHolder.svg');
  background-position: center;
  background-repeat: no-repeat;
  @include vw(margin-top, 0);
  @include vw(margin-bottom, 0);
  @include vw(background-size, 100);
}

.util-image-placeholder {
  width: 100%;
}
