@import './sections/community.component.scss';
@import './sections/patch.component.scss';
@import './sections/launcher-detail.component.scss';
a {
  outline: none;
}
.launcher-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  background-image: url('../../../assets/images/launcher-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  a {
    outline: none;
  }
  .launcher-carousel-news {
    @include vw(margin-right, 94);
    @include vw(margin-left, 94);
    @include vw(left, 100);

    .launcher-news-block {
      @include vw(height, 700);
      width: 100%;
      border: solid 1px $grayButton;
      background-clip: padding-box;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 200%;
      // transition-delay: 1s ease-in-out; /* delays for 1 second */
      // -webkit-transition-delay: 1s ease-in-out; /* for Safari & Chrome */

      @include breakpoint(mobile) {
        @include vwMobile(height, 500);
      }
      &:hover {
        transition-duration: $animations-duration;
      }
      .news-block {
        position: relative;
        width: 100%;
        height: 100%;
        border: 0;
        /* prettier-ignore */
        transition: $corners-transition;
        /* prettier-ignore */
        -ms-transition: none;
        @extend .util-image-placeholder;
        @include vw(padding, 0);
        &:hover {
          @include vw(padding, 15);
          /* prettier-ignore */
          transition: $corners-transition;
          /* prettier-ignore */
          -ms-transition: none;
        }
        .news-header {
          position: absolute;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background-color: $blueLight;
          color: $white;
          text-transform: uppercase;
          transform: translate(-50%, -50%);
          @include vw(top, 60);
          @include vw(height, 41);
          @include vw(width, 169);
          @include breakpoint(mobile) {
            @include vwMobile(height, 39);
            @include vwMobile(width, 169);
            @include vwMobile(top, 40);
          }
          p {
            @include vw(font-size, 16);
            color: $white;
            line-height: 1.38;
            text-emphasis: bold;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }
        }
        .news-content-detail {
          @include vw(min-height, 195);
          position: relative;
          width: 100%;
          padding-bottom: 0;
          /* prettier-ignore */
          transition: $corners-transition;
          /* prettier-ignore */
          -ms-transition: none;
          @include breakpoint(mobile) {
            @include vwMobile(min-height, 300);
          }
          .content,
          h3 {
            @include vw(padding-bottom, 50);
            @include breakpoint(mobile) {
              @include vwMobile(padding-bottom, 150);
            }
          }
          .social-media-group {
            position: absolute;
            bottom: 0;
            width: 88%;
          }
        }
        &:hover .news-content-detail {
          .content,
          h3 {
            color: $yellowIg;
            opacity: none;
          }
          /* prettier-ignore */
          transition: $corners-transition;
          /* prettier-ignore */
          -ms-transition: none;
          @include vw(margin-bottom, 30);
        }
        &:hover .linebefore {
          @include vw(height, 1);
          background: $yellowIg;
          opacity: 1;
        }
        &:hover .news-content::before {
          background: $yellowIg;
        }
        .real-content {
          width: 100%;
          height: 100%;
          @include buildCorners(20, 0);
          @include breakpoint(mobile) {
            //   @include buildCorners(65, 0);
          }
        }
      }
    }
    .news-content {
      @include vw(right, 0);
      .news-content-detail {
        width: 100%;
        .list-social-media {
          @include vw(right, 0);
        }
      }
    }
    .carousel-item-padding {
      @include vw(padding-left, 25);
      @include vw(padding-right, 25);
    }
    .react-multi-carousel-dot button {
      height: 2px;
      border: 0;
      background: $gray-carousel;
      border-radius: 0;
    }

    .react-multi-carousel-dot--active button {
      background: $white;
    }
    .react-multi-carousel-list {
      @include vw(height, 800);
      @include breakpoint(mobile) {
        @include vwMobile(height, 650);
      }
    }

    .react-multiple-carousel__arrow {
      z-index: 0;
      border-style: solid;
      border-color: $white;
      background: transparent;
      border-radius: 0;
      @include vw(border-width, 1);
      @include vw(min-height, 40);
      @include vw(min-width, 40);
      @include breakpoint(mobile) {
        @include vwMobile(min-height, 40);
        @include vwMobile(min-width, 40);
      }
      &::before {
        @include vw(font-size, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
        }
      }
    }
    .react-multiple-carousel__arrow--right {
      @include vw(right, -0.4);
    }
    .react-multiple-carousel__arrow--left {
      @include vw(left, -0.5);
    }
  }
}
