.pts-container {
  @include vw(min-height, 1000);
  @include breakpoint(mobile) {
    @include vwMobile(min-height, 500);
  }
  h2 {
    @include vw(font-size, 35);
    line-height: 1.4;
    @include vw(margin-bottom, 30);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 25);
      @include vwMobile(margin-bottom, 30);
      @include vwMobile(margin-top, 30);
    }
  }
  .pts-description {
    p {
      color: $gray-dark;
      @include vw(font-size, 20);
      @include vw(margin-bottom, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    @include vw(margin-bottom, 55);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 30);
    }
  }
  .pts-description-under {
    @include vw(margin-top, 55);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 55);
    }
  }
  .button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    .theme-button {
      @include vw(min-width, 250);
      @include vw(height, 65);
      @include breakpoint(mobile) {
        @include vwMobile(min-width, 250);
        @include vwMobile(height, 65);
      }
      .theme-button-container {
        div {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Download.svg');
        }
      }
    }
  }
}
