.buypage-total {
  //
  // @include vw(margin-top, 50);
  // @include vw(margin-bottom, 100);
  border: solid 0.1px rgba(255, 255, 255, 0.2);
  background: $blueDark;
  .corners-bottom {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    @include buildCorners(18, 0);
    border-top-width: 0;
    @include vw(padding-top, 23);
    @include vw(padding-right, 28);
    @include vw(padding-left, 25);
    @include vw(padding-bottom, 5);
    @include breakpoint(mobile) {
      @include vwMobile(padding-top, 22);
      @include buildCornersMobile(22, 0);
      border-top-width: 0;
    }
  }
  @include breakpoint(mobile) {
    // @include vwMobileAll(padding, 20, 10, 20, 10);
    // @include vwMobile(margin-bottom, 0);
  }
  h3,
  span {
    @include vw(font-size, 22);
    @include vw(line-height, 36);
    padding: 0;
    margin: 0;

    @include breakpoint(mobile) {
      @include vwMobile(font-size, 22);
      @include vwMobile(line-height, 22);
    }
  }
  h3 {
    @include vw(margin-right, 40);
  }
  span {
    color: $yellowIg;
  }
}
