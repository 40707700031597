.server-shard-status-section {
  @include vw(margin-bottom, 0);
  @include vw(min-height, 300);
  border: solid 1px rgba(255, 255, 255, 0.2);
  background-color: $blueDeep;

  @include vw(border-top-width, 4);

  &.border-up {
    border-top-color: $green;
  }

  &.border-issue {
    border-top-color: $maintenance-color;
  }

  &.border-down {
    border-top-color: $red;
  }
  &.border-maintenance {
    border-top-color: $maintenance-color;
  }
  @include breakpoint(mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-width: 3px;
  }
  .top-container {
    @include vw(height, 160);
    @include vw(padding-top, 40);
    @include vw(padding-right, 40);
    @include vw(padding-bottom, 40);
    @include vw(padding-left, 40);

    @include columns((1, 9));
    @include breakpoint(mobile) {
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .main-line-icon {
      @include vw(width, 80);
      @include vw(height, 80);
      @include vw(padding, 10);
      @include vw(border-width, 2);
      border-color: $white;
      border-style: solid;
      background-origin: content-box;
      @include breakpoint(mobile) {
        @include vwMobile(width, 58);
        @include vwMobile(height, 58);
        @include vwMobile(padding, 10);
        @include vwMobile(margin-top, 10);
        @include vwMobile(margin-bottom, 10);
      }
    }

    div {
      @include vw(padding-left, 60);
      @include breakpoint(mobile) {
        padding-left: 0;
      }
      .main-line-desc-title {
        @extend .header_secondary_big;
        color: $white;
        font-weight: bold;
        text-transform: uppercase;
        @include breakpoint(mobile) {
          text-align: center;
        }
      }

      .main-line-desc {
        @extend .paragraph_regular;
        color: $gray-medium;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 2;
          width: 145%;
          @include vwMobile(margin-left, -22);
          @include vwMobile(font-size, 16);
        }
      }
      .logs {
        line-height: 1.38;
        @include breakpoint(mobile) {
          width: 100%;
          margin-left: 0;
          text-align: center;
          @include vwMobile(padding-left, 30);
          @include vwMobile(padding-right, 30);
        }
      }
    }
  }

  .main-container {
    @include vw(min-height, 100);
    @include breakpoint(mobile) {
      width: 100%;
    }
    .main-container-line {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: solid 1px rgba(255, 255, 255, 0.2);
      border-bottom: solid 1px rgba(255, 255, 255, 0.2);
      background-color: $blueDark;

      @include vw(padding-right, 40);
      @include vw(padding-left, 40);
      @include columns((2, 8), $margin: 40px, $flush-padding: true);
      @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include vwMobile(height, 103);
        @include vwMobile(padding-left, 19);
      }
      .main-line-status {
        display: flex;
        height: 100%;
        align-items: center;
        text-align: left;

        @include vw(margin-top, 32);
        @include vw(margin-bottom, 32);
        @include breakpoint(mobile) {
          height: auto;
          margin-bottom: 0;
        }
      }

      .main-line-status-desc {
        @include vw(margin-left, 30);
        color: $white;
        @extend .paragraph_regular;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 2;
          @include vwMobile(margin-left, 0);
        }
      }
    }

    .main-container-maintenance-line {
      .container-sub-line-maintenance {
        @include columns((1, 1, 8));
        @include vw(margin-left, 60);
        @include vw(margin-right, 60);
        @include vw(height, 75);
        display: flex;
        align-items: center;

        @include vw(border-bottom-width, 1);
        border-bottom-style: solid;
        border-bottom-color: rgba(255, 255, 255, 0.2);

        .sub-line-maintenance-date {
          @extend .paragraph_regular;
        }

        .sub-line-maintenance-time {
          @extend .paragraph_regular;
        }

        .sub-line-maintenance-desc {
          @extend .paragraph_regular;
          color: $gray-dark;
        }
      }
    }
    .description {
      @include vw(font-size, 20);
      line-height: 2;
      color: $white;
      @include vw(padding, 40);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
        @include vwMobile(padding, 20);
      }
    }
    .main-container-sub-line {
      display: flex;

      @include vw(min-height, 200);

      align-items: center;

      @include vw(padding-right, 40);
      @include vw(padding-left, 40);

      @include columns((7, 2.3), $flush-padding: true);
      @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      div.probleme {
        @extend .paragraph_regular;
        color: $white;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 2;
          text-align: center;
          @include vwMobile(margin-top, 26);
        }
      }
      a {
        @include breakpoint(mobile) {
          width: auto !important;
        }
      }
      .theme-button {
        @include vw(min-width, 250);
        @include breakpoint(mobile) {
          @include vwMobile(min-width, 230);
          @include vwMobile(min-height, 65);
          @include vwMobile(margin-bottom, 32);
          @include vwMobile(margin-top, 15);
        }
        .theme-button-container {
          div {
            background-image: url('../../../../assets/images/icons/Icon-Warning.svg');
            @include breakpoint(mobile) {
              @include vwMobile(margin-right, 10);
              @include vwMobile(background-size, 20);
              @include vwMobile(height, 20);
              @include vwMobile(width, 20);
            }
          }
          span {
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              line-height: 1.38;
            }
          }
        }
      }
    }
  }

  .top-container {
  }
}
