.site-map-bg {
  @include vw(height, 1200);
  background-position-x: center;
  background-repeat: no-repeat;
}
.site-map-page {
  @include vw(padding-left, 150);
  @include vw(padding-right, 150);
  @include vw(padding-bottom, 200);
  @include vw(margin-top, -1200);
  h1 {
    @include vw(padding-top, 220);
    margin: 0;

    text-align: center;
    text-transform: uppercase;

    @include vw(font-size, 70);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 55);
    }
  }
  .section-title {
    display: flex;
    @include vw(margin-top, 100);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 60);
    }
    @include vw(margin-bottom, 60);
    img {
      @include vw(margin-right, 40);

      @include vw(width, 50);
      @include breakpoint(mobile) {
        @include vwMobile(width, 50);
      }
    }
    h2 {
      display: inline-block;

      @include vw(font-size, 35);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
      }
    }
  }
  h3 {
    position: relative;
    border-width: 0;
    border-style: solid;
    border-color: $gray-psw;
    font-weight: normal;
    text-transform: none;
    @include vw(padding-bottom, 10);
    @include vw(border-bottom-width, 1);
    @include vw(width, 500);

    @include vw(font-size, 20);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 20);
      @include vwMobile(padding-bottom, 10);
      @include vwMobile(margin-top, 30);
      width: 100%;
    }

    &:hover {
      border-color: $yellowIg;
      color: $yellowIg;
      &::after {
        position: absolute;
        display: inline-block;
        background-image: url('../../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        text-align: right;
        @include vw(width, 20);
        @include vw(height, 20);
        @include vw(right, 0);
        @include vw(top, 16);
        @include breakpoint(mobile) {
          @include vwMobile(top, 14);
          @include vwMobile(width, 15);
          @include vwMobile(height, 15);
        }
      }
    }
  }
  .section-links {
    display: grid;
    grid-template-columns: auto auto auto;

    @include breakpoint(mobile) {
      display: block;
    }

    .section-link {
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
  }
}
