.buypage-aboutyou {
  height: auto;
  border: solid 1px rgba(255, 255, 255, 0.1);
  // @include breakpoint(mobile) {
  //     @include vwMobile(height, 335);
  // }

  .container {
    // @include vw(width, 448);
    // @include vw(height, 448);

    height: 100%;
    background: $blueDark;
    @include vw(padding, 10);
    @include buildCorners(18, 0);
    @include breakpoint(mobile) {
      @include buildCornersMobile(22, 0);
      //  @include vwMobile(padding, 10);
      // @include vwMobile(padding-top, 60);
      // @include vwMobile(padding-bottom, 40);
      // @include vwMobile(margin-top, 60);
      // width: 100%;
      // @include vwMobile(height, 200);
    }

    h5 {
      color: $white;
      font-weight: normal;
      line-height: 1.42;
      // text-transform: uppercase;
      @include vw(margin-bottom, 40);
      @include vw(font-size, 24);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 22);
        font-weight: bold;
        @include vwMobile(margin-bottom, 40);
      }
    }
    .tooltip-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 20);
      }
      .input-title {
        display: inline-block;
        @include vw(font-size, 20);
        color: $yellowMild;
        line-height: 2;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
        }
      }
      .popup-content {
        padding: 1% !important;
        border: 0 !important;
        background-color: $yellowIg !important;
        @include vw(min-width, 354);
        span {
          @include vw(font-size, 16);
          color: $almostBlack;
          line-height: 1.38;
        }
      }
      .popup-arrow {
        background-color: $yellowIg !important;
      }
    }
    .util-input {
      width: auto;
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(height, 65);
      }
      div:first-of-type {
        border-style: none;
        margin: auto 0;
        background-image: url('../../../../assets/images/icons/no-border/Icon-Email.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(width, 25);
        @include vw(height, 25);
        @include vw(margin-right, 25);
        @include vw(margin-left, 25);
        @include breakpoint(mobile) {
          @include vwMobile(width, 25);
          @include vwMobile(height, 25);
          @include vwMobile(margin-right, 25);
          @include vwMobile(margin-left, 25);
        }
      }
      div:last-of-type {
        border-style: none;
        margin: auto 0;
        background-image: url('../../../../assets/images/icons/no-border/Icon-Loading.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(width, 25);
        @include vw(height, 25);
        @include vw(margin-right, 25);
        @include vw(margin-left, 25);
        @include breakpoint(mobile) {
          @include vwMobile(width, 25);
          @include vwMobile(height, 25);
          @include vwMobile(margin-right, 25);
        }
      }
    }
    .desc {
      @extend .list_item;
      text-align: right;
      @include vw(margin-top, 20);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 27);
        @include vwMobile(font-size, 16);
      }
    }

    div {
      &.centerButton {
        text-align: center;
        button {
          &.continue {
            @include vw(height, 65);
            @include vw(width, 228);

            @include breakpoint(mobile) {
              @include vwMobile(height, 65);
              @include vwMobile(width, 200);
            }
            @include vw(margin-top, 80);

            &:hover {
              background-color: $color-positive;
            }
            .theme-button-container {
              div {
                background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
                @include vw(height, 17.1);
                @include vw(width, 17.3);
                @include vw(background-size, 17);
                @include breakpoint(mobile) {
                  @include vwMobile(height, 25);
                  @include vwMobile(width, 20);
                  @include vwMobile(background-size, 18);
                  @include vwMobile(margin-right, 10);
                }
              }
            }

            &:hover .theme-button-container div {
              background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
            }
            &:hover .theme-button-container span {
              color: $black;
            }
            @include breakpoint(mobile) {
              @include vwMobile(height, 65);
              @include vwMobile(width, 205);
              @include vwMobile(font-size, 16);
              @include vwMobile(margin-top, 60);
            }
          }
        }
      }
    }

    .signin {
      color: #a8a8a8;
      line-height: 1.4;
      text-align: right;
      text-transform: none;
      @include vw(margin-top, 57);
      @include vw(font-size, 20);
      link {
        @include vw(font-size, 20);
        @include vw(margin-left, 10);
      }
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }

    .signinbutton {
      @include vw(margin-top, 30);
      @include breakpoint(mobile) {
        @include vwMobile(height, 65);
        @include vwMobile(width, 180);
        @include vwMobile(font-size, 16);
        @include vwMobile(margin-top, 30);
      }
    }

    .errorMessage {
      background-color: $reddish;
      color: $white;
      line-height: 2.35;
      @include vw(font-size, 17);
      @include vw(margin-bottom, 20);
      @include vw(padding, 10);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 17);
        @include vwMobile(margin-bottom, 20);
      }
      &:before {
        border: 1px solid $white;
        background-image: url('../../../../assets/images/icons/Icon-Close.svg');
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        @include vw(background-size, 18);
        @include vwAll(padding, 3, 15, 3, 15);
        @include vw(margin-right, 15);
        @include breakpoint(mobile) {
          @include vwMobileAll(padding, 3, 15, 3, 15);
          @include vwMobile(margin-right, 15);
          @include vwMobile(background-size, 18);
        }
      }
    }
  }
}
.tooltip-box {
  @include vw(width, 25);
  @include vw(height, 25);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1px solid $white;
  cursor: pointer;
  opacity: 0.8;
  span {
    color: $white;
    opacity: 0.8;
  }
  @include breakpoint(mobile) {
    display: none;
  }
}
// .popup-content {
//     padding: 1% !important;
//     border: 0 !important;
//     background-color: $yellowIg !important;
//     @include vw(min-width, 354);
//     span {
//         @include vw(font-size, 16);
//         color: $almostBlack;
//         line-height: 1.38;
//     }
// }
// .popup-arrow {
//     background-color: $yellowIg !important;
// }
