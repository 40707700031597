@import './buypage-aboutyou.styles';
@import './buypage-actions.styles';
@import './buypage-entercode.styles';
@import './buypage-total.styles';
@import './buypage-yourselection.styles';
@mixin vwGrid2Col($property, $value1) {
  #{$property}: ($value1 / $viewport-desktop) * 100vw 1fr;
}
.buypage-validation {
  @include vw(padding-top, 45);
  background-image: url('../../../../assets/images/buypage/buypagetwobackground.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .textButtonContainer {
    @include vw(margin-top, 10);
    @include vw(margin-bottom, 50);
    display: flex;
    align-items: center;
    justify-content: start;
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 10);
      @include vwMobile(margin-bottom, 40);
    }
    .active {
      color: $yellowIg;
    }
    p {
      color: $white;
      font-weight: bold;
      @include vw(margin-right, 40);
      @include vw(font-size, 20);
      line-height: 1.4;
      text-transform: uppercase;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 11);
        line-height: 1.36;
        @include vwMobile(margin-right, 10);
      }
      &:nth-child(1):before {
        color: $gray-medium;
        content: '1.';
        @include vw(margin-right, 10);
      }
      &:nth-child(2):before {
        content: '2.';
        @include vw(margin-right, 10);
      }
      &:nth-child(3):before {
        color: $gray-medium;
        content: '3.';
        @include vw(margin-right, 10);
      }
    }
  }
  h2.title {
    @include vw(font-size, 50);
    line-height: 1.4;
  }

  .disclamer {
    @include vw(margin-top, 74);
    @include vw(margin-bottom, 200);

    @include vw(width, 1200);
    margin-right: auto;
    margin-left: auto;
    color: $gray-dark;
    text-align: center;
    @include breakpoint(mobile) {
      width: 100%;
      text-align: justify;
      @include vwMobile(margin-top, 40);
      @include vwMobile(margin-bottom, 50);
    }
    p {
      @include vw(font-size, 16);
      color: $gray-dark;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }

    a {
      color: $yellowMild;
      text-decoration: underline;
      @include vw(font-size, 16);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
  }
  .cols {
    @include breakpoint(mobile) {
      grid-template-columns: 1fr;
      @include vwMobile(margin-top, 30);
    }
    @include breakpoint(from-phablet) {
      @include vw(margin-top, 50);
      display: grid;
      @include vwGrid2Col(grid-template-columns, 496);
      @include vw(gap, 60);
    }
  }
}
