div {
  &.homepagebetasocial {
    width: 100%;
    div {
      &.homepagebeta {
        position: relative;
        z-index: 3;
        display: none;
        width: 100%;
        background-image: url('../../../../assets/images/homepage/spacemeteor.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include vw(height, 1122);
        @include vw(bottom, 435);
        @include vwAll(padding, 535, 0, 0, 246);
        @include breakpoint(mobile) {
          @include vwMobile(height, 561);
          @include vwMobile(width, 960);
          @include vw(bottom, 0);
          @include vwAllMobile(padding, 118, 99, 83, 99);
          @include vwMobile(right, 960-481);
        }
        div {
          &.leftSide {
            display: inline-block;

            @include breakpoint(from-phablet) {
              @include vw(margin-right, 384);
            }

            div {
              &.dualUniverseLogo {
                @include vw(width, 350);
                @include vw(height, 150);
                background-image: url('../../../../assets/images/icons/du-logo.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                @include vw(margin-bottom, 44);

                @include breakpoint(mobile) {
                  @include vwMobile(margin-bottom, 27);
                  @include vwMobile(width, 216);
                  @include vwMobile(height, 92);
                  @include vwMobile(left, 960-450);
                  position: relative;
                }
              }
            }
            button {
              @include vw(min-width, 229);
              @include vw(height, 65);

              @include breakpoint(mobile) {
                @include vwMobile(width, 215);
                @include vwMobile(height, 61);
                @include vwMobile(font-size, 15);
                position: relative;
                @include vwMobile(left, 960-450);
              }
            }
          }

          &.spaceship {
            position: absolute;
            display: inline-block;
            background-image: url('../../../../assets/images/homepage/spaceship.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include vw(bottom, 210);
            @include vw(width, 420);
            @include vw(height, 360);
            @include vw(margin-bottom, 44);
            @include breakpoint(mobile) {
              display: none;
            }
          }
        }
      }
      &.bottomLine {
        position: relative;
        z-index: 1;
        display: none;
        width: 50%;
        height: 0;
        border: solid 0.1px $white02;
        @include vw(bottom, 635);

        @include breakpoint(mobile) {
          width: 100%;
          @include vw(bottom, 520);
        }
      }
      &.rocks {
        position: relative;
        // background-image: url('../../../../assets/images/homepage-actual/rocks.png');
        @include vw(height, 470);
        @include vw(margin-top, -60);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;
        @include breakpoint(mobile) {
          display: none;
        }
      }
      &.planet {
        position: relative;
        @include vw(margin-left, 1400);
        @include vw(margin-top, -550);
        // background-image: url('../../../../assets/images/homepage-actual/planet.png');
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        @include vw(width, 565);
        @include vw(height, 730);
        z-index: 9;
        @include breakpoint(mobile) {
          @include vwMobile(margin-left, 100);
          @include vwMobile(margin-top, -70);
          background-position: top;
          @include vwMobile(width, 527);
          @include vwMobile(height, 298);
          z-index: -1;
        }
      }
      &.game-updates-bg {
        @include vw(height, 1130);
        @include vw(margin-top, -590);
        background-image: url('../../../../assets/images/homepage-actual/galaxy.jpg');
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        background-color: $almostBlack;
        z-index: 9;
        @include breakpoint(mobile) {
          display: none;
        }
      }
      &.game-updates {
        position: relative;
        @include vw(margin-top, -1130);
        z-index: 9;
        width: 100%;
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, -200);
        }
        .game-update-content {
          width: 100%;
          display: flex;
          justify-content: space-between;
          @include breakpoint(mobile) {
            flex-direction: column;
          }
          h2,
          p {
            @include vw(padding-left, 156);
          }
          h2 {
            @include vw(font-size, 100);
            font-weight: 900;
            line-height: normal;
            @include vw(margin-top, 70);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 45);
              text-align: center;
            }
          }
          p {
            @include vw(font-size, 25);
            @include vw(margin-top, -15);
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            font-style: normal;
            color: $yellowIg;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              text-align: center;
            }
          }
          .btn-group {
            @include vw(padding-right, 156);
            @include vw(padding-top, 100);
            display: flex;
            align-items: center;
            @include breakpoint(mobile) {
              flex-direction: column;
              @include vwMobile(padding-top, 20);
              @include vwMobile(padding-right, 20);
              @include vwMobile(padding-left, 20);
            }

            button.left {
              @include vw(height, 65);
              @include vw(min-width, 323);
              @include breakpoint(mobile) {
                @include vwMobile(margin-bottom, 10);
                @include vwMobile(height, 65);
                width: 100%;
              }
              .theme-button-container {
                div {
                  @include vw(height, 17);
                  @include vw(width, 12);
                  background-size: contain;
                  background-image: url('../../../../assets/images/icons/Icon-Play.svg');
                  @include breakpoint(mobile) {
                    @include vwMobile(height, 17);
                    @include vwMobile(width, 12);
                    @include vwMobile(margin-right, 10);
                  }
                }
                span {
                  font-family: eurostile-extended, sans-serif;
                  @include vw(font-size, 13);
                  font-weight: 900;
                  @include breakpoint(mobile) {
                    @include vwMobile(font-size, 13);
                  }
                }
              }
            }
            button.right {
              @include vw(height, 65);
              @include vw(min-width, 252);
              @include vw(margin-left, 20);
              border: 1px solid $white02;
              background-color: $almostBlack;
              background-image: none;
              @include breakpoint(mobile) {
                @include vwMobile(margin-left, 0);
                @include vwMobile(height, 65);
                width: 100%;
              }
              .theme-button-container {
                div {
                  background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
                  @include vw(height, 9);
                  @include vw(width, 8);
                  background-size: contain;
                  @include breakpoint(mobile) {
                    @include vwMobile(height, 9);
                    @include vwMobile(width, 8);
                    @include vwMobile(margin-right, 15);
                  }
                }
                span {
                  color: $white;
                  font-family: eurostile-extended, sans-serif;
                  @include vw(font-size, 13);
                  font-weight: 900;
                  @include breakpoint(mobile) {
                    @include vwMobile(font-size, 13);
                  }
                }
              }

              &:hover .theme-button-container {
                @include buildCornersYellow(13, 4);
                @include breakpoint(mobile) {
                  @include buildCornersMobileYellow(13, 4);
                }
                div {
                  background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
                }
                span {
                  color: $yellowIg;
                }
              }
            }
          }
        }
        .game-update-swipe {
          display: none;
          @include breakpoint(mobile) {
            display: flex;
            text-transform: uppercase;
            justify-content: flex-end;
            color: #a8a8a8;
            @include vwMobile(font-size, 12);
            @include vwMobile(margin-bottom, 10);
            font-weight: bold;
            @include vwMobile(padding-left, 20);
            @include vwMobile(padding-right, 20);
            @include vwMobile(padding-top, 20);
          }
        }
        .game-update-carousel {
          position: relative;
          width: 100%;
          @include vw(margin-top, 46);
          @include vw(padding-left, 120);
          @include vw(padding-right, 120);
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 12);
            @include vwMobile(padding-left, 20);
            @include vwMobile(padding-right, 20);
            -ms-overflow-style: none; /* IE and Edge */
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            scrollbar-width: none; /* Firefox */
          }
          &::-webkit-scrollbar {
            @include breakpoint(mobile) {
              display: none;
            }
          }
          .game-update-carousel-wrapper {
            display: flex;
          }
        }
        .filter {
          -webkit-backdrop-filter: blur(14px) brightness(1.4);
          backdrop-filter: blur(14px) brightness(1.4);
          background-color: rgba(22, 32, 36, 0.1);
        }
        .game-update-box {
          position: relative;
          @include vw(height, 926);
          // @include vw(width, 483);
          // width: auto;
          border: 1px solid $white02;
          -webkit-backdrop-filter: blur(14px) brightness(1.4);
          backdrop-filter: blur(14px) brightness(1.4);
          background-color: rgba(22, 32, 36, 0.1);

          @include breakpoint(mobile) {
            @include vwMobile(width, 400);
            @include vwMobile(height, 674);
            @include vwMobile(margin-right, 12);
          }
          .game-update-box-corners-container {
            position: relative;
            padding: 0;
            width: 100%;
            height: 100%;
            transition: $corners-transition;

            .game-update-box-corners {
              position: relative;
              top: 0;
              width: 100%;
              height: 100%;
              @include buildCorners(18, 0);
              z-index: 2;
              @include breakpoint(mobile) {
                @include buildCornersMobile(18, 0);
              }
            }
          }
          .game-update-box-content {
            @include vw(margin-top, -925);
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            @include breakpoint(mobile) {
              @include vwMobile(width, 400);
              @include vwMobile(margin-top, -674);
            }
            .game-update-box-image-intro {
              @include vw(height, 270);
              width: 100%;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              z-index: 0;
              @include breakpoint(mobile) {
                @include vwMobile(height, 200);
              }
            }
            .game-update-box-detail {
              @include vw(padding-left, 30);
              @include vw(padding-right, 30);
              @include vw(padding-top, 25);
              @include breakpoint(mobile) {
                @include vwMobile(padding-left, 20);
                @include vwMobile(padding-right, 20);
                @include vwMobile(padding-top, 20);
              }
              .game-update-box-version {
                color: $yellowIg;
                @include vw(font-size, 20);
                font-weight: 900;
                @include breakpoint(mobile) {
                  @include vwMobile(font-size, 18);
                }
              }

              .game-update-box-title {
                * {
                  @include vw(font-size, 30);
                  color: $white;
                  font-family: eurostile-extended, sans-serif;
                  line-height: 1.17;
                  font-weight: 900;
                  @include breakpoint(mobile) {
                    @include vwMobile(font-size, 22);
                    line-height: 1.18;
                  }
                }
              }
              .game-update-box-release {
                @include vw(font-size, 18);
                color: #a8a8a8;
                text-transform: uppercase;
                @include breakpoint(mobile) {
                  @include vwMobile(font-size, 14);
                  @include vwMobile(margin-bottom, 10);
                }
              }
              .hidden {
                visibility: hidden;
              }
              .game-update-box-separator {
                @include vw(height, 7);
                background-image: url('../../../../assets/images/icons/separator.svg');
                background-size: cover;
                @include vw(margin-top, 10);
                @include vw(margin-bottom, 20);
                @include breakpoint(mobile) {
                  display: none;
                }
              }
              .game-update-box-small {
                @include vw(height, 80);
                position: relative;
                display: flex;
                align-items: center;
                @include vw(margin-bottom, 6);
                @include breakpoint(mobile) {
                  @include vwMobile(height, 60);
                  @include vwMobile(margin-bottom, 4);
                }
                .game-update-box-small-label {
                  * {
                    @include vw(font-size, 18);
                    // @include vw(padding-top, 25);
                    line-height: 1.11;
                    text-transform: uppercase;
                    @include breakpoint(mobile) {
                      @include vwMobile(font-size, 15);
                      line-height: 0.93;
                    }
                  }
                }
                .label-even {
                  @include vw(padding-left, 30);
                  text-align: left;
                }
                .label-odd {
                  position: absolute;
                  @include vw(right, 30);
                  text-align: right;
                }
                .game-update-box-small-image {
                  background-repeat: no-repeat;
                  background-size: contain;
                  @include vw(height, 80);
                  @include vw(width, 230);
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  @include breakpoint(mobile) {
                    @include vwMobile(height, 60);
                    @include vwMobile(width, 173);
                  }
                }
                .image-even {
                  right: 0;
                  background-position: right;
                }
                .image-odd {
                  left: 0;
                  background-position: left;
                }
              }
            }
          }
          .poster {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            .game-update-box-title-container {
              position: relative;
              @include vw(height, 785);
              @include breakpoint(mobile) {
                @include vwMobile(height, 580);
              }
            }
            .game-update-box-logo {
              position: absolute;
              @include vw(top, 65);
              left: 0;
              right: 0;
              margin: auto;
              @include vw(height, 92);
              @include vw(width, 335);
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              @include breakpoint(mobile) {
                @include vwMobile(height, 80);
                @include vwMobile(width, 300);
                @include vwMobile(top, 65);
              }
            }
            .game-update-box-title {
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              * {
                text-align: center;
                @include vw(font-size, 48);
                font-weight: 900;
                line-height: 0.81;
                text-transform: uppercase;
                @include breakpoint(mobile) {
                  @include vwMobile(font-size, 30);
                }
              }
            }
          }
          .game-update-box-buttons {
            position: absolute;
            @include vw(left, 30);
            @include vw(right, 30);
            @include vw(bottom, 45);
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include vw(height, 65);
            @include breakpoint(mobile) {
              @include vwMobile(left, 20);
              @include vwMobile(right, 20);
              @include vwMobile(bottom, 16);
              @include vwMobile(height, 60);
            }
            button,
            a {
              @include vw(height, 65);
              @include vw(width, 207);
              cursor: pointer;
              background-color: $almostBlack;
              border: 1px solid transparent;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 6;
              @include breakpoint(mobile) {
                @include vwMobile(height, 60);
                @include vwMobile(width, 162);
              }
              &:hover {
                border: 1px solid #6a6a6a;
              }
              span {
                text-transform: uppercase;
                font-family: eurostile-extended, sans-serif;
                font-weight: 900;
                font-style: normal;
                color: $white;
                @include vw(font-size, 13);
                @include breakpoint(mobile) {
                  @include vwMobile(font-size, 11);
                }
              }
            }
            .game-update-box-buttons-soon {
              text-transform: uppercase;
              font-family: eurostile-extended, sans-serif;
              font-weight: 900;
              font-style: normal;
              color: $white;
              @include vw(font-size, 13);
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 11);
              }
            }
          }
          .center {
            justify-content: center;
          }
        }
        .react-multi-carousel-list {
          overflow: inherit;
        }
        .react-multi-carousel-item[aria-hidden='true'] {
          opacity: 0.4 !important;
        }

        .carousel-item-padding {
          @include vw(padding-left, 37);
          @include vw(padding-right, 30);
        }
        .react-multi-carousel-track {
          will-change: unset !important;
        }
        .react-multiple-carousel__arrow {
          z-index: 0;
          border-style: solid;
          border-color: $white;
          background: transparent;
          border-radius: 0;
          @include vw(border-width, 1);
          @include vw(min-height, 60);
          @include vw(min-width, 60);
          @include breakpoint(mobile) {
            @include vwMobile(min-height, 40);
            @include vwMobile(min-width, 40);
          }
          &::before {
            @include vw(font-size, 20);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 20);
            }
          }
        }
        .react-multiple-carousel__arrow--right {
          right: -3.15104vw !important;
        }
        .react-multiple-carousel__arrow--left {
          left: -3.15104vw !important;
        }
      }
      &.planets {
        position: relative;
        z-index: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        @include vw(margin-top, -300);
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 0);
        }
        .left {
          @include vw(width, 508);
          @include vw(height, 1000);
          background-image: url('../../../../assets/images/homepage/planets.png');
          background-repeat: no-repeat;
          @include vw(background-position-x, -270);
          @include vw(background-position-y, 80);
          @include vw(background-size, 1740);
          @include breakpoint(mobile) {
            width: 100%;
            @include vwMobile(height, 1000);
            @include vwMobile(background-position-x, -270);
            @include vwMobile(background-size, 1500);
            z-index: -1;
            @include vwMobile(background-position-y, 160);
          }
        }
        .right {
          @include vw(width, 508);
          @include vw(height, 515);
          background-image: url('../../../../assets/images/homepage/planets-right.png');
          background-repeat: no-repeat;
          @include vw(background-position-x, -260);
          @include vw(background-position-y, -400);
          @include vw(background-size, 1730);
        }
        @include vw(height, 1000);
        // @include vw(bottom, 450);

        @include breakpoint(mobile) {
          @include vwMobile(width, 953);
          @include vwMobile(height, 473);
          @include vwMobile(bottom, 330);
        }
      }
      &.starsImage {
        position: relative;
        z-index: 0;
        display: inline-block;
        width: 100%;
        border-top: solid 0.1px rgba(255, 255, 255, 0.2);
        background-image: url('../../../../assets/images/homepage/spacestars.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include vw(height, 1700);
        @include vw(bottom, 997);
        @include breakpoint(mobile) {
          @include vwMobile(height, 2900);
          @include vwMobile(bottom, 500);
        }
      }
      &.news-twitter-bg {
        background-color: $blueDark;
        @include vw(margin-top, -640);
        @include vw(height, 1600);
        @include breakpoint(mobile) {
          display: none;
        }
      }
      &.homepageNewsTwitter-container {
        @include vw(margin-top, -1600);
        z-index: 8;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .news-twitter-intro-text {
          @include breakpoint(mobile) {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          h2.news-twitter-title {
            @include vw(padding-top, 100);
            @include vw(font-size, 100);
            font-weight: 900;
            text-align: center;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 45);
            }
          }
          p.news-twitter-desc {
            @include vw(font-size, 25);
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            font-style: normal;
            color: $yellowIg;
            text-transform: uppercase;
            @include vw(margin-top, 25);
            @include vw(margin-bottom, -40);
            @include breakpoint(mobile) {
              text-align: center;
              @include vwMobile(font-size, 16);
              @include vwMobile(margin-bottom, 0);
            }
          }
        }

        .news-twitter-button {
          position: absolute;
          @include vw(right, 156);
          @include vw(top, 120);
          @include breakpoint(mobile) {
            display: none;
          }
          button {
            @include vw(height, 65);
            @include vw(min-width, 250);
            @include vw(margin-left, 20);
            border: 1px solid $white02;
            background-image: none;
            background-color: $almostBlack;
            .theme-button-container {
              div {
                background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
                @include vw(height, 9);
                @include vw(width, 8);
                background-size: contain;
              }
              span {
                color: $white;
                font-family: eurostile-extended, sans-serif;
                @include vw(font-size, 13);
                font-weight: 900;
              }
            }

            &:hover .theme-button-container {
              @include buildCornersYellow(13, 4);
              div {
                background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
              }
              span {
                color: $yellowIg;
              }
            }
          }
        }
      }
      &.homepageNewsTwitter {
        position: relative;
        width: 100%;
        color: $white;
        text-align: left;
        @include vwAll(padding, 120, 156, 204, 156);
        @include breakpoint(mobile) {
          @include vwMobileAll(padding, 20, 20, 82, 20);
        }
        @include breakpoint(from-phablet) {
          display: flex;
          justify-content: center;
        }
        div {
          &.newsContent {
            @include breakpoint(mobile) {
              @include vwMobile(margin-bottom, 20);
              width: 100%;
            }
            @include vw(width, 1000);
            p {
              &.titleNews {
                color: $white;
                font-weight: bold;
                text-transform: uppercase;
                @include vw(font-size, 50);
                @include vw(line-height, 1.4);
                @include vw(margin-bottom, 64);

                @include breakpoint(mobile) {
                  @include vwMobile(font-size, 35);
                  @include vwMobile(margin-bottom, 40);
                }
              }
            }
            div {
              &.newsGrid {
                position: relative;
                display: grid;
                overflow: hidden;
                grid-template-columns: 1fr;

                @include vw(grid-row-gap, 30);
                @include breakpoint(mobile) {
                  @include vwMobile(grid-row-gap, 30);
                }
                .container {
                  @include vw(height, 342);
                  position: relative;
                  border: solid 1px $white02;
                  background-color: $blueMedium;
                  @include vw(padding, 0);
                  transition: padding $corners-transition;
                  @include breakpoint(mobile) {
                    @include vwMobile(height, 475);
                    background-color: $almostBlack;
                  }
                  &:hover {
                    @include vw(padding, 15);
                    background-color: $blueLight;
                    transition: padding $corners-transition;
                  }
                  &:hover #title {
                    background-color: $blueMedium;
                  }
                  &:hover .explanation {
                    text-decoration: underline;
                  }
                  .square {
                    width: 100%;
                    height: 100%;
                    border-color: $white;
                    cursor: pointer;
                    @include buildCorners(20, 0);
                    @include breakpoint(mobile) {
                      @include buildCorners(65, 0);
                    }
                  }
                  a {
                    &.newsContentBox {
                      position: absolute;
                      display: grid;
                      box-sizing: border-box;

                      border-color: $white;
                      grid-template-columns: 1fr;
                      @include vw(top, 20);
                      @include vw(left, 20);
                      @include vw(right, 20);
                      @include vw(bottom, 20);
                      @include vw(padding, 20);
                      @include breakpoint(from-phablet) {
                        grid-template-columns: 1fr 1fr;
                      }

                      @include vw(grid-column-gap, 40);

                      @include breakpoint(mobile) {
                        @include vwMobile(border-width, 20);
                        @include vwMobile(top, 20);
                        @include vwMobile(left, 20);
                        @include vwMobile(right, 20);
                        @include vwMobile(bottom, 20);
                      }

                      div {
                        &.image {
                          @include vw(width, 466);
                          @include vw(height, 261);
                          background-position: center;
                          background-repeat: no-repeat;
                          background-size: cover;
                          @include breakpoint(mobile) {
                            width: 100%;
                            @include vwMobile(height, 187);
                          }
                          img {
                            width: 100%;
                            height: 100%;
                          }
                        }
                        &.decShort {
                          position: absolute;
                          top: 47%;
                          line-height: 1.44;
                          text-transform: capitalize;
                          @include vw(width, 447);
                          @include vw(font-size, 16);
                          @include breakpoint(mobile) {
                            display: none;
                          }
                          p {
                            @include vw(font-size, 18);
                            line-height: 1.36;
                          }
                        }
                        &.content {
                          @include vw(height, 261);
                          @include breakpoint(mobile) {
                            @include vwMobile(height, 261);
                          }

                          p {
                            &.title {
                              @include vw(width, 169);
                              @include vw(height, 41);
                              display: flex;
                              box-sizing: border-box;
                              align-items: center;
                              justify-content: center;
                              background-color: $blueLight;
                              color: $white;
                              font-weight: bold;
                              text-transform: uppercase;
                              @include vw(margin-bottom, 28);
                              @include vw(font-size, 16);
                              @include breakpoint(mobile) {
                                @include vwMobile(font-size, 14);
                                @include vwMobile(min-width, 169);
                                @include vwMobile(height, 40);
                                @include vwMobile(margin-top, 20);
                                @include vwMobile(margin-bottom, 20);
                              }
                            }

                            &.explanation {
                              @include vw(font-size, 20);
                              @include vw(margin-bottom, 90);
                              overflow: hidden;
                              color: $white;
                              line-height: 1.4;
                              @include vw(width, 447);
                              @include vw(height, 83);
                              text-transform: uppercase;
                              @include breakpoint(mobile) {
                                @include vwMobile(font-size, 20);
                                @include vwMobile(width, 320);
                                @include vwMobile(height, 104);
                                @include vwMobile(margin-bottom, 40);
                              }
                            }

                            &.date {
                              @include vw(font-size, 16);
                              color: $gray-medium;
                              @include breakpoint(mobile) {
                                @include vwMobile(font-size, 16);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &.news-content-button {
            display: none;

            @include breakpoint(mobile) {
              display: flex;
              @include vwMobile(margin-bottom, 40);
            }
            a {
              width: 100%;
            }
            button {
              @include vwMobile(height, 65);
              width: 100%;
              border: 1px solid $white02;
              background-image: none;
              background-color: $almostBlack;
              .theme-button-container {
                div {
                  background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
                  @include vwMobile(height, 9);
                  @include vwMobile(width, 8);
                  @include vwMobile(margin-right, 15);
                  background-size: contain;
                }
                span {
                  color: $white;
                  font-family: eurostile-extended, sans-serif;
                  @include vwMobile(font-size, 13);
                  font-weight: 900;
                }
              }

              &:hover .theme-button-container {
                @include buildCornersYellow(13, 4);
                @include breakpoint(mobile) {
                  @include buildCornersMobileYellow(13, 4);
                }
                div {
                  background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
                }
                span {
                  color: $yellowIg;
                }
              }
            }
          }
          &.twitterContent {
            p {
              &.titleTwitter {
                color: $white;
                font-weight: bold;
                text-transform: uppercase;
                @include vw(font-size, 50);
                @include vw(line-height, 1.4);
                @include vw(margin-bottom, 64);
                @include breakpoint(mobile) {
                  @include vwMobile(font-size, 35);
                  @include vwMobile(margin-bottom, 40);
                }
              }
            }

            div {
              &.twitterContentBox {
                border: solid 0.1px rgba(255, 255, 255, 0.2);
                @include vw(height, 1086);
                // @include vw(padding-bottom, 100);
                overflow-y: scroll;
                scrollbar-color: #7f7f7f $almostBlack;
                scrollbar-width: thin;
                &::-webkit-scrollbar {
                  width: 7.5px;
                }
                &::-webkit-scrollbar-track {
                  background: $almostBlack;
                }
                &::-webkit-scrollbar-thumb {
                  border-right: 1px solid $almostBlack;
                  background: #7f7f7f;
                }
                @include breakpoint(mobile) {
                  @include vwMobile(height, 1023);
                  overflow-y: hidden;
                }
              }
            }
          }
        }
        #right {
          justify-content: center;
          .theme-button-container {
            div {
              @include vw(height, 30);
              @include vw(width, 30);
              @include vw(margin-right, 13);
              background-image: url('../../../../assets/images/icons/Twitter-noborder.svg');
              background-size: contain;
            }
          }
          .theme-button:hover .theme-button-container div {
            background-image: url('../../../../assets/images/icons/Twitter-noborder-yellow.svg');
          }
        }
      }
    }
  }
}
.road-map-img {
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
