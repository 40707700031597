// TODO : Fix some minor differences bewteen design and render cf : Thomas Slack
.referral-block-rewards-friends {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include vw(top, 0);
  @include breakpoint(mobile) {
    @include vwMobile(margin-bottom, 30);
  }
  .titleOnlyMobile {
    display: none;
    @include breakpoint(mobile) {
      display: flex;
      line-height: 1.4;
      @include vwMobile(font-size, 35);
      @include vwMobile(width, 320);
      @include vwMobile(padding-top, 30);
      @include vwMobile(margin-left, 20);
      @include vwMobile(margin-bottom, 20);
    }
  }
  .content-container {
    display: flex;
    @include breakpoint(mobile) {
      display: flex;
      flex-direction: column;
      @include vwColumns(1, 35, true);
      @include vwMobile(padding-bottom, 0);
    }

    @include breakpoint(mobile) {
      background: none;
    }
    @include vw(padding-top, 120);
    @include vw(padding-bottom, 120);
    @include breakpoint(mobile) {
      @include vwMobile(padding-top, 20);
      @include vwMobile(padding-bottom, 20);
    }
    .content-image-container {
      @include vw(min-height, 783);
      width: 50%;
      @extend .util-image-container;
      background-image: url('../../../../assets/images/recruit-a-friend/friend.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(height, 474);
      }
      .content-image-placeholder {
        @extend .util-image-placeholder;
        @include buildCorners(40, 50);
        @include breakpoint(mobile) {
          @include buildCorners(70, 80);
        }
      }
    }

    .content-block-container {
      width: 50%;
      height: auto;
      @include vwAll(padding, 160, 0, 160, 100);
      background-color: $blueDark;
      @include breakpoint(mobile) {
        width: 100%;
      }
      .content-block {
        h2 {
          @include vw(min-width, 340);
          margin: 0;
          @include vw(margin-bottom, 50);
          @include breakpoint(mobile) {
            display: none;
          }
        }

        h5 {
          @include vw(margin-bottom, 20);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 20);
            line-height: 1.4;
            @include vwMobile(margin-top, 40);
            @include vwMobile(margin-bottom, 20);
          }
        }
        p {
          @include vw(margin-bottom, 10);
          @include vw(margin-top, 20);
          @include breakpoint(mobile) {
            @include vwMobile(margin-bottom, 10);
            @include vwMobile(margin-top, 20);
          }
        }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
        }
        li::before {
          display: inline-block;
          @include vw(width, 20);
          @include vw(margin-right, 10);
          color: $white;
          content: '+';
          font-weight: normal;
          @include breakpoint(mobile) {
            @include vwMobile(width, 20);
            @include vwMobile(margin-right, 10);
          }
        }
        p,
        li {
          @extend .paragraph_regular;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            line-height: 1.38;
          }
        }
      }
    }
  }
}
