.account-prod-container {
  h1.account-title {
    @include vw(font-size, 35);
    line-height: 1.4;
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 25);
      @include vwMobile(margin-bottom, 33);
      @include vwMobile(margin-top, 50);
    }
  }
  .account-desc {
    @include vw(margin-bottom, 40);
    @include vw(margin-top, -30);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 30);
    }
    * {
      @include vw(font-size, 20);
      color: #a8a8a8;
      line-height: 2;
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    p:last-of-type {
      margin-bottom: 0;
    }
    a {
      color: $yellowMild;
      text-decoration: underline;
    }
  }
  .green-box {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: $green;
    @include vw(padding, 20);
    @include vw(margin-bottom, 60);
    height: auto;
    @include breakpoint(mobile) {
      @include vwMobile(height, 65);
      @include vwMobile(margin-bottom, 30);
    }
    .green-box-icon {
      @include vw(height, 40);
      @include vw(width, 40);
      background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-white-border.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include vw(margin-left, 15);
      @include vw(margin-right, 15);
      @include breakpoint(mobile) {
        @include vwMobile(height, 40);
        @include vwMobile(width, 40);
        @include vwMobile(margin-left, 15);
        @include vwMobile(margin-right, 15);
      }
    }
    .green-box-content {
      display: inline-block;
      p {
        @include vw(font-size, 20);
        font-weight: 700;
        line-height: 2;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 14);
          line-height: 1.43;
          @include vwMobile(width, 282);
        }
      }
    }
  }
  .disclaimerGhost {
    @include vw(font-size, 28);
    @include vw(margin-bottom, 40);
    color: $white;
    text-emphasis: bold;
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 20);
      @include vwMobile(margin-bottom, 20);
    }
  }
  .row-option {
    @include vw(height, 110);
    @include vw(margin-bottom, 40);
    display: flex;
    align-items: center;
    @include breakpoint(mobile) {
      @include vwMobile(height, 50);
      @include vwMobile(margin-bottom, 20);
      height: auto;
      flex-wrap: wrap;
    }
    .row-img {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      border: 0.1px solid $white;
      @include vw(width, 110);
      @include breakpoint(mobile) {
        @include vwMobile(width, 50);
        @include vwMobile(height, 50);
      }
      div {
        @include vw(width, 60);
        @include vw(height, 60);
        background-image: url('../../../../assets/images/Logo-DU.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(mobile) {
          @include vwMobile(width, 30);
          @include vwMobile(height, 30);
        }
      }
    }
    .row-text {
      @include vw(margin-left, 40);
      @include breakpoint(mobile) {
        @include vwMobile(margin-left, 20);
      }
      .text-title {
        color: $yellowIg;
        font-weight: 700;
        line-height: 1.36;
        text-transform: uppercase;
        @include vw(font-size, 22);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 18);
          line-height: 1.44;
        }
      }
      .text-subtitle {
        @include vw(font-size, 20);
        color: $gray;
        line-height: 1.4;
        @include vw(width, 317);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          @include vwMobile(width, 317);
        }
      }
    }
    .row-button {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      @include breakpoint(mobile) {
        width: 100%;
        margin-left: 0;
        @include vwMobile(margin-top, 20);
        @include vwMobile(margin-bottom, 30);
      }
      button:disabled {
        &:hover {
          cursor: not-allowed;
          border-color: $gray-home;
        }
        color: $gray-home;
      }
      button {
        display: flex;
        width: auto;
        align-items: center;
        justify-content: center;
        border: 1px solid $gray-home;
        background-color: $almostBlack;
        color: $white;
        cursor: pointer;
        // outline: none;
        text-transform: uppercase;
        @include breakpoint(mobile) {
          display: flex;
          width: 100%;
          @include vwMobile(padding-top, 15);
          @include vwMobile(padding-bottom, 18);
        }
        @include vw(min-width, 200);
        @include vw(min-height, 50);
        &:first-of-type {
          @include vwMobile(margin-bottom, 3);
        }
        &.disabled {
          cursor: inherit;
        }
        &:hover {
          border-width: 1px;
          border-color: $white;
        }
        span {
          @include vw(font-size, 16);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
          font-weight: bold;
          line-height: 1.38;
          &:nth-child(2) {
            @include vw(margin-left, 5);
          }
          &.status-off {
            color: $red;
            &:before {
              @include vw(margin-right, 5);
              content: '\25CF';
            }
          }
          &.status-on {
            color: $green;
            &:before {
              @include vw(margin-right, 5);
              content: '\25CF';
            }
          }
        }
      }
    }
  }
  .big-btn-mobile {
    display: none;
    @include breakpoint(mobile) {
      display: flex;
      width: 100%;
      @include vwMobile(margin-bottom, 40);
      button {
        @include breakpoint(mobile) {
          @include vwMobile(height, 50);
          width: 100%;
          border: 0.1px solid $gray-home;
          background-color: $almostBlack;
          color: $white;
          cursor: pointer;
          outline: none;
          text-transform: uppercase;
        }
      }
    }
  }
  .line {
    display: block;
    width: 100%;
    height: 1px;
    margin: 0.5em auto 1em;
    background: $white;
    content: '';
    opacity: 0.25;
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 30);
      @include vwMobile(margin-bottom, 30);
    }
  }
  .input-group-code {
    @include vw(margin-bottom, 60);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 60);
    }
    label {
      color: $yellowIg;
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    .input-row {
      display: flex;
      justify-content: space-between;
      @include vw(margin-top, 20);

      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 10);
      }
      .theme-button {
        @include vw(min-width, 220);
        .theme-button-container {
          div.send {
            background-image: url('../../../../assets/images/icons/icon-send-black.svg');
            @include vw(height, 35);
            @include vw(width, 35);
            background-size: contain;
            @include vw(margin-right, 10);
          }
          div.check {
            background-image: url('../../../../assets/images/icons/no-border/Icon-Validate.svg');
            @include vw(height, 25);
            @include vw(width, 25);
            background-size: contain;
          }
        }
        @include breakpoint(mobile) {
          @include vwMobile(height, 65);
          @include vwMobile(width, 60);
          span {
            display: none;
          }
          .theme-button-container {
            div.send {
              background-image: url('../../../../assets/images/icons/icon-send-black.svg');
              @include vwMobile(height, 35);
              @include vwMobile(width, 35);
              background-size: contain;
            }
            div.copy {
              background-image: url('../../../../assets/images/icons/copy-black.svg');
              @include vwMobile(height, 25);
              @include vwMobile(width, 25);
              background-size: contain;
            }
          }
        }
      }
      .input-box {
        @include vw(width, 509);
        @include vw(height, 65);
        display: flex;
        align-items: center;
        border: 0.1px solid $gray-home;
        @include breakpoint(mobile) {
          @include vwMobile(width, 292);
          @include vwMobile(height, 65);
        }
        .icon {
          @include vw(width, 20);
          @include vw(height, 20);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          @include vw(padding, 10);
          @include vw(margin-left, 15);
          @include vw(margin-right, 15);
          @include breakpoint(mobile) {
            @include vwMobile(width, 20);
            @include vwMobile(height, 20);
            @include vwMobile(margin-left, 15);
            @include vwMobile(margin-right, 15);
            @include vwMobile(padding, 10);
          }
        }
        .email-icon {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Email.svg');
        }
        .code-icon {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Code.svg');
        }
        input {
          width: 100%;
          height: 100%;
          border: 0;
          background-color: $blueDeep;
          color: $white;
          outline: none;
        }
      }
    }
    .warning {
      @include vw(margin-top, 30);
      @include vw(margin-bottom, 80);
      @include vw(font-size, 16);
      line-height: 1.38;
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 30);
        @include vwMobile(margin-bottom, 70);
        @include vwMobile(font-size, 16);
      }
    }
  }
  .redeem-stem-gametime {
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 30);
    }
    .theme-button {
      @include vw(margin-top, 15);
      @include vw(min-width, 220);
      .theme-button-container {
        div.send {
          background-image: url('../../../../assets/images/icons/icon-send-black.svg');
          @include vw(height, 35);
          @include vw(width, 35);
          background-size: contain;
          @include vw(margin-right, 10);
        }
        div.check {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Validate.svg');
          @include vw(height, 25);
          @include vw(width, 25);
          background-size: contain;
        }
      }
      @include breakpoint(mobile) {
        @include vwMobile(height, 65);
        @include vwMobile(min-width, 300);
        span {
          @include vwMobile(font-size, 16);
        }
        .theme-button-container {
          div.send {
            background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
            @include vwMobile(height, 15);
            @include vwMobile(width, 15);
            @include vwMobile(margin-right, 15);
            background-size: contain;
          }
        }
      }
    }
  }
  .small-input-group {
    @include vw(margin-bottom, 60);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 40);
    }
  }
  .available {
    font-weight: 700;
    line-height: 1.36;
    text-transform: uppercase;
    @include vw(font-size, 22);
    @include vw(margin-bottom, 30);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 22);
      @include vwMobile(margin-bottom, 30);
    }
  }
  .pending-group {
    @include vw(margin-bottom, 45);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 28);
    }
    .pending-row {
      display: flex;
      align-items: center;
      @include vw(margin-bottom, 10);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 10);
      }
      p {
        @include vw(font-size, 20);
        color: $gray;
        line-height: 2;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 2;
        }
        &::before {
          color: $yellowMild;
          content: '+';
          @include vw(margin-right, 26);
          @include breakpoint(mobile) {
            @include vwMobile(margin-right, 13);
          }
        }
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.1px solid $gray-home;
        margin-left: auto;
        background-color: $almostBlack;
        color: $white;
        cursor: pointer;
        text-transform: uppercase;
        @include vw(width, 200);
        @include vw(height, 50);
        @include breakpoint(mobile) {
          @include vwMobile(width, 50);
          @include vwMobile(height, 50);
        }
        div {
          background-image: url('../../../../assets/images/icons/Icon-Close.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          @include vw(width, 17);
          @include vw(height, 17);
          @include vw(margin-right, 19);
          @include breakpoint(mobile) {
            @include vwMobile(width, 17);
            @include vwMobile(height, 17);
            @include vwMobile(margin-right, 0);
          }
        }
        span {
          @include vw(font-size, 16);
          line-height: 1.38;
          @include breakpoint(mobile) {
            display: none;
          }
        }
      }
    }
  }
  .error {
    color: $color-negative;
  }
  .error-position-fixed {
    @include vw(margin-bottom, 60);
    @include vw(margin-top, -60);
  }
  .notice {
    color: $white;
  }
  .section-twofa {
    @include vw(margin-bottom, 40);
  }
  .steam {
    @include vw(min-width, 400);
    background-image: linear-gradient(to bottom, #0027ff, #221772);
    color: $white;
    border: none;
    .theme-button-container {
      div {
        background-image: url('../../../../assets/images/icons/Icon-Wishlist.svg');
        @include vw(height, 25);
        @include vw(width, 25);
        @include vw(margin-right, 10);
        filter: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(mobile) {
          @include vwMobile(height, 25);
          @include vwMobile(width, 25);
          @include vwMobile(margin-right, 10);
        }
      }
      span {
        color: $white;
      }
    }
    &:hover {
      border: none;
      .theme-button-container {
        border-image-source: url('../../../../assets/images/utils/border-image-corners-white.svg');
        div {
          background-image: url('../../../../assets/images/icons/Icon-Wishlist.svg');
        }
        span {
          color: $white !important;
        }
      }
    }
  }
}
.products-cancel-renew {
  background-color: $white;
  @include vwAll(padding, 20, 40, 35, 40);
  @include breakpoint(mobile) {
    @include vwMobileAll(padding, 20, 40, 35, 40);
  }
  .renew-cancel-title {
    * {
      @include vw(font-size, 50);
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
      color: $black;
      line-height: normal;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 35);
      }
    }
  }
  .renew-cancel-dec {
    @include vw(margin-top, 10);
    @include vw(margin-bottom, 30);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 10);
      @include vwMobile(margin-bottom, 30);
    }
    * {
      margin: 0;
      @include vw(font-size, 20);
      line-height: 2;
      color: $black;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
  }
  .close-button {
    background-image: url('../../../../assets/images/icons/Icon-Close-Black.svg');
  }
  .button-group {
    display: flex;
    justify-content: center;
    button {
      cursor: pointer;
      @include vw(min-width, 208);
      @include vw(height, 65);
      border: 0;
      margin: 0;
      @include breakpoint(mobile) {
        @include vwMobile(min-width, 208);
        @include vwMobile(height, 65);
      }
      div {
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        @include vw(width, 14);
        @include vw(height, 12);
        @include vw(margin-right, 20);
      }
      span {
        text-transform: uppercase;
        color: $white;
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        @include vw(font-size, 13);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 13);
        }
      }
      &.util-button-green {
        background-color: $green;
        @include vw(margin-left, 27);
      }
      &.util-button-black {
        background-color: $black;
      }
    }
  }
}
