.footerLandingOne {
  // @include vw(margin-top, 200);

  // @include vw(height, 5500);
  position: relative;
  z-index: 5;
  @include vw(margin-top, 84);

  div {
    &.footerIcon {
      @include vw(height, 90);
      @include vw(margin-bottom, 40);
      display: flex;
      align-items: center;
      @include breakpoint(mobile) {
        width: 100%;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-bottom-style: none;
        @include vwMobile(margin-bottom, 60);
        @include vwMobile(height, 220);
        @include vwMobile(padding-right, 81.5);
        @include vwMobile(padding-left, 82.5);
      }

      .iconLogo {
        @include vw(width, 40);
        @include vw(margin-right, 37);
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(mobile) {
          @include vwMobile(width, 40);
          @include vwMobile(margin-right, 30);
          @include vwMobile(margin-bottom, 20);
          @include vwMobile(height, 40);
        }
      }

      p {
        color: $white;
        text-emphasis: bold;
        text-transform: uppercase;
        @include vw(font-size, 20);
        @include vw(margin-left, 847);
        @include vw(margin-top, 0);
        @include breakpoint(mobile) {
          display: none;
        }
      }

      a {
        @include breakpoint(from-phablet) {
          height: 100%;
        }

        span {
          height: 100%;
          &.iconLogoTwitter {
            @extend .iconLogo;
            background-image: url('../../../../assets/images/icons/Twitter.svg');
            &:hover {
              background-image: url('../../../../assets/images/icons/yellow-withBorder/icon-twitter.svg');
            }
          }

          &.iconLogoFacebook {
            @extend .iconLogo;
            background-image: url('../../../../assets/images/icons/Facebook.svg');
            &:hover {
              background-image: url('../../../../assets/images/icons/yellow-withBorder/icon-facebook.svg');
            }
          }

          &.iconLogoInstagram {
            @extend .iconLogo;
            background-image: url('../../../../assets/images/icons/Instagram.svg');
            &:hover {
              background-image: url('../../../../assets/images/icons/yellow-withBorder/icon-instagram.svg');
            }
          }

          &.iconLogoYoutube {
            @extend .iconLogo;
            background-image: url('../../../../assets/images/icons/Youtube.svg');
            &:hover {
              background-image: url('../../../../assets/images/icons/yellow-withBorder/icon-youtube.svg');
            }
          }

          &.iconLogoDiscord {
            @extend .iconLogo;
            margin-right: 0px;
            background-image: url('../../../../assets/images/icons/Discord.svg');
            &:hover {
              background-image: url('../../../../assets/images/icons/yellow-withBorder/icon-discord.svg');
            }
          }
        }
      }
    }
  }

  section {
    div {
      &.footeremail {
        @include breakpoint(from-phablet) {
          @include vw(width, 496);
          display: inline-block;
        }

        form {
          p {
            &.title {
              color: $white;
              font-weight: bold;
              text-transform: uppercase;
              @include vw(font-size, 26);
              @include vw(margin-bottom, 40);

              @include breakpoint(mobile) {
                @include vwMobile(font-size, 22);
                @include vwMobile(margin-bottom, 20);
              }
            }
          }
          div.disclaimer {
            p {
              color: $gray-dark;
              @include vw(font-size, 16);
              line-height: 1.38;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
              }
            }
            a {
              line-height: 1.38;
              text-decoration: underline;
              @include vw(font-size, 16);
            }
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              @include vwMobile(margin-bottom, 80);
              @include vwMobile(line-height, 22);

              a {
                @include breakpoint(mobile) {
                  @include vwMobile(font-size, 16);
                }
              }
            }
          }

          .validated {
            border-color: $color-positive;
          }
          .util-input {
            width: 100%;
            @include vw(margin-bottom, 40);
            @include breakpoint(mobile) {
              @include vwMobile(height, 65);
              @include vwMobile(margin-bottom, 40);
            }

            div.first {
              border-style: none;
              margin: auto 0;
              background-image: url('../../../../assets/images/icons/no-border/Icon-Email.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              @include vw(width, 25);
              @include vw(height, 25);
              @include vw(margin-right, 25);
              @include vw(margin-left, 25);
              @include breakpoint(mobile) {
                @include vwMobile(width, 25);
                @include vwMobile(height, 25);
                @include vwMobile(margin-right, 25);
                @include vwMobile(margin-left, 25);
              }
            }
            button {
              display: flex;
              align-items: center;
              border: 0;
              background-color: transparent;
              cursor: pointer;
              outline: none;
              div.last {
                border-style: none;
                margin: auto 0;
                background-image: url('../../../../assets/images/icons/no-border/Icon-Send.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                @include vw(width, 25);
                @include vw(height, 25);
                @include vw(margin-right, 25);
                @include vw(margin-left, 25);
                @include breakpoint(mobile) {
                  @include vwMobile(width, 25);
                  @include vwMobile(height, 25);
                  @include vwMobile(margin-right, 25);
                }
              }
              div.validated {
                background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-Green.svg');
              }
            }
          }
        }
      }
      &.footerlinks {
        vertical-align: top;
        @include breakpoint(from-phablet) {
          @include vw(width, 331);
          display: inline-block;
          height: 100%;
          box-sizing: border-box;
          @include vw(margin-right, 293);
          @include vw(margin-left, 120);
        }

        a {
          div {
            &.linkblock {
              display: inline-block;
              @include vw(width, 275);
              box-sizing: border-box;
              @include vw(font-size, 20);
              @include vw(padding-bottom, 11.5);
              border-bottom: solid 1px rgba(255, 255, 255, 0.2);
              color: $gray;
              @include vw(margin-bottom, 24);
              @include vw(margin-right, 62);
              @include breakpoint(mobile) {
                width: 100%;
                @include vwMobile(font-size, 16);
                @include vwMobile(padding-bottom, 11.5);
              }
              &:hover {
                color: $yellowMild;
              }
            }
          }
        }
      }

      &.dualLogo {
        display: inline-block;
        background-image: url('../../../../assets/images/icons/du-logo-underline.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(width, 332);
        @include vw(height, 147);

        @include breakpoint(mobile) {
          display: block;
          margin: 0 auto;
          @include vwMobile(margin-top, 20);
          @include vwMobile(width, 332/1.6);
          @include vwMobile(height, 147/1.6);
        }
      }
    }

    div {
      &.footerEnd {
        width: 100%;
        // @include vw(width, 165.5);
        @include vw(padding-top, 37.5);
        text-align: center;

        @include breakpoint(mobile) {
          border-top-style: none;
          @include vwMobile(padding-top, 81);
        }

        p {
          display: inline-block;
          margin-left: auto;
          @include vw(margin-left, 950);
          color: #a8a8a8;
          @include vw(font-size, 16);
          @include breakpoint(mobile) {
            @include vwMobile(margin-bottom, 40);
            @include vwMobile(margin-top, 80);
            @include vwMobile(font-size, 16);
          }
        }

        div {
          text-align: left;

          &.footerEndLinks {
            display: flex;
            justify-content: start;
            @include vw(margin-bottom, 60);
            @include breakpoint(mobile) {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            }

            a {
              color: $white;
              @include vw(font-size, 18);
              @include vw(margin-right, 40);
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 15);
                margin-right: 0px;
                @include vwMobile(margin-bottom, 60);
              }
              &:hover {
                color: $yellowMild;
              }
            }
          }
        }
      }
    }
  }
}
.importancebig {
  color: #ffd255 !important;
}
