.referral-block-rewards-your {
  @include vw(margin-bottom, 0);
  position: relative;
  @include vw(top, 0);
  .content-header-container {
    text-align: center;
    @include breakpoint(mobile) {
      @include vwMobile(margin-right, 20);
      @include vwMobile(margin-left, 20);
    }
    h2 {
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 38);
        text-align: left;
      }
    }
    p {
      @include vw(max-width, 1040);
      margin-right: auto;
      margin-left: auto;
      @include vw(margin-bottom, 30);
      @include breakpoint(mobile) {
        max-width: 100%;
        line-height: 1.4;
        text-align: left;
        @include vwMobile(font-size, 20);
      }
    }
  }
}
