.buypage-actions {
  @include vw(margin-top, 81);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  // text-align: right;
  @include breakpoint(mobile) {
    flex-direction: column;
    align-items: unset;
  }
  .side {
    display: inline-block;
    // @include vw(padding-left, 40);
    @include breakpoint(mobile) {
      padding-left: 0;
    }
  }

  p {
    @include vw(margin-top, 40);
    @include vw(font-size, 18);
    color: $gray;
    text-align: center;
    a {
      color: $yellowIg;
    }

    @include breakpoint(mobile) {
      @include vwMobile(font-size, 18);
      line-height: 1;
    }

    &.buyingDisclaimer {
      @include vw(margin-bottom, 200);
      @include vw(font-size, 16);
      color: $gray-dark;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }

      .buypagetwolink {
        color: $yellowMild;
        text-decoration: underline;
        @include vw(font-size, 16);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
  }
  .btn-container {
    position: absolute;
    display: flex;
    align-items: flex-end;
    @include vw(top, 250);
    @include vw(right, 160);

    @include breakpoint(mobile) {
      @include vwMobile(top, 133);
    }

    a {
      &.cancel {
        @include vw(font-size, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 11);
        }
      }
    }
  }
  button {
    &.grayOut {
      // background-color: gray;
      cursor: not-allowed;
    }
  }
}
