.loginmenucontainer {
  z-index: 10;
  @extend .submenutemplate;
  @include breakpoint(from-phablet) {
    @include vw(width, 270);
    @include vw(right, 0);
  }

  @include breakpoint(mobile) {
    width: 100%;
  }

  height: auto;

  .loginheader {
    display: none;

    @include breakpoint(mobile) {
      display: flex;
      width: inherit;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: $grayButton;
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      @include vwMobile(font-size, 20);
      line-height: 1.4;
      @include vwMobile(padding-bottom, 15);
      @include vwMobile(margin-bottom, 55);
    }
  }

  .simplesubmenu {
    @extend .simplesubmenutemplate;
    // border-style: none;
    //@include buildCorners(12, 0);
    @include breakpoint(mobile) {
      border: 0;
    }
    .login {
      display: flex;
      flex-direction: column;
      .util-input {
        border-width: 0.1px;
        @include vw(margin-top, 10);
        @include breakpoint(mobile) {
          @include vwMobile(margin-bottom, 20);
        }
        @include breakpoint(from-phablet) {
          width: 100%;
          @include vw(height, 40);
        }

        @include breakpoint(mobile) {
          width: 100%;
          @include vwMobile(height, 65);
        }
        .util-input-text {
          width: 80%;
        }
        .emaillogo {
          span {
            @include vw(width, 15);
            @include vw(height, 15);
            display: inline-block;
            border-style: none;
            background-image: url('../../../../assets/images/icons-navbar/Icon-Email.svg');
            content: '';
            @extend .navbarimage;
            @include breakpoint(mobile) {
              @include vwMobile(width, 15);
              @include vwMobile(height, 15);
            }
          }
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: none;
          @include vw(width, 40);
          @include vw(height, 40);
          // @include vwAll(margin, 10, 10, 10, 10);
          @include breakpoint(mobile) {
            @include vwAllMobile(margin, 25, 25, 25, 25);
            @include vwMobile(width, 15);
            @include vwMobile(height, 15);
          }
        }

        .passwordlogo {
          span {
            display: inline-block;
            border-style: none;
            background-image: url('../../../../assets/images/icons-navbar/Icon-Password.svg');
            content: '';
            @extend .navbarimage;
            @include vw(width, 15);
            @include vw(height, 15);
            @include breakpoint(mobile) {
              @include vwMobile(width, 15);
              @include vwMobile(height, 15);
            }
          }
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: none;
          @include vw(width, 40);
          @include vw(height, 40);
          //@include vwAll(margin, 10, 10, 10, 10);
          @include breakpoint(mobile) {
            @include vwAllMobile(margin, 25, 25, 25, 25);
            @include vwMobile(width, 15);
            @include vwMobile(height, 15);
          }
        }
      }
    }

    .errorMessage {
      @include vw(margin-top, 0);
      @include vw(font-size, 14);
      color: $reddish;
      line-height: 1.43;
      text-align: left;

      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 0);
        @include vwMobile(font-size, 14);
      }
    }

    a {
      &.forgotpassword {
        display: flex;
        flex-direction: row-reverse;
        justify-content: right;
        color: $gray;
        @include vw(font-size, 12);
        @include vw(margin-top, 10);

        @include breakpoint(mobile) {
          @include vwMobile(font-size, 12);
          margin-top: 0;
        }
      }

      &.createaccount {
        display: flex;
        @include vw(margin-top, 40);
        @include vw(font-size, 14);
        justify-content: center;

        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 40);
          @include vwMobile(margin-bottom, 40);
          @include vwMobile(font-size, 16);
          color: $white;
          text-transform: uppercase;
        }
      }
    }

    .util-button-text {
      border-style: none;
      margin: 0 auto;
      background-color: $button-continue;
      @include vw(margin-top, 18);
      @include vw(width, 130);
      @include vw(height, 40);
      @extend .paragraph_base;

      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(height, 65);
        @include vwMobile(margin-top, 40);
      }
    }

    p {
      &.or {
        margin: 0 auto;
        text-align: center;
        @include vw(margin-top, 20);
        @include vw(margin-bottom, 20);
        @include vw(font-size, 12);

        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          @include vwMobile(margin-top, 60);
          @include vwMobile(margin-bottom, 40);
        }
      }
    }

    button {
      margin: 0 auto;
      div {
        display: none;
        @include breakpoint(mobile) {
          @include vwMobile(height, 24);
          @include vwMobile(width, 23);
          @include vwMobile(margin-right, 15);
          border: 0;
          background-image: url('../../../../assets/images/icons/no-border/Icon-Profile.svg');
          background-position: center;
          @include vwMobile(background-size, 15);
          background-repeat: no-repeat;
        }
      }
      &.util-button-facebook {
        @extend .navbuttons;
      }

      &.util-button-google {
        @extend .navbuttons;
      }
    }
    div {
      text-align: right;

      &.error-component,
      &.error-component div {
        text-align: left;
      }

      a {
        &.forgotAccount {
          display: inline-block;

          @include vw(margin-top, 15);
          @include vw(font-size, 12);
          opacity: 0.7;

          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 15);
            @include vwMobile(font-size, 12);
          }
        }

        &.cookiesNotice {
          display: inline-block;

          @include vw(margin-top, 15);
          @include vw(font-size, 12);
          opacity: 0.7;
        }
      }
    }
    .loading {
      text-align: center;
      @include vw(margin-top, 40);
      div {
        display: inline-block;
        text-align: center;
      }
    }
  }
}
