.account-kickstarter {
  .line {
    @include vw(margin-top, 60);
  }
  .account-kickstarter-title {
    @include vw(margin-bottom, 10);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 10);
      @include vwMobile(margin-top, 30);
    }
    * {
      @include vw(font-size, 35);
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
      }
    }
  }
  .account-kickstarter-desc {
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 30);
    }
    * {
      @include vw(font-size, 20);
      color: #a8a8a8;
      line-height: 2;
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    a {
      color: $yellowMild;
      text-decoration: underline;
    }
  }
  .account-kickstarter-desc.title {
    * {
      @include vw(font-size, 20);

      text-transform: uppercase;
    }
  }
  .checkBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include vw(margin-top, 34);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 60);
    }

    label {
      @include vw(margin-bottom, 0);
      @include vw(font-size, 20);
      color: $yellowIg;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
        @include vwMobile(width, 270);
        line-height: 2;
      }
    }
    .checkboxcontainer {
      color: $white;

      input[type='checkbox'] {
        box-sizing: border-box;
        border-style: solid;
        border-color: $white;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background-color: $blueDeep;
        cursor: auto;
        outline: none;
        @include vw(width, 20);
        @include vw(height, 20);
        @include vwAll(margin, 0, 0, 0, 0);
        @include vw(border-width, 1);
        @include breakpoint(mobile) {
          @include vwMobile(width, 25);
          @include vwMobile(height, 25);
        }
      }
      input[type='checkbox']:checked {
        background-clip: content-box;
        background-color: $yellowIg;
        @include vwAll(padding, 5, 5, 5, 5);
        @include breakpoint(mobile) {
          @include vwMobileAll(padding, 7, 7, 7, 7);
        }
      }
    }
  }
  .input-group-code {
    @include vw(margin-bottom, 30);
    label {
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 15);
      }
    }
  }
  .theme-button {
    @include vw(margin-top, 30);
    .theme-button-container {
      div {
        @include vw(height, 15);
        @include vw(width, 15);
        background-size: contain;
      }
    }
    @include breakpoint(mobile) {
      width: 100%;
      @include vwMobile(height, 60);
      @include vwMobile(margin-top, 30);
      .theme-button-container {
        div {
          @include vwMobile(margin-right, 15);
          @include vwMobile(height, 15);
          @include vwMobile(width, 15);
          background-size: cover;
        }
      }
    }
  }
  .image {
    max-width: 100%;
  }
  .select-field {
    border-style: solid;
    @include vw(border-width, 1);
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent
      url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='20' height='20' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>")
      no-repeat;
    background-position: right 0.35625vw top 55%;
    background-repeat: no-repeat;
    color: $white;
    cursor: pointer;
    @include vw(width, 150);
    @include vw(padding, 15);
    @include vw(background-size, 20);
    @include vw(margin-right, 10);
    @include vw(margin-top, 5);
    outline: none;
    @include breakpoint(mobile) {
      @include vwMobile(margin-right, 10);
      @include vwMobile(width, 150);
      @include vwMobile(padding, 15);
      @include vwMobile(background-size, 20);
    }
    option {
      color: $white;
      background-color: $almostBlack;
    }
  }
  select[disabled]:hover {
    cursor: auto;
  }
  .account-notice {
    display: flex;
    align-items: center;
    background-color: $green;
    @include vw(height, 65);
    @include vw(margin-top, 39);
    animation-duration: 4s;
    animation-name: custom;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    position: sticky;
    @include vw(top, 160);
    z-index: 10;
    @include breakpoint(mobile) {
      @include vwMobile(height, 65);
      @include vwMobile(top, 65);
      @include vwMobile(margin-top, 39);
    }
    p {
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    div {
      background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-white-border.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include vw(height, 40);
      @include vw(width, 40);
      @include vw(margin-left, 15);
      @include vw(margin-right, 17);
      @include breakpoint(mobile) {
        @include vwMobile(height, 25);
        @include vwMobile(width, 25);
        @include vwMobile(margin-left, 15);
        @include vwMobile(margin-right, 17);
      }
    }
  }
  .success {
    color: $green;
    @include vw(margin-top, 20);
  }
  .non-editable-fields {
    @include vw(margin-top, 40);
    .digital-group {
      display: flex;
      align-items: top;
      a {
        padding-left: 20px;
        color: $yellowMild;
        text-decoration: underline;
      }
    }
  }
  button[disabled] {
    cursor: unset;
  }
}
