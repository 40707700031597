.login-modal-container {
  @include vw(width, 1200);
  @include vw(height, 657);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border: solid 1px $gray-oblique;
  background-image: linear-gradient(to bottom, $blueDark, $almostBlack);
  animation-duration: 0.6s;
  animation-name: fadeInUpModal;
  animation-timing-function: cubic-bezier (0.65, 0, 0.35, 1);
  animation-fill-mode: forwards;
  @keyframes fadeInUpModal {
    from {
      opacity: 0;
      transform: translate(-50%, -30%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  @include breakpoint(mobile) {
    width: 100%;
    height: auto;
    transform: none;
    @include vwMobile(top, 65);
    background-image: none;
    background-color: $almostBlack;
    left: 0;
    animation: none;
    border: none;
  }
  .corners {
    display: flex;
    @include buildCorners(20, 0);
    width: 100%;
    height: 100%;
    .login-modal-left {
      .loading {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @include vw(width, 508);
      display: flex;
      flex-direction: column;
      @include vw(padding-left, 47);
      @include vw(padding-right, 87);
      @include vw(padding-top, 40);
      @include breakpoint(mobile) {
        width: 100%;
      }
      .intro {
        display: flex;
        @include vw(margin-bottom, 30);
        @include breakpoint(mobile) {
          @include vwMobile(margin-bottom, 30);
          @include vwMobile(padding-bottom, 20);
          border-bottom: 1px solid $white02;
        }
        .lock-icon {
          @include vw(width, 60);
          @include vw(height, 60);
          @include vw(margin-right, 20);
          background-image: url('../../../../assets/images/icons-navbar/Icon-Password.svg');
          background-repeat: no-repeat;
          background-size: 50%;
          background-position: center;
          border: 1px solid $white;
          @include breakpoint(mobile) {
            display: none;
          }
        }
        .content {
          .desc {
            color: $yellowIg;
            @include vw(font-size, 13);
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            font-style: normal;
            text-transform: uppercase;
            @include breakpoint(mobile) {
              display: none;
            }
          }
          .main {
            @include vw(font-size, 35);
            line-height: 1;
            text-align: left;
            margin: 0;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 20);
            }
          }
        }
      }
      .login {
        display: flex;
        flex-direction: column;
        .util-input {
          border-width: 0.1px;
          @include vw(margin-top, 10);
          position: relative;
          @include breakpoint(mobile) {
            @include vwMobile(margin-bottom, 20);
          }
          @include breakpoint(from-phablet) {
            @include vw(width, 374);
            @include vw(height, 65);
          }
          @include breakpoint(mobile) {
            width: 100%;
            @include vwMobile(height, 65);
          }
          .util-input-text {
            width: 80%;
          }
          .icon {
            span {
              @include vw(width, 20);
              @include vw(height, 20);
              display: inline-block;
              border-style: none;
              background-image: url('../../../../assets/images/icons-navbar/Icon-Profile.svg');
              @extend .navbarimage;
              @include breakpoint(mobile) {
                @include vwMobile(width, 15);
                @include vwMobile(height, 15);
              }
            }
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: none;
            @include vw(width, 65);
            @include vw(height, 65);
            // @include vwAll(margin, 10, 10, 10, 10);
            @include breakpoint(mobile) {
              @include vwAllMobile(margin, 25, 25, 25, 25);
              @include vwMobile(width, 15);
              @include vwMobile(height, 15);
            }
          }
          .icon-loading {
            span {
              background-image: url('../../../../assets/images/icons/no-border/Icon-Loading.svg');
              @include vw(width, 20);
              @include vw(height, 20);
              @include breakpoint(mobile) {
                @include vwMobile(width, 15);
                @include vwMobile(height, 15);
              }
            }
          }
          .icon-error {
            span {
              background-image: url('../../../../assets/images/icons/no-border/Icon-Close-Red.svg');
            }
          }
          .icon-validate {
            span {
              background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-Green.svg');
            }
          }
          .passwordlogo {
            span {
              display: inline-block;
              border-style: none;
              background-image: url('../../../../assets/images/icons-navbar/Icon-Password.svg');
              @extend .navbarimage;
              @include vw(width, 20);
              @include vw(height, 20);
              @include breakpoint(mobile) {
                @include vwMobile(width, 15);
                @include vwMobile(height, 15);
              }
            }
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: none;
            @include vw(width, 65);
            @include vw(height, 65);
            //@include vwAll(margin, 10, 10, 10, 10);
            @include breakpoint(mobile) {
              @include vwAllMobile(margin, 25, 25, 25, 25);
              @include vwMobile(width, 15);
              @include vwMobile(height, 15);
            }
          }
          .arrow {
            position: absolute;
            z-index: 1;
            right: 0;
            left: 0;
            margin-right: auto;
            margin-left: auto;
            @include vw(bottom, -10);
            @include vw(height, 12);
            @include vw(width, 26);
            @include vw(border-left-width, 26);
            @include vw(border-right-width, 26);
            @include vw(border-bottom-width, 26);
            border-right-style: solid; /* stylelint-disable-line */
            border-right-color: transparent; /* stylelint-disable-line */
            border-bottom-style: solid; /* stylelint-disable-line */
            border-bottom-color: $yellowIg; /* stylelint-disable-line */
            border-left-style: solid; /* stylelint-disable-line */
            border-left-color: transparent; /* stylelint-disable-line */

            @include breakpoint(mobile) {
              @include vwMobile(bottom, -10);
              @include vwMobile(height, 14);
              @include vwMobile(width, 32);
              @include vwMobile(border-left, 26);
              @include vwMobile(border-right, 26);
              @include vwMobile(border-bottom, 26);
              border-right-style: solid;
              border-right-color: transparent;
              border-bottom-style: solid;
              border-bottom-color: $yellowIg;
              border-left-style: solid;
              border-left-color: transparent;
            }
          }
          .yellow-notice {
            position: absolute;
            right: 0;
            left: 0;
            @include vw(bottom, -40);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: auto;
            margin-left: auto;
            background-color: $yellowIg;
            background-image: none;
            //@include vw(bottom, -10);
            @include vw(height, 48);
            @include vw(width, 315);
            z-index: 1;
            @include breakpoint(mobile) {
              @include vwMobile(width, 315);
              @include vwMobile(min-height, 52);
              @include vwMobile(bottom, -50);
            }
            p {
              @include vw(font-size, 16);
              font-weight: normal;
              color: $almostBlack;
              line-height: 1.38;
              text-transform: lowercase;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
              }
            }
            p::first-letter {
              text-transform: uppercase;
            }
          }
        }
        .util-input:not(.error):hover {
          border-color: $white;
        }
        .error {
          border: $reddish 1px solid;
        }
      }
      .twofa-container {
        .input-group {
          display: flex;
          justify-content: space-between;
        }
        .request-enter {
          @include vw(margin-bottom, 20);
          * {
            @include vw(font-size, 20);
            line-height: 2;
            font-weight: normal;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 20);
            }
            &::first-letter {
              text-transform: uppercase;
            }
          }
          @include breakpoint(mobile) {
            @include vwMobile(margin-bottom, 20);
          }
        }
        input {
          outline: none;
          background-color: #162024;
          color: $white;
          border: 0;
          @include vw(width, 50);
          @include vw(height, 65);
          @include vw(font-size, 30);
          border: 1px solid $white01;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $yellowIg;
          @include breakpoint(mobile) {
            @include vwMobile(width, 50);
            @include vwMobile(height, 65);
            @include vwMobile(font-size, 30);
          }
        }
        input:focus {
          border-color: $yellowIg;
        }
        input:nth-child(3) {
          @include vw(margin-right, 30);
          @include breakpoint(mobile) {
            @include vwMobile(margin-right, 30);
          }
        }
        .theme-button {
          margin-top: 0;
        }
        .checkbox {
          display: flex;
          align-items: center;
          justify-content: center;
          @include breakpoint(mobile) {
            justify-content: flex-start;
          }
        }
        .checkboxcontainer {
          color: $white;
          @include vw(margin-top, 170);
          @include vw(margin-bottom, 15);
          input[type='checkbox'] {
            box-sizing: border-box;
            border-style: solid;
            border-color: $white;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            background-color: $blueDeep;
            cursor: pointer;
            outline: none;
            @include vw(width, 20);
            @include vw(height, 20);
            @include vwAll(margin, 0, 20, 0, 0);
            @include vw(border-width, 1);
            @include breakpoint(mobile) {
              @include vwMobile(margin-right, 30);
              @include vwMobile(width, 25);
              @include vwMobile(height, 25);
            }
          }
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 60);
            @include vwMobile(margin-bottom, 20);
          }
          input[type='checkbox']:checked {
            background-clip: content-box;
            background-color: $yellowIg;
            @include vwAll(padding, 5, 5, 5, 5);
            @include breakpoint(mobile) {
              @include vwMobileAll(padding, 7, 7, 7, 7);
            }
          }
          p {
            @include vw(font-size, 18);
            color: #a8a8a8;
            line-height: 2;
            text-transform: lowercase;
            font-weight: normal;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 20);
            }
            &::first-letter {
              text-transform: uppercase;
            }
          }
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
      .links-group {
        display: flex;
        justify-content: flex-end;
      }
      .create-link {
        @include vw(font-size, 16);
        color: #a8a8a8;
        //text-transform: lowercase;
        font-weight: normal;
        @include vw(margin-top, 12);
        display: inline-block;
        text-align: right;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          @include vwMobile(margin-top, 20);
        }
      }
      .create-link:hover {
        color: $yellowIg;
      }
      .problem {
        @include vw(margin-right, 20);
        @include breakpoint(mobile) {
          @include vwMobile(margin-right, 10);
        }
      }
      // a::first-letter {
      //   text-transform: uppercase;
      // }
      .theme-button {
        width: 100%;
        background-image: linear-gradient(to right, #11b167 50%, #11b167 50%);
        @include vw(margin-top, 183);
        @include breakpoint(mobile) {
          @include vwMobile(height, 65);
        }
        &:hover .theme-button-container {
          div {
            -webkit-filter: invert(100%); /* Safari/Chrome */
            filter: invert(100%);
          }
          span {
            color: $black;
          }
        }
        .theme-button-container {
          div {
            background-image: url('../../../../assets/images/icons/Icon-Exit.svg');
            @include breakpoint(mobile) {
              @include vwMobile(height, 20);
              @include vwMobile(width, 16);
              @include vwMobile(margin-right, 20);
              background-size: contain;
            }
          }
          span {
            color: $white;
            @include vw(font-size, 13);
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            font-style: normal;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 13);
            }
          }
        }
      }
    }
  }
  .login-modal-right {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    height: 100%;
    @include vw(width, 692);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    display: flex;
    animation-name: parallaximgModal;
    //animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    @keyframes parallaximgModal {
      from {
        background-position-y: 40px;
      }
      to {
        background-position-y: 0;
      }
    }
    @include breakpoint(mobile) {
      display: none;
    }
    .corners-right {
      display: flex;
      border-left: 0;
      height: 100%;
      width: 100%;
      @include buildCorners(20, 0);
    }
    .carousel-box-title-author {
      z-index: 8;
      position: relative;
      @include vw(width, 316);
      @include vw(height, 65);
      @include vw(margin-left, 20);
      @include vw(margin-top, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $almostBlack;
      border: 1px solid $white02;
      p,
      span {
        color: #a8a8a8;
        @include vw(font-size, 16);
        text-transform: lowercase;
        display: inline-block;
        font-weight: normal;
      }
      span {
        @include vw(margin-left, 5);
      }
      p::first-letter,
      span::first-letter {
        text-transform: uppercase;
      }
    }
    .carousel-box-coordinates {
      @include vw(width, 65);
      @include vw(height, 65);
      z-index: 8;
      position: relative;
      @include vw(margin-top, 0);
      @include vw(margin-left, 10);
      background-color: $almostBlack;
      border: 1px solid $white02;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .carousel-box-coordinates-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .icon {
          @include vw(width, 20);
          @include vw(height, 20);
          background-image: url('../../../../assets/images/icons/Icon\ -\ Location.svg');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .icon-close {
          background-image: url('../../../../assets/images/icons/Icon-Close.svg');
        }
      }
    }
    .close {
      @include vw(margin-left, 196);
    }
    .icon-close {
      transition: transform 0.2s;
      transition-timing-function: cubic-bezier (0.65, 0, 0.35, 1);
    }
    .close:hover .icon-close {
      transform: rotate(90deg);
    }
  }
}
.popup-content {
  animation-duration: 0.6s;
  animation-name: fadeInUpModalCopy;
  animation-timing-function: cubic-bezier (0.65, 0, 0.35, 1);
  animation-fill-mode: forwards;
}
@keyframes fadeInUpModalCopy {
  from {
    opacity: 0;
    transform: translate(0, 40%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
