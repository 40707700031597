.builders-container {
  .navbar-container {
    @include vw(padding-left, 156);
    @include vw(padding-right, 156);
    @include vw(height, 65);
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    @include vw(top, 80);
    z-index: 9;
    border-bottom: 1px solid $white02;
    background-color: $almostBlack;
    @include breakpoint(mobile) {
      @include vwMobile(height, 50);
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      border-top: 1px solid $white02;
      justify-content: space-between;
      @include vwMobile(top, 65);
    }
    .navbar-left {
      position: absolute;
      @include vw(left, 156);
      display: flex;
      align-items: center;
      cursor: pointer;
      & div {
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
        transform: rotate(180deg);
        @include vw(background-size, 8);
        background-position: center;
        background-repeat: no-repeat;
        @include vw(margin-right, 18);
        @include vw(margin-top, 3);
        @include vw(height, 16);
        @include vw(width, 16);
      }
      @include breakpoint(mobile) {
        display: none;
      }
    }
    .navbar-right {
      display: flex;
    }
    a {
      color: $white;
      height: 100%;
      width: 100%;
      @include vw(font-size, 20);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 18);
      }
    }
    .nav-link {
      @include vw(height, 65);
      display: flex;
      align-items: center;
      border-bottom: solid transparent;
      border-top: solid transparent;
      @include vw(border-width, 3);
      position: relative;
      p.notice {
        position: absolute;
        @include vw(top, 8);
        left: 0;
        right: 0;
        color: $yellowIg;
        text-transform: uppercase;
        @include vw(font-size, 8);
        text-align: center;
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        @include breakpoint(mobile) {
          @include vwMobile(top, 5);
          @include vwMobile(font-size, 8);
        }
      }
      a {
        @include vw(padding-top, 15);
        @include vw(padding-right, 25);
        @include vw(padding-left, 25);
        @include breakpoint(mobile) {
          @include vwMobile(padding-right, 20);
          @include vwMobile(padding-left, 20);
          @include vwMobile(padding-top, 19);
        }
      }
      @include breakpoint(mobile) {
        @include vwMobile(height, 50);
        @include vwMobile(border-width, 3);
      }
      // &:hover {
      //   border-bottom: solid $yellowIg;
      //   @include vw(border-width, 3);
      //   @include breakpoint(mobile) {
      //     @include vwMobile(border-width, 3);
      //   }
      // }
    }
    .selected {
      border-bottom: solid $yellowIg;
      @include vw(border-width, 3);
      @include breakpoint(mobile) {
        @include vwMobile(border-width, 3);
      }
    }
  }
  .builders-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    .builders-intro-title,
    .builders-intro-desc,
    span {
      font-weight: 900;
      font-style: normal;
      margin: 0;
    }
    .builders-intro-title {
      @include vw(margin-top, -430);
      z-index: 3;
      animation-duration: 2s;
      animation-name: title;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      * {
        @include vw(font-size, 194);
        line-height: 0.8;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 63);
          line-height: 1.1;
        }
      }
    }
    @keyframes title {
      0% {
        // opacity: 0;
        @include vw(margin-top, 0);
      }
      // 8% {
      //   opacity: 1;
      // }
      // 12% {
      //   opacity: 0;
      // }
      // 20% {
      //   opacity: 1;
      // }
      // 28% {
      //   opacity: 0;
      // }
      // 48% {
      //   opacity: 1;
      // }
      100% {
        // opacity: 1;
        @include vw(margin-top, -430);
      }
    }
    @keyframes subtitle {
      0% {
        @include vw(margin-top, 100);
      }

      100% {
        @include vw(margin-top, 0);
      }
    }
    .builders-intro-desc {
      animation-duration: 2.5s;
      animation-name: subtitle;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      z-index: 3;
      * {
        font-family: eurostile-extended, sans-serif;
        @include vw(font-size, 50);
        line-height: 1.9;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 21);
          text-align: center;
        }
      }
    }
    button {
      @include vw(min-width, 260);
      @include vw(height, 65);
      @include vw(margin-top, 44);
      z-index: 3;
      @include breakpoint(mobile) {
        @include vwMobile(height, 65);
        @include vwMobile(margin-top, 25);
        @include vwMobile(min-width, 440);
      }
      .theme-button-container {
        div {
          background-image: url('../../../../assets/images/icons/Icon-Play.svg');
          background-size: 70%;
          @include vw(margin-right, 13);
          margin-left: 0;
          @include breakpoint(mobile) {
            @include vwMobile(margin-right, 13);
            @include vwMobile(height, 15);
            @include vwMobile(width, 15);
          }
        }
        span {
          @include vw(font-size, 13);
          font-family: eurostile-extended, sans-serif;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 13);
          }
        }
      }
    }
    .space-button {
      @include vw(height, 65);
      @include vw(margin-top, 44);
      @include breakpoint(mobile) {
        @include vwMobile(height, 0);
        //  @include vwMobile(margin-top, 25);
      }
    }
    .background-video {
      position: relative;
      width: 100%;
      object-fit: cover;
      height: 39.95vw;
      z-index: 1;
      @include breakpoint(mobile) {
        @include vwMobile(height, 646);
      }
    }
    .background-image {
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 39.95vw;
      @include breakpoint(mobile) {
        @include vwMobile(height, 646);
      }
    }
    .opacity {
      z-index: 2;
      position: absolute;
      left: 0;
      width: 100%;
      height: 39.95vw;
      background-image: linear-gradient(180deg, rgba(12, 19, 21, 0) 30%, #0c1315);
      @include breakpoint(mobile) {
        @include vwMobile(height, 648);
      }
    }
  }
  .wrapper {
    background-repeat: repeat-y;
    background-position: center;
    background-size: cover;
  }
  .builders-initial-section {
    @include vw(margin-top, 98);
    @include vw(margin-bottom, 98);
    display: flex;
    justify-content: center;
    position: relative;
    @include breakpoint(mobile) {
      @include vwMobile(padding-right, 20);
      @include vwMobile(padding-left, 20);
      @include vwMobile(margin-top, 60);
      @include vwMobile(margin-bottom, 60);
    }
    .builders-initial-text {
      @include vw(width, 800);
      * {
        @include vw(margin-bottom, 20);
        text-align: center;
        @include vw(font-size, 22);
        line-height: 1.82;
        color: #dbdbdb;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 2;
        }
      }
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
    .absolute-elements {
      position: absolute;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      transition: transform 500ms linear;
      @include breakpoint(mobile) {
        display: none;
      }
    }
    .planet-left {
      z-index: 2;
      @include vw(top, -280);
      @include vw(left, -130);
      @include vw(width, 690);
      @include vw(height, 890);
      background-image: url('../../../../assets/images/pillars/building/planet-big.png');
    }
    .ship-left {
      z-index: 3;
      @include vw(top, 380);
      @include vw(left, 170);
      @include vw(width, 350);
      @include vw(height, 224);
      background-image: url('../../../../assets/images/pillars/building/sat.png');
    }
    .planet-right {
      @include vw(top, 390);
      @include vw(right, 50);
      background-image: url('../../../../assets/images/pillars/building/planet-medium.png');
      @include vw(width, 467);
      @include vw(height, 467);
    }
    .planet-right-merchants {
      @include vw(top, 30);
    }
  }
  .carousel-component,
  .text-centered-component {
    display: flex;
    @include vw(padding-left, 156);
    @include vw(padding-right, 60);
    @include vw(padding-bottom, 110);
    @include breakpoint(mobile) {
      flex-direction: column-reverse;
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 0);
    }
    .section-text {
      width: 45%;
      @include vw(min-height, 720);
      @include vw(padding-bottom, 180);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      z-index: 6;
      @include breakpoint(mobile) {
        width: 96%;
        justify-content: flex-start;
        height: auto;
        @include vw(padding-bottom, 0);
      }
      h2 {
        color: $yellowIg;
        @include vw(font-size, 70);
        line-height: 0.76;
        font-weight: 900;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 50);
          line-height: normal;
        }
      }
      .slice-subtitle {
        * {
          color: #a8a8a8;
          @include vw(font-size, 16);
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          line-height: 3.75;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 14);
            @include vwMobile(margin-bottom, 10);
            line-height: 1.43;
          }
        }
        em {
          font-style: normal;
          color: $white;
          display: inline-block;
          @include vw(font-size, 16);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 14);
            line-height: 1.43;
          }
        }
      }
      .slice-talents-title {
        @include vw(font-size, 16);
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        color: $yellowIg;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 14);
        }
      }
      p {
        @include vw(margin-right, 94);
        @include vw(font-size, 20);
        line-height: 2;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          @include vwMobile(margin-right, 0);
        }
      }
    }
    .section-carousel {
      width: 55%;
      z-index: 6;
      position: relative;
      @include breakpoint(mobile) {
        width: 100%;
        display: none;
      }
      .react-multiple-carousel__arrow {
        z-index: 9;
        border: solid 1px $white02;
        background: $almostBlack;
        border-radius: 0;
        opacity: 0.8;
        @include vw(min-height, 65);
        @include vw(min-width, 65);
        @include vw(bottom, 0);
      }
      .react-multiple-carousel__arrow::before {
        @include vw(font-size, 18);
      }
      .react-multiple-carousel__arrow--right {
        @include vw(left, 74);
        right: auto;
      }
      .react-multiple-carousel__arrow--left {
        @include vw(left, 0);
      }
      .carousel-box:active {
        cursor: grabbing;
      }
      .carousel-box {
        @include vw(width, 960);
        @include vw(height, 600);

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        cursor: grab;
        @include breakpoint(mobile) {
          width: 100%;
          @include vwMobile(height, 374);
        }
      }
      .carousel-info-content {
        @include vw(height, 120);
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
      .carousel-box-title-author {
        z-index: 8;
        position: relative;
        @include vw(width, 316);
        @include vw(height, 65);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $almostBlack;
        border: 1px solid $white02;
        p,
        span {
          color: #a8a8a8;
          @include vw(font-size, 16);
        }
        span {
          @include vw(margin-left, 5);
        }
      }
      .carousel-box-coordinates {
        @include vw(width, 65);
        @include vw(height, 65);
        z-index: 8;
        position: relative;
        @include vw(margin-left, 10);
        background-color: $almostBlack;
        border: 1px solid $white02;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .carousel-box-coordinates-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .icon {
            @include vw(width, 20);
            @include vw(height, 20);
            background-image: url('../../../../assets/images/icons/Icon\ -\ Location.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
    .carousel-swipe {
      display: none;
      @include breakpoint(mobile) {
        display: inline;
        text-align: right;
        @include vwMobile(margin-right, 20);
        @include vwMobile(margin-bottom, 10);
        color: #a8a8a8;
        @include vwMobile(font-size, 12);
        font-weight: bold;
      }
    }
    .section-carousel-mobile {
      display: none;
      @include breakpoint(mobile) {
        width: 100%;
        display: grid;
        @include vwMobile(gap, 10);
        // grid-template-columns: repeat(2, 1fr);
        -ms-overflow-style: none; /* IE and Edge */
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: none; /* Firefox */
        @include vwMobile(height, 420);
        .carousel-box {
          @include vwMobile(height, 374);
          @include vwMobile(width, 440);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
      .carousel-author-mobile {
        display: none;
        @include breakpoint(mobile) {
          display: block;
          position: relative;
          @include vwMobile(margin-top, 10);
          @include vwMobile(font-size, 16);
          color: #a8a8a8;
          * {
            color: #a8a8a8;
            @include vwMobile(font-size, 16);
            @include vwMobile(margin-left, 5);
          }
        }
      }
    }
    .talents-content {
      position: absolute;
      bottom: 0;
      @include breakpoint(mobile) {
        position: relative;
      }
      .talents-container {
        display: flex;
        .talents-box {
          @include vw(width, 80);
          @include vw(height, 103);
          @include vw(margin-right, 20);
          background-color: $white;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 0;
          @include breakpoint(mobile) {
            @include vwMobile(width, 80);
            @include vwMobile(height, 103);
            background-color: $almostBlack;
            border: 1px solid $white02;
            @include vwMobile(margin-right, 20);
            @include vwMobile(margin-top, 10);
          }
          .talents-box-image {
            @include vw(margin-top, 10);
            @include vw(width, 57);
            @include vw(height, 57);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            filter: brightness(0);
            @include breakpoint(mobile) {
              @include vwMobile(margin-top, 10);
              @include vwMobile(width, 57);
              @include vwMobile(height, 57);
              filter: brightness(100);
            }
          }
          .talents-box-title {
            * {
              margin: 0;
              color: $black;
              @include vw(font-size, 10);
              font-weight: bold;
              line-height: 1;
              text-align: center;
              text-transform: uppercase;
              @include breakpoint(mobile) {
                color: $white;
                @include vwMobile(font-size, 10);
              }
            }
          }
        }
      }
    }
  }
  .text-centered-component:last-of-type {
    margin-bottom: 0;
    @include vw(padding-bottom, 110);
  }
  .carousel-component-inverse {
    flex-direction: row-reverse;
    @include vw(padding-left, 60);
    @include vw(padding-right, 156);
    @include breakpoint(mobile) {
      flex-direction: column-reverse;
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 0);
    }
    .section-text {
      text-align: right;
      @include breakpoint(mobile) {
        text-align: left;
      }
      p {
        margin-right: 0;
        @include vw(margin-left, 94);
        @include breakpoint(mobile) {
          margin-left: 0;
        }
      }
    }
    .talents-content {
      @include breakpoint(from-phablet) {
        right: 0;
      }
      .talents-container {
        @include breakpoint(from-phablet) {
          flex-direction: row-reverse;
        }
        .talents-box {
          @include vw(margin-right, 0);
          @include vw(margin-left, 20);
          @include breakpoint(mobile) {
            margin-left: 0;
          }
        }
      }
    }
  }
  .text-centered-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include vw(padding-left, 60);
    @include vw(padding-right, 60);
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 0);
    }
    .carousel-swipe {
      display: none;
      @include breakpoint(mobile) {
        display: inline;
        text-align: right;
        @include vwMobile(margin-right, 43);
        @include vwMobile(margin-bottom, 10);
        color: #a8a8a8;
        @include vwMobile(font-size, 12);
        font-weight: bold;
        width: 100%;
      }
    }
    .carousel-swipe-none {
      display: none;
    }
    .section-text {
      justify-content: flex-start;
      @include vw(width, 800);
      @include vw(min-height, 680);

      //height: auto;
      @include vw(padding-top, 80);
      @include vw(padding-left, 75);
      @include vw(padding-right, 75);
      @include vw(padding-bottom, 280);
      @include vw(margin-top, -440);
      background-color: $almostBlack;
      text-align: center;
      @include breakpoint(mobile) {
        width: 100%;
        background-color: transparent;
        height: auto;
        @include vwMobile(padding-top, 0);
        @include vwMobile(padding-left, 0);
        @include vwMobile(padding-right, 20);
        @include vw(margin-top, 0);
        @include vw(padding-bottom, 0);
      }
      p {
        margin-right: 0;
      }
      .talents-content {
        left: 0;
        @include breakpoint(from-phablet) {
          right: 0;
        }
        @include vw(bottom, 75);
        @include breakpoint(mobile) {
          @include vwMobile(bottom, 0);
        }
        .talents-container {
          justify-content: center;
          @include breakpoint(mobile) {
            justify-content: flex-start;
          }
        }
      }
    }
    .image-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @include vw(gap, 53);
      @include breakpoint(mobile) {
        display: none;
        @include vwMobile(gap, 10);
        grid-template-columns: repeat(2, 1fr);
        -ms-overflow-style: none; /* IE and Edge */
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: none; /* Firefox */
        @include vwMobile(height, 420);
      }
      .image {
        @include vw(height, 1012);
        @include vw(width, 560);
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @include breakpoint(mobile) {
          @include vwMobile(height, 374);
          @include vwMobile(width, 430);
          background-size: cover;
        }
      }
      .complete {
        @include vw(width, 1800);
        z-index: 5;
      }
      .carousel-box-title-author {
        position: absolute;
        @include vw(top, 40);
        @include vw(right, 125);
        @include vw(width, 316);
        @include vw(height, 65);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $almostBlack;
        border: 1px solid $white02;
        p,
        span {
          color: #a8a8a8;
          @include vw(font-size, 16);
        }
        span {
          @include vw(margin-left, 5);
        }
      }
      .carousel-box-coordinates {
        @include vw(width, 65);
        @include vw(height, 65);
        position: absolute;
        @include vw(top, 40);
        @include vw(right, 40);
        background-color: $almostBlack;
        border: 1px solid $white02;
        .carousel-box-coordinates-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .icon {
            @include vw(width, 20);
            @include vw(height, 20);
            background-image: url('../../../../assets/images/icons/Icon\ -\ Location.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
    .only-one {
      grid-template-columns: 1fr;
    }
  }
  .builders-form-container {
    width: 100%;
    @include vw(padding-right, 0);
    @include vw(padding-left, 0);
    @include breakpoint(mobile) {
      padding: 0;
      display: none;
    }
    .builders-form {
      position: relative;
      @include vw(min-height, 702);
      background-color: $blueDark;
      background-image: linear-gradient(
          120deg,
          #162024 55%,
          rgba(12, 19, 21, 0.64) 30%,
          rgba(12, 19, 21, 0.1) 63%,
          rgba(12, 19, 21, 0) 66%
        ),
        url('../../../../assets/images/pillars/building/form-bg.png');
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right;
      @include vw(padding-left, 156);
      @include vw(padding-top, 225);
      @include breakpoint(mobile) {
        @include vw(min-height, 619);
        background-image: none !important;
        width: 100%;
        @include vwMobile(padding-left, 20);
        @include vwMobile(padding-right, 20);
        @include vwMobile(padding-top, 20);
        margin-bottom: 0;
      }
      .util-input {
        @include vw(width, 496);
        @include vw(margin-bottom, 30);
        background-color: $almostBlack;
      }
      .builders-form-img-mobile {
        display: none;
        @include breakpoint(mobile) {
          display: block;
          width: 100%;
          @include vwMobile(height, 217);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .builders-form-title {
        * {
          @include vw(font-size, 35);
          font-weight: bold;
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          font-style: normal;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 25);
            font-weight: bold;
            line-height: 1.4;
          }
        }
        //  @include vw(padding-top, 50);
        @include breakpoint(mobile) {
          //   @include vwMobile(padding-top, 40);
        }
      }
      .builders-form-description {
        * {
          line-height: 2;
          @include vw(font-size, 20);
          color: $gray;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
        @include vw(width, 552);
        @include vw(margin-bottom, -15);
        @include vw(margin-top, 5);
        @include breakpoint(mobile) {
          width: 100%;
          //  @include vwMobile(margin-top, 20);
        }
      }
      .builders-form-ship {
        @include vw(width, 1050);
        @include vw(height, 576);
        position: absolute;
        @include vw(top, 40);
        @include vw(left, 800);
        background-image: url('../../../../assets/images/pillars/building/form-ship.png');
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .builders-form-ship:hover {
        cursor: pointer;
      }
      .builders-form-ship:hover + .ship-label {
        display: flex;
        align-items: center;
        @include vw(top, 470);
        @include vw(right, 380);
        cursor: pointer;
      }
      .ship-label {
        display: none;
        position: absolute;
        z-index: 2;
        p {
          @include vw(font-size, 16);
          line-height: 1.81;
          @include vw(margin-left, 16);
        }
        div {
          background-image: url('../../../../assets/images/icons/Icon-Ship-Bar.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          @include vw(width, 40);
          @include vw(height, 10);
        }
      }
    }
  }
}
// .popup-content {
//   z-index: 8 !important;
// }
