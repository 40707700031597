.newsletter-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  z-index: 6;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: $almostBlack;

  h2 {
    color: $white;
    line-height: 1.38;
    text-align: center;
    text-transform: uppercase;
    @include vw(font-size, 22);
    @include vw(margin-top, 40);
    @include vw(margin-bottom, 50);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 22);
      line-height: 1.36;
      @include vwMobile(margin-top, 80);
      @include vwMobile(margin-bottom, 50);
    }
  }
  .exit {
    border-width: 0.1px;
    border-style: solid;
    border-color: #dbdbdb;
    background-clip: content-box;
    background-image: url('../../../../assets/images/icons-buttons/Icon-Close.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    float: right;
    transition: transform 200ms ease-in-out;
    @include vw(width, 30);
    @include vw(height, 30);
    @include vw(padding, 5);
    &:hover {
      cursor: pointer;
      transform: scale(1);
    }
    @include breakpoint(mobile) {
      @include vwMobile(width, 30);
      @include vwMobile(height, 30);
      @include vwMobile(padding, 5);
    }
  }
  .box {
    @include vw(width, 600);
    height: auto;
    margin-bottom: 0;
    border: solid 1px #9999;
    @include breakpoint(mobile) {
      @include vwMobile(width, 374);
      @include vwMobile(height, 631);
    }
    .wrapper {
      width: 100%;
      height: 100%;
      @include buildCorners(30, 0);
      @include breakpoint(mobile) {
        @include buildCorners(40, 0);
      }
      form {
        .input-icons {
          display: flex;
          width: 100%;
          border: solid 1px #9999;
          background-color: $almostBlack;
          @include vw(height, 65);
          @include breakpoint(mobile) {
            @include vwMobile(height, 65);
            @include vwMobile(width, 334);
          }
          input {
            border: 0;
            background-color: $almostBlack;
            color: $white;
            outline: none;
          }
          .icon-container {
            @include vw(width, 65);
            @include vw(height, 65);
            display: flex;
            align-items: center;
            justify-content: center;
            @include breakpoint(mobile) {
              @include vwMobile(height, 65);
              @include vwMobile(width, 65);
            }
            .icon {
              @include vw(width, 20);
              @include vw(height, 20);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              @include breakpoint(mobile) {
                @include vwMobile(height, 20);
                @include vwMobile(width, 20);
              }
            }
            .icon-email {
              background-image: url('../../../../assets/images/icons/no-border/Icon-Email.svg');
            }
            .icon-check {
              background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-Green.svg');
            }
            .icon-loading {
              background-image: url('../../../../assets/images/icons/no-border/Icon-Loading.svg');
            }
          }
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        .submit {
          @include vw(margin-top, 40);
          @include vw(width, 200);
          @include vw(height, 58);
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background-color: $white;
          cursor: pointer;
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 80);
            @include vwMobile(width, 200);
            @include vwMobile(height, 65);
          }
          &:hover {
          }
          div.icon {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: url('../../../../assets/images/icons/icon-send-black.svg');
            @include vw(width, 40);
            @include vw(height, 35);
            @include vw(margin-right, 5);
            @include breakpoint(mobile) {
              @include vwMobile(margin-right, 15);
              @include vwMobile(width, 40);
              @include vwMobile(height, 35);
            }
          }
          span {
            font-weight: 800;
            line-height: 1.38;
            text-transform: uppercase;
            @include vw(font-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              @include vwMobile(margin-right, 15);
            }
          }
        }
        .notice {
          @include vw(font-size, 16);
          @include vw(margin-top, 30);
          &.success {
            color: $green;
          }
          &.failure {
            color: $red;
          }
        }
      }
      .policy {
        @include vw(margin-top, 30);
        * {
          @include vw(font-size, 16);
          line-height: 2.5;
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 100);
            @include vwMobile(font-size, 16);
            line-height: 1.88;
            text-align: center;
            @include vwMobile(margin-left, 16);
            @include vwMobile(margin-right, 16);
          }
        }
        a {
          * {
            @include vw(font-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              line-height: 1.88;
            }
          }
        }
      }
    }
  }
}
