.referral-block-line {
  @extend .util-main-padding;
  @extend .sub-section;

  .util-block-base {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-logo {
      @include vw(height, 40);
      @include vw(width, 40);
      background-image: url('../../../../../assets/images/Logo-DU.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    @include breakpoint(mobile) {
      display: none;
    }
  }

  // odd / even bg color management
  @include vw(padding-top, 80);
  &:nth-of-type(odd) {
    background-color: $blueDeep;

    .referral-block-reward-container {
      background-color: $blueDark;
    }
  }

  &:nth-of-type(even) {
    background-color: $blueDark;
    .referral-block-reward-container {
      background-color: $blueDeep;
    }
  }

  .referral-block-line-header {
    @include columns(2);
    @include vw(margin-bottom, 80);

    div {
      &:first-of-type:not(.util-block-base) {
        display: flex;
        align-items: center;

        h3 {
          display: inline-block;
          @include vw(margin-left, 50);
          @include breakpoint(mobile) {
            margin-left: 0;
          }
        }

        input {
          background-color: $blueDeep;
        }
      }
    }

    h3 {
      margin-top: 0;
      margin-bottom: 0;

      &:first-of-type {
        text-align: left;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 25);
          line-height: 1.4;
        }
      }

      &:last-of-type {
        text-align: right;
        @include vw(font-size, 28);
        @include vw(line-height, 40);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 25);
          line-height: 1.4;
        }
      }
    }
  }

  .referral-block-line-container {
    display: flex;
    @include vwColumns(4, 35, true);
    @include breakpoint(mobile) {
      flex-wrap: nowrap;
      -ms-overflow-style: none; /* IE and Edge */
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none; /* Firefox */
      @include vwMobile(height, 650);
      // @include vwMobile(width, 404);
      @include vwColumns(4, 750, true);
    }
  }
  .referral-block-line-container::-webkit-scrollbar {
    @include breakpoint(mobile) {
      display: none;
    }
  }
}
