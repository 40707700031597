.footer {
  position: relative;
  z-index: 5;
  div {
    &.footerIcon {
      @include vw(height, 90);
      @include vw(margin-bottom, 100);
      display: flex;
      align-items: center;
      border: solid 1px $grayButton;
      border-right: 0;
      border-left: 0;
      @include breakpoint(mobile) {
        width: 100%;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-bottom-style: none;
        @include vwMobile(margin-bottom, 10);
        @include vwMobile(height, 220);
        @include vwMobile(padding-right, 81.5);
        @include vwMobile(padding-left, 82.5);
      }

      .iconLogo {
        @include vw(width, 40);
        @include vw(margin-right, 37);
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(mobile) {
          @include vwMobile(width, 40);
          @include vwMobile(margin-right, 30);
          @include vwMobile(margin-bottom, 20);
          @include vwMobile(height, 40);
        }
      }

      p {
        color: $white;
        font-weight: 500;
        text-emphasis: bold;
        text-transform: uppercase;
        @include vw(font-size, 20);
        @include vw(margin-left, 156);
        @include vw(margin-right, 80);
        @include breakpoint(mobile) {
          display: none;
        }
      }

      a {
        @include breakpoint(from-phablet) {
          height: 100%;
        }

        span {
          height: 100%;
          &.iconLogoTwitter {
            @extend .iconLogo;
            background-image: url('../../../assets/images/icons/Twitter.svg');
            &:hover {
              background-image: url('../../../assets/images/icons/yellow-withBorder/icon-twitter.svg');
            }
          }

          &.iconLogoFacebook {
            @extend .iconLogo;
            background-image: url('../../../assets/images/icons/Facebook.svg');
            &:hover {
              background-image: url('../../../assets/images/icons/yellow-withBorder/icon-facebook.svg');
            }
          }

          &.iconLogoInstagram {
            @extend .iconLogo;
            background-image: url('../../../assets/images/icons/Instagram.svg');
            &:hover {
              background-image: url('../../../assets/images/icons/yellow-withBorder/icon-instagram.svg');
            }
          }

          &.iconLogoYoutube {
            @extend .iconLogo;
            background-image: url('../../../assets/images/icons/Youtube.svg');
            &:hover {
              background-image: url('../../../assets/images/icons/yellow-withBorder/icon-youtube.svg');
            }
          }

          &.iconLogoTwitch {
            @extend .iconLogo;
            background-image: url('../../../assets/images/icons/Twitch.svg');
            &:hover {
              background-image: url('../../../assets/images/icons/yellow-withBorder/icon-twitch.svg');
            }
          }
          &.iconLogoDiscord {
            @extend .iconLogo;
            background-image: url('../../../assets/images/icons/Discord.svg');
            &:hover {
              background-image: url('../../../assets/images/icons/yellow-withBorder/icon-discord.svg');
            }
          }
          &.iconLogoReddit {
            @extend .iconLogo;
            background-image: url('../../../assets/images/icons/Reddit.svg');
            &:hover {
              background-image: url('../../../assets/images/icons/yellow-withBorder/icon-reddit.svg');
            }
          }
          &.iconLogoCloud {
            @extend .iconLogo;
            background-image: url('../../../assets/images/icons/Soundcloud.svg');
            &:hover {
              background-image: url('../../../assets/images/icons/yellow-withBorder/icon-soundcloud.svg');
            }
          }
        }
      }
    }
  }
  .navbar-item {
    height: 100%;
    @include breakpoint(mobile) {
      display: none;
    }
    @include vw(margin-left, 410);
    a {
      &.navbar-play {
        @extend .util-navbar-link;
        @extend .util-navbar-link-secondary;
        text-transform: uppercase;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(to bottom, #0027ff, #221772);
        color: $white;
        transform: skew(-36deg, 0deg);
        @include vw(width, 221);
        &:hover {
          box-shadow: 0 -25px 50px #0e90ff;
        }
        div {
          transform: skew(36deg, 0deg);
          background-image: url('../../../assets/images/icons/Icon-Wishlist.svg');
          @include vw(height, 25);
          @include vw(width, 25);
          @include vw(margin-right, 10);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
        span {
          transform: skew(36deg, 0deg);
          @include vw(font-size, 16);
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          font-style: normal;
          height: auto;
        }
      }
    }
  }
  section {
    div {
      &.footeremail {
        @include breakpoint(from-phablet) {
          @include vw(width, 447);
          @include vw(margin-bottom, 60);
          display: inline-block;
        }
      }
      &.footerlinks {
        vertical-align: top;
        @include breakpoint(from-phablet) {
          @include vw(width, 620);
          display: inline-block;
          height: 100%;
          box-sizing: border-box;
          @include vw(margin-right, 80);
          @include vw(margin-left, 100);
        }
        a {
          &.linkblockleft {
            display: inline-block;
            @include vw(width, 300);
            box-sizing: border-box;
            @include vw(font-size, 20);
            @include vw(padding-bottom, 11.5);
            border-bottom: solid 1px $grayButton;
            color: $gray;
            @include vw(margin-bottom, 23);
            @include vw(margin-right, 62);
            @include breakpoint(mobile) {
              width: 100%;
              @include vwMobile(font-size, 20);
              @include vwMobile(padding-bottom, 11.5);
              @include vwMobile(margin-bottom, 30.5);
              line-height: 1.4;
            }
            &:hover {
              color: $yellowMild;
            }
          }
          &.linkblockright {
            display: inline-block;
            @include vw(width, 250);
            box-sizing: border-box;
            @include vw(font-size, 20);
            @include vw(padding-bottom, 11.5);
            border-bottom: solid 1px $grayButton;
            color: $gray;
            @include vw(margin-bottom, 23);
            @include breakpoint(mobile) {
              width: 100%;
              @include vwMobile(font-size, 20);
              @include vwMobile(padding-bottom, 11.5);
              @include vwMobile(margin-bottom, 30.5);
              line-height: 1.4;
            }
            &:hover {
              color: $yellowMild;
            }
          }
        }
      }
      &.advertiser {
        @include breakpoint(from-phablet) {
          vertical-align: top;
          @include vw(width, 333);
          @include vw(height, 200);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
        }
      }
    }

    div {
      &.footerEnd {
        width: 100%;
        @include vw(padding-top, 37.5);
        border-top: solid 1px $grayButton;
        text-align: center;

        @include breakpoint(mobile) {
          border-top-style: none;
          @include vwMobile(padding-top, 61);
          @include vwMobile(padding-left, 25);
          @include vwMobile(padding-right, 25);
        }

        div {
          text-align: left;

          &.footerEndLinks {
            display: flex;
            justify-content: start;
            @include breakpoint(mobile) {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              @include vwMobile(margin-bottom, 80);
            }

            a {
              color: $white;
              @include vw(font-size, 18);
              @include vw(margin-right, 40);
              cursor: pointer;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 15);
                margin-right: 0px;
                @include vwMobile(margin-bottom, 40);
                @include vwMobile(margin-right, 40);
                &:nth-child(3),
                &:nth-child(6) {
                  margin-right: 0;
                }
                &:nth-child(4) {
                  @include vwMobile(padding-left, 40);
                }
                &:nth-child(6) {
                  @include vwMobile(padding-right, 40);
                }
                &:nth-child(7) {
                  @include vwMobile(padding-left, 128);
                }
              }
              &:hover {
                color: $yellowMild;
              }
            }

            .logo {
              border-style: none;
              margin-left: auto;
              background-image: url('../../../assets/images/logo-novaquark.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              @include vw(width, 186);
              @include vw(height, 24);
              margin-right: 0 !important;
              @include breakpoint(mobile) {
                @include vwMobile(width, 186);
                @include vwMobile(height, 24);
                margin: 0 auto;
                @include vwMobile(margin-top, 40);
              }

              a {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        p {
          @include vw(margin-top, 65);
          @include vw(margin-bottom, 40);
          @include vw(font-size, 16);
          color: #a8a8a8;
          @include breakpoint(mobile) {
            @include vwMobile(margin-bottom, 40);
            @include vwMobile(margin-top, 80);
            @include vwMobile(font-size, 16);
          }
        }
      }
    }
  }
}
.importancebig {
  color: $yellowIg !important;
  font-weight: bold;
}
form.newsletter {
  p {
    &.title {
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      @include vw(font-size, 26);
      @include vw(margin-bottom, 40);

      @include breakpoint(mobile) {
        @include vwMobile(font-size, 22);
        @include vwMobile(margin-bottom, 20);
      }
    }
  }
  div.disclaimer {
    p {
      color: $gray-dark;
      @include vw(font-size, 16);
      line-height: 1.38;
    }
    a {
      line-height: 1.38;
      text-decoration: underline;
      @include vw(font-size, 16);
    }
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 80);
      p {
        @include vwMobile(font-size, 16);
        @include vwMobile(line-height, 22);
      }
      a {
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
  }

  .validated {
    border-color: $color-positive;
  }
  .invalidated {
    border-color: $red;
  }
  .util-input {
    width: 100%;
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      @include vwMobile(height, 65);
      @include vwMobile(margin-bottom, 40);
    }

    div.first {
      border-style: none;
      margin: auto 0;
      background-image: url('../../../assets/images/icons/no-border/Icon-Email.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include vw(width, 25);
      @include vw(height, 25);
      @include vw(margin-right, 25);
      @include vw(margin-left, 25);
      @include breakpoint(mobile) {
        @include vwMobile(width, 25);
        @include vwMobile(height, 25);
        @include vwMobile(margin-right, 25);
        @include vwMobile(margin-left, 25);
      }
    }
    button {
      display: flex;
      align-items: center;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      outline: none;
      div.last {
        border-style: none;
        margin: auto 0;
        background-image: url('../../../assets/images/icons/no-border/Icon-Send.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(width, 25);
        @include vw(height, 25);
        @include vw(margin-right, 25);
        @include vw(margin-left, 25);
        @include breakpoint(mobile) {
          @include vwMobile(width, 25);
          @include vwMobile(height, 25);
          @include vwMobile(margin-right, 25);
        }
        // &:hover {
        //   background-image: url('../../../assets/images/icons/no-border/yellow/Icon-Send-Yellow.svg');
        // }
      }
      div.validated {
        background-image: url('../../../assets/images/icons/no-border/Icon-Validate-Green.svg');
      }
      div.invalidated {
        background-image: url('../../../assets/images/icons/no-border/Icon-Close-Red.svg');
      }
    }
  }
}
