.mobilesubmenucontainer {
  @include vwMobile(padding-top, 25);
  @include vwMobile(padding-bottom, 20);
  display: none;
  &:hover {
    display: block;
  }
  width: 100%;

  .mobilesubmenu {
    .submenublock {
      a {
        &.submenuheader {
          .header {
            display: inline-block;
            @include vwMobile(margin-bottom, 30);
            @include vwMobile(font-size, 18);
            color: $white;

            &.current,
            &:hover,
            &:focus,
            &:active {
              @extend .util-navbar-link-secondary;
              display: inline-block;
              cursor: pointer;
            }
          }

          &:focus + .submenucontent {
            display: block;
          }

          &:focus .submenu-open {
            background-image: url('../../../../assets/images/icons-navbar/icon-closemenu.svg');
          }

          .submenu-open {
            display: inline-block;
            background-image: url('../../../../assets/images/icons-navbar/arrow-down.svg');
            content: '';
            @extend .navbarimage;
            @include vwMobile(width, 10);
            @include vwMobile(height, 10);
            @include vwMobile(margin-left, 30);
          }
        }
      }

      .submenucontent {
        // display: none;
        a,
        .confirm-link {
          // @extend .paragraph_base;
          color: $white;
          @include vwMobile(padding-bottom, 15);
          @include vwMobile(font-size, 16);

          &.current,
          &:hover,
          &:focus,
          &:active {
            @extend .util-navbar-link-secondary;
            display: block;
            cursor: pointer;
          }
          &:before {
            display: inline-block;
            content: '+';
            @include vwMobile(margin-right, 15);
            @include vwMobile(margin-left, 20);
          }
        }
        &:hover {
          display: block;
        }
      }

      .submenuheader:focus + .submenucontent {
        display: block;
      }
    }
  }
}
