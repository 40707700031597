.patch-container {
  display: flex;
  height: 100vh;
  .patch-left-section {
    width: 75%;
    min-height: 100vh;
    overflow-y: scroll;
    scrollbar-color: $gray-scroll $almostBlack;
    scrollbar-width: thin;
    @include breakpoint(mobile) {
      @include vwMobile(height, 1080);
    }
    &::-webkit-scrollbar {
      width: 7.5px;
    }
    &::-webkit-scrollbar-track {
      background: $almostBlack;
    }
    &::-webkit-scrollbar-thumb {
      border-right: 1px solid $almostBlack;
      background: $gray-scroll;
    }
    @include vw(padding-top, 47);
    @include vw(padding-left, 55);
    @include vw(padding-right, 71);
    h1 {
      margin-bottom: 0;
      font-weight: bold;
      line-height: 1.4;
      @include vw(font-size, 45);
    }
    .patch-section-dec {
      * {
        @include vw(margin-top, 10);
        @include vw(font-size, 26);
        color: $gray-dark;
        line-height: 1.54;
      }
    }
    .patch-section-date {
      @include vw(font-size, 18);
      @include vw(margin-top, 40);
      @include vw(margin-bottom, 25);
      color: $yellowIg;
      line-height: 1.44;
      text-transform: uppercase;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 10);
      }
    }
    .patch-section-content {
      border-top: 1px solid $gray-patch;
      h1,
      h2,
      h3 {
        @include vw(margin-top, 50);
        color: $blue-patch;
        font-weight: bold;
        line-height: 1.2;
        @include vw(font-size, 25);
        @include vw(margin-bottom, 30);
      }
      h3 {
        @include vw(font-size, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 12);
        }
      }
      h1 {
        @include vw(font-size, 30);
        margin-bottom: 0;
      }
      li,
      p {
        color: $gray-psw;
        line-height: 2;
      }
      img {
        width: 100%;
      }
      ul {
        padding-left: 0;
        li {
          @include vw(padding-left, 0);
          @include vw(margin-bottom, 8);
          @include vw(margin-left, 28);
          @include vw(font-size, 20);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 18);
          }
          list-style-type: none;
          &::before {
            display: inline-block;
            color: $gray-psw;
            content: '+';
            font-weight: normal;
            @include vw(width, 20);
            @include vw(margin-left, -33);
            @include vw(margin-right, 20);
            @include breakpoint(mobile) {
              @include vwMobile(width, 20);
              @include vwMobile(margin-left, -33);
              @include vwMobile(margin-right, 10);
            }
          }
        }
      }
    }
  }
  .patch-right-section {
    width: 25%;
    @include vw(margin-left, 20);
    min-height: 100vh;
    border-left: 1px solid $gray-patch-nav;
    background-color: $blueDark;
    overflow-y: scroll;
    @include breakpoint(mobile) {
      width: 30%;
    }
    .releases {
      @include vw(font-size, 20);
      line-height: 1.4;
      text-transform: uppercase;
      @include vw(margin-top, 38);
      @include vw(margin-left, 40);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    a {
      display: block;
      border-bottom: 1px solid $gray-medium;
      color: $gray-medium;
      @include vw(margin-top, 20);
      @include vw(margin-left, 40);
      @include vw(margin-right, 40);
      @include vw(height, 45);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 10);
        @include vwMobile(font-size, 16);
        height: auto;
        line-height: 2;
      }
      &:hover {
        border-color: $yellowIg;
        color: $yellowIg;
      }
    }
    .selected {
      border-color: $yellowIg;
      color: $yellowIg;
    }
    a:first-of-type {
      @include vw(margin-top, 25);
    }
  }
}
