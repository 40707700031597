.referral-block-faq-bg {
  position: relative;
  background-image: url('../../../../assets/images/recruit-a-friend/Image\ 48.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include vw(height, 1162);
  @include vw(background-position-y, -250);
  @include vw(top, 0);
}
.referral-block-faq {
  @extend .util-main-padding;
  @include vw(margin-top, -1162);
  @include vw(border-top-width, 1);
  position: relative;
  border-top-style: solid;
  border-top-color: $gray-psw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include vw(top, 0);
  @include vw(margin-bottom, 0);
  @include vw(padding-bottom, 100);
  @include breakpoint(mobile) {
    @include vwMobile(margin-bottom, -30);
  }

  h2 {
    @include vw(margin-top, 120);
    @include vw(margin-bottom, 49);
    text-align: center;
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 115);
      @include vwMobile(margin-bottom, 48);
      @include vwMobile(font-size, 35);
      line-height: 1.4;
      text-align: left;
    }
  }
  h3 {
    margin: 0;
    @include vw(font-size, 35);
    @include vw(margin-bottom, 40);
  }
}
