.registration-container-bg {
  @include vw(height, 1080);
  width: 100%;
  background-image: linear-gradient(to top, #0c1315, rgba(8, 12, 13, 0.64) 34%, rgba(0, 0, 0, 0)),
    url('../../../../assets/images/news/Image\ 13.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.registration-container {
  @include vw(margin-top, -1080);
  @include vw(height, 2000);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include breakpoint(mobile) {
    @include vwMobile(height, 2200);
    justify-content: space-between;
    @include vwMobile(margin-bottom, 50);
  }
  .reg-main-box {
    @include vw(width, 1000);
    border: 0.1px solid $gray-oblique;
    background-color: $blueDeep;
    @include vw(padding, 35);
    @include breakpoint(mobile) {
      width: 100%;
      border: 0;
      @include vwMobile(padding, 0);
    }
    .reg-square-border {
      width: 100%;
      height: 100%;

      p {
        &.redirectNotice {
          line-height: 1.4;
          word-break: break-word;
          @include vw(font-size, 36);
          @include vwAll(margin, 30, 0, 30, 0);

          @include breakpoint(mobile) {
            @include vwMobile(font-size, 36);
            @include vwAllMobile(margin, 600, 2, 30, 2);
          }
        }
      }
      .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          background-image: url('../../../../assets/images/logoRegister/Image\ 14@3x.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          @include vw(height, 141);
          @include vw(width, 192);
          @include breakpoint(mobile) {
            @include vwMobile(height, 77);
            @include vwMobile(width, 105);
            @include vwMobile(margin-top, 34);
          }
        }
        p {
          color: $yellowMild;
          line-height: 1.38;
          @include vw(font-size, 26);
          @include vw(width, 479);
          @include vw(margin-left, 34);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 18);
            line-height: 1.44;
            @include vwMobile(width, 278);
          }
        }
        @include vw(margin-bottom, 36);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 18);
          line-height: 1.44;
          @include vwMobile(width, 479);
          @include vwMobile(padding-top, 65);
        }
      }
      .reg-title {
        @include vw(height, 150);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.1px solid $gray-oblique;
        background-color: $blueLight;
        @include breakpoint(mobile) {
          @include vwMobile(height, 150);
          border-right: 0;
          border-left: 0;
          @include vwMobile(margin-top, 40);
          @include vwMobile(margin-bottom, 40);
        }
        h1 {
          @include vw(font-size, 50);
          margin: 0;
          line-height: 1.4;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 35);
          }
        }
      }
      @include buildCorners(30, 0);
      @include breakpoint(mobile) {
        border: 0;
      }
      form {
        width: 100%;
        height: 100%;
        &.registrationForm {
          color: $yellowIg;
          @include vw(margin-left, 0);
          @include vw(margin-right, 0);
          @include vw(padding-left, 70);
          @include vw(padding-right, 70);
          @include vw(margin-top, 60);
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 0);
            @include vwMobile(padding-left, 20);
            @include vwMobile(padding-right, 20);
          }
          .row {
            display: flex;
            height: auto;
            justify-content: space-between;
            @include vw(margin-bottom, 20);
            @include breakpoint(mobile) {
              flex-direction: column;
              @include vwMobile(margin-bottom, 0);
            }
            a {
              color: $yellowMild;
            }
          }
          .mb-0 {
            margin-bottom: 0;
          }
          .col {
            .label {
              @include vw(margin-bottom, 20);
              @include vw(font-size, 20);
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
                line-height: 2;
              }
              &::after {
                content: '*';
              }
              &.none {
                &::after {
                  content: '';
                }
              }
            }
            .label-tooltip {
              @include vw(margin-bottom, 0);
            }
            input:invalid {
              box-shadow: none;
              outline: none;
            }

            input {
              width: 100%;
              border: solid 1px $gray-home;
              background-color: $almostBlack;
              color: $gray;
              line-height: 1.38;
              outline: none;
              @include vw(font-size, 20);
              @include vw(height, 60);
              @include vw(border-left, 0);
              @include vw(border-right, 0);
              @include breakpoint(mobile) {
                @include vwMobile(height, 65);
                @include vwMobile(font-size, 16);
              }
            }

            button[type='submit'] {
              @include vw(min-width, 217);
              @include vw(height, 67);
              display: flex;
              align-items: center;
              justify-content: center;
              border-left: solid 1px $gray-home;
              background-color: $green;
              color: $white;
              cursor: pointer;
              float: right;
              line-height: 1.38;
              text-transform: uppercase;
              @include vw(font-size, 18);
              @include vw(border, 0);
              @include breakpoint(mobile) {
                width: 100%;
                @include vwMobile(height, 66);
                @include vwMobile(font-size, 16);
                @include vwMobile(margin-top, 30);
              }
              &.formButtonFailure {
                @include vw(min-width, 273);
                background-color: $red;
                @include breakpoint(mobile) {
                  width: 100%;
                }
              }
              &:disabled {
                cursor: not-allowed;
              }

              .iconSubmitWraper {
                display: flex;
                height: inherit;
                align-items: center;
                justify-content: center;
                border: 0;
                @include vw(width, 20);
                @include breakpoint(mobile) {
                  @include vwMobile(width, 20);
                }
              }
              .submitText {
                @include vw(margin-left, 10);
                @include breakpoint(mobile) {
                  @include vwMobile(margin-left, 13);
                }
              }
            }
          }
          .error {
            @include vw(font-size, 16);
            @include vw(margin-top, 0);
            @include vw(margin-bottom, 5);
            display: flex;
            flex-direction: column;
            @include breakpoint(mobile) {
              @include vwMobile(margin-top, 10);
              @include vwMobile(font-size, 14);
            }
          }
          .col-6 {
            width: 48%;
            @include breakpoint(mobile) {
              width: 100%;
            }
          }
          .col-12 {
            width: 100%;
          }
          .input-container {
            display: -ms-flexbox; /* IE10 */
            display: flex;
            width: 100%;
            flex-direction: column;
            @include vw(margin-bottom, 0);
            @include vw(margin-top, 0);
            @include breakpoint(mobile) {
              @include vwMobile(margin-bottom, 25);
              @include vwMobile(margin-top, 15);
            }
          }
          .input-container__row {
            display: flex;
            width: 100%;
            flex-direction: column;
            @include vw(margin-bottom, 20);
            @include vw(margin-top, 30);
          }
          .input-container__row {
            display: flex;
            flex-direction: row;
          }
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 1px $gray-home;
            background: $almostBlack;
            color: $white;
            text-align: center;
            @include vw(border-right, 0);
            @include vw(min-width, 50);
          }
          .checkBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include vw(margin-top, 34);
            @include breakpoint(mobile) {
              @include vwMobile(margin-top, 60);
            }

            label {
              @include vw(margin-bottom, 0);
              @include vw(font-size, 20);
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 20);
                @include vwMobile(width, 270);
                line-height: 2;
              }
            }
            .checkboxcontainer {
              color: $white;

              input[type='checkbox'] {
                box-sizing: border-box;
                border-style: solid;
                border-color: $white;
                -webkit-appearance: none;
                -moz-appearance: none;
                -o-appearance: none;
                appearance: none;
                background-color: $blueDeep;
                cursor: pointer;
                outline: none;
                @include vw(width, 20);
                @include vw(height, 20);
                @include vwAll(margin, 0, 0, 0, 0);
                @include vw(border-width, 1);
                @include breakpoint(mobile) {
                  @include vwMobile(width, 25);
                  @include vwMobile(height, 25);
                }
              }
              input[type='checkbox']:checked {
                background-clip: content-box;
                background-color: $yellowIg;
                @include vwAll(padding, 5, 5, 5, 5);
                @include breakpoint(mobile) {
                  @include vwMobileAll(padding, 7, 7, 7, 7);
                }
              }
            }
          }
          .icon-type {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include vw(width, 20);
            @include vw(height, 20);
            @include breakpoint(mobile) {
              @include vwMobile(width, 18);
              @include vwMobile(height, 18);
            }
          }
          .icon-profile {
            @extend .icon-type;
            background-image: url('../../../../assets/images/icons/no-border/Icon-Profile.svg');
          }
          .icon-email {
            @extend .icon-type;
            background-image: url('../../../../assets/images/icons/no-border/Icon-Email.svg');
          }
          .icon-code {
            @extend .icon-type;
            background-image: url('../../../../assets/images/icons/no-border/Icon-Code.svg');
          }
          .icon-psw {
            @extend .icon-type;
            background-image: url('../../../../assets/images/icons/no-border/Icon-Password.svg');
          }
          .icon-loading {
            @extend .icon-type;
            background-image: url('../../../../assets/images/icons/no-border/Icon-Loading.svg');
          }
          .icon-validate {
            @extend .icon-type;
            background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-Green.svg');
          }
          .icon-validate-white {
            @extend .icon-type;
            background-image: url('../../../../assets/images/icons/no-border/Icon-Validate.svg');
          }
          .icon-fail {
            @extend .icon-type;
            background-image: url('../../../../assets/images/icons/no-border/Icon-Close-Red.svg');
          }
          .iconWapper {
            display: flex;
            height: inherit;
            align-items: center;
            justify-content: center;
            border: 1px solid $gray-home;
            border-right: 0;
            @include vw(width, 75);
            @include breakpoint(mobile) {
              @include vwMobile(width, 65);
            }
          }
          .iconWapperRight {
            border-right: 1px solid $gray-home;
            border-left: 0;
          }

          .policy {
            height: auto;
            text-align: right;
            @include vw(margin-bottom, 50);
            @include vw(margin-top, 75);
            @include breakpoint(mobile) {
              @include vwMobile(margin-top, 80);
            }
            a {
              color: $yellowMild;
              @include vw(font-size, 16);
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
                line-height: 1.38;
              }
              &:hover {
                color: $white;
              }
            }
          }
          p {
            @include vw(font-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }
        }
      }

      div {
        &.underlineBox {
          display: flex;
          width: 100%;
          border-width: 0.1px;
          border-color: $gray-dark;
          border-top-style: solid;
          border-bottom-style: solid;
          @include vw(padding-bottom, 40);
          @include vw(padding-top, 40);
          @include vw(margin-bottom, 60);
          @include breakpoint(mobile) {
            @include vwMobile(margin-top, 40);
            @include vwMobile(margin-bottom, 15);
          }
          div {
            &.successIcon {
              background-image: url('../../../../assets/images/icons/validate-border-green.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              @include vw(width, 40);
              @include vw(height, 40);
              @include vw(margin-right, 22);
              @include breakpoint(mobile) {
                @include vwMobile(width, 70);
                @include vwMobile(height, 70);
                @include vwMobile(margin-right, 15);
              }
            }

            &.failureIcon {
              background-image: url('../../../../assets/images/icons/error-border-red.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              @include vw(width, 40);
              @include vw(height, 40);
              @include vw(margin-right, 22);
              @include breakpoint(mobile) {
                @include vwMobile(width, 70);
                @include vwMobile(height, 70);
                @include vwMobile(margin-right, 15);
              }
            }
          }
          div {
            &.successText {
              margin: 0 0;
              color: $green;
              font-weight: bold;
              @include vw(font-size, 20);
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 20);
              }
            }
            &.failureText {
              margin: 0 0;
              color: $red;
              font-weight: bold;
              line-height: 2;
              @include vw(font-size, 20);
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 20);
              }
            }
          }
          p {
            @include vw(font-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              line-height: 1.38;
            }
          }
        }
      }
      .g-recaptcha {
        @include vw(margin-top, 0);
        @include vw(margin-bottom, 50);

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 40);
          @include vwMobile(margin-bottom, 40);
        }
        // @include vw(height, 50);
        .textRecaptcha {
          color: $yellowMild;
          text-align: right;
          @include vw(font-size, 16);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
      }
    }
  }
  .text-end {
    @include vw(margin-top, 34);
    p {
      @include vw(font-size, 20);
      line-height: 1.4;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    a {
      color: $yellowMild;
      text-decoration: underline;
    }
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 34);
    }
  }
}
.tooltip-container {
  display: flex;
  justify-content: space-between;
  @include breakpoint(mobile) {
    @include vwMobile(margin-bottom, 20);
  }
  .input-title {
    display: inline-block;
    @include vw(font-size, 20);
    color: $yellowMild;
    line-height: 2;
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 20);
    }
  }
}
.tooltip-box {
  @include vw(width, 25);
  @include vw(height, 25);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $white;
  cursor: pointer;
  opacity: 0.8;
  span {
    color: $white;
    opacity: 0.8;
  }
  @include breakpoint(mobile) {
    display: none;
  }
}
.tooltip-msg {
  @include vw(padding, 10);
  p {
    @include vw(font-size, 16);
    color: $almostBlack;
    line-height: 1.38;
  }
}
.strenght-psw-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .strenght-psw-bars {
    display: flex;
    .bar {
      @include vw(height, 2);
      @include vw(width, 40);
      @include vw(margin-right, 10);
      background-color: $gray-psw;
    }
    .weak {
      background-color: $red;
    }
    .normal {
      background-color: $yellowMild;
    }
    .strong {
      background-color: $green;
    }
  }
  .strenght-psw-label {
    @include vw(font-size, 16);
    color: $gray-dark;
    text-transform: capitalize;
    @include vw(line-height, 16);
  }
}
