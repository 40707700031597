.tutorial-categories {
  $item-width: 500;
  $item-height: 273;
  $item-over: 20;

  @include vw(margin-top, 100);
  @include vw(margin-bottom, 60);
  .item {
    position: relative;
    border-style: solid;
    border-color: $whiteLessTransparent;
    background-color: $blueLight;
    text-align: center;

    // @include vw(width, $item-width);
    @include vw(height, $item-height);
    @include vw(border-width, 1);
    @include vw(margin-bottom, 60);

    @include vw(padding, 0);
    transition: $corners-transition;
    &:hover {
      @include vw(padding, 15);
      @include breakpoint(mobile) {
        @include vwMobile(padding, 15);
      }
    }
    @include breakpoint(mobile) {
      @include vwMobile(height, $item-height);
    }
    img {
      @include vw(margin-top, 22);
    }
    .corners {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      @include buildCorners(30, 0);
      @include breakpoint(mobile) {
        @include buildCornersMobile(30, 0);
        @include vwMobile(margin-bottom, 40);
        @include vwMobile(margin-bottom, 40);
      }
    }
    .square {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    &:hover {
      .icon {
        // this filter is generated with this script : https://codepen.io/sosuke/pen/Pjoqqp
        filter: $white-to-yellow-filter;
      }
      h2 {
        color: $yellowIg;
      }
    }
  }
  .icon {
    @include vw(width, 120);
    @include breakpoint(mobile) {
      @include vwMobile(width, 130);
    }
  }
}
