.account-dacs-container {
  .account-notice {
    display: flex;
    align-items: center;
    background-color: $green;
    @include vw(height, 65);
    @include vw(margin-top, 39);
    animation-duration: 4s;
    animation-name: custom;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    position: sticky;
    @include vw(top, 160);
    z-index: 10;
    @include breakpoint(mobile) {
      @include vwMobile(height, 65);
      @include vwMobile(top, 65);
      @include vwMobile(margin-top, 39);
    }
    p {
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    div {
      background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-white-border.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include vw(height, 40);
      @include vw(width, 40);
      @include vw(margin-left, 15);
      @include vw(margin-right, 17);
      @include breakpoint(mobile) {
        @include vwMobile(height, 25);
        @include vwMobile(width, 25);
        @include vwMobile(margin-left, 15);
        @include vwMobile(margin-right, 17);
      }
    }
  }
  @keyframes custom {
    0% {
      opacity: 0;
    }
    8% {
      opacity: 0;
    }
    12% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    28% {
      opacity: 1;
    }
    48% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .error {
    background-color: $red;
    @include breakpoint(mobile) {
      @include vwMobile(padding, 5);
      height: auto;
    }
    div {
      background-image: url('../../../../assets/images/icons/Icon-Close.svg');
      @include vw(background-size, 20);
      @include breakpoint(mobile) {
        @include vwMobile(background-size, 18);
      }
    }
  }
  .account-dacs-initial-content {
    display: flex;
    justify-content: space-between;
    @include breakpoint(mobile) {
      flex-direction: column;
    }
    .account-dacs-text-container {
      @include vw(width, 558);
      @include breakpoint(mobile) {
        width: 100%;
      }
      .account-dacs-title {
        @include vw(margin-bottom, 10);
        @include breakpoint(mobile) {
          @include vwMobile(margin-bottom, 10);
          @include vwMobile(margin-top, 30);
        }
        * {
          @include vw(font-size, 35);
          color: $white;
          font-weight: bold;
          text-transform: uppercase;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 25);
          }
        }
      }
      .account-dacs-desc {
        @include vw(margin-bottom, 40);
        @include breakpoint(mobile) {
          @include vwMobile(margin-bottom, 30);
        }
        * {
          @include vw(font-size, 20);
          color: #a8a8a8;
          line-height: 2;
          @include vw(margin-bottom, 20);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
      }
    }
    .account-dacs-image-container {
      @include vw(width, 320);
      @include vw(height, 320);
      background-color: $blueDark;
      border: 1px solid #454d50;
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(height, 327);
      }
      .box-corners {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include buildCorners(20, 0);
        @include breakpoint(mobile) {
          @include buildCornersMobile(20, 0);
        }
        .account-dacs-image {
          @include vw(width, 223);
          @include vw(height, 221);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          @include breakpoint(mobile) {
            @include vwMobile(width, 223);
            @include vwMobile(height, 221);
          }
        }
        .account-dacs-subtext {
          * {
            color: #a8a8a8;
            line-height: 2;
          }
          i {
            color: $yellowIg;
            text-transform: uppercase;
            font-style: normal;
          }
        }
      }
    }
  }
  .account-dacs-offline {
    .account-dacs-title {
      @include vw(margin-bottom, 10);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 10);
        @include vwMobile(margin-top, 30);
      }
      * {
        @include vw(font-size, 35);
        color: $white;
        font-weight: bold;
        text-transform: uppercase;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 25);
        }
      }
    }
    .account-dacs-desc {
      @include vw(margin-bottom, 40);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 30);
      }
      * {
        @include vw(font-size, 20);
        color: #a8a8a8;
        line-height: 2;
        @include vw(margin-bottom, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
    .account-dacs-image {
      @include vw(width, 223);
      @include vw(height, 221);
      margin-left: auto;
      margin-right: auto;
      @include vw(margin-top, 60);
      @include vw(margin-bottom, 40);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include breakpoint(mobile) {
        @include vwMobile(width, 223);
        @include vwMobile(height, 221);
      }
    }
    .account-dacs-main-msg {
      * {
        @include vw(font-size, 20);
        color: $white;
        line-height: 2;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        @include vw(margin-bottom, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
  }
  .account-convert-dacs,
  .account-tranfer-dacs {
    background-color: $blueDark;
    border: 1px solid $white02;
    @include vw(margin-top, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 40);
    }
    .section-corners {
      height: 100%;
      width: 100%;
      @include buildCorners(20, 0);
      @include breakpoint(mobile) {
        @include buildCornersMobile(20, 0);
      }
      .section-padding {
        @include vwAll(padding, 7, 13, 13, 13);
        @include breakpoint(mobile) {
          @include vwMobileAll(padding, 20, 20, 20, 20);
        }
      }
      .section-psw-title {
        @include vw(margin-bottom, 10);
        @include breakpoint(mobile) {
          @include vwMobile(margin-bottom, 10);
        }
        * {
          @include vw(font-size, 26);
          color: $white;
          font-weight: bold;
          text-transform: uppercase;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 18);
          }
        }
      }
      .section-psw-desc {
        @include vw(margin-bottom, 25);
        * {
          @include vw(font-size, 20);
          color: #a8a8a8;
          line-height: 2;
          @include vw(margin-bottom, 20);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
        a {
          color: $yellowMild;
          text-decoration: underline;
        }
        @include breakpoint(mobile) {
          @include vwMobile(margin-bottom, 25);
        }
      }
      .theme-button-spec-positive {
        @include vw(width, 263);
        @include vw(height, 65);
        @include breakpoint(mobile) {
          @include vwMobile(height, 65);
          @include vwMobile(width, 250);
          @include vwMobile(margin-top, 20);
          @include vwMobile(margin-bottom, 20);
        }
      }
      .theme-button-container {
        div {
          background-image: url('../../../../assets/images/icons-buttons/Icon-Plus.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          @include vw(width, 14);
          @include vw(height, 15);
          @include breakpoint(mobile) {
            @include vwMobile(width, 15);
            @include vwMobile(height, 15);
            @include vwMobile(margin-right, 10);
          }
        }
        span {
          @include vw(font-size, 13);
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          font-style: normal;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 13);
          }
        }
      }
      button:hover {
        background-color: $green;
        .theme-button-container div {
          background-image: url('../../../../assets/images/icons-buttons/Icon-Plus.svg');
          filter: invert(100%);
        }
        span {
          color: $black !important;
        }
      }
      .disclamer {
        @include vw(margin-top, 20);
        * {
          color: #a8a8a8;
          @include vw(font-size, 16);
          line-height: 2;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            @include vwMobile(margin-top, 0);
            line-height: 1.25;
          }
        }
        a {
          color: $yellowIg;
          text-decoration: underline;
        }
      }
      form {
        @include breakpoint(mobile) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        input:invalid {
          box-shadow: none;
          outline: none;
        }
        .input-container {
          @include vw(width, 370);
          @include vw(margin-bottom, 20);
          @include breakpoint(mobile) {
            width: 100%;
            @include vwMobile(margin-bottom, 20);
          }
          .dac {
            background-image: url('../../../../assets/images/icons-buttons/Icon-Dac.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 28%;
          }
          label {
            width: auto;
            color: $yellowIg;
            line-height: 2;
            text-transform: capitalize;
            @include vw(font-size, 20);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 20);
              width: 100%;
              line-height: 1;
            }
          }
          .input-container__row {
            display: flex;
            width: 100%;
            @include vw(margin-top, 15);
            @include breakpoint(mobile) {
              @include vwMobile(margin-top, 20);
            }
            input {
              padding: 0;
              @include vw(margin-top, 0);
              width: 13vw;
              border: solid 1px $gray-home;
              background-color: transparent;
              color: $gray;
              line-height: 1.38;
              outline: none;
              color: $white !important;
              @include vw(font-size, 16);
              @include vw(height, 65);
              @include vw(border-left, 0);
              @include breakpoint(mobile) {
                width: 100%;
                @include vwMobile(height, 65);
                @include vwMobile(font-size, 16);
              }
            }
            .iconWrapper {
              @include vw(width, 65);
              @include vw(height, 65);
              border: solid 1px $gray-home;
              border-right: 0;
              @include breakpoint(mobile) {
                @include vwMobile(height, 65);
                @include vwMobile(width, 65);
              }
            }
          }
        }
      }
    }
  }
  .account-tranfer-dacs {
    .input-container {
      display: inline-block;
      .user {
        background-image: url('../../../../assets/images/icons/no-border/Icon-Profile.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 23%;
      }
    }
    .theme-button-spec-positive {
      .theme-button-container {
        div {
          background-image: url('../../../../assets/images/icons-buttons/Icon-Send-White.svg');
          @include vw(width, 22);
          @include vw(height, 20);
          @include breakpoint(mobile) {
            @include vwMobile(width, 18);
            @include vwMobile(height, 16);
          }
        }
      }
    }
    button:hover .theme-button-container div {
      background-image: url('../../../../assets/images/icons-buttons/Icon-Send-White.svg') !important;
      filter: invert(100%);
    }
  }
  .account-dacs-history {
    @include vw(margin-top, 80);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 60);
    }
    .account-dacs-history-title {
      @include vw(margin-bottom, 10);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 10);
      }
      * {
        @include vw(font-size, 26);
        font-weight: bold;
        text-transform: uppercase;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 26);
        }
      }
    }
    .table {
      @include breakpoint(mobile) {
        @include vwMobile(width, 430);
        overflow-x: auto;
        -ms-overflow-style: none;
      }
    }
    .row {
      position: relative;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      @include vw(height, 73);
      border-bottom: 1px solid $white02;
      align-items: center;
      @include breakpoint(mobile) {
        @include vwMobile(height, 73);
        display: flex;
        flex-wrap: nowrap;
        @include vwMobile(width, 800);
      }
      .column {
        color: #a8a8a8;
        @include vw(font-size, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
          @include vwMobile(width, 200);
        }
      }
      .yellow {
        color: $yellowIg;
      }
    }
    ul {
      display: flex;
      justify-content: center;
      @include vw(margin-top, 40);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 40);
        @include vwMobile(margin-bottom, 40);
      }
      li {
        @include vw(margin-right, 20);
        list-style: none;
        cursor: pointer;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
