.referral-block-how {
  @extend .util-main-padding;
  position: relative;
  @include vw(top, 0);
  @include vw('padding-top', 96);
  @include breakpoint(mobile) {
    @include vwMobile(top, 0);
  }
  h2 {
    text-align: center;
    @include vw('margin-bottom', 90);
    @include breakpoint(mobile) {
      @include vwMobile('margin-bottom', 38);
      text-align: left;
    }
  }

  .content-container {
    @include vwColumns(4, 65, true);
    @include vw('margin-bottom', 100);
    @include breakpoint(mobile) {
      display: flex;
      flex-wrap: nowrap;

      -ms-overflow-style: none; /* IE and Edge */
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none; /* Firefox */
      @include vwMobile(height, 600);
    }
    &::-webkit-scrollbar {
      @include breakpoint(mobile) {
        display: none;
      }
    }
    .content-column {
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      @include breakpoint(mobile) {
        @include vwMobile(width, 350);
        flex: 0 0 auto;
        background-color: #162024;
      }

      h4 {
        text-transform: uppercase;
        @include vw('margin-bottom', 27);
        @include breakpoint(mobile) {
          @include vwMobile('margin-top', 74);
          @include vwMobile('margin-bottom', 23);
          @include vwMobile(font-size, 22);
          line-height: 1.44;
        }
      }
      p,
      a {
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 18);
          @include vwMobile(max-width, 271);
          margin: auto;
          line-height: 1.36;
        }
      }
      a {
        text-decoration: underline;
      }
      .content-icon {
        @include vw('width', 150);
        @include vw('height', 150);
        margin: auto;
        @include vw('margin-bottom', 75);
        @include breakpoint(mobile) {
          @include vwMobile('margin-top', 150);
          @include vwMobile('width', 150);
          @include vwMobile('height', 150);
        }
      }
    }
  }

  .paragraph_disclaimer {
    margin: auto;
    text-align: center;
    @include vw('max-width', 630);
    @include breakpoint(mobile) {
      display: none;
      @include vwMobile('max-width', 332);
      @include vwMobile(font-size, 16);
    }
  }
}
