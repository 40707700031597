$height-buypage-info: 700;
$height-buypage-info-mobile: 500;

.buypage-block-landingone {
  display: none;
  @include vw(margin-top, 80);
  @include vw(margin-bottom, 80);
  @include vw(padding-left, 160);
  @include columns(1);
  @include container-align(center);
  overflow: hidden;
  height: auto;
  background-color: $blueDark;
  text-align: left;
  // @include vw(height, $height-buypage-info);
  @include breakpoint(mobile) {
    @include vwMobile(padding-right, 20);
    @include vwMobile(padding-left, 20);

    height: auto;
  }
  .content-container {
    @include vw(height, 700);
    display: inline-block;
    width: 100%;

    @include breakpoint(mobile) {
      height: auto;
    }
    .content-block {
      position: relative;
      z-index: 2;
      display: inline-block;
      @include vw(width, 800);

      @include breakpoint(mobile) {
        width: 100%;
      }
      h3 {
        @include vw(margin-top, 180);
        @include vw(margin-bottom, 50);
        position: relative;
        z-index: 2;
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 40);
          @include vwMobile(margin-bottom, 30);
          width: 100%;
        }
      }
      p {
        position: relative;
        z-index: 2;
        color: $gray;
        @include vw(width, 800);
        @include vw(line-height, 40);
        @include vw(font-size, 20);

        @include breakpoint(mobile) {
          @include vwMobile(line-height, 30);
          @include vwMobile(font-size, 15);
          width: 100%;
          @include vwMobile(margin-bottom, 50);
        }
      }
    }
    .content-image {
      position: relative;

      z-index: 1;
      display: inline-block;

      background-image: url('../../../../assets/images/buypage/buypageonewarrior.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include vw(border-left-width, 2);
      @include vw(left, 800-160);
      @include vw(bottom, 700-700/3.75);

      @include vw(width, 1180);
      @include vw(height, 700);
      @include breakpoint(mobile) {
        position: static;
        z-index: 2;
        top: 0px;
        bottom: 0px;
        width: 100%;
        background-image: url('../../../../assets/images/buypage/buypageonewarriormobile.png');
        @include vwMobile(height, 217);
        @include vwMobile(margin-top, 20);
      }
    }
  }
}
