.base-corner {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-style: solid;
  -webkit-border-image-slice: 60%;
  border-image-slice: 60%;
  border-color: transparent;
}

@mixin buildCorners($border-width, $margin) {
  // Size of the square for the border
  @extend .base-corner;

  border-image-source: url('../../../assets/images/utils/border-image-corners.svg');

  @include vw(border-width, $border-width);
  @include vw(margin, $margin);
}

@mixin buildCornersMobile($border-width, $margin) {
  // Size of the square for the border
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-style: solid;
  -webkit-border-image-slice: 60%;
  border-image-slice: 60%;
  border-image-source: url('../../../assets/images/utils/border-image-corners.svg');
  @include vwMobile(border-width, $border-width);
  @include vwMobile(margin, $margin);
}
@mixin buildCornersMobileBlack($border-width, $margin) {
  // Size of the square for the border
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-style: solid;
  -webkit-border-image-slice: 60%;
  border-image-slice: 60%;
  border-image-source: url('../../../assets/images/utils/border-image-corners-black.svg');
  @include vwMobile(border-width, $border-width);
  @include vwMobile(margin, $margin);
}
@mixin buildCornersMobileYellow($border-width, $margin) {
  // Size of the square for the border
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-style: solid;
  -webkit-border-image-slice: 60%;
  border-image-slice: 60%;
  border-image-source: url('../../../assets/images/utils/border-image-corners-yellow.svg');
  @include vwMobile(border-width, $border-width);
  @include vwMobile(margin, $margin);
}

/* prettier-ignore */
@mixin buildCornersImage($border-width, $margin, $svg) {
  // Size of the square for the border
  @extend .base-corner;
  border-image-source: url('../../../assets/images/utils/'+ $svg +'.svg');
  @include vw(border-width, $border-width);
  @include vw(margin, $margin);
}

@mixin buildCornersBlack($border-width, $margin) {
  // Size of the square for the border
  @extend .base-corner;

  border-image-source: url('../../../assets/images/utils/border-image-corners-black.svg');

  @include vw(border-width, $border-width);
  @include vw(margin, $margin);
}

@mixin buildCornersWhite($border-width, $margin) {
  // Size of the square for the border
  @extend .base-corner;
  border-image-source: url('../../../assets/images/utils/border-image-corners-white.svg');
  @include vw(border-width, $border-width);
  @include vw(margin, $margin);
}

@mixin buildCornersYellow($border-width, $margin) {
  // Size of the square for the border
  @extend .base-corner;
  border-image-source: url('../../../assets/images/utils/border-image-corners-yellow.svg');
  @include vw(border-width, $border-width);
  @include vw(margin, $margin);
}

@mixin buildCornersPlaceholder($border-width, $margin) {
  // Size of the square for the border
  @extend .base-corner;
  border-image-source: url('../../../assets/images/utils/border-image-corners-placeholder.svg');
  @include vw(border-width, $border-width);
  @include vw(margin, $margin);
}
