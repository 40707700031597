.buyPage-notice {
  @include vw(width, 600);
  height: auto;
  @include breakpoint(mobile) {
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .corners {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include vwAll(padding, 50, 25, 13, 25);
    @include breakpoint(mobile) {
      @include vwMobileAll(padding, 50, 25, 30, 25);
    }
    p {
      text-align: center;
    }
    .reminder-title {
      @include vw(margin-bottom, 17);
      * {
        @include vw(font-size, 22);
        font-weight: bold;
        color: $white;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 18);
        }
      }
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 17);
      }
    }
    .reminder-desc {
      @include vw(margin-bottom, 40);
      * {
        @include vw(font-size, 20);
        line-height: 1.5;
        color: $gray-dark;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 40);
      }
    }
    .theme-button {
      @include vw(min-width, 300);
      background-color: #0027ff;
      background-image: linear-gradient(to bottom, #0027ff, #221772);
      border: none;
      @include breakpoint(mobile) {
        @include vwMobile(height, 65);
        width: 100%;
      }
      &:hover .theme-button-container {
        border-image-source: url('../../../../assets/images/utils/border-image-corners-white.svg');
        span {
          color: $white;
        }
      }
      .theme-button-container {
        div {
          background-image: url('../../../../assets/images/icons-buttons/Icon-Steam.svg');
          @include vw(height, 25);
          @include vw(width, 25);
          background-size: contain;
          @include breakpoint(mobile) {
            @include vwMobile(height, 25);
            @include vwMobile(width, 25);
            @include vwMobile(margin-right, 15);
            background-size: contain;
          }
        }
        span {
          color: $white;
          @include vw(font-size, 13);
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          font-style: normal;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 13);
          }
        }
      }
    }
  }
  .icon-close-reminder {
    position: absolute;
    border: 1px solid $white;
    background-clip: content-box;
    background-image: url('../../../../assets/images/icons/Icon-Close.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    cursor: pointer;
    @include vw(top, 30);
    @include vw(right, 30);
    @include vw(width, 30);
    @include vw(height, 30);
    @include vw(padding, 2);
    @include breakpoint(mobile) {
      @include vwMobile(width, 40);
      @include vwMobile(height, 40);
    }
  }
}
.popup-overlay-reminder {
  position: fixed;
  @include vw(top, 0);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0% 0%;
}
