@import './homepagesections/homepagestart.styles';
@import './homepagesections/homepagemiddle.styles';

div {
  &.HomeLandingOne {
    @include breakpoint(from-phablet) {
      // @include vw(height, 9359);
      // @include vw(height, 6690);
    }

    @include breakpoint(mobile) {
      @include vwMobile(height, 4060);
    }

    overflow: hidden;
    .homePageParent {
      position: relative;
      @include vw(margin-top, 100);
      button.joinBeta {
        &:hover {
          background-color: $color-positive;
        }
        .theme-button-container {
          div {
            background-image: url('../../../../assets/images/icons/no-border/Icon-Game.svg');
          }
        }

        &:hover .theme-button-container div {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Game-Green.svg');
        }
        &:hover .theme-button-container span {
          color: #000;
        }
        @include breakpoint(from-phablet) {
          // @include vw(margin-right, 155);
          @include vw(margin-left, 40);
        }
      }
    }
    div.opacity {
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(to bottom, rgba(12, 19, 21, 0) 69%, $almostBlack);
      .background-video {
        position: relative;
        z-index: -100;
        width: 100%;
        @include breakpoint(mobile) {
          opacity: 0.4;
        }
      }
    }
  }
}
