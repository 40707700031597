.confirm-leave-container {
  position: relative;
  display: flex;
  @include vw(width, 600);
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: $almostBlack;
  border: 0.1px solid $grayButton;
  @include vw(min-height, 500);
  @include breakpoint(mobile) {
    @include vwMobile(min-height, 581);
    width: 100%;
  }
  a {
    margin: 0 !important;
    &::before {
      content: none !important;
    }
  }
  .corners {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    @include buildCorners(30, 0);
    @include breakpoint(mobile) {
      @include buildCorners(80, 0);
    }
  }
  .confirm-leave-icon {
    @include vw(height, 100);
    @include vw(width, 100);
    @include vw(margin-top, 60);
    background-image: url('../../../../assets/images/icons/icon-out.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include breakpoint(mobile) {
      @include vwMobile(height, 80);
      @include vwMobile(width, 80);
      @include vwMobile(margin-top, 80);
    }
  }
  .confirm-leave-title {
    @include vw(margin-top, 40);
    @include vw(font-size, 22);
    z-index: 1;
    font-weight: bold;
    line-height: 1.36;
    color: $white;
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 22);
      @include vwMobile(margin-top, 40);
    }
  }
  .confirm-leave-desc {
    @include vw(margin-top, 40);
    p {
      @include vw(font-size, 20);
      color: $gray-dark;
      font-weight: normal;
      line-height: 2;
      text-align: center;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    z-index: 1;
    @include vw(padding-left, 46);
    @include vw(padding-right, 46);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 40);
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
    }
  }

  .confirm-leave-cta {
    z-index: 1;
    display: flex;
    justify-content: center;
    @include vw(margin-top, 40);
    @include breakpoint(mobile) {
      flex-direction: column-reverse;
      @include vwMobile(margin-top, 40);
      @include vwMobile(width, 200);
    }
    .proceed {
      border-color: transparent;
      background-color: $white;
      &:hover {
        background-color: $yellowIg;
      }

      .theme-button-container {
        div {
          background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
          @include vw(padding, 12);
          @include vw(height, 20);
          @include vw(width, 20);
          @include vw(background-size, 18);
          @include breakpoint(mobile) {
            @include vwMobile(padding, 12);
            @include vwMobile(height, 25);
            @include vwMobile(width, 20);
            @include vwMobile(background-size, 18);
            @include vwMobile(margin-right, 10);
          }
        }
      }

      &:hover .theme-button-container div {
        border-color: $black;
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
      }
      &:hover .theme-button-container span {
        color: $black;
      }
      @include breakpoint(mobile) {
        @include vwMobile(height, 65);
        width: 100%;
        @include vwMobile(font-size, 16);
        @include vwMobile(margin-top, 0);
      }
    }
    .cancel {
      @include vw(margin-right, 20);
      border: 0.1px solid $gray-home;
      background-color: $blueDark;
      background-image: linear-gradient(to right, $blueDark 50%, transparent 50%);

      @include breakpoint(mobile) {
        @include vwMobile(height, 65);
        width: 100%;
        @include vwMobile(font-size, 16);
        @include vwMobile(margin-top, 20);
      }
      &:hover {
        border-color: $yellowIg;
        .theme-button-container {
          @include buildCornersYellow(13, 5);
          div {
            border-color: $yellowIg;
            background-image: url('../../../../assets/images/icons/no-border/Icon-Close-Yellow.svg');
          }
          span {
            color: $yellowIg;
          }
        }
      }
      .theme-button-container {
        // height: 100%;
        background-color: $blueDark;

        font-weight: bold;
        text-transform: uppercase;
        @include vw(font-size, 16);
        span {
          color: $white;
        }
        div {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Close.svg');
          @include vw(padding, 12);
          @include vw(height, 16);
          @include vw(width, 16);
          @include vw(background-size, 16);
          @include breakpoint(mobile) {
            @include vwMobile(padding, 12);
            @include vwMobile(height, 25);
            @include vwMobile(width, 20);
            @include vwMobile(background-size, 18);
            @include vwMobile(margin-right, 10);
          }
        }
      }
    }
  }
}
