.faq-line-creator {
  overflow: hidden;
  //@include vw(max-height, 95);
  background-color: $blueDark;
  @include vw(padding-top, 35);
  @include vw(padding-bottom, 35);
  @include vw(margin-bottom, 70);

  @include breakpoint(mobile) {
    @include vwMobile(padding-top, 20);
    @include vwMobile(padding-bottom, 20);
    @include vwMobile(margin-bottom, 20);
  }
  &-container {
    overflow: hidden;
    width: 100%;

    &-label {
      display: flex;
      align-items: center;
      cursor: pointer;
      h5 {
        width: 100%;
        @include breakpoint(mobile) {
          @include vwMobile(padding-left, 20);
        }
      }
      div {
        @include vw(height, 20);
        @include vw(width, 40);
        @include vw(margin-right, 40);
        background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='20' height='20' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>")
          no-repeat;
        background-position: right 3/1920 * 100vw top 55%;
        background-repeat: no-repeat;
        cursor: pointer;
        @include vw(background-size, 20);
      }
      &:after,
      &:before {
        color: $white;
        @include vw(line-height, 30);
      }

      &:before {
        content: '\002B';
        text-align: center;
        @include vw(font-size, 30);
        @include vw(padding-left, 55);
        @include vw(padding-right, 55);
        @include breakpoint(mobile) {
          display: none;
        }
      }
    }

    &-content {
      display: none;
      max-height: 0;
      opacity: 0;
      @include vw(padding-top, 35);
      @include vw(padding-left, 40);
      @include vw(padding-right, 40);

      p {
        @include vw(font-size, 20);
        line-height: 2;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 2;
          @include vw(padding-left, 20);
        }
      }
    }

    .faq-line-creator-container-close {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }

  input {
    display: none;
  }

  input:checked {
    + .faq-line-creator-container-label {
      div {
        @include vw(background-size, 20);
        background-image: url('../../../../assets/images/icons-buttons/Icon-Close.svg');
      }
    }

    ~ div {
      display: flex;
      max-height: 100vw;
      opacity: 1;
    }
  }
}
