.block-container {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  @include breakpoint(mobile) {
    @include vwMobile(width, 350);
    flex: 0 0 auto;
  }
  .block-top {
    @include vw(padding, 20);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include vw(height, 242);
    @include vw(margin-bottom, 30);
    @include breakpoint(mobile) {
      @include vwMobile(padding, 20);
      @include vwMobile(height, 242);
    }
    div {
      @include buildCorners(20, 0);
      border-bottom-width: 0;

      @include breakpoint(mobile) {
        @include vwMobile(border-top-width, 20);
        @include vwMobile(border-left-width, 20);
        @include vwMobile(border-right-width, 20);
      }
    }
  }

  .block-content {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    // Border related stuff
    @include vw(padding, 20);
    @include breakpoint(mobile) {
      @include vwMobile(padding-bottom, 20);
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
    }
    .block-footer {
      // Border related stuff

      flex-grow: 1;
      @include buildCorners(20, 0);
      @include vw(padding, 20);
      border-top-width: 0;
      @include breakpoint(mobile) {
        @include vwMobile(border-bottom-width, 20);
        @include vwMobile(border-left-width, 20);
        @include vwMobile(border-right-width, 20);
      }
    }
  }
}
