.news-detail {
  background-clip: padding-box;
  background-image: url('../../../../assets/images/recruit-a-friend/Image\ 48.png');
  background-position-x: center;
  background-position-y: -5%;
  background-repeat: no-repeat;
  background-size: contain;
  @include breakpoint(mobile) {
    background-position: top;
  }
  .popup-content {
    width: 100px !important;
    padding: 0 !important;
    border: 0 !important;
    background-color: $yellowIg !important;

    .link-container {
      @include vw(height, 50);
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint(mobile) {
        @include vwMobile(height, 50);
      }
      p {
        @include vw(font-size, 16);
        color: $almostBlack;
        line-height: 1.38;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
    .popup-arrow {
      background-color: $yellowIg !important;
    }
  }
  .news-detail-intro {
    .intro-content {
      &:after {
        position: absolute;
        z-index: 1;
        border-top: 1px solid $gray-light;
        content: '';
        transform: rotate(-56deg);
        transform-origin: 0% 0%;
        @include vw(width, 900);
        @include vw(left, 1200);
        @include breakpoint(mobile) {
          display: none;
        }
      }

      @include vw(margin-right, 282);
      @include vw(margin-left, 282);

      @include breakpoint(mobile) {
        @include vwMobile(margin-right, 20);
        @include vwMobile(margin-left, 20);
        @include vwMobile(padding-top, 40);
      }
      .type-wrapper {
        @include vw(padding-top, 240);
      }
      p.type {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background-color: $blueLight;
        color: $white;
        text-transform: uppercase;
        @include vw(height, 41);
        @include vw(width, 169);
        @include vw(font-size, 16);
        @include vw(margin-bottom, 57);
        @include breakpoint(mobile) {
          @include vwMobile(height, 41);
          @include vwMobile(width, 169);
          @include vwMobile(margin-top, 0);
          @include vwMobile(margin-bottom, 57);
          @include vwMobile(font-size, 18);
        }
      }
      .social-media {
        @include vw(margin-top, 48);
        @include vw(margin-bottom, 63);
        display: flex;
        align-items: center;
        justify-content: space-between;
        p.date {
          color: #7b7b7b;
          line-height: 1.38;
          @include vw(font-size, 16);
          @include breakpoint(mobile) {
            margin-bottom: 0;
            text-align: left;
            @include vwMobile(font-size, 16);
            @include vwMobile(margin-top, 50);
            @include vwMobile(margin-top, 48);
          }
          &:first-letter {
            text-transform: uppercase;
          }
        }
        @include breakpoint(mobile) {
          @include vwMobile(margin-bottom, 60);
          flex-direction: column;
          align-items: flex-start;
        }
      }
      h2 {
        // @include vw(margin-top, 57);
        @include vw(width, 752);
        // @include vw(margin-bottom, 48);
        @include vw(font-size, 50);
        @include breakpoint(mobile) {
          // @include vwMobile(margin-top, 40);
          @include vwMobile(margin-left, 0);
          //  @include vwMobile(margin-bottom, 0);
          @include vwMobile(font-size, 35);
          @include vwMobile(width, 374);
          line-height: 1.4;
        }
      }
    }
  }
  article {
    @include vw(padding-left, 272);
    @include vw(padding-right, 272);
    @include breakpoint(mobile) {
      @include vwMobile(padding-right, 20);
      @include vwMobile(padding-left, 20);
    }
    .img-article {
      @include vw(height, 774);
      background-color: $black;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include breakpoint(mobile) {
        @include vwMobile(height, 414);
      }
    }
    .content-article {
      @include vw(margin-top, 100);
      @include vw(width, 1000);
      margin-right: auto;
      margin-left: auto;
      img,
      iframe {
        width: 100%;
      }
      iframe {
        @include vw(height, 600);
        @include vw(margin-bottom, 30);
        @include breakpoint(mobile) {
          @include vwMobile(height, 300);
        }
      }
      p {
        @include vw(font-size, 20);
        @include vw(margin-bottom, 30);
        line-height: 2;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 2.5;
        }
      }
      @include breakpoint(mobile) {
        width: 100%;
        margin-left: 0;
      }
      ul {
        padding-left: 0;
        li {
          @include vw(padding-left, 0);
          @include vw(margin-left, 28);
          @include vw(font-size, 20);
          margin-bottom: 0.2vw;
          line-height: 2;
          list-style-type: none;
          strong {
            @include vw(font-size, 20);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
          &::before {
            display: inline-block;
            color: $yellowIg;
            content: '+';
            font-weight: normal;
            @include vw(width, 20);
            @include vw(margin-left, -33);
            @include vw(margin-right, 20);
            @include breakpoint(mobile) {
              @include vwMobile(width, 20);
              @include vwMobile(margin-left, -33);
              @include vwMobile(margin-right, 10);
            }
          }
        }
      }
      h1,
      h2,
      h3 {
        @include vw(margin-bottom, 20);
        @include breakpoint(mobile) {
          @include vwMobile(margin-bottom, 20);
        }
      }
      h1 {
        @include vw(font-size, 35);
        line-height: 1.4;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 25);
        }
      }
      h2 {
        @include vw(font-size, 28);
        line-height: 1.43;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
        }
      }
      h3 {
        @include vw(font-size, 24);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
    .list-social-media {
      @include vw(margin-top, 100);
    }
  }
  .follow-options {
    @include vw(height, 700);
    @include vw(margin-left, 155);
    @include vw(margin-right, 155);
    @include vw(margin-top, 100);
    @include vw(margin-bottom, 190);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include breakpoint(mobile) {
      -ms-overflow-style: none; /* IE and Edge */
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none; /* Firefox */
    }
    &::-webkit-scrollbar {
      @include breakpoint(mobile) {
        display: none;
      }
    }

    @include breakpoint(mobile) {
      @include vwMobile(height, 500);
      @include vwMobile(margin-left, 20);
      @include vwMobile(margin-right, 20);

      @include vwGridColumnsMobile(grid-template-columns, 350);
    }

    .next {
      text-align: right;
      &::after {
        content: '>';
        @include vw(margin-left, 10);
      }
    }
    .directions {
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        display: none;
      }
    }
    .before {
      text-align: left;
      &::before {
        content: '<';
        @include vw(margin-right, 10);
      }
    }
    @include vw(grid-gap, 60);
    a {
      @include vw(height, 646);
      text-decoration: none;
      @include breakpoint(mobile) {
        @include vwMobile(height, 500);
      }
      .news-block {
        position: relative;
        height: 100%;
        border: 1px solid $grayButton;
        background-clip: padding-box;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: $corners-transition;
        @include vw(padding, 0);
        .news-block-corners {
          width: 100%;
          height: 100%;
          @include buildCorners(20, 0);
        }
        .news-content-detail {
          position: absolute;
          width: 100%;
          transition: $corners-transition;
          @include vw(left, 1);
          @include vw(right, 20);
          @include vw(bottom, 55);
        }
        &:hover {
          @include vw(padding, 15);
          transition: $corners-transition;
        }

        &:hover .news-content-detail {
          p.content {
            color: $yellowIg;
            opacity: none;
          }
          .linebefore {
            background-color: $yellowIg;
            opacity: none;
          }
          transition: $corners-transition;
          @include vw(margin-bottom, 30);
        }
        p.type {
          position: absolute;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background-color: $blueLight;
          color: $white;
          text-transform: uppercase;
          transform: translate(-50%, 0);
          @include vw(top, 60);
          @include vw(height, 41);
          @include vw(width, 169);
          @include vw(font-size, 16);
          @include breakpoint(mobile) {
            @include vwMobile(height, 41);
            @include vwMobile(width, 169);
            @include vwMobile(margin-top, 44);
            margin-right: auto;
            margin-left: auto;
            @include vwMobile(font-size, 18);
          }
        }
      }
    }
  }
}
