.social-media-group {
  @include vw(margin-top, 40);
  @include breakpoint(mobile) {
    @include vwMobile(margin-top, 20);
    @include vwMobile(margin-bottom, 25);
  }
  p.date {
    display: inline-block;
    color: $gray-dark;
    line-height: 1.38;
    @include vw(font-size, 16);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 16);
      @include vwMobile(margin-bottom, 40);
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include breakpoint(mobile) {
    flex-direction: column;
  }
  .social-btn {
    position: relative;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    @include breakpoint(mobile) {
    }
    &:hover .list-social-media {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .list-social-media {
      position: absolute;
      z-index: 1;
      display: none;
      border: solid 1px $gray-oblique;
      @include vw(width, 250);
      background-color: $almostBlack;
      @include vw(top, -80);
      @include vw(right, -100);
      @include vw(height, 80);
      @include breakpoint(mobile) {
        @include vwMobile(width, 316);
        @include vwMobile(height, 80);
        @include vwMobile(top, -80);
        @include vwMobile(left, -130);
      }

      .icon {
        @include vw(width, 40);
        @include vw(height, 40);
        @include image-display();
        @include breakpoint(mobile) {
          @include vwMobile(width, 40);
          @include vwMobile(height, 40);
        }
      }

      button.icon-facebook {
        background-image: url('../../../../../assets/images/icons/Facebook.svg');

        &:hover {
          background-image: url('../../../../../assets/images/icons/yellow-withBorder/icon-facebook.svg');
        }
      }
      button.icon-twitter {
        background-image: url('../../../../../assets/images/icons/Twitter.svg');

        &:hover {
          background-image: url('../../../../../assets/images/icons/yellow-withBorder/icon-twitter.svg');
        }
      }
      i.icon-instagram {
        display: none;
        background-image: url('../../../../../assets/images/icons/Instagram.svg');
        &:hover {
          background-image: url('../../../../../assets/images/icons/yellow-withBorder/icon-instagram.svg');
        }
      }
      button.icon-email {
        background-image: url('../../../../../assets/images/icons/Icon\ -\ Email.svg');

        &:hover {
          background-image: url('../../../../../assets/images/icons/yellow-withBorder/icon-email.svg');
        }
      }
      i.icon-copy {
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px $gray-home;
        border-top-color: $white;
        border-left-color: $white;

        div {
          background-image: url('../../../../../assets/images/icons/copy.svg');
          background-size: contain;
          @include vw(width, 25);
          @include vw(height, 23);
          @include image-display();
          @include breakpoint(mobile) {
            @include vwMobile(width, 25);
            @include vwMobile(height, 23);
          }
        }
        &:hover {
          border: solid 1px $yellowIg;
          background-image: url('../../../../../assets/images/icons/yellow-withBorder/icon-copy.svg');
        }
        &:hover div {
          background-image: none;
        }
      }
    }
    .social-media-image {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px $gray-home;
      transition: transform 200ms ease-in-out;
      @include vw(width, 60);
      @include vw(height, 50);
      @include breakpoint(mobile) {
        @include vwMobile(width, 60);
        @include vwMobile(height, 50);
      }
      div {
        @include vw(width, 20);
        @include vw(height, 20);
        @include image-display();
        @include breakpoint(mobile) {
          @include vwMobile(width, 20);
          @include vwMobile(height, 20);
        }
        background-image: url('../../../../../assets/images/icons/share.svg');
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
