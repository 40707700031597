@import './faq.styles';
@import './partials/faq-line.styles';

.content-creator-container {
  .content-intro {
    // background-image: url('../../../assets/images/content-creator/intro.png');
    @include vw(height, 1000);
    @include vw(padding-left, 157);
    @include vw(padding-right, 157);
    @include breakpoint(mobile) {
      @include vwMobile(height, 831);
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
    }
    h1 {
      @include vw(width, 640);
      color: $yellowIg;
      line-height: 1;
      @include vw(padding-top, 320);
      @include vw(margin-bottom, 0);
      @include breakpoint(mobile) {
        @include vwMobile(padding-top, 344);
        @include vwMobile(font-size, 35);
        font-weight: bold;
        line-height: 1.4;
      }
    }
    p {
      @include vw(margin-top, 40);
      @include vw(width, 700);
      @include vw(font-size, 26);
      line-height: 1.38;

      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(margin-top, 30);
        @include vwMobile(font-size, 22);
        line-height: 1.36;
      }
    }
    &:after {
      position: absolute;
      z-index: 0;
      @include vw(top, 80);
      border-left-style: solid;
      border-left-color: rgba(255, 255, 255, 0.6);
      content: ' ';
      transform: skew(-36deg, 0deg);
      @include vw(border-left-width, 1);
      @include vw(left, 1365);
      @include vw(height, 1000);
      @include breakpoint(mobile) {
        display: none;
      }
      @include breakpoint(mobile) {
        display: none;
      }
    }
    background-image: url('../../../assets/images/Background-Ref-Bottom.svg'),
      url('../../../assets/images/content-creator/intro.png');
    background-position-x: center, center;
    background-position-y: (800 / $viewport-desktop) * 100vw, center;
    background-repeat: no-repeat;
    background-size: contain, cover;
    @include breakpoint(mobile) {
      background-image: none;
    }
  }
  .why-stream-bg {
    background-image: url('../../../assets/images/recruit-a-friend/Image\ 48.png');
    @include vw(height, 1200);
    background-position-x: center;
    @include vw(background-position-y, -150);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .why-stream {
    @include vw(margin-top, -1200);
    @include vw(padding-left, 157);
    @include vw(padding-right, 157);
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
    }
    .why-title {
      @include vw(padding-top, 120);
      @include vw(margin-bottom, 60);
      @include vw(font-size, 50);
      line-height: 1.4;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 35);
        line-height: 1.4;
        @include vwMobile(padding-top, 120);
        @include vwMobile(margin-bottom, 39);
      }
    }
    .row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @include vw(gap, 60);
      @include vw(margin-bottom, 60);
      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
      }
      div.column {
        @include vw(height, 736);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: $almostBlack;
        .square {
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          align-items: center;
          @include buildCorners(20, 0);
          @include breakpoint(mobile) {
            @include buildCorners(65, 0);
          }
        }
        @include breakpoint(mobile) {
          @include vwMobile(height, 461);
        }
        .img-container {
          @include vw(height, 390);
          @include vw(width, 694);
          background-color: $black;
          background-size: cover;
          @include vw(margin-top, 20);
          @include vw(margin-bottom, 55);

          @include breakpoint(mobile) {
            @include vwMobile(height, 187);
            width: 100%;
            @include vwMobile(margin-top, 20);
            @include vwMobile(margin-bottom, 40);
          }
        }
        .img-placement {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .ultimate {
          @extend .img-placement;
          background-image: url('../../../assets/images/content-creator/ultimate.png');
        }
        .moments {
          @extend .img-placement;
          background-image: url('../../../assets/images/content-creator/moments.png');
        }
        .multiple {
          @extend .img-placement;
          background-image: url('../../../assets/images/content-creator/multiple.png');
        }
        .heaven {
          @extend .img-placement;
          background-image: url('../../../assets/images/content-creator/heaven.png');
        }

        h5 {
          line-height: 1.4;
          text-transform: uppercase;
          @include vw(font-size, 35);
          @include vw(margin-bottom, 24);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 22);
            line-height: 1.36;
            @include vwMobile(margin-bottom, 22);
          }
        }
        p {
          @include vw(width, 650);
          @include vw(font-size, 20);
          line-height: 2;
          text-align: center;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            width: 100%;
            line-height: 2;
          }
        }
      }
    }
    .row-complete {
      width: 100%;
      @include vw(height, 736);
      border: solid 1px $grayButton;
      @include vw(margin-bottom, 119);
      @include breakpoint(mobile) {
        @include vwMobile(height, 618);
        @include vwMobile(margin-bottom, 80);
      }
      .square {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        @include buildCorners(20, 0);
        @include breakpoint(mobile) {
          @include buildCorners(65, 0);
          flex-direction: column;
        }
      }
      .img-container {
        @include vw(width, 694);
        @include vw(height, 656);
        background-color: $black;
        background-image: url('../../../assets/images/content-creator/challenge.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include vw(margin-left, 20);
        @include breakpoint(mobile) {
          width: 100%;
          @include vwMobile(height, 187);
          @include vwMobile(margin-left, 0);
        }
      }
      .content-column {
        text-align: center;
        @include vw(width, 650);
        @include vw(margin-left, 117);
        @include breakpoint(mobile) {
          width: 100%;
          @include vwMobile(margin-left, 0);
        }

        h5.title {
          @include vw(font-size, 35);
          line-height: 1.4;
          text-transform: uppercase;
          @include vw(margin-bottom, 27);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 22);
            line-height: 1.36;
            @include vwMobile(margin-bottom, 22);
            @include vwMobile(margin-top, 40);
          }
        }
        p.description {
          @include vw(font-size, 20);
          line-height: 2;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            line-height: 2;
          }
        }
      }
    }
  }
  .perks-program {
    @include vw(padding-left, 157);
    @include vw(padding-right, 157);
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
    }
    background-color: $blueLight;
    h2.perks-title {
      @include vw(padding-top, 120);
      @include vw(margin-bottom, 60);
      @include vw(font-size, 50);
      line-height: 1.4;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 35);
        @include vwMobile(padding-top, 80);
        @include vwMobile(margin-bottom, 40);
      }
    }
    .row-perks {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @include vw(gap, 60);
      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
      }
      .row-item {
        height: auto;
        .img-container {
          @include vw(height, 273);
          background-color: $black;
          background-size: cover;
          @include vw(margin-bottom, 60);
          @include breakpoint(mobile) {
            @include vwMobile(height, 273);
            @include vwMobile(margin-bottom, 40);
          }
        }
        .img-placement {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .free {
          background-image: url('../../../assets/images/content-creator/free.png');
          @extend .img-placement;
        }
        .money {
          background-image: url('../../../assets/images/content-creator/money.png');
          @extend .img-placement;
        }
        .vip {
          background-image: url('../../../assets/images/content-creator/vip.png');
          @extend .img-placement;
        }
        h5 {
          @include vw(font-size, 26);
          line-height: 1.38;
          text-transform: uppercase;
          @include vw(margin-bottom, 40);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 22);
            line-height: 1.36;
            @include vwMobile(margin-bottom, 20);
          }
        }
        p {
          @include vw(font-size, 19);
          line-height: 2;
          @include vw(margin-bottom, 62);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
            line-height: 2;
            @include vwMobile(margin-bottom, 40);
          }
        }
        .negative-margin {
          @include vw(margin-top, -38);
        }
        .theme-button {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          background-color: $white;
          cursor: pointer;
          @include vw(height, 65);
          @include vw(margin-bottom, 112);
          @include breakpoint(mobile) {
            @include vwMobile(height, 65);
            @include vwMobile(margin-bottom, 60);
          }
          .theme-button-container {
            width: 100%;
            div {
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              @include vw(height, 30);
              @include vw(width, 30);
              @include vw(margin-right, 22);
              @include breakpoint(mobile) {
                @include vwMobile(height, 30);
                @include vwMobile(width, 30);
                @include vwMobile(margin-right, 22);
              }
              &.dual {
                background-image: url('../../../assets/images/Logo-DU-Black.svg');
              }
              &.discord {
                background-image: url('../../../assets/images/icons/Discord-black.svg');
                @include vw(margin-right, 12);
                @include vw(height, 40);
                @include vw(width, 40);
                @include breakpoint(mobile) {
                  @include vwMobile(height, 40);
                  @include vwMobile(width, 40);
                  @include vwMobile(margin-right, 12);
                }
              }
              &.next {
                background-image: url('../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
                @include vw(height, 15);
                @include vw(width, 15);
                @include vw(margin-right, 19);
                @include breakpoint(mobile) {
                  @include vwMobile(height, 15);
                  @include vwMobile(width, 15);
                  @include vwMobile(margin-right, 19);
                }
              }
            }
          }

          span {
            @include vw(font-size, 16);
            color: $almostBlack;
            font-weight: bolder;
            line-height: 1.38;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }
        }
      }
    }
  }
  .frequent-questions-bg {
    background-image: url('../../../assets/images/recruit-a-friend/Image\ 48.png');
    @include vw(height, 1162);
    background-position-x: center;
    @include vw(background-position-y, -250);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .frequent-questions {
    // .referral-block-faq {
    //     position: relative;
    //     top: 0;
    // }
    @include vw(margin-top, -1162);
    h2 {
      @include vw(margin-top, 160);
      @include vw(margin-bottom, 90);
      text-align: center;
      @include breakpoint(mobile) {
        @include vwMobile(padding-left, 20);
        @include vwMobile(margin-top, 115);
        @include vwMobile(margin-bottom, 48);
        @include vwMobile(font-size, 35);
        line-height: 1.4;
        text-align: left;
      }
    }
  }
}
