.tutorials-about {
  $total-width: 1920;
  $content-width: 750;
  $triangle-width: 550;

  display: flex;
  background-color: $blueLight;
  @include vw(margin-bottom, 100);

  @include vw(margin-right, -150);
  @include vw(margin-left, -150);
  @include breakpoint(mobile) {
    flex-direction: column;
    @include vwMobile(margin-right, -20);
    @include vwMobile(margin-left, -20);
    @include vwMobile(padding, 20);
  }
  .content {
    @include vw(padding-top, 150);
    @include vw(padding-right, 0);
    @include vw(padding-bottom, 150);
    @include vw(padding-left, 156);
    @include vw(width, $content-width);
    line-height: 2;

    @include breakpoint(mobile) {
      padding: 0;
      width: 100%;
      order: 1;
    }
  }
  .triangle {
    z-index: 2;
    width: 0;
    height: 0;
    display: flex;
    border-top-style: solid;
    border-top-color: $blueLight;

    border-right-style: solid;
    border-right-color: transparent;

    border-left: 1px solid $blueLight;

    margin-right: -$triangle-width;
    @include vw(border-top-width, $triangle-width * 1.5);
    @include vw(border-right-width, $triangle-width);
    @include vw(margin-right, -$triangle-width);
    @include breakpoint(mobile) {
      display: none;
    }
  }
  h2 {
    @include vw(font-size, 35);
    margin: 0;
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 25);
      font-weight: bold;
      line-height: 1.4;
      @include vwMobile(margin-top, 40);
      @include vwMobile(margin-bottom, 30);
    }
  }
  p {
    @include vw(font-size, 20);
    @include vw(margin-top, 38);
    color: $gray;
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 16);
      line-height: 2;
      @include vwMobile(margin-top, 0);
      @include vwMobile(margin-bottom, 30);
    }
  }
  .image {
    width: 100%;
    background-color: $blueLight;
    background-position-x: right;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include vw(width, $total-width - $content-width);
    @include breakpoint(mobile) {
      width: 100%;
      @include vwMobile(height, 217);
    }
  }
}
