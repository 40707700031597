.recruit-container {
  .title,
  .subtitle {
    font-weight: bold;
  }
  .title {
    @include vw(font-size, 35);
    line-height: 1.4;
    text-transform: uppercase;
    @include vw(margin-bottom, 20);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 25);
      @include vwMobile(margin-bottom, 20);
      @include vwMobile(margin-top, 60);
    }
  }
  .intro {
    a,
    p {
      @include vw(font-size, 20);
      line-height: 2;
      color: $white;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    a {
      color: $yellowMild;
      text-decoration: underline;
    }
  }
  .subtitle-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.1px solid $white01;
    border-bottom: 0.1px solid $white01;
    @include vw(margin-bottom, 40);
    @include vw(margin-top, 60);
    @include vw(padding-bottom, 20);
    @include vw(padding-top, 20);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 40);
      @include vwMobile(margin-top, 40);
      @include vwMobile(padding-bottom, 20);
      @include vwMobile(padding-top, 20);
      flex-direction: column;
      align-items: start;
    }
    .sub-friend {
      @include vw(font-size, 22);
      color: $yellowIg;
      line-height: 1.36;
      font-weight: bold;
      text-transform: uppercase;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 14);
        @include vwMobile(margin-left, 22);
      }
    }
    .subtitle {
      @include vw(font-size, 22);
      line-height: 1.36;
      text-transform: uppercase;
      &::before {
        color: $white;
        content: '+';
        @include vw(margin-right, 40);
        @include breakpoint(mobile) {
          @include vwMobile(margin-right, 10);
        }
      }
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 18);
      }
    }
  }
  .subtitle-info {
    @include vw(padding-top, 30);
    * {
      color: $white;
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    @include breakpoint(mobile) {
      @include vwMobile(padding-top, 30);
    }
  }
  .subtitle-cannot {
    color: $white;
    @include vw(font-size, 20);
    line-height: 2;
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 16);
    }
  }
  .recruited-by {
    color: #dbdbdb;
    @include vw(font-size, 20);
    line-height: 2;
    text-align: center;
    @include vw(margin-top, 25);
    @include vw(margin-right, 10);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 16);
      @include vwMobile(margin-top, 25);
    }
    span {
      @include vw(margin-left, 3);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
  }
  .input-group {
    label {
      color: $yellowIg;
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    .input-row {
      display: flex;
      justify-content: space-between;
      @include vw(margin-top, 17);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 10);
      }
      .theme-button {
        .theme-button-container {
          div.send {
            background-image: url('../../../../assets/images/icons/icon-send-black.svg');
            @include vw(height, 35);
            @include vw(width, 35);
            background-size: contain;
            @include vw(margin-right, 10);
          }
          .copy {
            background-image: url('../../../../assets/images/icons/copy-black.svg');
            @include vw(height, 20);
            @include vw(width, 25);
            transform: rotate(135deg);
            background-size: contain;
          }
        }
        @include breakpoint(mobile) {
          @include vwMobile(height, 65);
          @include vwMobile(width, 60);
          span {
            display: none;
          }
          .theme-button-container {
            div.send {
              background-image: url('../../../../assets/images/icons/icon-send-black.svg');
              @include vwMobile(height, 35);
              @include vwMobile(width, 35);
              background-size: contain;
            }
            div.copy {
              background-image: url('../../../../assets/images/icons/copy-black.svg');
              @include vwMobile(height, 25);
              @include vwMobile(width, 25);
              background-size: contain;
            }
          }
        }
      }
      .input-box {
        border: 0.1px solid #999999;
        @include vw(width, 509);
        @include vw(height, 65);
        display: flex;
        align-items: center;
        @include breakpoint(mobile) {
          @include vwMobile(width, 332);
          @include vwMobile(height, 65);
        }
        .icon {
          @include vw(width, 20);
          @include vw(height, 20);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          @include vw(padding, 10);
          @include vw(margin-left, 15);
          @include vw(margin-right, 15);
          @include breakpoint(mobile) {
            @include vwMobile(width, 20);
            @include vwMobile(height, 20);
            @include vwMobile(margin-left, 15);
            @include vwMobile(margin-right, 15);
            @include vwMobile(padding, 10);
          }
        }
        .world-icon {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Language.svg');
        }
        .email-icon {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Email.svg');
        }
        input {
          width: 100%;
          height: 100%;
          border: 0;
          @include vw(font-size, 16);
          line-height: 1.38;
          background-color: $blueDeep;
          color: #dbdbdb;
          outline: none;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
      }
    }
    .warning {
      @include vw(margin-top, 25);
      @include vw(margin-bottom, 80);
      * {
        @include vw(font-size, 16);
        line-height: 1.38;
        color: #a8a8a8;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 30);
          @include vwMobile(margin-bottom, 70);
        }
      }
      a {
        color: $yellowMild;
        text-decoration: underline;
      }
    }
  }
  div.how {
    @include vw(margin-top, 30);
    @include vw(margin-bottom, 30);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 60);
      @include vwMobile(margin-bottom, 50);
    }
    .how-title {
      @include vw(font-size, 20);
      line-height: 1.4;
      text-transform: uppercase;
      @include vw(margin-bottom, 30);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
        @include vwMobile(margin-bottom, 20);
      }
      &-desc {
        @include vw(font-size, 20);
        line-height: 1.44;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
        }
      }
    }
    ul {
      padding: 0;
      @include vw(padding-left, 15);
    }
    li {
      @include vw(font-size, 18);
      line-height: 1.44;
      @include vw(padding-left, 20);
      @include vw(margin-bottom, 15);
      @include breakpoint(mobile) {
        @include vwMobile(margin-right, 15);
        @include vwMobile(font-size, 16);
        line-height: 2;
      }

      &::marker {
        @include vw(font-size, 18);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 18);
        }
        color: $yellowIg;
        content: '+';
      }
    }
  }
  .reward-text-under {
    line-height: 1.4;
    text-align: center;
    @include vw(font-size, 20);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 16);
    }
  }
  .reward-button-under {
    color: $white;
    float: right;
    @include vw(margin-top, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 30);
      @include vwMobile(margin-bottom, 50);
      float: none;
    }
    button {
      @include vw(width, 200);
      @include vw(height, 50);
      border: 0.1px solid #999999;
      background-color: #0000;
      color: $white;
      cursor: pointer;
      span {
        @include vw(font-size, 16);
        line-height: 2;
        text-transform: uppercase;
        font-weight: bold;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(height, 50);
      }
    }
  }
  .recruit-status {
    display: flex;
    align-items: center;
    justify-content: center;
    @include vw(padding-top, 10);
    @include breakpoint(mobile) {
      justify-content: space-between;
    }
    .copy,
    .recruit,
    .recruiter {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .copy {
      background-image: url('../../../../assets/images/icons/copy.svg');
      @include vw(width, 25);
      @include vw(height, 25);
      @include vw(margin-left, 40);
      @include vw(margin-right, 40);
      @include breakpoint(mobile) {
        @include vwMobile(width, 35);
        @include vwMobile(height, 35);
      }
    }
    .recruit {
      background-image: url('../../../../assets/images/recruit-a-friend/recruit.png');
      @include vw(width, 140);
      @include vw(height, 140);
      @include breakpoint(mobile) {
        @include vwMobile(width, 140);
        @include vwMobile(height, 140);
      }
    }
    .recruiter {
      background-image: url('../../../../assets/images/recruit-a-friend/recruiter.png');
      @include vw(width, 140);
      @include vw(height, 140);
      @include breakpoint(mobile) {
        @include vwMobile(width, 140);
        @include vwMobile(height, 140);
      }
    }
  }
  .rewards-block {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @include vw(gap, 20);
    .block-content {
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
        @include vw(height, 172);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      p {
        color: $white;
        @include vw(margin-top, 20);
        text-align: center;
      }
    }
  }
  .account-notice {
    display: flex;
    align-items: center;
    background-color: $green;
    @include vw(height, 65);
    @include vw(margin-top, 39);
    animation-duration: 4s;
    animation-name: custom;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    position: sticky;
    @include vw(top, 160);
    z-index: 10;
    @include breakpoint(mobile) {
      @include vwMobile(height, 65);
      @include vwMobile(top, 65);
      @include vwMobile(margin-top, 39);
    }
    p {
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    div {
      background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-white-border.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include vw(height, 40);
      @include vw(width, 40);
      @include vw(margin-left, 15);
      @include vw(margin-right, 17);
      @include breakpoint(mobile) {
        @include vwMobile(height, 25);
        @include vwMobile(width, 25);
        @include vwMobile(margin-left, 15);
        @include vwMobile(margin-right, 17);
      }
    }
  }
  @keyframes custom {
    0% {
      opacity: 0;
    }
    8% {
      opacity: 0;
    }
    12% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    28% {
      opacity: 1;
    }
    48% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .error {
    background-color: $red;
    @include breakpoint(mobile) {
      @include vwMobile(padding, 5);
      height: auto;
    }
    div {
      background-image: url('../../../../assets/images/icons/Icon-Close.svg');
      @include vw(background-size, 20);
      @include breakpoint(mobile) {
        @include vwMobile(background-size, 18);
      }
    }
  }
}
