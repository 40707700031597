.buypageparentlandingone {
  h2 {
    text-align: left;
    @include vw(margin-top, 0);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 20);
    }
  }
  .notice-notlogin {
    position: absolute;
    left: 0;
    display: none;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: $yellowIg;
    opacity: 0.95;
    p {
      color: $almostBlack;
      font-weight: bolder;
      @include vw(font-size, 22);
    }
    @include vw(top, 80);
    @include vw(height, 70);
    @include breakpoint(mobile) {
      display: none;
    }
  }

  .buypage-packages {
    //@include vw(margin-top, 99);
    .textButtonContainer {
      @include vw(margin-top, 10);
      @include vw(margin-bottom, 50);
      display: none;
      align-items: center;
      justify-content: start;
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 10);
        @include vwMobile(margin-bottom, 40);
      }

      p {
        color: $white;
        font-weight: bold;
        @include vw(margin-right, 40);
        @include vw(font-size, 20);
        line-height: 1.4;
        text-transform: uppercase;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 11);
          line-height: 1.36;
          @include vwMobile(margin-right, 10);
        }
        &:nth-child(1):before {
          content: '1.';
          @include vw(margin-right, 10);
        }
        &:nth-child(2):before {
          color: $gray-medium;
          content: '2.';
          @include vw(margin-right, 10);
        }
        &:nth-child(3):before {
          color: $gray-medium;
          content: '3.';
          @include vw(margin-right, 10);
        }
      }
      .active {
        color: $yellowIg;
      }

      @include breakpoint(from-phablet) {
        //  @include vwColumnsOrders(3, 0, true, (3, 1, 2));
      }
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 40);
      }

      .headerModificationPackages {
        @include vw(margin-bottom, 15);
        display: inline-block;
      }

      .button-container {
        // @include columns(2, $order: (2, 1));
        margin-left: auto;
        @include breakpoint(mobile) {
          display: none;
        }
        p {
          display: none;
        }
      }

      .steam-buttonBuy {
        background-color: $almostBlack;
        @include vw('width', 250);
      }
    }

    .image-container {
      @include breakpoint(from-phablet) {
        display: flex;
        height: auto;
        align-items: center;
        text-align: center;
        @include vwColumns(3, 15, true);
        @include container-align(center);
        @include vw(margin-top, 60);
      }

      .borderToggle {
        // border-style: solid;
        // border-color: white !important;
        // @include vw(border-width, 1);
      }

      .priceBox {
        position: relative;
        border: 1px solid $grayButton;

        background-color: $blueDark;
        text-transform: uppercase;
        @include vw(height, 568);
        // @include vw(width, 496);
        transition: $corners-transition;
        @include vw('padding', 0);
        &:hover {
          cursor: pointer;
          transition: $corners-transition;
          @include vw('padding', 30);
        }

        @include breakpoint(mobile) {
          width: 100%;
          box-sizing: border-box;
          @include vwMobile('margin-bottom', 20);
          @include vwMobile('height', 80);
        }
        .real-content {
          position: absolute;
          right: 0;
          left: 0;
          margin-right: auto;
          margin-left: auto;
          @include vw(top, 54);
          @include breakpoint(mobile) {
          }
          .buyPage-logo {
            @include vw(height, 150);
            display: inline-block;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include breakpoint(mobile) {
              display: none;
            }
          }

          .buyPage-logo-1 {
            background-image: url('../../../../assets/images/buypage/3months.png');
          }
          .buyPage-logo-2 {
            background-image: url('../../../../assets/images/buypage/6months.png');
          }
          .buyPage-logo-3 {
            background-image: url('../../../../assets/images/buypage/12months.png');
          }

          .buyPage-time {
            @include vwAll('margin', 25, 0, 0, 0);

            color: $white;
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            letter-spacing: normal;
            text-align: center;
            @include vw(height, 42);
            @include vw(font-size, 41);
          }

          .buyPage-price {
            @include vw('margin-top', 45);
            color: $yellowIg;
            font-weight: bold;
            @include vw(font-size, 30);
          }

          .buyPage-total {
            @include vw('margin-top', 10);
            color: $gray-dark;
            font-weight: bold;
            @include vw(font-size, 20);
            @include vw(height, 24);
            line-height: 1.4;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 12);
              @include vwMobile(height, 15);
            }
          }
          .buyPage-save {
            @include vw('margin-top', 10);
            color: $yellowIg;
            font-weight: bold;
            @include vw(font-size, 20);
            line-height: 1.4;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 10);
              @include vwMobile(height, 15);
            }
          }

          button {
            @include vw(margin-top, 40);
            //  @include vw(margin-bottom, 21);

            &.buyButtonGray {
              background-color: $grayButton;
              background-image: none;
            }
          }
          @include breakpoint(mobile) {
            top: 0;
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            border-style: none;
            .buyPage-time {
              margin-top: 0;
              color: $white;
              font-stretch: normal;
              font-style: normal;
              font-weight: bold;
              letter-spacing: normal;
              @include vwMobile(font-size, 25);
              @include vwMobile('margin-left', 10);
            }
            .d-none {
              display: none;
            }
            .price-box {
              display: flex;
              width: auto;
              flex-direction: column;
              justify-content: center;

              @include vwMobile(margin-right, 10);
              .buyPage-price {
                position: relative;
                color: $yellowIg;
                font-weight: bold;
                text-align: right;
                @include vwMobile(font-size, 20);
                @include vwMobile('margin-bottom', 10);
              }

              .buyPage-free {
                position: relative;
                color: $yellowIg;
                text-align: right;
                @include vwMobile(font-size, 15);
                @include vwMobile(height, 15);
                @include vwMobile('margin-top', 10);
              }
            }

            button {
              display: none;
            }
          }
        }
        .visibleBorder {
          width: 100%;
          height: 100%;
          @include breakpoint(from-phablet) {
            @include buildCorners(20, 0);
          }
          @include breakpoint(mobile) {
            border-style: none;
          }
        }
      }

      .priceBoxBest {
        position: relative;
        border: 2px solid $yellowIg;
        background-color: $bestPriceBG;
        text-transform: uppercase;
        @include vw(height, 627);
        // @include vw(width, 496);
        transition: $corners-transition;
        @include vw('padding', 20);
        &:hover {
          cursor: pointer;
          transition: $corners-transition;
          @include vw('padding', 40);
        }

        @include breakpoint(mobile) {
          width: 100%;
          box-sizing: border-box;
          @include vwMobile('margin-bottom', 20);
          @include vwMobile('height', 80);
        }

        .buyPage-bestvalue {
          position: absolute;
          @include vw(top, -1);
          // top: 0;
          right: 0;
          left: 0;
          border-style: none;
          margin-right: auto;
          margin-left: auto;
          background-color: $yellowIg;
          font-weight: bold;
          pointer-events: none;
          text-transform: uppercase;
          // @include vwAll('padding', 20, 35, 20, 35);
          //@include vwDimensions(190, 60);
          @include vw(min-width, 244);
          @include vw(height, 60);
          @include vw('font-size', 20);
          @include breakpoint(mobile) {
            display: none;
          }
        }
        .visibleBorderBest {
          width: 100%;
          height: 100%;
          @include breakpoint(from-phablet) {
            @include buildCorners(20, 0);
          }
          @include breakpoint(mobile) {
            border-style: none;
          }
        }
        .real-content {
          position: absolute;
          right: 0;
          left: 0;
          margin-right: auto;
          margin-left: auto;
          @include vw(top, 83);
          .buyPage-logo {
            @include vw(height, 150);
            display: inline-block;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include breakpoint(mobile) {
              display: none;
            }
          }

          .buyPage-logo-1 {
            background-image: url('../../../../assets/images/buypage/3months.png');
          }
          .buyPage-logo-2 {
            background-image: url('../../../../assets/images/buypage/6months.png');
          }
          .buyPage-logo-3 {
            background-image: url('../../../../assets/images/buypage/12months.png');
          }

          .buyPage-time {
            @include vwAll('margin', 25, 0, 0, 0);
            color: $white;
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            letter-spacing: normal;
            text-align: center;
            @include vw(height, 41);
            @include vw(font-size, 41);
          }

          .buyPage-price {
            @include vw('margin-top', 45);
            color: $yellowIg;
            font-weight: bold;
            @include vw(font-size, 30);
          }

          .buyPage-total {
            @include vw('margin-top', 10);
            color: $gray-dark;
            font-weight: bold;
            @include vw(font-size, 20);
            @include vw(height, 24);
            line-height: 1.4;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 12);
              @include vwMobile(height, 15);
            }
          }
          .buyPage-save {
            @include vw('margin-top', 10);
            color: $yellowIg;
            font-weight: bold;
            @include vw(font-size, 20);
            line-height: 1.4;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 10);
              @include vwMobile(height, 15);
            }
          }

          button {
            @include vw(margin-top, 40);
            //@include vw(margin-bottom, 21);
          }
          @include breakpoint(mobile) {
            top: 0;
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            border-style: none;

            .buyPage-time {
              margin-top: 0;
              color: $white;
              font-stretch: normal;
              font-style: normal;
              font-weight: bold;
              letter-spacing: normal;
              @include vwMobile(font-size, 25);
              @include vwMobile('margin-left', 10);
            }
            .d-none {
              display: none;
            }
            .price-box {
              display: flex;
              width: auto;
              flex-direction: column;
              justify-content: center;

              @include vwMobile(margin-right, 10);
              .buyPage-price {
                position: relative;
                // display: inline-block;
                color: $yellowIg;
                font-weight: bold;
                // @include vwMobile(bottom, 10);
                //@include vwMobile(left, 38);
                text-align: right;
                @include vwMobile(font-size, 20);
                @include vwMobile('margin-bottom', 10);
              }

              .buyPage-free {
                position: relative;
                color: $yellowIg;
                text-align: right;
                @include vwMobile(font-size, 15);
                @include vwMobile(height, 15);
                @include vwMobile('margin-top', 10);
                //@include vwMobile(bottom, 15);
              }
            }
            button {
              display: none;
            }
          }
        }
      }

      .graybox {
        background-color: $graybox;
      }
    }
    .buyPage-disclaimer {
      strong {
        color: $yellowIg;
        font-weight: normal;
        @include vw(font-size, 17);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 15);
        }
      }
      margin: 0 auto;
      color: $gray-dark;
      @include vw('margin-top', 20);
      p {
        @include vw(font-size, 17);
        text-align: center;
      }
      @include vw(min-width, 956);

      @include breakpoint(mobile) {
        width: 100%;
        @include vw('margin-top', 0);
        p {
          @include vwMobile(font-size, 15);
        }
      }
    }
  }
}
