.contest-thankyou {
  height: 93vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .notice {
    * {
      @include vw(font-size, 70);
      opacity: 1;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      line-height: 1.2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 50);
      }
    }
  }
  .subnotice {
    * {
      @include vw(font-size, 30);
      color: $white;
      text-align: center;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
      }
    }
    @include vw(margin-top, 30);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 20);
    }
  }
}
