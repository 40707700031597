.buypage-entercode {
  display: none;
  .tooltip-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include vw(margin-bottom, 20);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 20);
    }
    h6 {
      display: inline-block;
      color: $yellowMild;
      font-weight: normal;
      text-align: left;
      @include vw(font-size, 20);
      @include vw(line-height, 20);
      @include vw(margin-top, 0);
      @include vw(margin-bottom, 0);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 41);
        @include vwMobile(font-size, 20);
        @include vwMobile(margin-bottom, 0);
      }
    }
  }

  .util-input {
    @include breakpoint(mobile) {
      width: 100%;
      @include vwMobile(height, 75);
    }

    div:first-of-type {
      border-style: none;
      margin: auto 0;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Code.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include vw(width, 25);
      @include vw(height, 25);
      @include vw(margin-right, 25);
      @include vw(margin-left, 25);
      @include breakpoint(mobile) {
        @include vwMobile(width, 25);
        @include vwMobile(height, 25);
        @include vwMobile(margin-right, 25);
        @include vwMobile(margin-left, 25);
      }
    }
    div:last-of-type {
      border-style: none;
      margin: auto 0;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Loading.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include vw(width, 25);
      @include vw(height, 25);
      @include vw(margin-right, 25);
      @include vw(margin-left, 25);
      @include breakpoint(mobile) {
        @include vwMobile(width, 25);
        @include vwMobile(height, 25);
        @include vwMobile(margin-right, 25);
      }
    }
  }
  .popup-content {
    padding: 1% !important;
    border: 0 !important;
    background-color: $yellowIg !important;
    @include vw(min-width, 354);
    span {
      @include vw(font-size, 16);
      color: $almostBlack;
      line-height: 1.38;
    }
  }
  .popup-arrow {
    background-color: $yellowIg !important;
  }
}
