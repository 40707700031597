$navbar-height: 80;
$navbar-mobile-height: 60;
$navbar-border-width: 1;

.navbar-center {
  @extend .flex-center-all;
}

.navbar {
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-bottom-width: 0.1px;
  border-bottom-style: solid;
  border-bottom-color: $white02;
  background-color: $blueDeep;
  font-weight: bold;
  //text-transform: uppercase;
  //@include vw(border-bottom-width, $navbar-border-width);
  @extend .util-main-padding;
  @include vw(padding-left, 30);
  @include vw(padding-right, 30);
  @include columns((14, 6, 5));
  @include breakpoint(from-phablet) {
    @include vw(height, $navbar-height);
  }
  .menuIcon {
    display: inline-block;
    background-image: url('../../../assets/images/icons-navbar/Icon-NavDropArrow.svg');
    @extend .navbarimage;

    @include vw(width, 12);
    @include vw(height, 10);
    @include vw(margin-left, 10);
    @include vw(margin-top, 4);
    transition: transform 0.2s linear;
  }
  .menuIconClose {
    background-image: url('../../../assets/images/icons-navbar/Icon-NavDropArrow.svg');
    transform: rotate(180deg);
  }
  .leftBar {
    //  @include columns((1, 5.6), $order: (1, 2));
    display: flex;
    height: 100%;
    justify-content: flex-start;
    .navbar-item-logo {
      a {
        @extend .nabarlinks;

        &.navbar-logo {
          @extend .navbar-center;
          display: flex;
          border-color: $gray;
          @extend .navbarimage;
          @include vw(padding, 7);
          @include vw(width, 30);
          @include vw(height, 50);
          @include vw(margin-right, 85);
        }
      }
    }
    .navbar-item-icon {
      @include breakpoint(from-phablet) {
        // @include vwColumns((0, 1, 1, 1.3, 1.3, 1), 5, true);
        //  @include vwColumns((0, 1.3, 0.8, 1.3, 1.3, 1), 6, true);
      }

      a {
        @extend .nabarlinks;
        @extend .navbar-center;

        &.navbar-icon {
          display: none;

          background-image: url('../../../assets/images/icons-navbar/icon-openmenu.svg');
          content: '';
          @extend .navbarimage;
          @include vw(width, 30);
          @include vw(height, 50);
          @include vw(margin-right, 10);
        }

        &.navbar-icon:focus {
          background-image: url('../../../assets/images/icons-navbar/icon-closemenu.svg');
        }
      }
      .navbar-item {
        position: relative;
        @include vw(margin-right, 40);
        // &:hover {
        //   @include vw(border-bottom-width, 3);
        //   @include vw(margin-bottom, -1);
        //   z-index: 3;
        //   box-sizing: border-box;
        //   border-color: $white;
        //   border-bottom-style: solid;
        //   a {
        //     @include vw(margin-bottom, -3);
        //   }
        // }
        a.navbar-SubMenuGame,
        a.navbar-SubMenu {
          @extend .nabarlinks;
          @include vw(font-size, 16);
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          font-style: normal;
          text-transform: uppercase;
          &.navbar-SubMenuGame {
            position: relative;
            z-index: 3;
            @include vw(height, 40);

            &:hover + .simplesubmenucontainer-1,
            &:hover + .simplesubmenucontainer-2,
            &:hover + .simplesubmenucontainer-3 {
              // display: flex;
            }
            // &:after {
            //   display: inline-block;
            //   background-image: url('../../../assets/images/icons-navbar/Icon-NavDropArrow.svg');
            //   content: '';
            //   @extend .navbarimage;
            //   @include vw(width, 12);
            //   @include vw(height, 10);
            //   @include vw(margin-left, 10);
            //   @include vw(margin-top, 4);
            // }
          }

          &.navbar-SubMenuMegaGame {
            position: relative;
            z-index: 3;

            &:focus + .megasubmenucontainer {
              display: flex;
            }

            &:after {
              display: inline-block;
              background-image: url('../../../assets/images/icons-navbar/Icon-NavDropArrow.svg');
              content: '';
              @extend .navbarimage;

              @include vw(width, 12);
              @include vw(height, 10);
              @include vw(margin-left, 10);
              @include vw(margin-top, 4);
            }
          }
        }
      }
    }
  }

  .middleBar {
    display: flex;
    justify-content: center;
    height: 100%;
    button {
      @extend .util-navbar-link;
    }

    .navbar-item {
      a {
        &.navbar-play {
          @extend .util-navbar-link;
          @extend .util-navbar-link-secondary;
          text-transform: uppercase;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: linear-gradient(to bottom, #ffa700, #ffd55f);
          color: $almostBlack;
          transform: skew(-36deg, 0deg);
          @include vw(width, 221);
          &:hover {
            box-shadow: 0 0 50px #ffae0e;
          }
          span {
            transform: skew(36deg, 0deg);
            @include vw(font-size, 16);
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            font-style: normal;
          }
          // &::after {
          //   @extend .util-navbar-oblique-border-base;
          //   @include vw(border-right-width, 2);
          //   border-right-style: solid;
          //   border-right-color: $yellowIg;

          //   @include vw(right, 705);
          // }

          // &::before {
          //   @extend .util-navbar-oblique-border-base;
          //   @include vw(border-left-width, 2);
          //   border-left-style: solid;
          //   border-left-color: $yellowIg;

          //   @include vw(left, 1355);
          // }
        }
        &.wishlist {
          background-image: linear-gradient(to bottom, #0027ff, #221772);
          color: $white;
          div {
            transform: skew(36deg, 0deg);
            background-image: url('../../../assets/images/icons/Icon-Wishlist.svg');
            @include vw(height, 25);
            @include vw(width, 25);
            @include vw(margin-right, 10);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
          &:hover {
            box-shadow: 0 -25px 50px #0e90ff;
          }
        }
      }
    }
  }

  .rightBar {
    //  @include vwColumns((7, 10), 10, true);
    display: flex;
    height: 100%;
    justify-content: flex-end;
    .navbar-item {
      position: relative;
    }
    .navbar-item:last-of-type {
      @include vw(margin-left, 42);
    }
    a.language,
    a.userlogin {
      text-transform: uppercase;
      font-family: eurostile-extended, sans-serif;
      font-weight: 900;
      font-style: normal;
    }
    a {
      display: flex;
      align-items: flex-start;
      text-align: left;
      @extend .nabarlinks;
      @include vw(font-size, 16);

      &.language {
        z-index: 4 !important;

        justify-content: start;
        @include vw(height, 45);
        &:hover + .languagemenucontainer {
          display: flex;
        }

        div:not(.menuIcon) {
          i {
            background-image: url('../../../assets/images/icons-navbar/Icon-Language.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include vw(width, 20);
            @include vw(height, 20);
          }
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border-width: 1px;
          //@include vw(top, -8);
          border-style: solid;
          //@include vw(border-width, $navbar-border-width);
          border-color: $gray;
          @extend .navbarimage;
          //content: '';
          // box-sizing: border-box;
          @include vw(width, 30);
          @include vw(height, 30);
          @include vw(margin-right, 10);
        }

        // &:after {
        //   display: inline-block;
        //   background-image: url('../../../assets/images/icons-navbar/Icon-NavDropArrow.svg');
        //   content: '';
        //   @extend .navbarimage;
        //   @include vw(width, 12);
        //   @include vw(height, 10);
        //   @include vw(margin-left, 10);
        //   @include vw(margin-top, 4);
        // }
      }

      &.userlogin {
        z-index: 4 !important;
        &:hover,
        &:active,
        &:focus,
        &:focus-within {
          + .loginmenucontainer,
          + .profilemenucontainer {
            display: flex;
          }
        }
        // position: absolute;
        // @include vw(left, 0);
        @include vw(height, 45);
        &:hover p {
          color: $yellowIg;
        }
        @include breakpoint(from-phablet) {
          p {
            @include vw(font-size, 16);
            font-family: eurostile-extended, sans-serif;
            font-weight: 900;
            font-style: normal;
            @include vw(max-width, 180);
            overflow: hidden;
            color: $white;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        div:not(.menuIcon) {
          i {
            background-image: url('../../../assets/images/icons-navbar/Icon-Profile.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include vw(width, 23);
            @include vw(height, 24);
          }
          position: relative;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          border-width: 1px;
          // @include vw(top, -8);
          border-style: solid;
          //@include vw(border-width, $navbar-border-width);
          border-color: $gray;
          content: '';
          // background-image: url('../../../assets/images/icons-navbar/Icon-Profile.svg');
          @extend .navbarimage;
          // box-sizing: border-box;
          @include vw(width, 30);
          @include vw(height, 30);
          @include vw(margin-right, 10);
        }

        // &:after {
        //   display: inline-block;
        //   background-image: url('../../../assets/images/icons-navbar/Icon-NavDropArrow.svg');
        //   content: '';
        //   @extend .navbarimage;

        //   @include vw(width, 12);
        //   @include vw(height, 10);
        //   @include vw(margin-left, 10);
        //   @include vw(margin-top, 4);
        // }
      }

      &.search {
        @extend .navbar-center;

        position: relative;
        display: flex;
        box-sizing: border-box;
        border-style: solid;
        border-color: $gray;
        background-image: url('../../../assets/images/icons-navbar/Icon-Search.svg');

        content: '';
        content: '';
        @include vw(top, -8);
        @include vw(border-width, $navbar-border-width);
        @extend .navbarimage;
        @include vw(width, 30);
        @include vw(height, 30);
      }
    }
  }
}
.navbar-de {
  @include breakpoint(from-phablet) {
    @include columns((10, 4, 4));
  }
  .leftBar .navbar-item-icon .navbar-item {
    @include vw(margin-right, 25);
  }
}
.navbar-fr {
  @include breakpoint(from-phablet) {
    @include columns((10, 3, 4));
  }
  .leftBar .navbar-item-icon .navbar-item {
    @include vw(margin-right, 30);
  }
  .middleBar {
    .navbar-play {
      span {
        text-align: center;
      }
    }
    #navbar-play-fr::after {
      @include vw(right, 760);
    }
  }
}
.leftBar,
.rightBar,
.middleBar {
  .navbar-item,
  .navbar-item-logo,
  .navbar-item-icon {
    @extend .navbar-center;
    a {
      &.navbar-SubMenuGame,
      &.navbar-SubMenuMegaGame,
      &.navbar-SubMenu,
      &.language,
      &.userlogin {
        @extend .navbar-center;
      }
    }
  }
}

.leftBar,
.rightBar {
  .navbar-item {
    display: flex;
    flex-direction: column;
    .line {
      position: relative;
      align-self: flex-start;
      width: 0;
      animation-duration: 0.2s;
      animation-name: line;
      animation-timing-function: cubic-bezier (0.65, 0, 0.35, 1);
      animation-fill-mode: forwards;
      @keyframes line {
        from {
          width: 0;
        }

        to {
          width: 100%;
        }
      }
    }
  }
  // .navbar-item {
  //   &:hover {
  //     @include vw(border-bottom-width, 3);
  //     @include vw(margin-bottom, -1);
  //     z-index: 3;
  //     box-sizing: border-box;
  //     border-color: $white;
  //     border-bottom-style: solid;
  //     a {
  //       &.userlogin,
  //       &.language {
  //         @include vw(margin-bottom, -3);
  //       }
  //     }
  //   }
  // }
}
.navbar-item-login:focus-within {
  .loginmenucontainer,
  .profilemenucontainer {
    display: flex;
  }
}

.anchor {
  @include vw(margin-top, -$navbar-height);
  position: absolute;
}
.popup-overlay-nav {
  position: fixed;
  @include vw(top, 0);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0% 0%;
  cursor: pointer;
}
@import './navbarsubcomponents/simplesubmenu';
@import './navbarsubcomponents/megasubmenu';
@import './navbarsubcomponents/loginmenu';
@import './navbarsubcomponents/loginmodal';
@import './navbarsubcomponents/languagemenu';
@import './navbarsubcomponents/profilemenu';
@import './navbarsubcomponents/navbarmobile';
@import './navbarsubcomponents/mobilesubmenu';
@import './navbarsubcomponents/popupconfirm';
@import './navbarsubcomponents/confirmleave';
@import './navbarsubcomponents/2fa-remindermodal';
@import './navbarsubcomponents/resendmodal';
