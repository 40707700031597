.contest-container {
  .intro {
    @include vw(height, 580);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @include breakpoint(mobile) {
      @include vwMobile(height, 330);
    }
    .intro-pack {
      position: absolute;
      @include vw(top, 120);
      @include vw(left, 0);
      @include vw(right, 0);
      @include breakpoint(mobile) {
        @include vwMobile(top, 100);
      }
      .intro-logo {
        @include vw(height, 217);
        @include vw(width, 811);
        margin-left: auto;
        margin-right: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include breakpoint(mobile) {
          @include vwMobile(height, 100);
          @include vwMobile(width, 300);
        }
      }
      .intro-sub {
        margin-left: auto;
        margin-right: auto;
        @include vw(margin-top, 90);
        * {
          @include vw(font-size, 24);
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          font-style: normal;
          color: $yellowIg;
          text-align: center;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
      }
      .intro-title {
        * {
          @include vw(font-size, 70);
          text-align: center;
          font-weight: 900;
          line-height: 0.8;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 30);
          }
        }
      }
    }
  }
  .content {
    @include vw(width, 749);
    margin: 0 auto;
    @include breakpoint(mobile) {
      @include vwMobile(padding, 20);
      padding-top: 0;
      width: 100%;
    }

    p:first-of-type {
      //@include vw(font-size, 23);
      font-weight: bold;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        @include vwMobile(margin-bottom, 10);
      }
    }
    li {
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    h3,
    h2 {
      line-height: 2.5;
    }
    a {
      color: $yellowMild;
      text-decoration: underline;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    img {
      width: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      @include vw(height, 200);
      @include vw(margin-bottom, 20);
      @include vw(margin-top, 10);
      @include breakpoint(mobile) {
        @include vwMobile(height, 100);
        @include vwMobile(margin-top, 10);
        @include vwMobile(margin-bottom, 20);
      }
    }
    b,
    strong {
      color: $yellowIg;
    }
    ul {
      margin: 0;
      list-style-type: none;
      padding-left: 0;
      li::before {
        content: '+';
        @include vw(margin-right, 30);
        @include breakpoint(mobile) {
          @include vwMobile(margin-right, 15);
        }
      }
    }
  }
  .content:last-of-type {
    @include vw(margin-bottom, 538);
  }
  .notice {
    @include vw(width, 749);
    margin: 0 auto;
    @include breakpoint(mobile) {
      width: 100%;
      @include vwMobile(margin-top, 30);
      @include vwMobile(margin-bottom, 30);
    }
    * {
      color: $white;
      @include vw(font-size, 20);
      line-height: 2;
      text-align: center;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    .elegible {
      color: $green;
    }
    .not-elegible {
      color: $red;
    }
  }
  .btn-participate {
    @include vw(height, 65);
    @include vw(width, 218);
    margin: 0 auto;
    @include vw(margin-top, 50);
    background-color: $green;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    @include breakpoint(mobile) {
      @include vwMobile(height, 65);
      @include vwMobile(width, 218);
      @include vwMobile(margin-top, 0);
    }
    &:hover {
      cursor: pointer;
      div {
        background-image: url('../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
      }
      span {
        color: $black;
      }
    }
    &:disabled {
      cursor: not-allowed;
      div {
        background-image: url('../../../assets/images/icons-buttons/Icon-Continue-White.svg');
      }
      span {
        color: $white;
      }
    }
    div {
      background-image: url('../../../assets/images/icons-buttons/Icon-Continue-White.svg');
      @include vw(height, 9);
      @include vw(width, 7);
      @include vw(margin-right, 20);
      @include vw(margin-top, 2);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include breakpoint(mobile) {
        @include vwMobile(height, 9);
        @include vwMobile(width, 7);
        @include vwMobile(margin-right, 10);
      }
    }
    span {
      @include vw(font-size, 13);
      font-family: eurostile-extended, sans-serif;
      font-weight: 900;
      font-style: normal;
      color: $white;
      @include vw(margin-top, 2);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 12);
      }
    }
  }
  .loading {
    display: flex;
    justify-content: center;
    @include vw(margin-top, 30);
  }
  .disclamer {
    @include vw(margin-top, 20);
    * {
      text-align: center;
      @include vw(width, 749);
      margin: 0 auto;
      @include vw(font-size, 16);
      color: #a8a8a8;
      line-height: 1.88;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 20);
    }
    a {
      color: $yellowMild;
      text-decoration: underline;
    }
  }
}
