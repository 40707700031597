.account-linked-container {
  .theme-button-spec-positive {
    @include vw(min-width, 370);
    .theme-button-container {
      div {
        background-image: url('../../../../assets/images/icons/Discord-white-noborder.svg');
        @include vw(width, 30);
        @include vw(height, 32);
      }
      span {
        color: $white;
      }
    }
    &:hover .theme-button-container {
      div {
        background-image: url('../../../../assets/images/icons/Discord-black.svg');
        @include vw(width, 30);
        @include vw(height, 32);
      }
    }
  }
  .steam-btn {
    .theme-button-container div {
      background-image: url('../../../../assets/images/icons/steam.svg') !important;
      @include vw(width, 20);
      @include vw(height, 20);
    }
    &:hover .theme-button-container {
      div {
        filter: invert(100%);
        @include vw(width, 20);
        @include vw(height, 20);
      }
    }
  }
  .notice-refund {
    @include vw(margin-top, 20);
    * {
      color: $yellowIg;
    }
  }

  .theme-button-unlink {
    @include vw(min-width, 400);
    background-image: linear-gradient(to right, $red 50%, $red 50%);
    border-color: $red;
  }
  .section-psw-title {
    span {
      color: $yellowIg;
    }
  }
  button[disabled]:hover {
    div {
      filter: none !important;
    }
    span {
      color: $white !important;
    }
  }
}
