@mixin vwGridColumnsMobile($property, $value) {
  #{$property}: ($value / $viewport-mobile) * 100vw ($value / $viewport-mobile) * 100vw ($value / $viewport-mobile) *
    100vw ($value / $viewport-mobile) * 100vw ($value / $viewport-mobile) * 100vw;
}
@mixin vwGridColumns($property, $value) {
  #{$property}: ($value / $viewport-desktop) * 100vw ($value / $viewport-desktop) * 100vw ($value / $viewport-desktop) *
    100vw ($value / $viewport-desktop) * 100vw ($value / $viewport-desktop) * 100vw;
}
@import './news-sections/news-intro.component.scss';
@import './news-sections/news-block-big.scss';
@import './news-sections/news-block.component.scss';
@import './news-sections/news-small-sections/news-navigation.component.scss';
@import './news-sections/news-detail.component.scss';

@import './news-sections/news-newsletter.component.scss';
@import './news-sections/news-archives.component.scss';
@import './news-sections/newslettersignin.component.scss';
@import './news-sections/news-small-sections/news-social-media.component.scss';
.news {
  .parentBlur {
    position: fixed;
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(30.8px);
    backdrop-filter: blur(30.8px);
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0% 0%;
    cursor: pointer;
  }
  .newsletter-shell {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 5;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .popUpWaitingList {
    @include vw(width, 600);
    @include vw(height, 480);

    @include buildCorners(35, 0);

    position: fixed;
    z-index: 40;

    background-color: $blueDeep;
    text-align: center;
    @include vw(top, 315);
    @include vw(left, 660);

    @include breakpoint(mobile) {
      @include vwMobile(width, 440);
      @include vwMobile(height, 631);

      @include vwMobile(top, 83);
      @include vwMobile(left, 20);
      @include vwMobile(border-width, 20);
    }
    .close {
      z-index: 3;
      border-style: solid;
      border-color: $gray;
      background-clip: content-box;
      background-image: url('../../../assets/images/icons/no-border/Icon-Close.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      @include vw(width, 30);
      @include vw(height, 30);
      @include vw(padding, 7);
      @include vw(border-width, 0.1);
      @include vw(margin-left, 504);

      @include breakpoint(mobile) {
        @include vwMobile(width, 30);
        @include vwMobile(height, 30);
        margin-left: auto;
      }
    }
    .footeremail {
      z-index: 3;
      @include breakpoint(from-phablet) {
        @include vw(width, 496);
        @include vw(margin-bottom, 100);
        display: inline-block;
      }
      form {
        p {
          &.popUpheader {
            font-weight: bold;
            text-transform: uppercase;
            @include vw(font-size, 22);
            @include vw(margin-bottom, 60);

            @include breakpoint(mobile) {
              @include vwMobile(font-size, 22);
              @include vwMobile(margin-bottom, 60);
            }
          }

          &.disclaimer {
            color: $gray-dark;
            @include vw(font-size, 14);

            p,
            a {
              @include vw(font-size, 14);
            }
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 14);
              @include vwMobile(margin-bottom, 80);

              a {
                @include breakpoint(mobile) {
                  @include vwMobile(font-size, 14);
                }
              }
            }
          }
        }
        .util-input {
          @include vw(margin-left, 13);
          @include vw(margin-right, 7);
          width: 100%;
          @include vw(margin-bottom, 35);
          @include breakpoint(mobile) {
            @include vwMobile(height, 65);
            @include vwMobile(margin-bottom, 80);
          }

          .first {
            border-style: none;
            margin: auto 0;
            background-image: url('../../../assets/images/icons/no-border/Icon-Email.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include vw(width, 25);
            @include vw(height, 25);
            @include vw(margin-right, 25);
            @include vw(margin-left, 25);
            @include breakpoint(mobile) {
              @include vwMobile(width, 25);
              @include vwMobile(height, 25);
              @include vwMobile(margin-right, 25);
              @include vwMobile(margin-left, 25);
            }
          }
          .last {
            border-style: none;
            margin: auto 0;
            background-image: url('../../../assets/images/icons/no-border/checkmark-green.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include vw(width, 25);
            @include vw(height, 25);
            @include vw(margin-right, 25);
            @include vw(margin-left, 25);
            @include breakpoint(mobile) {
              @include vwMobile(width, 25);
              @include vwMobile(height, 25);
              @include vwMobile(margin-right, 25);
            }
          }
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          margin: 0 auto;
          background-color: $white;
          cursor: pointer;
          outline: none;
          @include vw(height, 65);
          @include vw(width, 200);

          @include vw(margin-bottom, 75);
          @include breakpoint(mobile) {
            @include vwMobile(height, 65);
            @include vwMobile(width, 200);

            @include vwMobile(margin-bottom, 100);
          }
          .icon-send {
            @include vw(height, 12);
            @include vw(width, 20);
            background-image: url('../../../assets/images/icons/icon-send-black.svg');
            background-position: center;
            background-repeat: no-repeat;
            @include vw(background-size, 30);
            @include vw(margin-right, 14);
            @include breakpoint(mobile) {
              @include vwMobile(height, 35);
              @include vwMobile(width, 35);
              @include vwMobile(background-size, 30);
              @include vwMobile(margin-right, 8);
            }
          }
          span {
            @include vw(font-size, 14);
            font-weight: 800;
            line-height: 1.38;
            text-transform: uppercase;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }
        }
      }
    }
  }
  .news-header {
    position: absolute;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: $blueLight;
    color: $white;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    @include vw(top, 60);
    @include vw(height, 41);
    @include vw(width, 169);
    @include breakpoint(mobile) {
      @include vwMobile(height, 39);
      @include vwMobile(width, 169);
      @include vwMobile(top, 40);
    }
    p {
      @include vw(font-size, 16);
      color: $white;
      text-emphasis: bold;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
  }
  .row {
    display: grid;
    @include vw(grid-gap, 60);
    @include vw(margin-left, 156);
    @include vw(margin-right, 156);

    @include breakpoint(mobile) {
      grid-template-columns: 1fr;
      @include vwMobile(margin-left, 20);
      @include vwMobile(margin-right, 20);
    }
  }
  .news-container-carousel {
    position: relative;
    // top: -35px;
    @include vw(margin-top, -100);
    width: 100%;
    // @include vw(height, 648);
    @include breakpoint(mobile) {
      top: 0;
      @include vw(margin-top, 0);
    }

    .news-carousel {
      @include vw(margin-right, 120);
      @include vw(margin-left, 120);
      @include vw(left, 100);
      .news-wapper {
        background-position: top center;
        background-size: 200%;
      }
      .news-content {
        @include vw(right, 0);
        .news-content-detail {
          width: 100%;
          .list-social-media {
            @include vw(right, 0);
          }
        }
      }
      .carousel-item-padding {
        @include vw(padding-left, 35);
        @include vw(padding-right, 30);
      }
      .react-multiple-carousel__arrow {
        z-index: 8;
        border: solid 1px $white;
        background: transparent;
        border-radius: 0;
        opacity: 0.95;
        @include vw(height, 80);
        @include vw(width, 80);
        &::before {
          opacity: 0.95;
        }
      }
      .react-multiple-carousel__arrow--right {
        @include vw(right, -0.4);
      }
      .react-multiple-carousel__arrow--left {
        @include vw(left, -0.5);
      }
    }
  }
  .news-two-equals {
    grid-template-columns: repeat(2, 1fr);
    @include vw(margin-bottom, 60);
    .news-wapper {
      background-position: top center;
      background-size: 130%;
    }

    .news-content {
      @include vw(right, 0);
      .news-content-detail {
        width: 100%;
      }
    }
    @include breakpoint(mobile) {
      grid-template-columns: 1fr;
      @include vwMobile(margin-bottom, 60);
    }
  }
  .news-not-equal {
    grid-template-columns: 1fr 2fr;
    @include vw(margin-bottom, 60);
    .news-wapper {
      background-position: top center;
      background-size: 130%;
    }
    .news-wapper:nth-child(1) {
      background-position: top center;
      background-size: 200%;
    }
    .news-content {
      @include vw(right, 0);
      .news-content-detail {
        width: 100%;
      }
    }
    @include breakpoint(mobile) {
      grid-template-columns: 1fr;
      @include vwMobile(margin-bottom, 60);
    }
  }
  .news-complete {
    display: grid;
    grid-template-columns: 1fr;
    @include vw(margin-bottom, 60);
    .news-wapper {
      background-position: top right;
      background-size: contain;
    }
    .news-content {
      position: absolute;
      height: 90%;
      @include vw(top, 20);
      @include vw(right, 0);
      .news-content-detail {
        width: 60%;
      }
      &::before {
        display: none;
      }
    }
    @include breakpoint(mobile) {
      grid-template-columns: 1fr;
      .news-content {
        @include vw(right, 0);
        .news-content-detail {
          width: 100%;
        }
      }
    }
  }
  .news-title-filter {
    display: flex;
    align-items: center;
    @include vwAll(margin, 80, 156, 80, 156);
    @include breakpoint(mobile) {
      @include vwMobileAll(margin, 60, 20, 40, 20);
      justify-content: space-between;
    }
    h2 {
      margin-bottom: 0;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
        line-height: 1.4;
      }
    }
    p {
      margin-left: auto;
      text-emphasis: bold;
      text-transform: uppercase;
      @include vw(font-size, 20);
      @include vw(margin-right, 40);
      @include breakpoint(mobile) {
      }
    }
    .box-filter {
      position: relative;
      border: solid 1px $gray-home;
      margin-right: 0;
      cursor: pointer;
      @include vw(height, 65);
      @include vw(width, 65);
      // display: flex;
      // justify-content: center;
      // align-items: center;
      @include breakpoint(mobile) {
        @include vwMobile(height, 40);
        @include vwMobile(width, 40);
      }

      div.button {
        @include vw(height, 66);
        @include vw(width, 66);
        @include vw(padding, 20);
        background-image: url('../../../assets/images/icons/no-border/icon-filter.svg');
        background-origin: content-box;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include breakpoint(mobile) {
          @include vwMobile(height, 15);
          @include vwMobile(width, 15);
        }
        &:hover {
          background-image: url('../../../assets/images/icons/yellow-withBorder/icon-filter.svg');
        }
      }
      .filtering {
        position: absolute;
        z-index: 8;
        top: 100%;
        right: 0;
        border: 1px solid $gray-home;
        background-color: $almostBlack;
        @include vw(height, 403);
        @include vw(width, 1053);
        @include breakpoint(mobile) {
          @include vwMobile(height, 403);
          @include vwMobile(width, 1053);
        }
        h5 {
          color: $white;
          text-transform: uppercase;
        }
        button {
          color: $white;
          text-transform: uppercase;
        }

        h5.title {
          @include vw(margin-top, 40);
          @include vw(margin-left, 40);
          @include vw(margin-bottom, 20);
        }
        .options {
          display: grid;
          cursor: pointer;
          grid-template-columns: repeat(5, 1fr);
          @include vw(margin-left, 40);
          @include vw(margin-right, 40);
          @include vw(margin-bottom, 63);
          @include vw(gap, 30);

          button {
            width: 100%;
            padding: 0;
            background-color: $blueDark;
            color: $white;
            cursor: pointer;
            outline: none;
            text-emphasis: bold;
            @include vw(height, 41);
            @include vw(font-size, 16);
            &:focus {
              border-color: $yellowIg;
              background-color: $yellowIg;
              color: $black;
              text-emphasis: bold;
            }
          }
        }
        .date {
          &-title {
            @include vw(margin-left, 40);
          }
          .selectors-wrapper {
            display: flex;
            flex-direction: column;
            visibility: hidden;
            .selectors {
              @include vw(margin-left, 40);
              @include vw(margin-top, 20);
              display: flex;
              select {
                @include vw(margin-right, 80);
                @include vw(font-size, 18);
                border: none;
                background-color: $blueLight;
                color: $white;
                line-height: 1.44;
                outline: none;
              }
            }
          }
          button {
            border: solid 1px $gray-home;
            background-color: transparent;
            cursor: pointer;
            @include vw(margin-right, 40);
            @include vw(height, 65);
            @include vw(width, 188);
          }
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .news-wapper {
    @include vw(height, 648);
    position: relative;
    border: solid 1px $grayButton;
    background-clip: padding-box;
    background-repeat: no-repeat;
    @include vw(border-width, 1);
    @include breakpoint(mobile) {
      @include vwMobile(height, 500);
    }

    .news-block {
      position: relative;
      width: 100%;
      height: 100%;
      @extend .util-image-placeholder;
      @include vw(padding, 0);
      transition: $corners-transition;
      .news-content-detail {
        @include vw(min-height, 195);
        position: relative;
        width: 100%;
        padding-bottom: 0;
        transition: $corners-transition;
        @include breakpoint(mobile) {
          @include vwMobile(min-height, 300);
        }
        .content {
          @include vw(padding-bottom, 50);
          @include breakpoint(mobile) {
            @include vwMobile(padding-bottom, 150);
          }
        }
        .social-media-group {
          position: absolute;
          bottom: 0;
          @include vw(right, 30);
          @include vw(left, 30);
        }
      }
      &:hover {
        @include vw(padding, 15);
        transition: $corners-transition;
      }
      &:hover .news-content-detail {
        p.content {
          color: $yellowIg;
          opacity: none;
        }
        transition: $corners-transition;
        @include vw(margin-bottom, 30);
      }
      &:hover .linebefore {
        background: $yellowIg;
        opacity: none;
      }
      &:hover .news-content::before {
        background: $yellowIg;
      }
      .corners {
        width: 100%;
        height: 100%;
        @include buildCorners(20, 0.1);
        @include breakpoint(mobile) {
          @include buildCorners(65, 0);
        }
      }
      .real-content {
        width: 100%;
        height: 100%;
      }
    }
  }
  .news-load-container {
    @include vw(margin-top, 100);
    @include vw(margin-left, 156);
    @include vw(margin-right, 156);
    @include vw(margin-bottom, 200);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 55);
      @include vwMobile(margin-bottom, 123);
    }
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      p {
        text-transform: uppercase;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          line-height: 1.38;
          @include vwMobile(margin-top, 20);
        }
      }
      .load-more {
        background-image: url('../../../assets/images/icons/no-border/Icon-Loading.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(height, 40);
        @include vw(width, 40);
        @include vw(margin-bottom, 20);
        @include breakpoint(mobile) {
          @include vwMobile(height, 30);
          @include vwMobile(width, 30);
        }
      }
    }
  }
}
.content-newsletter-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal {
  position: absolute;
  top: 100;
  width: 60vw;
  height: 50vh;
  background-color: $almostBlack;
  transition: 0.1s ease-in-out;
}
