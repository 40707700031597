.server-logs-container {
  @include vw(margin-bottom, 60);
  @include vw(margin-top, -22); //-22
  .icon-maintenance {
    @include vw(height, 80);
    @include vw(width, 80);
  }
  .server-shard-status-section {
    min-height: auto;
    .top-container {
      .icon-maintenance {
        @include vw(margin-left, -10);
        @include breakpoint(mobile) {
          @include vwMobile(height, 58);
          @include vwMobile(width, 58);
          @include vwMobile(margin-top, 13);
          @include vwMobile(margin-bottom, 15);
        }
      }
      div {
        @include vw(margin-left, -10);
      }
    }
    .empty-logs {
      text-align: center;
      @include vw(font-size, 16);
      line-height: 1.25;
      color: #a8a8a8;
      @include vw(padding-top, 16);
      @include vw(padding-bottom, 16);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        @include vwMobile(padding-top, 16);
        @include vwMobile(padding-bottom, 16);
      }
    }
    .server-header-tab {
      @include vw(padding-left, 40);
      @include vw(padding-right, 40);
      display: grid;
      grid-template-columns: 1fr 1fr 0.5fr 2fr;
      @include vw(height, 50);
      background-color: $blueDark;
      border-top: solid 1px $white02;
      border-bottom: solid 1px $white02;
      @include breakpoint(mobile) {
        display: none;
      }
      h5 {
        @include vw(font-size, 14);
        line-height: 2.86;
        color: $white;
        text-transform: uppercase;
        @include vw(padding-top, 4);
      }
    }
    .row {
      @include vw(padding-left, 40);
      @include vw(padding-right, 40);
      display: grid;
      grid-template-columns: 1fr 1fr 0.5fr 2fr;
      // @include vw(height, 80);
      height: auto;
      @include breakpoint(mobile) {
        display: none;
      }
      p,
      span {
        @include vw(font-size, 16);
        line-height: 1.25;
        color: #a8a8a8;
        // text-transform: capitalize;
        @include vw(padding-top, 20);
        @include vw(padding-bottom, 20);
        &:nth-child(1),
        &:nth-child(2) {
          @include vw(width, 160);
        }
      }
      .up {
        text-transform: capitalize;
      }
    }
    .none {
      background-color: $blueDark;
    }
    .server-header-tab-mobile {
      display: none;
      @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        width: 100%;
        .row-mobile {
          width: auto;
          @include vwMobile(padding, 20);
          @include vwMobile(margin-top, 20);
          border-top: 1px solid $white02;
          border-bottom: 1px solid $white02;
          display: grid;
          grid-template-columns: 1fr;
          .row-mobile-item {
            display: flex;
            justify-content: space-between;
            p,
            h5,
            span {
              @include vwMobile(font-size, 16);
              line-height: 1.75;
              color: $white;
              font-weight: normal;
              @include vwMobile(padding-bottom, 5);
              @include vwMobile(padding-top, 5);
            }
            p,
            span {
              color: #7b7b7b;
            }
          }
          .row-mobile-item:last-of-type {
            flex-direction: column;
            p {
              @include vwMobile(margin-top, -5);
            }
          }
          .alterante {
          }
        }
        .row-mobile:last-of-type {
          border-bottom: 0;
        }
      }
    }
  }
}
