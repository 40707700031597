.account-security-container {
  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .account-notice {
    display: flex;
    align-items: center;
    background-color: $green;
    @include vw(height, 65);
    @include vw(margin-top, 39);
    animation-duration: 4s;
    animation-name: custom;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    position: sticky;
    @include vw(top, 160);
    z-index: 10;
    @include breakpoint(mobile) {
      @include vwMobile(height, 65);
      @include vwMobile(top, 65);
      @include vwMobile(margin-top, 39);
    }
    p {
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    div {
      background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-white-border.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include vw(height, 40);
      @include vw(width, 40);
      @include vw(margin-left, 15);
      @include vw(margin-right, 17);
      @include breakpoint(mobile) {
        @include vwMobile(height, 25);
        @include vwMobile(width, 25);
        @include vwMobile(margin-left, 15);
        @include vwMobile(margin-right, 17);
      }
    }
  }
  @keyframes custom {
    0% {
      opacity: 0;
    }
    8% {
      opacity: 0;
    }
    12% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    28% {
      opacity: 1;
    }
    48% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .error {
    background-color: $red;
    @include breakpoint(mobile) {
      @include vwMobile(padding, 5);
      height: auto;
    }
    div {
      background-image: url('../../../../assets/images/icons/Icon-Close.svg');
      @include vw(background-size, 20);
      @include breakpoint(mobile) {
        @include vwMobile(background-size, 18);
      }
    }
  }
  .account-security-title {
    @include vw(margin-bottom, 10);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 10);
      @include vwMobile(margin-top, 30);
    }
    * {
      @include vw(font-size, 35);
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
      }
    }
  }
  .account-security-desc,
  .section-psw-desc {
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 30);
    }
    * {
      @include vw(font-size, 20);
      color: #a8a8a8;
      line-height: 2;
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    p:last-of-type {
      margin-bottom: 0;
    }
    a {
      color: $yellowMild;
      text-decoration: underline;
    }
  }
  .section-psw-desc {
    @include vw(margin-bottom, 25);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 25);
    }
  }
  .section-psw-code-mobile {
    .carousel-box-coordinates {
      display: none;
      @include breakpoint(mobile) {
        display: flex;
        justify-content: space-between;
        border: 1px solid $white01;
        @include vwMobileAll(padding, 20, 20, 20, 20);
        @include vwMobile(margin-bottom, 15);
        .qr-text {
          color: #a8a8a8;
          @include vwMobile(font-size, 18);
        }
        .qr-link {
          color: $yellowIg;
          font-weight: bold;
          @include vwMobile(font-size, 16);
        }
      }
    }
  }
  .m-off {
    @include vw(margin-bottom, 16);
  }
  .section-psw-title {
    @include vw(margin-bottom, 10);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 10);
    }
    * {
      @include vw(font-size, 26);
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 18);
      }
    }
  }
  .section-change-psw,
  .section-twofa {
    background-color: $blueDark;
    border: 1px solid $white02;
    .section-corners {
      height: 100%;
      width: 100%;
      @include buildCorners(20, 0);
      @include breakpoint(mobile) {
        @include buildCornersMobile(20, 0);
      }
      .section-padding {
        @include vwAll(padding, 7, 13, 13, 13);
      }
    }
    .open {
      border-bottom: 0;
      @include vw(padding-bottom, 20);
    }
  }
  .section-twofa:not(.detail) {
    @include vw(margin-top, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 40);
    }
  }
  .section-twofa:last-child {
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 60);
    }
  }
  .detail {
    border-top: 0;
    .section-corners {
      border-top: 0;
      .section-padding {
        @include vwAll(padding, 25, 13, 13, 13);
        display: flex;
        @include breakpoint(mobile) {
          @include vwAllMobile(padding, 25, 0, 0, 0);
        }
        .section-left {
          @include vw(margin-right, 20);
          .input-group {
            display: flex;
            justify-content: space-between;
            @include vw(margin-bottom, 40);
            width: 80%;
            @include breakpoint(mobile) {
              width: 100%;
            }
          }
          .request-enter {
            @include vw(margin-bottom, 20);
            * {
              @include vw(font-size, 20);
              line-height: 2;
              font-weight: normal;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 20);
              }
            }
            @include breakpoint(mobile) {
              @include vwMobile(margin-bottom, 20);
            }
          }
          input {
            outline: none;
            background-color: #162024;
            color: $white;
            border: 0;
            @include vw(width, 50);
            @include vw(height, 65);
            @include vw(font-size, 30);
            border: 1px solid $white01;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $yellowIg;
            @include breakpoint(mobile) {
              @include vwMobile(width, 50);
              @include vwMobile(height, 65);
              @include vwMobile(font-size, 30);
            }
          }
          input:focus {
            border-color: $yellowIg;
          }
          input:nth-child(3) {
            @include vw(margin-right, 30);
            @include breakpoint(mobile) {
              @include vwMobile(margin-right, 30);
            }
          }
          .input::-webkit-outer-spin-button,
          .input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .input[type='number'] {
            -moz-appearance: textfield;
          }
        }
        .section-right {
          .qr-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include vw(width, 284);
            @include vw(height, 333);
            background-color: $almostBlack;
            border: 1px solid $white01;
            @include breakpoint(mobile) {
              display: none;
            }
            .qr-image {
              @include vw(width, 188);
              @include vw(height, 188);
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: $blueDark;
              border: solid $white;
              @include vw(border-width, 6);
              @include vw(margin-bottom, 10);
              position: relative;
            }
            .qr-link {
              @include vw(font-size, 18);
              line-height: 2.22;
              color: $yellowMild;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .turn-off {
    .row {
      .col-6 {
        display: flex;
        justify-content: flex-end;
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 5);
          @include vwMobile(margin-bottom, 5);
        }
      }
    }
    .input-container {
      @include vw(margin-bottom, 10);
    }
    .input-container__row {
      margin-top: 0;
    }
    .theme-button-spec-positive {
      background-color: $red;
      border-color: $red;
      background-image: linear-gradient(to right, $red 50%, transparent 50%) !important;
    }
  }
  label {
    width: 70%;
    color: $yellowIg;
    line-height: 1.1;
    text-transform: capitalize;
    @include vw(font-size, 20);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 20);
      width: 100%;
    }
  }
  .row {
    display: flex;
    height: auto;
    justify-content: space-between;
    @include breakpoint(mobile) {
      flex-direction: column;
      @include vwMobile(margin-bottom, 0);
    }
    a {
      color: $yellowMild;
    }
  }
  .d-none {
    display: none;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .col {
    position: relative;
    .label {
      position: absolute;
      @include vw(font-size, 20);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        line-height: 2;
      }
      &::after {
        content: '*';
      }
      &.none {
        &::after {
          content: '';
        }
      }
    }
    input:invalid {
      box-shadow: none;
      outline: none;
    }
    input {
      width: 100%;
      border: solid 1px $gray-home;
      background-color: transparent;
      color: $gray;
      line-height: 1.38;
      outline: none;
      @include vw(font-size, 16);
      @include vw(height, 66);
      @include vw(border-left, 0);
      @include vw(border-right, 0);
      @include breakpoint(mobile) {
        @include vwMobile(height, 65);
        @include vwMobile(font-size, 16);
      }
    }
    .strenght-psw-container {
      @include vw(margin-top, 15);
      .strenght-psw-label {
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 15);
          @include vwMobile(font-size, 16);
        }
      }
    }
  }
  .tooltip-box {
    position: absolute;
    right: 0;
  }
  label {
    @include breakpoint(mobile) {
      width: 86%;
      word-break: break-word;
    }
  }
  .col-6 {
    width: 48%;
    @include breakpoint(mobile) {
      width: 100%;
    }
  }
  .theme-button-spec-positive {
    @include vw(width, 263);
    @include vw(height, 65);
    @include breakpoint(mobile) {
      width: 100%;
      @include vwMobile(height, 65);
      @include vwMobile(margin-top, 20);
      @include vwMobile(margin-bottom, 30);
    }
  }
  .theme-button-container {
    div {
      background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include vw(width, 8);
      @include vw(height, 9);
      @include breakpoint(mobile) {
        @include vwMobile(width, 6);
        @include vwMobile(height, 7);
        @include vwMobile(margin-right, 10);
      }
    }
    span {
      @include vw(font-size, 13);
      font-family: eurostile-extended, sans-serif;
      font-weight: 900;
      font-style: normal;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 13);
      }
    }
  }
  button:hover {
    background-color: $green;
    .theme-button-container div {
      background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
    }
    span {
      color: $black !important;
    }
  }
  .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    flex-direction: column;
    @include vw(margin-bottom, 40);
    @include vw(margin-top, 0);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 15);
      @include vwMobile(margin-top, 5);
    }
  }
  .input-container__row {
    display: flex;
    width: 100%;
    flex-direction: row;
    @include vw(margin-top, 45);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 30);
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $gray-home;
    background: $almostBlack;
    color: $white;
    text-align: center;
    @include vw(border-right, 0);
    @include vw(min-width, 50);
  }
  .icon-type {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include vw(width, 20);
    @include vw(height, 20);
    @include breakpoint(mobile) {
      @include vwMobile(width, 18);
      @include vwMobile(height, 18);
    }
  }
  .icon-psw {
    @extend .icon-type;
    background-image: url('../../../../assets/images/icons/no-border/Icon-Password.svg');
  }
  .icon-loading {
    @extend .icon-type;
    background-image: url('../../../../assets/images/icons/no-border/Icon-Loading.svg');
  }
  .icon-validate {
    @extend .icon-type;
    background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-Green.svg');
  }
  .icon-fail {
    @extend .icon-type;
    background-image: url('../../../../assets/images/icons/no-border/Icon-Close-Red.svg');
  }
  .iconWapper {
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray-home;
    border-right: 0;
    @include vw(width, 75);
    @include breakpoint(mobile) {
      @include vwMobile(width, 65);
    }
  }
  .iconWapperRight {
    border-right: 1px solid $gray-home;
    border-left: 0;
  }
}
.custom-tooltip-content {
  display: none;
}
