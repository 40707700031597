.list-social-media {
  @include vw(width, 250);
  justify-content: space-between;
  background-color: transparent;
  .icon-copy,
  .icon-facebook,
  .icon-twitter,
  .icon-email,
  .icon-instagram {
    margin: 0;
  }

  @include breakpoint(mobile) {
    @include vwMobile(width, 300);
    @include vwMobile(margin-top, 10);
  }
}

.list-social-media {
  @include vw(width, 320);
  @include vw(height, 80);
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: auto;
  margin-left: auto;
  @include breakpoint(mobile) {
    @include vwMobile(width, 316);
    @include vwMobile(height, 80);
    @include vwMobile(top, -100);
    @include vwMobile(left, -130);
  }
  .icon {
    @include vw(width, 40);
    @include vw(height, 40);
    @include image-display();
    @include breakpoint(mobile) {
      @include vwMobile(width, 40);
      @include vwMobile(height, 40);
    }
  }

  button.icon-facebook {
    background-image: url('../../../assets/images/icons/Facebook.svg');
    &:hover {
      background-image: url('../../../assets/images/icons/yellow-withBorder/icon-facebook.svg');
    }
  }
  button.icon-twitter {
    background-image: url('../../../assets/images/icons/Twitter.svg');
    &:hover {
      background-image: url('../../../assets/images/icons/yellow-withBorder/icon-twitter.svg');
    }
  }
  i.icon-instagram {
    display: none;
    background-image: url('../../../assets/images/icons/Instagram.svg');

    &:hover {
      background-image: url('../../../assets/images/icons/yellow-withBorder/icon-instagram.svg');
    }
  }
  button.icon-email {
    background-image: url('../../../assets/images/icons/Icon\ -\ Email.svg');
    // border: solid 1px $white !important;
    &:hover {
      background-image: url('../../../assets/images/icons/yellow-withBorder/icon-email.svg');
      // border: solid 1px $yellowIg !important;
    }
  }
  i.icon-copy {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $white;
    // border-top-color: $white;
    // border-left-color: $white;
    @include breakpoint(mobile) {
      @include vwMobile(margin-right, 19);
    }
    div {
      background-image: url('../../../assets/images/icons/copy.svg');
      background-size: contain;
      @include vw(width, 25);
      @include vw(height, 23);
      @include image-display();
      @include breakpoint(mobile) {
        @include vwMobile(width, 25);
        @include vwMobile(height, 23);
      }
    }
    &:hover {
      border: solid 1px $yellowIg;
      background-image: url('../../../assets/images/icons/yellow-withBorder/icon-copy.svg');
    }
    &:hover div {
      background-image: none;
    }
  }
}
.m-0 {
  margin: 0;
}
.link-container {
  @include vw(min-width, 100);
  border: 0;
  background-color: $yellowIg;
  @include vw(height, 50);
  display: flex;
  align-items: center;
  justify-content: center;
  @include vw(padding-left, 10);
  @include vw(padding-right, 10);
  @include breakpoint(mobile) {
    @include vwMobile(height, 50);
  }
  p {
    @include vw(font-size, 16);
    color: $almostBlack;
    line-height: 1.38;
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 16);
    }
  }
}
