@import './buypage-one-blocks/buypage-packages.styles';
@import './buypage-one-blocks/buy-page-notice.styles';
@import './buypage-one-blocks/buypage-info.styles';
@import './buypage-one-blocks/buypage-logindisclaim.styles';
@import './buypage-one-blocks/buypage-specs.styles';
@import './buypage-two-blocks/buypage-two.styles';
@import './buypage-three-blocks/buypage-thankyou.scss';
@import './buypage-three-blocks/resend-confirm-block/resend-confirm.scss';
@import './buypage-two-blocks/buypage-convert-modal.styles';
div {
  &.limitHeader {
    position: absolute;
    display: grid;
    width: 100%;
    background-color: $yellowHeader;
    grid-template-columns: 1154fr 395fr 59fr;
    @include vw(top, 78);
    @include vw(min-height, 70);
    @include vwAll(padding, 0, 156, 0, 156);

    @include breakpoint(mobile) {
      @include vwMobile(min-height, 70);
      @include vwMobile(top, 65);
      @include vwMobileAll(padding, 0, 20, 0, 20);
    }

    div {
      &.displayMessage {
        display: flex;
        height: inherit;
        align-items: center;
        p {
          @include vw(font-size, 20);
          color: $blueDeep;

          @include breakpoint(mobile) {
            @include vwMobile(font-size, 14);
          }
        }
      }

      &.progressBar {
        @include breakpoint(mobile) {
          display: none;
        }
        .bar {
          position: relative;
          z-index: 1;
          width: 100%;
          background-color: $black;
          @include vw(height, 4);
          @include vwAll(margin, 20, 0, 0, 0);
        }
        div {
          &.barLimit {
            @extend .bar;
          }

          &.barUsed {
            position: relative;
            z-index: 2;
            background-color: $white;
            @include vw(bottom, 4);
            @include vw(height, 4);
          }
        }
        p {
          color: black;
          font-weight: bold;
          text-transform: uppercase;
          &.left {
            float: left;
            @include vw(font-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }

          &.right {
            float: right;
            @include vw(font-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }
        }
      }

      &.close {
        @include vw(height, 20);
        @include vw(width, 20);
        @include vw(margin-top, 25);
        @include vw(margin-left, 40);
        background-image: url('../../../assets/images/icons/no-border/Icon-Close-Black.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        @include breakpoint(mobile) {
          @include vwMobile(height, 20);
          @include vwMobile(width, 20);
          @include vwMobile(margin-top, 25);
          @include vwMobile(margin-left, 66);
        }
      }
    }
  }

  &.limitHeaderTwo {
    display: grid;
    grid-template-columns: 1549fr 59fr;

    span {
      text-decoration: underline;
      @include vw(font-size, 16);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
  }
  &.errorMessage {
    p {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      color: $white;
      @include vw(font-size, 50);
      @include vw(height, 1080);
    }
  }

  &.parentBlur {
    position: absolute;

    z-index: 3;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(30.8px);
    backdrop-filter: blur(30.8px);
    background-color: $black;

    opacity: 0.8;
  }

  &.popUpWaitingList {
    @include vw(width, 600);
    @include vw(height, 450);

    @include buildCorners(35, 0);

    position: absolute;
    z-index: 4;

    background-color: $blueDeep;
    text-align: center;
    @include vw(top, 315);
    @include vw(left, 660);

    @include breakpoint(mobile) {
      @include vwMobile(width, 440);
      @include vwMobile(height, 631);

      @include vwMobile(top, 83);
      @include vwMobile(left, 20);
      @include vwMobile(border-width, 20);
    }
    div {
      &.close {
        border-style: solid;
        border-color: $gray;

        background-clip: content-box;
        background-image: url('../../../assets/images/icons/no-border/Icon-Close.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(width, 30);
        @include vw(height, 30);
        @include vw(padding, 7);
        @include vw(border-width, 0.1);
        @include vw(margin-left, 504);

        @include breakpoint(mobile) {
          @include vwMobile(width, 30);
          @include vwMobile(height, 30);
          margin-left: auto;
        }
      }

      &.footeremail {
        @include breakpoint(from-phablet) {
          @include vw(width, 496);
          @include vw(margin-bottom, 100);
          display: inline-block;
        }

        form {
          p {
            &.popUpheader {
              font-weight: bold;
              text-transform: uppercase;
              @include vw(font-size, 22);
              @include vw(margin-bottom, 60);

              @include breakpoint(mobile) {
                @include vwMobile(font-size, 22);
                @include vwMobile(margin-bottom, 60);
              }
            }

            &.disclaimer {
              color: $gray-dark;
              @include vw(font-size, 14);

              a {
                @include vw(font-size, 14);
              }
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 14);
                @include vwMobile(margin-bottom, 80);

                a {
                  @include breakpoint(mobile) {
                    @include vwMobile(font-size, 14);
                  }
                }
              }
            }
          }

          .util-input {
            @include vw(margin-left, 13);
            @include vw(margin-right, 7);
            width: 100%;
            @include vw(margin-bottom, 35);
            @include breakpoint(mobile) {
              @include vwMobile(height, 65);
              @include vwMobile(margin-bottom, 80);
            }

            div.first {
              border-style: none;
              margin: auto 0;
              background-image: url('../../../assets/images/icons/no-border/Icon-Email.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              @include vw(width, 25);
              @include vw(height, 25);
              @include vw(margin-right, 25);
              @include vw(margin-left, 25);
              @include breakpoint(mobile) {
                @include vwMobile(width, 25);
                @include vwMobile(height, 25);
                @include vwMobile(margin-right, 25);
                @include vwMobile(margin-left, 25);
              }
            }

            div.last {
              border-style: none;
              margin: auto 0;
              background-image: url('../../../assets/images/icons/no-border/checkmark-green.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              @include vw(width, 25);
              @include vw(height, 25);
              @include vw(margin-right, 25);
              @include vw(margin-left, 25);
              @include breakpoint(mobile) {
                @include vwMobile(width, 25);
                @include vwMobile(height, 25);
                @include vwMobile(margin-right, 25);
              }
            }
          }
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            margin: 0 auto;
            background-color: $white;
            cursor: pointer;
            outline: none;
            @include vw(height, 65);
            @include vw(width, 200);

            @include vw(margin-bottom, 75);
            @include breakpoint(mobile) {
              @include vwMobile(height, 65);
              @include vwMobile(width, 200);

              @include vwMobile(margin-bottom, 100);
            }
            .icon-send {
              @include vw(height, 12);
              @include vw(width, 20);
              background-image: url('../../../assets/images/icons/icon-send-black.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              @include vw(margin-right, 8);
              @include breakpoint(mobile) {
                @include vwMobile(height, 35);
                @include vwMobile(width, 35);
                @include vwMobile(margin-right, 8);
              }
            }
            span {
              @include vw(font-size, 14);
              font-weight: 800;
              line-height: 1.38;
              text-transform: uppercase;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
              }
            }
          }
        }
      }
    }
  }
  .modal-content-nvidia {
    max-height: 100vh;
    overflow-y: auto;
    outline: none;
    scrollbar-color: $gray-scroll $almostBlack;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 7.5px;
    }
    &::-webkit-scrollbar-track {
      background: $almostBlack;
    }
    &::-webkit-scrollbar-thumb {
      border-right: 1px solid $almostBlack;
      background: $gray-scroll;
    }
    .nvidia-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      @include vw(padding-bottom, 150);
      .close-button {
        background-color: $almostBlack;
      }
      @include breakpoint(mobile) {
        @include vwMobile(padding-bottom, 100);
      }
      .corners {
        position: absolute;
        height: 100%;
        width: 100%;
        padding: 0;
      }
      .nvidia-description-title {
        @include vw(margin-top, 25);
        * {
          @include vw(font-size, 22);
          line-height: 1.36;
          color: $white;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 22);
          }
        }
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 25);
        }
      }
      .nvidia-description {
        width: 100%;
        @include vw(padding-left, 30);
        @include vw(padding-right, 30);
        * {
          margin: 0;
          @include vw(margin-top, 20);
        }
      }
      .nvidia-image-intro {
        width: 100%;
        @include vw(height, 250);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include breakpoint(mobile) {
          @include vwMobile(height, 200);
        }
      }
      .nvidia-logo {
        position: absolute;
        background-size: cover;
        @include vw(width, 290);
        @include vw(height, 73);
        @include vw(top, 110);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        @include breakpoint(mobile) {
          @include vwMobile(width, 190);
          @include vwMobile(height, 50);
          @include vwMobile(top, 80);
        }
      }
      .nvidia {
        position: absolute;
        bottom: 0;
        background-color: $almostBlack;
        text-decoration: none;
        border: 1px solid $gray-home;
        @include vw(margin-bottom, 54);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        outline: none;
        @include breakpoint(mobile) {
          @include vwMobile(min-width, 200);
          @include vwMobile(height, 65);
          @include vwMobile(margin-bottom, 30);
        }
        div {
          background-image: url('../../../assets/images/icons/no-border/Icon-Language.svg');
          background-size: contain;
          @include vw(height, 25);
          @include vw(width, 25);
          @include breakpoint(mobile) {
            @include vwMobile(height, 25);
            @include vwMobile(width, 25);
          }
        }
        span {
          @include vw(font-size, 16);
          line-height: 1.38;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 14);
          }
        }
      }
    }
  }
  .play-everywhere-container {
    width: auto;
    @include vw(height, 500);
    border: 1px solid $white02;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .play-everywhere-title {
    @include vw(margin-top, 102);
    @include vw(margin-left, 60);
    * {
      margin: 0;
      @include vw(font-size, 70);
      line-height: 0.86;
      font-weight: 900;
    }
  }
  .play-everywhere-desc {
    @include vw(margin-top, 20);
    @include vw(margin-left, 60);
    * {
      @include vw(font-size, 20);
      line-height: 0.86;
      color: #ffd255;
      font-family: eurostile-extended, sans-serif;
      font-weight: 900;
      font-style: normal;
    }
  }
  .box-corners {
    height: 100%;
    width: 100%;
    @include buildCorners(20, 0);
    @include breakpoint(mobile) {
      @include buildCornersMobile(20, 0);
    }
  }
  .steam-btns {
    display: flex;
    @include vw(margin-top, 40);
    @include breakpoint(mobile) {
      grid-template-columns: 1fr;
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
    }
    button,
    a {
      @include vw(min-width, 280);
      @include vw(height, 65);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        width: 100%;
        @include vwMobile(height, 65);
        @include vwMobile(margin-bottom, 15);
      }
      .theme-button-container {
        margin-right: auto;
        margin-left: auto;
        div {
          background-image: url('../../../assets/images/icons/Icon-Play.svg');
          @include vw(width, 20);
          @include vw(height, 20);
          background-size: contain;
          @include breakpoint(mobile) {
            @include vwMobile(width, 20);
            @include vwMobile(height, 20);
            @include vwMobile(margin-right, 10);
          }
        }
        span {
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          font-style: normal;
          @include vw(font-size, 15);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 15);
          }
        }
      }
      &.joinBeta {
        background-color: $yellowIg;
        @include vw(margin-left, 60);
        background-image: linear-gradient(to bottom, #ffa700, #ffd55f);
        &:hover {
          background-color: $yellowIg;
          border-color: $yellowIg;
        }
        .theme-button-container {
          div {
            background-image: url('../../../assets/images/icons/Icon-Wishlist.svg');
            @include vw(height, 25);
            @include vw(width, 25);
            filter: invert(100%);
            @include breakpoint(mobile) {
              @include vwMobile(height, 25);
              @include vwMobile(width, 25);
              @include vwMobile(margin-right, 15);
            }
          }
          span {
            color: $black;
          }
        }
        &:hover .theme-button-container div {
          background-image: url('../../../assets/images/icons/Icon-Wishlist.svg');
        }
        &:hover .theme-button-container span {
          color: $black;
        }
      }
      &.tryFree {
        @include vw(min-width, 280);
        @include vw(margin-left, 20);
        background-image: linear-gradient(to bottom, #0027ff, #221772);
        color: $white;
        border: none;
        .theme-button-container {
          div {
            background-image: url('../../../assets/images/icons/Icon-Wishlist.svg');
            @include vw(height, 25);
            @include vw(width, 25);
            @include vw(margin-right, 10);
            filter: none;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include breakpoint(mobile) {
              @include vwMobile(height, 25);
              @include vwMobile(width, 25);
              @include vwMobile(margin-right, 10);
            }
          }
          span {
            color: $white;
          }
        }
        &:hover {
          border: none;
          .theme-button-container {
            border-image-source: url('../../../assets/images/utils/border-image-corners-white.svg');
            div {
              background-image: url('../../../assets/images/icons/Icon-Wishlist.svg');
            }
            span {
              color: $white;
            }
          }
        }
      }
      &.geforce {
        @include vw(margin-left, 20);
        @include vw(min-width, 340);
        background-image: none;
        background-color: $white;
        .theme-button-container {
          div {
            background-image: url('../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
            @include vw(height, 10);
            @include vw(width, 10);
            @include breakpoint(mobile) {
              @include vwMobile(height, 25);
              @include vwMobile(width, 25);
              @include vwMobile(margin-right, 10);
            }
          }
          span {
            color: $black;
          }
        }
        &:hover {
          border: none;
          .theme-button-container {
            border-image-source: url('../../../assets/images/utils/border-image-corners-black.svg');
            div {
              background-image: url('../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
            }
            span {
              color: $black;
            }
          }
        }
      }
    }
  }
  .textButtonContainerMain {
    @include vw(height, 65);
    @include vw(padding-left, 156);
    @include vw(padding-right, 156);
    border-bottom: 1px solid $white02;
    width: 100%;
    background-color: $almostBlack;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include breakpoint(mobile) {
      @include vwMobile(height, 50);
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
    }
    .bread-item-container {
      display: flex;
      height: 100%;
      align-items: center;
      @include breakpoint(mobile) {
        display: none;
      }
    }
    .mobile {
      display: none;
      @include breakpoint(mobile) {
        display: flex;
        p.bread-item {
          &:nth-child(2):before {
            margin-right: 0;
            content: '2';
          }
          &:nth-child(3):before {
            margin-right: 0;
            content: '3';
          }
          &:nth-child(4):before {
            margin-right: 0;
            content: '4';
          }
        }
        p.bread-item.checkout {
          &:nth-child(1):before {
            margin-right: 0;
            content: '1';
          }
          &:nth-child(2):before {
            @include vwMobile(margin-right, 5);
            content: '2.';
          }
          &:nth-child(3):before {
            margin-right: 0;
            content: '3';
          }
          &:nth-child(4):before {
            margin-right: 0;
            content: '4';
          }
        }
      }
    }
    p,
    .change-currency-item {
      color: $white;
      font-weight: bold;
      @include vw(margin-right, 40);
      @include vw(font-size, 20);
      line-height: 1.4;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 18);
        line-height: 1.36;
        @include vwMobile(margin-right, 10);
      }
    }
    .bread-item {
      height: 100%;
      display: flex;
      align-items: center;
    }
    p.bread-item {
      &:nth-child(1):before {
        color: $gray-medium;
        content: '1.';
        @include vw(margin-right, 10);
      }
      &:nth-child(2):before {
        color: $gray-medium;
        content: '2.';
        @include vw(margin-right, 10);
      }
      &:nth-child(3):before {
        color: $gray-medium;
        content: '3.';
        @include vw(margin-right, 10);
      }
      &:nth-child(4):before {
        color: $gray-medium;
        content: '4.';
        @include vw(margin-right, 10);
      }
    }
    .change-currency:hover .change-currency-options {
      display: block;
    }
    .change-currency:hover .change-currency-item {
      border-bottom: 3px solid $yellowIg;
    }
    .change-currency {
      position: relative;
      @include breakpoint(mobile) {
      }
      .change-currency-item {
        margin-right: 0;
        cursor: pointer;
        @include vw(font-size, 16);
        @include vw(padding-bottom, 5);
        line-height: 1.6 !important;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          @include vwMobile(padding-bottom, 0);
          display: none;
        }
      }
      .mobile-item {
        display: none;
        @include breakpoint(mobile) {
          display: flex;
          align-items: center;
        }
      }
      .change-currency-item:after {
        display: inline-block;
        background-image: url('../../../assets/images/icons-navbar/Icon-NavDropArrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        content: '';
        @include vw(width, 10);
        @include vw(height, 10);
        @include vw(margin-left, 5);
        @include breakpoint(mobile) {
          @include vwMobile(width, 8);
          @include vwMobile(height, 8);
          @include vwMobile(padding-left, 15);
        }
      }
      .change-currency-options {
        display: none;
        @include vw(padding, 20);
        border: solid 1px #282e30;
        background-color: $almostBlack;
        @include vw(width, 270);
        height: auto;
        position: absolute;
        @include vw(top, 34);
        right: 0;
        z-index: 10;
        @include breakpoint(mobile) {
          @include vwMobile(width, 270);
          @include vwMobile(top, 28);
          @include vwMobile(padding, 20);
        }
        .corners {
          @include buildCorners(17, 0);
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          @include vw(padding-left, 13);
          @include vw(padding-top, 10);
          @include breakpoint(mobile) {
            @include buildCornersMobile(17, 0);
          }
          a {
            color: $gray-dark;
            @include vw(font-size, 16);
            cursor: pointer;
            @include vw(margin-bottom, 10);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              @include vwMobile(margin-bottom, 15);
              display: flex;
              align-items: center;
            }
            input[type='checkbox'] {
              box-sizing: border-box;
              border-style: solid;
              border-color: $white;
              -webkit-appearance: none;
              -moz-appearance: none;
              -o-appearance: none;
              appearance: none;
              background-color: $blueDeep;
              cursor: pointer;
              outline: none;
              @include vw(width, 12);
              @include vw(height, 12);
              @include vwAll(margin, 0, 15, 0, 0);
              @include vw(border-width, 1);
              @include breakpoint(mobile) {
                @include vwMobile(width, 20);
                @include vwMobile(height, 20);
                @include vwAllMobile(margin, 0, 15, 0, 0);
              }
            }
            input[type='checkbox']:checked {
              background-clip: content-box;
              background-color: $yellowIg;
              @include vwAll(padding, 3, 3, 3, 3);
              @include breakpoint(mobile) {
                @include vwMobileAll(padding, 5, 5, 5, 5);
              }
            }
          }
        }
      }
    }
    .active {
      color: $yellowIg;
      border-bottom: 2px solid $yellowIg;
      border-top: 2px solid transparent;
      &:before {
        color: $yellowIg !important;
      }
    }
    .headerModificationPackages {
      @include vw(margin-bottom, 15);
      display: inline-block;
    }
    .button-container {
      margin-left: auto;
      @include breakpoint(mobile) {
        display: none;
      }
      p {
        display: none;
      }
    }
    .steam-buttonBuy {
      background-color: $almostBlack;
      @include vw('width', 250);
    }
  }
}
