.copy-link {
  display: inline-block;
  span {
    @extend .iconLogo;
    @include vw(width, 40);
    @include vw(height, 40);
    @include vw(margin-right, 40);

    position: relative;
    z-index: 3;
    &:hover {
      filter: $white-to-yellow-filter;
    }

    &.iconLogoCopy {
      border: 1px solid $white;
      background-image: url('../../../assets/images/icons/copy.svg');
      @include vw(background-size, 35);
    }
  }

  .popup-content {
    width: 100px !important;
    padding: 0 !important;
    border: 0 !important;
    background: $yellowIg !important;
    @include vw(margin-left, -17);

    .link-container {
      @include vw(height, 50);
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint(mobile) {
        @include vwMobile(height, 50);
      }
      p {
        @include vw(font-size, 16);
        color: $almostBlack;
        line-height: 1.38;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
    .popup-arrow {
      background-color: $yellowIg !important;
    }
  }
}
