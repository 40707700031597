.profilemenucontainer {
  @extend .submenutemplate;
  z-index: 3;
  @include vw(right, 0);
  @include vw(width, 300);
  @include vw(min-height, 465);

  @include breakpoint(mobile) {
    width: 100%;
  }

  .profileheader {
    display: none;

    @include breakpoint(mobile) {
      display: flex;
      width: inherit;
      border-bottom-width: 1px;

      border-bottom-style: solid;
      border-bottom-color: $gray;
      color: $white;
      text-transform: uppercase;
      @include vwMobile(font-size, 22);
      @include vwMobile(padding-bottom, 15);
      @include vwMobile(margin-bottom, 40);
    }
  }

  .profilesubmenu {
    @extend .simplesubmenutemplate;
    // border-style: none;
    @include vwAll(padding, 10, 5, 15, 5);
    @include breakpoint(mobile) {
      border: 0;
    }
    width: 100%;
    .link-container-profile {
      display: flex;
      @include vw(margin-bottom, 15);
      a {
        color: $gray;
        font-weight: normal;
        @include vw(font-size, 16);
        text-decoration: none;
        text-transform: capitalize;
        &:hover {
          color: $yellowIg;
        }
        &::before {
          content: '+';
          @include vw(margin-right, 15);
          @include breakpoint(mobile) {
            @include vwMobile(margin-right, 15);
          }
        }
        @include breakpoint(mobile) {
          display: block;
          @include vwMobile(font-size, 16);
          // @include vwMobile(margin-left, 25);
        }
      }
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 25);
      }
      &:nth-child(4),
      &:nth-child(6),
      &:last-of-type {
        border-bottom: solid 1px rgba(255, 255, 255, 0.05);
        @include vw(padding-bottom, 20);
        @include breakpoint(mobile) {
          @include vwMobile(padding-bottom, 20);
        }
      }
    }
    .account-picture {
      margin: 0 auto;
      @include vw(width, 110);
      @include vw(height, 110);
      background-image: url('../../../../assets/images/placeholderavatar.jpg');
      @extend .navbarimage;

      @include breakpoint(mobile) {
        @include vwMobile(width, 130);
        @include vwMobile(height, 130);
      }
    }
    .intro {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 1px solid rgba($color: #414141, $alpha: 0.5);
      @include vw(padding-bottom, 15);
      @include vw(margin-bottom, 30);
      @include breakpoint(mobile) {
        @include vwMobile(padding-bottom, 15);
        @include vwMobile(margin-bottom, 30);
      }
      .intro-tag {
        color: $yellowIg;
        @include vw(font-size, 8);
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        line-height: 1.2;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 8);
        }
      }
      .intro-username {
        @include vw(font-size, 20);
        text-transform: uppercase;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
          text-transform: uppercase;
        }
      }
    }

    .submenucontent {
      @extend .submenucontenttemplate;
    }

    .util-button-text {
      margin: 0 auto;
      @include vw(margin-top, 40);
      @include vw(height, 40);
      background-color: $white;
      color: $almostBlack;
      @include vw(font-size, 16);
      line-height: 1.4;
      width: 100%;
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(margin-top, 60);
        @include vwMobile(margin-bottom, 40);
        @include vwMobile(height, 65);
        @include vwMobile(font-size, 16);
      }
      div {
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include vw(height, 8);
        @include vw(width, 9);
        @include vw(margin-right, 15);
        @include breakpoint(mobile) {
          @include vwMobile(height, 20);
          @include vwMobile(width, 16);
          @include vwMobile(margin-right, 20);
        }
      }
      span {
        @include vw(font-size, 10);
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        margin: 0;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 10);
        }
      }
    }
  }
}
