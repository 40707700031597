@import './homepagesections/homepagestart.styles';
@import './homepagesections/homepagemiddle.styles';
@import './homepagesections/homepagebetasocial.styles';
@import './homepagesections/home.menu.styles';

div {
  &.Home {
    overflow: hidden;
    width: 100%;
    .fullWidth {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .homePageParent {
      position: relative;
      // @include vw(margin-top, 100);
      button.joinBeta {
        &:hover {
          background-color: $color-positive;
        }
        .theme-button-container {
          div {
            background-image: url('../../../assets/images/icons/no-border/Icon-Game.svg');
          }
        }

        &:hover .theme-button-container div {
          background-image: url('../../../assets/images/icons/no-border/Icon-Game-Green.svg');
        }
        &:hover .theme-button-container span {
          color: $black;
        }
        @include breakpoint(from-phablet) {
          //  @include vw(margin-left, 40);
        }
      }
    }
    div.opacity {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(12, 19, 21, 0) 69%, $almostBlack);
      .background-video {
        position: relative;
        z-index: -100;
        width: 100%;
        @include breakpoint(mobile) {
          opacity: 0.4;
          @include vwMobile(height, 400);
          object-fit: cover;
        }
      }
      @include breakpoint(mobile) {
        @include vwMobile(top, 65);
        @include vwMobile(height, 400);
      }
    }
    .icon-title {
      text-transform: uppercase;
    }
    .green-gradient-btn {
      background-image: linear-gradient(to bottom, #5ea97d, #53f8df);
      &:hover {
        background-image: linear-gradient(178deg, #a7ffcb 7%, #53f8df 91%);
      }
      .theme-button-container {
        div {
          background-image: url('../../../assets/images/icons-buttons/Icon-Continue-Black.svg') !important;
          @include vw(height, 12);
          @include vw(width, 9);
          background-size: contain;
        }
        span {
          color: $black;
        }
      }
    }
  }
}
