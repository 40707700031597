.containerLogin {
  text-align: right;

  .util-button-continue div {
    border-right: 0;
    @include vw(margin-left, 30);
    @include breakpoint(mobile) {
      @include vwMobile(margin-left, 30);
    }
  }
  .util-button-continue span {
    justify-content: flex-start;
  }
  .loginCase {
    display: inline-block;
    @include breakpoint(mobile) {
      @include vwMobile(height, 60);
      @include vwMobile(width, 180);
      @include vwMobile(font-size, 16);
      @include vwMobile(margin-top, 60);
    }
  }
  .notice {
    @include vw(margin-top, 65);
    color: #a8a8a8;
    @include vw(font-size, 16);
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .logoutCase {
    display: inline-block;
    @include vw(margin-bottom, 80);
    @include breakpoint(mobile) {
      @include vwMobile(height, 60);
      @include vwMobile(width, 180);
      @include vwMobile(font-size, 16);
      @include vwMobile(margin-top, 60);
    }
  }

  .loginMessage {
    color: var(--grey);
    @include vw(font-size, 15);
    text-align: right;
    @include vwAll(margin, 40, 0, 55, 0);

    @include breakpoint(mobile) {
      @include vwMobile(font-size, 15);
      @include vwMobile(line-height, 30);
      @include vwAllMobile(margin, 40, 0, 40, 0);
    }
  }
}
