.community-container {
  display: flex;
  height: 100vh;
  align-items: center;
  background-image: url('../../../../assets/images/community-bg.jpg');
  background-position-y: 30%;
  background-repeat: no-repeat;
  background-size: cover;
  .community-link-container {
    a {
      @include breakpoint(mobile) {
        width: 100%;
      }
      .connect-comunity {
        @include vw(height, 160);
        @include vw(width, 496);
        @include vw(margin-left, 60);
        @include vw(margin-top, 35);
        @include vw(margin-bottom, 35);
        position: relative;
        border: 0.1px solid $grayButton;
        background-color: $almostBlack;
        transition: $corners-transition;
        @include breakpoint(mobile) {
          @include vwMobile(height, 160);
          width: 100%;
          @include vwMobile(margin-left, 0);
        }
        @include vw(padding, 0);
        &:hover {
          // @include vw(padding, 10);
          background-color: $blueDark;
          cursor: pointer;
          transition: $corners-transition;
          @include vw(padding, 15);
        }
        .corners {
          width: 100%;
          height: 100%;
          @include buildCorners(20, 0);
          @include breakpoint(mobile) {
            @include buildCorners(65, 0);
          }
        }
        .real-content {
          position: absolute;
          top: 0;
          left: 40px;
          display: flex;

          height: 100%;
          align-items: center;
          justify-content: space-between;
          @include breakpoint(mobile) {
            justify-content: start;
          }
          .image-users {
            @include vw(height, 80);
            @include vw(width, 80);
            background-image: url('../../../../assets/images/icons/Icon-Comunity.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include breakpoint(mobile) {
              @include vwMobile(height, 80);
              @include vwMobile(width, 80);
            }
          }
          .image-users-forum {
            background-image: url('../../../../assets/images/icons/Icon-Comment.svg');
          }
          .image-users-discord {
            background-image: url('../../../../assets/images/icons/Icon\ -\ Discord.svg');
          }
          .text-wrapper {
            @include vw(margin-left, 40);
            @include breakpoint(mobile) {
              @include vwMobile(margin-left, 20);
            }
            .title {
              line-height: 1.36;
              text-transform: uppercase;
              @include vw(font-size, 22);
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
                line-height: 1.38;
              }
            }
            .subtitle {
              @include vw(font-size, 20);
              line-height: 2;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
                line-height: 2.5;
              }
            }
          }
        }
      }
    }
  }
  .community-follow-us {
    h1 {
      @include vw(font-size, 57);
      @include vw(margin-left, 114);
      @include vw(margin-bottom, 44);
    }

    .social-icons {
      display: flex;
      flex-direction: column;
      .icon {
        @include vw(margin-left, 60);
        @include vw(height, 80);
        @include vw(width, 80);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .row-one,
      .row-two {
        display: flex;
      }
      .row-two {
        @include vw(margin-top, 50);
      }
      .twitter {
        @include vw(margin-left, 87);
        background-image: url('../../../../assets/images/icons/Twitter.svg');
        &:hover {
          background-image: url('../../../../assets/images/icons/yellow-withBorder/icon-twitter.svg');
        }
      }
      .facebook {
        background-image: url('../../../../assets/images/icons/Facebook.svg');
        &:hover {
          background-image: url('../../../../assets/images/icons/yellow-withBorder/icon-facebook.svg');
        }
      }
      .instagram {
        background-image: url('../../../../assets/images/icons/Instagram.svg');
        &:hover {
          background-image: url('../../../../assets/images/icons/yellow-withBorder/icon-instagram.svg');
        }
      }
      .reddit {
        @include vw(margin-left, 157);
        background-image: url('../../../../assets/images/icons/Reddit.svg');
        &:hover {
          background-image: url('../../../../assets/images/icons/yellow-withBorder/icon-reddit.svg');
        }
      }
      .twitch {
        background-image: url('../../../../assets/images/icons/Twitch.svg');
        &:hover {
          background-image: url('../../../../assets/images/icons/yellow-withBorder/icon-twitch.svg');
        }
      }
    }
  }
}
