.categories-container-custom {
  .image {
    cursor: pointer;
  }
  .popup-component-download,
  .popup-component-preview {
    .popup-overlay {
      background: rgba(0, 0, 0, 0.8) none repeat scroll 0% 0% !important;
    }
    .popup-exit-icon {
      position: absolute;
      z-index: 100;
      border-width: 1px;
      border-style: solid;
      border-color: $white;
      background-clip: content-box;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Close.svg');
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      @include vw(background-size, 30);
      @include vw(top, -70);
      @include vw(right, 0);
      @include vw(width, 50);
      @include vw(height, 50);

      @include breakpoint(mobile) {
        position: fixed;
        @include vwMobile(top, 20);
        @include vwMobile(right, 20);
        @include vwMobile(width, 30);
        @include vwMobile(height, 30);
        @include vwMobile(padding, 5);
        @include vwMobile(margin-left, 437);
        @include vwMobile(background-size, 20);
      }
    }
  }
  .popup-component-preview {
    .popup-content {
      width: 70% !important;
      padding: 0 !important;
      border: 0 !important;
      background-color: $almostBlack !important;
      @include breakpoint(mobile) {
        width: 100% !important;
      }
      .popup-container {
        width: 100%;
        height: 100%;
        .popup-image-name,
        .popup-image-position,
        .popup-arrow-left,
        .popup-arrow-right {
          position: absolute;
          color: $white;
        }
        .popup-image-name {
          @include vw(bottom, -50);
          display: flex;
          align-items: center;
          @include breakpoint(mobile) {
            @include vwMobile(bottom, -50);
            @include vwMobile(left, 20);
          }
          .popup-image-name-line {
            @include vw(height, 2);
            @include vw(width, 40);
            @include vw(margin-right, 40);
            background-color: $white;
            background-image: url('../../../../assets/images/icons/icon-line.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0.8;
            @include breakpoint(mobile) {
              @include vwMobile(height, 2);
              @include vwMobile(margin-right, 15);
              @include vwMobile(width, 20);
            }
          }
          p {
            @include vw(font-size, 20);
            color: $white;
            line-height: 2;
            opacity: 1;
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
              line-height: 2.5;
            }
          }
        }
        .popup-image-position {
          @include vw(bottom, -55);
          @include vw(right, 0);
          @include vw(font-size, 35);
          font-weight: bold;
          line-height: 1.4;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 25);
            @include vwMobile(top, 490);
            @include vwMobile(right, 381);
          }
        }
        .popup-arrow-left,
        .popup-arrow-right {
          top: 45%;
          border: 1px solid $white;
          background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          transform: rotate(180deg);
          @include vw(width, 70);
          @include vw(height, 70);
          @include vw(background-size, 25);
          &:hover {
            border: 2px solid $yellowIg;
            background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
          }
          @include breakpoint(mobile) {
            @include vwMobile(top, 490);
            @include vwMobile(width, 40);
            @include vwMobile(height, 40);
            @include vwMobile(background-size, 15);
          }
        }
        .popup-arrow-left {
          @include vw(left, -100);
          @include breakpoint(mobile) {
            @include vwMobile(left, 355);
          }
        }
        .popup-arrow-right {
          @include vw(right, -100);
          transform: none;
          @include breakpoint(mobile) {
            @include vwMobile(right, 20);
          }
        }

        .popup-image {
          width: 100%;
          background-image: url('https://i1.wp.com/fullsync.co.uk/wp-content/uploads/2019/01/dual-universe-header.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          @include vw(height, 700);
          @include breakpoint(mobile) {
            @include vwMobile(height, 227);
          }
        }
        .react-player {
          height: 70vh !important;
        }
        video {
          width: 100%;
          background-color: $almostBlack;
          object-fit: cover;
          outline: none;
          @include vw(height, 700);
          @include breakpoint(mobile) {
            @include vwMobile(height, 227);
          }
        }
        .popup-image-desc {
          width: 100%;
          @include vw(height, 80);
        }
      }
    }
  }
  .popup-component-download {
    .popup-exit-icon {
      @include vw(width, 40);
      @include vw(height, 40);
      @include vw(top, -60);
      @include breakpoint(mobile) {
        @include vwMobile(width, 30);
        @include vwMobile(height, 30);
        @include vwMobile(top, 20);
      }
    }
    .popup-content {
      width: 30% !important;
      padding: 0 !important;
      border: solid 1px $gray-oblique !important;
      background-color: $almostBlack !important;
      @include breakpoint(mobile) {
        width: 100% !important;
      }
    }
    .popup-container {
      position: relative;
    }
    .popup-container-corners {
      position: absolute;
      width: 100%;
      height: 100%;
      @include buildCorners(25, 0);
    }
    .popup-image {
      width: 100%;
      background-image: url('https://i1.wp.com/fullsync.co.uk/wp-content/uploads/2019/01/dual-universe-header.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include vw(height, 300);
      @include breakpoint(mobile) {
        @include vwMobile(height, 227);
      }
    }
    .popup-download-options {
      // @include vw(height, 408);
      height: auto;
      .download-options-title {
        color: $white;
        line-height: 1.36;
        text-align: center;
        text-transform: uppercase;
        @include vw(font-size, 22);
        @include vw(margin-top, 40);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 22);
          @include vwMobile(margin-top, 40);
        }
      }
    }
    .download-options-list {
      @include vw(margin-top, 20);
      @include vw(margin-left, 40);
      @include vw(margin-right, 40);
      @include vw(padding-bottom, 54);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      a {
        position: relative;
        display: block;
        width: 100%;
        border-bottom: 1px solid $grayButton;
        color: $gray-medium;
        cursor: pointer;
        line-height: 1.4;
        @include vw(font-size, 20);
        @include vw(padding-bottom, 20);
        @include vw(padding-top, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
          @include vwMobile(padding-bottom, 20);
          @include vwMobile(padding-top, 20);
        }
        &::before {
          content: '+';
          @include vw(margin-right, 50);
          @include breakpoint(mobile) {
            @include vwMobile(margin-right, 50);
          }
        }
        &:hover {
          color: $yellowMild;
        }
      }
    }
  }
  .categories-intro {
    @include vw(height, 169);
    @include vw(padding-left, 156);
    display: flex;
    align-items: center;
    background-image: url('../../../../assets/images/media/space.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include breakpoint(mobile) {
      @include vwMobile(height, 147);
      @include vwMobile(padding-left, 20);
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      background-position: bottom;
    }
    h2 {
      margin-bottom: 0;
      font-weight: bold;
      line-height: 1.4;
      @include vw(font-size, 50);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 35);
        line-height: 1.2;
      }
    }
    .categories-intro-title-section {
      @include vw(margin-left, 40);
      color: $yellowIg;
      @include breakpoint(mobile) {
        @include vwMobile(margin-left, 0);
      }
    }
  }

  .categories-content {
    width: 100%;
    background-color: $blueDark;
    @include vwAll(padding, 60, 156, 0, 156);

    @include breakpoint(mobile) {
      @include vwAllMobile(padding, 40, 20, 0, 20);
      height: 100%;
    }
    .category-name {
      display: inline-block;
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      @include vw(font-size, 35);
      @include vw(margin-bottom, 40);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
        @include vwMobile(margin-bottom, 36);
      }
    }
    .categories-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      @include vw(grid-column-gap, 46);
      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
      }
      .categories-three {
        @include breakpoint(from-phablet) {
          // display: grid;
          //background-color: $blueDeep;
          // grid-template-columns: 1fr 1fr 1fr;
          // @include vw(grid-column-gap, 46);
          @include vw(height, 350);
          @include vw(margin-bottom, 46);
          @include vw(border-width, 1);
        }
        @include breakpoint(mobile) {
          width: 100%;
          // @include vwMobile(height, 259);
          @include vwMobile(margin-bottom, 21);
        }
        video {
          @include vw(height, 283);
          position: relative;
          width: 100%;
          background-color: $almostBlack;
          object-fit: cover;
          outline: none;
          @include breakpoint(mobile) {
            @include vwMobile(height, 283);
          }
        }
        .imageContainer {
          display: flex;
          flex-direction: column;
          border-style: solid;
          border-color: $grayButton;
          @include vw(border-width, 1);
          @include breakpoint(mobile) {
            @include vwMobile(border-width, 1);
          }
          &:hover {
            border-color: $gray-box-media;
          }
          .image {
            width: 100%;
            background-color: $black;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            @include vw(height, 283);
            @include breakpoint(mobile) {
              @include vwMobile(height, 209);
            }
          }

          .header {
            @include vw(height, 60);
            @include vw(padding-left, 30);
            display: flex;
            align-items: center;
            background-color: $almostBlack;
            .header-icon {
              @include vw(height, 30);
              @include vw(width, 30);
              border: 1px solid $white;
              background-image: url('../../../../assets/images/icons/Icon-Play-White.svg');
              background-position: center;
              background-repeat: no-repeat;
              opacity: 0.8;
              @include vw(background-size, 12);
              @include vw(margin-right, 20);
              @include breakpoint(mobile) {
                @include vwMobile(height, 30);
                @include vwMobile(width, 30);
                @include vwMobile(background-size, 12);
                @include vwMobile(margin-right, 20);
              }
            }
            p {
              @include vw(font-size, 20);
              color: $white;
              line-height: 1.4;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
              }
            }
            @include breakpoint(mobile) {
              @include vwMobile(height, 49);
            }
          }
        }
      }

      .screenshoots-complete {
        //    width: 100%;
        border-style: solid;
        border-color: $grayButton;
        background-color: $almostBlack;
        content: '';
        // display: grid;
        grid-column: 1/4;
        // @include vw(height, 968);
        @include vw(border-width, 1);
        @include vw(margin-bottom, 46);

        @include breakpoint(mobile) {
          display: none;
          width: 100%;
          border-style: thin;
          @include vwMobile(height, 259);
        }

        .image {
          width: 100%;
          background-image: url('https://i1.wp.com/fullsync.co.uk/wp-content/uploads/2019/01/dual-universe-header.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          @include vw(height, 900);
          @include breakpoint(mobile) {
            @include vwMobile(height, 209);
          }
        }

        div {
          &.header {
            @include vw(height, 60);
            @include vw(padding-left, 30);
            // height: 100%;
            display: flex;
            align-items: center;
            p {
              @include vw(font-size, 20);
              color: $white;
            }
            @include breakpoint(mobile) {
              @include vwMobile(height, 49);

              @include vwMobile(font-size, 16);
            }
          }
        }
      }
    }
    .document-grid {
      @include breakpoint(from-phablet) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @include vw(grid-column-gap, 47);
      }

      div {
        &.document {
          width: 100%;
          border-style: solid;
          border-color: $grayButton;
          background-color: $blueDeep;
          color: $white;
          @include vw(height, 350);
          @include vw(margin-bottom, 50);
          @include vw(border-width, 1);
          cursor: pointer;
          @include breakpoint(mobile) {
            @include vwMobile(height, 264);
            @include vwMobile(margin-bottom, 20);

            @include vwMobile(border-width, 1);
          }
          &:hover {
            border-color: $gray-box-media;
          }
          .container {
            background-color: $black;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            content: '';
            @include vw(height, 283);

            @include breakpoint(mobile) {
              @include vwMobile(height, 209);
            }
          }

          .header {
            display: flex;
            align-items: center;
            @include vw(height, 60);
            @include vw(padding-left, 30);
            // @include vw(padding-top, 10);
            @include vw(font-size, 20);
            @include breakpoint(mobile) {
              @include vwMobile(height, 49);
              @include vwMobile(padding-left, 20);
              // @include vwMobile(padding-top, 17);
              @include vwMobile(font-size, 16);
            }
          }
        }
      }
    }
  }
  .alternate-bg {
    background-color: $almostBlack;
  }
}
