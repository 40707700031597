.news-status-section {
  @extend .util-main-padding;

  .server-status-news-container {
    display: grid;
    grid-template-columns: 0.72fr 0.29fr;
    @include vw(gap, 60);
    @include breakpoint(mobile) {
      grid-template-columns: 1fr;
      @include vwMobile(padding-bottom, 20);
    }
    .server-status-news-link {
      @include vw(margin-bottom, 30);
      &:hover {
        background-color: $blueDark;
        .server-status-news-border-container {
          .server-status-news-content {
            .server-status-news-container-text {
              .server-status-news-title {
                background-color: $blueDeep;
              }
            }
          }
        }
      }

      .server-status-news-border-container {
        position: relative;
        @include vw(height, 295);
        padding: 0;
        border: solid 1px rgba(255, 255, 255, 0.2);
        @include vw(border-width, 1);
        transition: $corners-transition;
        @include breakpoint(mobile) {
          @include vwMobile(min-height, 460);
          height: auto;
        }
        .server-status-news-corners {
          width: 100%;
          height: 100%;
          @include buildCorners(15, 0);
          @include breakpoint(mobile) {
            @include buildCornersMobile(18, 0);
            height: auto;
            @include vwMobile(min-height, 460);
          }
        }
        &:hover {
          @include vw(padding, 15);
          transition: $corners-transition;
        }
        /* prettier-ignore */
        .server-status-news-content {
          position: absolute;
          @include vw(top, 15);
          @include vw(left, 15);
          display: flex;
          
          margin: 0;
          //@include columns((1.3, 2));
          /* prettier-ignore */

          @include breakpoint(mobile){
            position: relative;
          flex-direction: column; 
          columns: 0;
          top: 0;
          left: 0;
          }
          .server-status-news-image-container {
            @include breakpoint(mobile){
              width: 100%;
            }
            .server-status-news-image {
              display: block;
              @include vw(height, 261);
              @include vw(padding, 25);
              @include breakpoint(mobile){
                @include vwMobile(height, 230);
              }
            }
          }

          .server-status-news-container-text {
            display: flex;
            flex-direction: column;
            // 40px - 15 of the border
            @include vw(padding-top, 25);
            @include vw(padding-right, 25);
            @include vw(padding-bottom, 25);
            @include breakpoint(mobile){
              width: 100% !important;
              @include vwMobile(padding-top, 20);
            }
            .server-status-news-title {
              @include vw(padding, 15);
              background-color: $blueDark;
              color: $white;
              inline-size: fit-content;
              text-transform: uppercase;
              @extend .paragraph_base;
              @include breakpoint(mobile){
                @include vwMobileAll(padding, 15,30,15,30);
                @include vwMobile(font-size, 14);
                line-height: 1.43;

              }
            }

            .server-status-news-desc {
              @extend .header_secondary_medium;
              @include vw(margin-top, 40);
              color: $white;
              @include breakpoint(mobile){
                @include vwMobile(margin-top, 20);
                @include vwMobile(margin-bottom, 38);
                @include vwMobile(font-size, 20);
                line-height: 1.4;
              }
            }

            .server-status-news-date {
              @extend .paragraph_base;
              margin-top: auto;
              color: $gray-medium;
              @include breakpoint(mobile){
                @include vwMobile(font-size, 16);
                line-height: 1.38;
              }
            }
          }


        }
      }
    }
  }
}
