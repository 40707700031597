.tutorials-search {
  position: relative;
  background-color: $blueDeep;
  @include vw(width, 1050);
  @include vw(margin-top, 40);
  @include breakpoint(mobile) {
    width: 100%;
    @include vwMobile(margin-top, 40);
    @include vwMobile(margin-bottom, 44);
  }
  .util-input {
    width: 100%;
    @include breakpoint(mobile) {
      @include vwMobile(height, 65);
    }
  }
  .util-input-text {
    @include vw(font-size, 20);
    @include vw(padding-left, 40);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 20);
      @include vwMobile(padding-left, 30);
    }
  }
  .util-input .magnifying-glass {
    background-image: url('../../../assets/images/icons-navbar/Icon-Search.svg');
    cursor: pointer;
    @include vw(background-size, 25);
    @include breakpoint(mobile) {
      @include vwMobile(background-size, 20);
      @include vwMobile(width, 40);
    }
  }
  .results {
    position: absolute;
    z-index: 3;
    width: 100%;
    border-style: solid;
    border-color: $gray-home;
    border-top: 0;
    background-color: $almostBlack;
    overflow-y: scroll;
    @include vw(height, 300);
    @include vw(border-width, 1);
    @include vw(padding-top, 40);
    @include vw(padding-right, 50);
    @include vw(padding-bottom, 40);
    @include vw(padding-left, 50);
    .result {
      display: block;
      border: 0;
      border-color: $white02;
      border-bottom-style: solid;
      @include vw(border-bottom-width, 1);
      @include vw(padding-bottom, 30);
      @include vw(padding-top, 30);
      transition: $corners-transition;
      &:before {
        content: '+';
        @include vw(padding-right, 15);
      }
      &:hover {
        background-color: $darkBlack;
        @include vw(padding-left, 30);
      }
    }
    h1 {
      display: inline;
      @include vw(font-size, 18);
      @include vw(line-height, 28);
      margin: 0;
      text-transform: unset;
    }
  }
}
