@import './legal-sections/legal-navigation.component.scss';

$yellow: $yellowIg;

.legal-container {
  .intro {
    @include vw(height, 720);
    @include breakpoint(mobile) {
      @include vwMobile(height, 187);
    }

    background-image: linear-gradient(to top, #0c1315, rgba(8, 12, 13, 0.64) 34%, rgba(0, 0, 0, 0)),
      url('../../../assets/images/presscontact/pressrelease.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
      @include vw(padding-left, 156);
      @include vw(padding-right, 156);
      @include vw(padding-top, 160);
      margin-bottom: 0;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 35);
        line-height: 1.44;
        text-align: center;
        @include vwMobile(padding-left, 20);
        @include vwMobile(padding-right, 20);
        @include vwMobile(padding-top, 60);
      }
    }
  }

  .legal-options {
    position: sticky;
    z-index: 1;
    border: 1px solid $grayButton;
    background-color: $almostBlack;
    transition: all 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    @include vw(margin-left, 156);
    @include vw(margin-right, 156);
    @include vw(top, 80);
    @include vw(height, 70);
    @include vw(margin-bottom, 70);
    @include breakpoint(mobile) {
      @include vwMobile(height, 50);
      @include vwMobile(margin-left, 20);
      @include vwMobile(margin-right, 20);
    }
    .wrapper {
      width: 100%;
      height: 100%;
    }
    .content {
      position: absolute;
      top: 0;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      a {
        display: flex;
        height: 100%;
        align-items: center;
        &.underlineTextcurrent {
          border-top: 3px solid transparent;
          border-bottom: 3px solid $yellowIg;
          color: $white;
          cursor: pointer;
          line-height: 1.36;
          @include vw(margin-left, 40);
          @include vw(font-size, 22);
        }

        &.underlineText {
          color: $white;
          cursor: pointer;
          line-height: 1.36;
          @include vw(margin-left, 40);
          @include vw(font-size, 22);
        }
        &.underlineText:nth-child(1),
        &.underlineTextcurrent:nth-child(1) {
          margin-left: 0;
        }
      }
    }
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .big {
    margin-right: 0;
    margin-left: 0;
    transition: all 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  }
  .legal-content {
    & > p:nth-child(1) {
      @include vw(font-size, 40);
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      @include vw(line-height, 40);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 30);
        @include vwMobile(line-height, 30);
      }
    }
    & > p:nth-child(2) {
      @include vw(margin-bottom, 30);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 30);
      }
    }
    & > p:nth-child(2) > strong:nth-child(1) {
      color: $yellow;
    }
    p.introP:nth-child(3) {
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 20);
      }
    }
    @include vw(width, 1046);
    @include vw(margin-top, 5);

    @include vw(margin-right, 156);
    @include breakpoint(mobile) {
      width: 100%;
    }
    &.full {
      width: 100%;
      @include vw(margin-left, 156);
      @include breakpoint(mobile) {
        @include vwMobile(margin-left, 0);
        @include vwMobile(margin-right, 0);
      }
    }
    p:not(.list) {
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 15);
      }
    }
    p,
    li {
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 18);
        line-height: 2.2;
      }
    }
    h1,
    h2,
    li.first-item {
      border-top: 0.1px solid $blacker;
      border-bottom: 0.1px solid $blacker;
      color: $yellow;
      line-height: 2;
      text-transform: uppercase;
      @include vw(font-size, 20);
      @include vw(padding-top, 20);
      @include vw(padding-bottom, 20);
      @include vw(margin-bottom, 40);
      @include vw(margin-top, 60);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
        @include vwMobile(padding-top, 20);
        @include vwMobile(padding-bottom, 20);
        @include vwMobile(margin-bottom, 40);
        @include vwMobile(margin-top, 60);
      }
    }
    ul {
      li {
        display: block;
        // padding-left: calc(1.745719vw * 3);
        @include vw(padding-left, 0);
        @include vw(margin-bottom, 8);
        @include vw(font-size, 20);

        strong {
          @include vw(font-size, 20);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 18);
          }
        }
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 18);
        }
        list-style-type: none;
        &::before {
          display: inline-block;
          @include vw(width, 20);
          @include vw(margin-left, -33);
          @include vw(margin-right, 10);
          color: $white;
          content: '+';
          font-weight: normal;
          @include breakpoint(mobile) {
            @include vwMobile(width, 20);
            @include vwMobile(margin-left, -33);
            @include vwMobile(margin-right, 10);
          }
        }
      }
    }
    ol {
      padding-left: 0;
      margin-left: 0;
      counter-reset: item;
      li {
        display: block;
        list-style-type: none;
        @include vw(margin-bottom, 8);
        @include vw(font-size, 20);
        @include vw(margin-left, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 18);
        }
      }
      li::before {
        display: inline-block;
        color: $white;
        content: counter(item) '.';
        counter-increment: item;
        // @include vw(width, 20);
        @include vw(margin-left, 0);
        @include vw(margin-right, 20);
        @include breakpoint(mobile) {
          @include vwMobile(margin-right, 10);
        }
      }
    }
    pre {
      color: $gray;
    }

    h2 {
      counter-increment: chapter;
      counter-reset: section 0;
    }
    h2:before {
      content: counter(chapter) '. ';

      @include vw(margin-right, 40);
      @include breakpoint(mobile) {
        @include vwMobile(margin-right, 10);
      }
    }
  }
  .flex-container {
    position: relative;
    @include vw(top, -490);
    display: flex;
    flex-direction: column;

    @include vw(margin-top, 80);
    @include vw(margin-bottom, -150);
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      @include vwMobile(top, -80);
    }
  }
  .flex-container-row {
    display: flex;
  }
  .table-of-contents {
    @include vw(margin-left, 156);

    display: inline-block;
    @include vw(width, 448);
    max-height: 80vh;
    border-style: solid;
    border-color: $grayer;
    list-style-position: inside;
    overflow-y: scroll;
    scrollbar-color: #7f7f7f $almostBlack;
    scrollbar-width: thin;
    @include breakpoint(mobile) {
      display: none;
    }
    &::-webkit-scrollbar {
      width: 7.5px;
    }

    &::-webkit-scrollbar-track {
      background: $almostBlack;
    }

    &::-webkit-scrollbar-thumb {
      border-right: 1px solid $almostBlack;
      background: #7f7f7f;
    }
    @include vw(padding-top, 54);
    @include vw(padding-right, 40);
    @include vw(padding-bottom, 54);
    @include vw(padding-left, 40);
    @include vw(border-width, 1);
    @include vw(margin-right, 114);
    li {
      border-width: 0;
      border-style: solid;
      border-color: $grayer;
      @include vw(border-bottom-width, 1);
      @include vw(padding-bottom, 20);
      @include vw(margin-bottom, 20);
      &::before {
        @include vw(margin-right, 15);
      }
    }
    li::marker,
    a {
      @include vw(margin-left, 25);
      color: $gray-medium;
      @include vw(font-size, 20);
      line-height: 1.4;
    }

    .current,
    .currentspy {
      border-color: $yellow;
      color: $yellow;
      &::marker,
      a {
        color: $yellow;
      }
    }
  }
  .sticky-container {
    position: sticky;
    @include vw(top, 130);
  }
  table {
    @include vw(margin-top, 40);
    border: 1px solid $grayer;
    border-spacing: 0;
    color: $white;
    tr {
      border-bottom: 1px solid $grayer;
      opacity: 0.95;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      padding: 0.5rem;
      border-right: 1px solid $grayer;
      border-bottom: 1px solid $grayer;
      margin: 0;
      opacity: 0.95;

      :last-child {
        border-right: 0;
      }
    }
  }
}
.test {
  height: 500px;
}
