div {
  &.mediaNavBar {
    position: sticky;
    @include vw(top, 80);
    z-index: 3;
    width: 100%;
    border-bottom-style: solid;
    border-bottom-color: $grayButton;
    background-color: $almostBlack;
    content: '';
    @include vw(height, 65);
    @include vw(padding-left, 900);
    @include vw(padding-right, 156);
    @include vw(border-bottom-width, 1);

    @include breakpoint(mobile) {
      @include vwMobile(top, 0);
      height: auto;
      border-top: 1px solid $grayButton;
      @include vwMobile(margin-top, 10);
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
    }

    div {
      &.gridAlign {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        @include vw(height, 65);
        // @include vw(grid-column-gap, 40);

        @include breakpoint(mobile) {
          display: none;
          @include vwMobile(height, 50);
        }

        a {
          display: flex;
          height: 100%;
          align-items: center;
          color: $gray;
          cursor: pointer;
          @include vw(font-size, 20);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 12);
          }

          &:hover,
          &.selected {
            border-top-style: solid;
            border-top-color: transparent;
            border-bottom-style: solid;
            border-bottom-color: $yellowIg;
            @include vw(border-bottom-width, 3);
            @include vw(border-top-width, 3);
          }
        }
      }
    }
    .navbar-mobile {
      display: none;
      @include breakpoint(mobile) {
        display: flex;
      }
      a {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @include vwMobile(height, 50);
        cursor: pointer;
        p {
          @include vwMobile(font-size, 16);
          line-height: 2.5;
          text-transform: uppercase;
        }
        p:before {
          color: $yellowIg;
          content: '\002B';
          @include vwMobile(padding-right, 20);
        }
        div {
          background-image: url('../../../../assets/images/icons-navbar/Icon-NavDropArrowYellow.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          @include vwMobile(background-size, 15);
          @include vwMobile(width, 20);
          @include vwMobile(height, 20);
        }
      }
    }
    .navbar-mobile-options {
      //  @include vwMobile(height, 160);
      @include vwMobile(margin-right, 20);
      @include vwMobile(margin-left, 20);

      a {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #9999;
        color: $white;
        line-height: 1.44;
        text-decoration: none;
        @include vwMobile(font-size, 18);
        @include vwMobile(margin-top, 20);
        @include vwMobile(padding-bottom, 20);
      }
    }
  }
}
