/* utils */
$viewport-desktop: 1920;
$viewport-mobile: 480;

@mixin vw($property, $value) {
  #{$property}: ($value / $viewport-desktop) * 100vw;
}

@mixin vw($property, $value) {
  #{$property}: ($value / $viewport-desktop) * 100vw;
}

@mixin vwMobile($property, $value) {
  #{$property}: ($value / $viewport-mobile) * 100vw;
}

@mixin vwMobileAll($property, $top: 0, $right: 0, $bottom: 0, $left: 0) {
  #{$property}: ($top / $viewport-mobile) * 100vw ($right / $viewport-mobile) * 100vw ($bottom / $viewport-mobile) *
    100vw ($left / $viewport-mobile) * 100vw;
}
@mixin vwAll($property, $top: 0, $right: 0, $bottom: 0, $left: 0) {
  #{$property}: ($top / $viewport-desktop) * 100vw ($right / $viewport-desktop) * 100vw ($bottom / $viewport-desktop) *
    100vw ($left / $viewport-desktop) * 100vw;
}

@mixin vwAllMobile($property, $top: 0, $right: 0, $bottom: 0, $left: 0) {
  #{$property}: ($top / $viewport-mobile) * 100vw ($right / $viewport-mobile) * 100vw ($bottom / $viewport-mobile) *
    100vw ($left / $viewport-mobile) * 100vw;
}

@mixin vwEqual($property, $top: 0, $right: 0) {
  #{$property}: ($top / $viewport-desktop) * 100vw ($right / $viewport-desktop) * 100vw;
}

@mixin vwMarginSides($value) {
  margin-right: ($value / $viewport-desktop) * 100vw;
  margin-left: ($value / $viewport-desktop) * 100vw;
}

@mixin vwMarginUpDown($value) {
  margin-top: ($value / $viewport-desktop) * 100vw;
  margin-bottom: ($value / $viewport-desktop) * 100vw;
}

@mixin vwColumns($columns, $margin, $flush-margin) {
  $margin-calc: ($margin / $viewport-desktop) * 100vw;
  @include columns($columns: $columns, $margin: $margin-calc, $flush-margin: $flush-margin);
}

@mixin vwColumnsOrders($columns, $margin, $flush-margin, $order) {
  $margin-calc: ($margin / $viewport-desktop) * 100vw;
  @include columns($columns: $columns, $margin: $margin-calc, $flush-margin: $flush-margin, $order: $order);
}

@mixin vwDimensions($width, $height) {
  width: ($width/ $viewport-desktop) * 100vw;
  height: ($height/ $viewport-desktop) * 100vw;
}

@mixin vwDimensionsMobile($width, $height) {
  width: ($width/ $viewport-mobile) * 100vw;
  height: ($height/ $viewport-mobile) * 100vw;
}

@mixin vwBoxShadow($h-offset, $v-offset, $blur, $spread, $color) {
  box-shadow: (($h-offset/ $viewport-desktop) * 100vw) (($v-offset/ $viewport-desktop) * 100vw)
    (($blur/ $viewport-desktop) * 100vw) (($spread/ $viewport-desktop) * 100vw) $color;
}
@mixin vwBoxShadowInsetOutset(
  $h-offsetI,
  $v-offsetI,
  $blurI,
  $spreadI,
  $colorI,
  $h-offsetO,
  $v-offsetO,
  $blurO,
  $spreadO,
  $colorO
) {
  box-shadow: inset (($h-offsetO/ $viewport-desktop) * 100vw) (($v-offsetO/ $viewport-desktop) * 100vw)
    (($blurO/ $viewport-desktop) * 100vw) (($spreadO/ $viewport-desktop) * 100vw) $colorO,
    (($h-offsetI/ $viewport-desktop) * 100vw) (($v-offsetI/ $viewport-desktop) * 100vw)
    (($blurI/ $viewport-desktop) * 100vw) (($spreadI/ $viewport-desktop) * 100vw) $colorI;
}
