.popup-content {
  width: auto;
  padding: 0;
  border: 0;
  background-color: $almostBlack;
  @include breakpoint(mobile) {
    width: 100%;
  }
  .popup-container-leaving,
  .popup-container-convert {
    .exit-icon {
      position: absolute;
      z-index: 100;
      border-width: 0.1px;
      border-style: solid;
      border-color: $gray;
      background-clip: content-box;
      background-image: url('../../../../assets/images/icons/no-border/Icon-Close.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      opacity: 0.8;
      @include vw(top, 35);
      @include vw(right, 35);
      @include vw(width, 30);
      @include vw(height, 30);
      @include vw(padding, 5);
      @include breakpoint(mobile) {
        @include vwMobile(top, 20);
        @include vwMobile(right, 20);
        @include vwMobile(width, 30);
        @include vwMobile(height, 30);
        @include vwMobile(padding, 5);
        @include vwMobile(margin-left, 437);
      }
    }
  }
  .popup-container-convert {
    width: 41.66667vw !important;
    height: 38.90625vw !important;
  }
}
