.account-preferences-container {
  .account-preferences-intro {
    position: sticky;
    @include vw(top, 80);
    display: flex;
    justify-content: space-between;
    z-index: 10;
    background-color: $almostBlack;
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      flex-direction: column;
      position: relative;
      @include vwMobile(margin-bottom, 30);
    }
    h2 {
      @include vw(font-size, 35);
      line-height: 1.4;

      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
        @include vwMobile(margin-top, 40);
      }
    }
    .theme-button {
      @include breakpoint(mobile) {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        width: 100%;
        @include vwMobile(height, 65);
      }
    }
    .theme-button-container {
      div {
        background-image: url('../../../../assets/images/icons/no-border/Icon-Edit-White.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(mobile) {
          @include vwMobile(width, 18);
          @include vwMobile(height, 18);
          @include vwMobile(margin-right, 10);
        }
      }
    }
    button:hover {
      background-color: $green;
      .theme-button-container div {
        background-image: url('../../../../assets/images/icons/no-border/Icon-Edit-Black.svg');
      }
      span {
        color: $black;
      }
    }
  }
  .account-preferences-desc {
    @include vw(margin-bottom, 30);
    * {
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 40);
    }
  }
  .account-preferences-disclamer {
    * {
      @include vw(font-size, 16);
      line-height: 1.38;
      color: #a8a8a8;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
  }
  .account-notice {
    display: flex;
    align-items: center;
    background-color: $green;
    @include vw(height, 65);
    @include vw(margin-top, 39);
    animation-duration: 4s;
    animation-name: custom;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    position: sticky;
    @include vw(top, 160);
    z-index: 10;
    @include breakpoint(mobile) {
      @include vwMobile(top, 65);
      @include vwMobile(height, 65);
      @include vwMobile(margin-top, 39);
    }
    p {
      @include vw(font-size, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    div {
      background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-white-border.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include vw(height, 40);
      @include vw(width, 40);
      @include vw(margin-left, 15);
      @include vw(margin-right, 17);
      @include breakpoint(mobile) {
        @include vwMobile(height, 25);
        @include vwMobile(width, 25);
        @include vwMobile(margin-left, 15);
        @include vwMobile(margin-right, 17);
      }
    }
  }
  @keyframes custom {
    0% {
      opacity: 0;
    }
    8% {
      opacity: 0;
    }
    12% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    28% {
      opacity: 1;
    }
    48% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .error {
    background-color: $red;
    @include breakpoint(mobile) {
      @include vwMobile(padding, 5);
      height: auto;
    }
    div {
      background-image: url('../../../../assets/images/icons/Icon-Close.svg');
      @include vw(background-size, 20);
      @include breakpoint(mobile) {
        @include vwMobile(background-size, 18);
      }
    }
  }
  .checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.03);
    @include vwAll(padding, 20, 40, 30, 40);
    @include breakpoint(mobile) {
      @include vwMobileAll(padding, 20, 30, 30, 20);
      @include vwMobile(margin-top, 10);
      @include vwMobile(margin-bottom, 40);
    }
  }
  .checkbox-transparent {
    @extend .checkbox;
    background-color: transparent;
  }
  .checkboxcontainer {
    color: $white;
    @include vw(margin-top, 15);
    input[type='checkbox'] {
      box-sizing: border-box;
      border-style: solid;
      border-color: $white;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      background-color: $blueDeep;
      cursor: pointer;
      outline: none;
      @include vw(width, 20);
      @include vw(height, 20);
      @include vwAll(margin, 0, 0, 0, 0);
      @include vw(border-width, 1);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 15);
        @include vwMobile(width, 25);
        @include vwMobile(height, 25);
      }
    }
    input[type='checkbox']:checked {
      background-clip: content-box;
      background-color: $yellowIg;
      @include vwAll(padding, 5, 5, 5, 5);
      @include breakpoint(mobile) {
        @include vwMobileAll(padding, 7, 7, 7, 7);
      }
    }
  }
  .preferences-list-container {
    @include vw(margin-top, 50);
    .category-title {
      color: $yellowIg;
      @include vw(font-size, 22);
      line-height: 1.36;
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 22);
        @include vwMobile(margin-bottom, 20);
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      @include breakpoint(mobile) {
        width: 80%;
      }
      h5 {
        @include vw(font-size, 20);
        color: $white;
        font-weight: bold;
        line-height: 2;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
      .description {
        * {
          color: $gray-dark;
          @include vw(font-size, 16);
          line-height: 1.38;
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
      }
    }
  }
  .preferences-all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include vw(margin-top, 50);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 50);
      @include vwMobile(margin-bottom, 30);
    }
    h2 {
      @include vw(font-size, 26);
      line-height: 1.38;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 24);
      }
    }
    .checkbox {
      @include vw(width, 175);
      @include vw(height, 62);
      @include vw(padding, 28);
      @include breakpoint(mobile) {
        @include vwMobile(width, 135);
        @include vwMobile(height, 62);
        @include vwMobileAll(padding, 28, 20, 28, 20);
        @include vwMobile(margin-bottom, 0);
      }
      input {
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 0);
        }
      }
      p {
        @include vw(font-size, 16);
        line-height: 1.38;
        color: #dbdbdb;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
  }
}
