.submissionFormBorders {
  @include vw(border-width, 2);
  @include vw(border-radius, 5);
  border-style: solid;
  border-color: $yellowIg;
}

.submissionForm {
  @include columns(1);
  @include container-align(center);
  @include vw(max-width, 1200);
  @include vw(padding, 32);
  @include vwMarginUpDown(32);
  margin-right: auto;
  margin-left: auto;
  color: $yellowIg;
  @extend .submissionFormBorders;

  label {
    display: block;
    @include vw(padding, 8);
    @extend .text_normal !optional;
    @include vw(font-size, 40);
  }
  input,
  textarea {
    @include vw(padding, 8);
    @include vwMarginUpDown(8);
    @include vwMarginSides(0);
    @extend .text_list_item !optional;
    color: black;
  }
  .checkBox {
    @include columns(4, $order: (1, 2, 3, 4));
    @include container-align(center);
    input[type='checkbox'] {
      transform: scale(1.5);
    }
  }
  .checkBoxError {
    @include columns(2);
    @include container-align(center);
  }
}

.formButton {
  @include vw(padding, 8);
  @include vw(border, 0);
  @include vwMarginUpDown(16);
  @include vwMarginSides(0);
  background: $yellowIg;
  cursor: pointer;

  &:hover {
    background: $yellowMild;
  }
}
