.account-payments-container {
  .account-payments-title {
    @include vw(margin-bottom, 40);
    * {
      @include vw(font-size, 35);
      line-height: 1.4;
      text-transform: uppercase;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
      }
    }
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 20);
      @include vwMobile(margin-bottom, 33);
    }
  }
  .account-payments-subtitle {
    @extend .account-payments-title;
    @include vw(font-size, 25);
    @include vw(padding-top, 30);
    @include vw(margin-bottom, 0);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 18);
      line-height: normal;
      @include vwMobile(padding-top, 0);
      @include vwMobile(margin-top, 0);
      @include vwMobile(margin-bottom, 20);
    }
  }
  .account-payments-history {
    @include vw(margin-top, 0);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 60);
    }
    .account-payments-history-title {
      @include vw(margin-bottom, 10);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 10);
      }
      * {
        @include vw(font-size, 26);
        font-weight: bold;
        text-transform: uppercase;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 26);
        }
      }
    }
    .table {
      @include breakpoint(mobile) {
        @include vwMobile(width, 430);
        overflow-x: auto;
        -ms-overflow-style: none;
      }
    }
    .table.payments {
      @include vwAll(padding, 0, 30, 70, 30);
      border-bottom: 1px solid $white02;
      background-color: #141f23;
    }
    .row {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr 0.2fr;
      @include vw(height, 73);
      border-bottom: 1px solid $white02;
      align-items: center;
      @include breakpoint(mobile) {
        @include vwMobile(height, 73);
        display: flex;
        flex-wrap: nowrap;
        @include vwMobile(width, 800);
      }
      &:hover {
        cursor: pointer;
      }
      .column {
        color: #a8a8a8;
        @include vw(font-size, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 20);
          @include vwMobile(width, 200);
        }
      }
      .active {
        color: $green;
      }
      .yellow {
        * {
          color: $yellowIg;
        }
      }
      .menuIcon {
        display: inline-block;
        background-image: url('../../../../assets/images/icons-navbar/Icon-NavDropArrow.svg');
        @extend .navbarimage;

        @include vw(width, 12);
        @include vw(height, 10);
        @include vw(margin-left, 10);
        @include vw(margin-top, 4);
        transition: transform 0.2s linear;
      }
      .menuIconClose {
        background-image: url('../../../../assets/images/icons-navbar/Icon-NavDropArrow.svg');
        transform: rotate(180deg);
      }
    }
    .row.selected {
      border-top: 1px solid $white;
      border-bottom: 1px solid $white;
      margin-top: -1px;
      background: rgba(255, 255, 255, 0.03);
    }
    .row.payments {
      grid-template-columns: repeat(4, 1fr);
    }
    ul {
      display: flex;
      justify-content: center;
      @include vw(margin-top, 40);
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 40);
        @include vwMobile(margin-bottom, 40);
      }
      li {
        @include vw(margin-right, 20);
        list-style: none;
        cursor: pointer;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  .account-table-mobile {
    .corners {
      border: 1px solid $white02;
      @include vwMobile(margin-bottom, 40);
    }
    .box,
    .payments-mobile-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: $blueLight;
      @include buildCornersMobile(20, 0);
      @include vwMobileAll(padding, 20, 20, 10, 20);
      .item {
        @include vwMobile(font-size, 16);
        @include vwMobile(margin-bottom, 10);
        color: $gray-dark;
        &.active {
          color: $green;
        }
        &.void {
          visibility: hidden;
        }
        * {
          @include vwMobile(font-size, 16);
        }
        &.yellow {
          * {
            color: $yellowIg;
          }
        }
      }
      .pay-btn-link {
        grid-column: span 2;
      }
      .pay-btn {
        @include vwMobile(width, 218);
        @include vwMobile(height, 65);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        @include vwMobile(margin-top, 20);
        background-color: $almostBlack;
        border: 1px solid $white02;
        div {
          background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          @include vwMobile(width, 12);
          @include vwMobile(height, 8);
          @include vwMobile(margin-right, 10);
        }
        span {
          color: $white;
          @include vwMobile(font-size, 13);
          font-family: eurostile-extended, sans-serif;
          font-weight: 900;
          font-style: normal;
          text-transform: uppercase;
        }
      }
    }
    .payments-mobile-container {
      border: 1px solid $white02;
      border-top: none;
      @include vwMobile(margin-top, -40);
      @include vwMobile(margin-bottom, 40);
      background-color: #0b181d;
      display: flex;
      flex-direction: column;
      @include vwMobileAll(padding, 30, 40, 30, 40);

      .table.payments {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid $white02;
        @include vwMobile(padding-bottom, 20);
        @include vwMobile(padding-top, 20);
      }
      .table.payments:first-of-type {
        @include vwMobile(padding-top, 0);
      }
      .table.payments:last-of-type {
        border-bottom: none;
      }
    }
  }
}
