.tutorials-page {
  .tutorials-bg {
    @include vw(height, 650);
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: 100%;
    @include breakpoint(mobile) {
      @include vwMobile(height, 210);
      background-position-x: right;
      background-size: cover;
    }
  }

  .tutorials-fg {
    @include vw(margin-top, -700);
    @include vw(padding-top, 120);
    @include breakpoint(mobile) {
      padding-top: 0;
      margin-top: 0;
      @include vwMobile(padding-right, 20);
      @include vwMobile(padding-left, 20);
    }
    @include vw(padding-right, 150);
    @include vw(padding-bottom, 200);
    @include vw(padding-left, 150);
    @include vw(background-position-y, 1600);
    background-size: contain;
    @include breakpoint(mobile) {
      @include vwMobile(background-position-y, 2860);
    }
    background-repeat: no-repeat;
  }

  h1 {
    @include vw(font-size, 70);
    margin: 0;
    @include vw(margin-bottom, 15);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 35);
      @include vwMobile(margin-bottom, 15);
      @include vwMobile(margin-top, 10);
    }
  }
  h2 {
    @include vw(font-size, 22);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 22);
    }
  }
  h3 {
    @include vw(font-size, 35);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 25);
      @include vwMobile(margin-bottom, 40);
    }
  }

  .description {
    p {
      @include vw(font-size, 26);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 22);
      }
      color: $yellowIg;
    }
  }

  .links,
  .tutorial-categories {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include vw(grid-column-gap, 60);

    @include breakpoint(mobile) {
      display: grid;
      grid-template-columns: 1fr;
      @include vwMobile(gap, 30);
    }
  }

  em {
    @include vw(font-size, 18);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 16);
      line-height: 2;
    }
  }
}
