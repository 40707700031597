.navigation-block {
  position: sticky;
  @include vw(top, 79);
  z-index: 2;
  @include breakpoint(mobile) {
    position: relative;
    @include vwMobile(top, 65);
    background-color: $almostBlack;
  }
  .news-detail-header {
    // position: fixed;
    // z-index: 2;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #9999;
    background-color: $almostBlack;
    @include vw(height, 65);
    //@include vw(top, 75);

    a,
    .header-columnTwo-link {
      display: flex;
      height: 100%;
      align-items: center;
      color: $white;
      cursor: pointer;
      line-height: 1.4;
      text-decoration: none;
      @include vw(font-size, 20);
      &:hover,
      &.active {
        border-top-style: solid;
        border-top-color: transparent;
        border-bottom-style: solid;
        border-bottom-color: $yellowIg;
        color: $yellowIg;
        cursor: pointer;
        @include vw(border-top-width, 3);
        @include vw(border-bottom-width, 3);
      }
    }
    .header-columnOne {
      text-transform: uppercase;
      @include vw(margin-left, 290);
    }
    .header-columnTwo {
      display: flex;
      height: 100%;
      align-items: center;
      @include vw(margin-right, 272);
      :nth-child(3),
      :nth-child(2) {
        @include vw(margin-left, 40);
      }
    }

    @include breakpoint(mobile) {
      display: none;
    }
  }
  .navbar-mobile {
    display: none;
    @include breakpoint(mobile) {
      display: flex;
    }
    a {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @include vwMobile(margin-right, 20);
      @include vwMobile(margin-left, 20);
      p {
        @include vwMobile(font-size, 16);
        line-height: 2.5;
        text-transform: uppercase;
      }
      p:before {
        content: '\002B'; //    Icon-NavDropArrow.41c5672c.svg)
        @include vwMobile(padding-right, 20);
      }
      div {
        background-image: url('../../../../../assets/images/icons-navbar/Icon-NavDropArrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vwMobile(width, 20);
        @include vwMobile(height, 20);
      }
    }
  }
  .navbar-mobile-options {
    @include vwMobile(min-height, 160);
    @include vwMobile(margin-right, 20);
    @include vwMobile(margin-left, 20);

    a {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #9999;
      color: $white;
      line-height: 1.44;
      text-decoration: none;
      @include vwMobile(font-size, 18);
      @include vwMobile(margin-top, 20);
      @include vwMobile(padding-bottom, 20);
    }
  }
}
