@import './buypage-one-blocks/buypage-packages.styles';
@import './buypage-one-blocks/buypage-info.styles';
@import './buypage-one-blocks/buypage-logindisclaim.styles';
@import './buypage-one-blocks/buypage-specs.styles';
@import './buypage-two-blocks/buypage-two.styles';

div {
  &.limitHeader {
    position: absolute;
    display: grid;
    width: 100%;
    background-color: $yellowHeader;
    grid-template-columns: 1154fr 395fr 59fr;
    @include vw(top, 78);
    @include vw(min-height, 70);
    @include vwAll(padding, 0, 156, 0, 156);

    @include breakpoint(mobile) {
      @include vwMobile(min-height, 70);
      @include vwMobile(top, 65);
      @include vwMobileAll(padding, 0, 20, 0, 20);
    }

    div {
      &.displayMessage {
        display: flex;
        height: inherit;
        align-items: center;
        p {
          @include vw(font-size, 20);
          color: $blueDeep;

          @include breakpoint(mobile) {
            @include vwMobile(font-size, 14);
          }
        }
      }

      &.progressBar {
        @include breakpoint(mobile) {
          display: none;
        }
        .bar {
          position: relative;
          z-index: 1;
          width: 100%;
          background-color: $black;
          @include vw(height, 4);
          @include vwAll(margin, 20, 0, 0, 0);
        }
        div {
          &.barLimit {
            @extend .bar;
          }

          &.barUsed {
            position: relative;
            z-index: 2;
            background-color: $white;
            @include vw(bottom, 4);
            @include vw(height, 4);
          }
        }
        p {
          color: black;
          font-weight: bold;
          text-transform: uppercase;
          &.left {
            float: left;
            @include vw(font-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }

          &.right {
            float: right;
            @include vw(font-size, 16);
            @include breakpoint(mobile) {
              @include vwMobile(font-size, 16);
            }
          }
        }
      }

      &.close {
        @include vw(height, 20);
        @include vw(width, 20);
        @include vw(margin-top, 25);
        @include vw(margin-left, 40);
        background-image: url('../../../assets/images/icons/no-border/Icon-Close-Black.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        @include breakpoint(mobile) {
          @include vwMobile(height, 20);
          @include vwMobile(width, 20);
          @include vwMobile(margin-top, 25);
          @include vwMobile(margin-left, 66);
        }
      }
    }
  }

  &.limitHeaderTwo {
    display: grid;
    grid-template-columns: 1549fr 59fr;

    span {
      text-decoration: underline;
      @include vw(font-size, 16);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
  }
  &.errorMessage {
    p {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      color: $white;
      @include vw(font-size, 50);
      @include vw(height, 1080);
    }
  }

  &.parentBlur {
    position: absolute;

    z-index: 3;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(30.8px);
    backdrop-filter: blur(30.8px);
    background-color: $black;

    opacity: 0.8;
  }

  &.popUpWaitingList {
    @include vw(width, 600);
    @include vw(height, 450);

    @include buildCorners(35, 0);

    position: absolute;
    z-index: 4;

    background-color: $blueDeep;
    text-align: center;
    @include vw(top, 315);
    @include vw(left, 660);

    @include breakpoint(mobile) {
      @include vwMobile(width, 440);
      @include vwMobile(height, 631);

      @include vwMobile(top, 83);
      @include vwMobile(left, 20);
      @include vwMobile(border-width, 20);
    }
    div {
      &.close {
        border-style: solid;
        border-color: $gray;

        background-clip: content-box;
        background-image: url('../../../assets/images/icons/no-border/Icon-Close.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include vw(width, 30);
        @include vw(height, 30);
        @include vw(padding, 7);
        @include vw(border-width, 0.1);
        @include vw(margin-left, 504);

        @include breakpoint(mobile) {
          @include vwMobile(width, 30);
          @include vwMobile(height, 30);
          margin-left: auto;
        }
      }

      &.footeremail {
        @include breakpoint(from-phablet) {
          @include vw(width, 496);
          @include vw(margin-bottom, 100);
          display: inline-block;
        }

        form {
          p {
            &.popUpheader {
              font-weight: bold;
              text-transform: uppercase;
              @include vw(font-size, 22);
              @include vw(margin-bottom, 60);

              @include breakpoint(mobile) {
                @include vwMobile(font-size, 22);
                @include vwMobile(margin-bottom, 60);
              }
            }

            &.disclaimer {
              color: $gray-dark;
              @include vw(font-size, 14);

              a {
                @include vw(font-size, 14);
              }
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 14);
                @include vwMobile(margin-bottom, 80);

                a {
                  @include breakpoint(mobile) {
                    @include vwMobile(font-size, 14);
                  }
                }
              }
            }
          }

          .util-input {
            @include vw(margin-left, 13);
            @include vw(margin-right, 7);
            width: 100%;
            @include vw(margin-bottom, 35);
            @include breakpoint(mobile) {
              @include vwMobile(height, 65);
              @include vwMobile(margin-bottom, 80);
            }

            div.first {
              border-style: none;
              margin: auto 0;
              background-image: url('../../../assets/images/icons/no-border/Icon-Email.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              @include vw(width, 25);
              @include vw(height, 25);
              @include vw(margin-right, 25);
              @include vw(margin-left, 25);
              @include breakpoint(mobile) {
                @include vwMobile(width, 25);
                @include vwMobile(height, 25);
                @include vwMobile(margin-right, 25);
                @include vwMobile(margin-left, 25);
              }
            }

            div.last {
              border-style: none;
              margin: auto 0;
              background-image: url('../../../assets/images/icons/no-border/checkmark-green.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              @include vw(width, 25);
              @include vw(height, 25);
              @include vw(margin-right, 25);
              @include vw(margin-left, 25);
              @include breakpoint(mobile) {
                @include vwMobile(width, 25);
                @include vwMobile(height, 25);
                @include vwMobile(margin-right, 25);
              }
            }
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            margin: 0 auto;
            background-color: $white;
            cursor: pointer;
            outline: none;
            @include vw(height, 65);
            @include vw(width, 200);

            @include vw(margin-bottom, 75);
            @include breakpoint(mobile) {
              @include vwMobile(height, 65);
              @include vwMobile(width, 200);

              @include vwMobile(margin-bottom, 100);
            }
            .icon-send {
              @include vw(height, 12);
              @include vw(width, 20);
              background-image: url('../../../assets/images/icons/icon-send-black.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              @include vw(margin-right, 8);
              @include breakpoint(mobile) {
                @include vwMobile(height, 35);
                @include vwMobile(width, 35);
                @include vwMobile(margin-right, 8);
              }
            }
            span {
              @include vw(font-size, 14);
              font-weight: 800;
              line-height: 1.38;
              text-transform: uppercase;
              @include breakpoint(mobile) {
                @include vwMobile(font-size, 16);
              }
            }
          }
        }
      }
    }
  }
  &.backgroundBuyPage_Mod {
    @include vw(padding-bottom, 50);
    @include vw(padding-top, 78);
    border-bottom: 1px solid $grayButton;
  }
}
