// .popup-component-preview {
.modal-content-referal {
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  margin-right: -50%;
  background-color: $almostBlack;
  outline: none;
  text-align: center;
  transform: translate(-50%, -50%);
  @include vw(width, 1200);
  @include breakpoint(mobile) {
    width: 100%;
  }

  .popup-exit-icon {
    position: absolute;
    border: 1px solid $white;
    background-clip: content-box;
    background-image: url('../../../../../assets/images/icons/no-border/Icon-Close.svg');
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    @include vw(background-size, 30);
    @include vw(top, -70);
    @include vw(right, 0);
    @include vw(width, 50);
    @include vw(height, 50);

    @include breakpoint(mobile) {
      position: fixed;
      @include vwMobile(top, 20);
      @include vwMobile(right, 20);
      @include vwMobile(width, 30);
      @include vwMobile(height, 30);
      @include vwMobile(padding, 5);
      @include vwMobile(margin-left, 437);
      @include vwMobile(background-size, 20);
    }
  }
  .popup-image-name {
    position: absolute;
    color: $white;
  }
  .popup-image-name {
    @include vw(bottom, -50);
    display: flex;
    align-items: center;
    @include breakpoint(mobile) {
      @include vwMobile(bottom, -50);
      @include vwMobile(left, 20);
    }
    .popup-image-name-line {
      @include vw(height, 2);
      @include vw(width, 40);
      @include vw(margin-right, 40);
      background-color: $white;
      background-image: url('../../../../../assets/images/icons/icon-line.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0.8;
      @include breakpoint(mobile) {
        @include vwMobile(height, 2);
        @include vwMobile(margin-right, 15);
        @include vwMobile(width, 20);
      }
    }
    p {
      @include vw(font-size, 20);
      color: $white;
      line-height: 2;
      opacity: 1;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        line-height: 2.5;
      }
    }
  }

  .popup-image {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include vw(height, 700);
    @include breakpoint(mobile) {
      @include vwMobile(height, 227);
    }
  }
}

// }
.referral-block-reward {
  display: flex;

  @include vw(margin-bottom, 80);
  .referral-block-reward-container {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    @include breakpoint(mobile) {
      @include vwMobile(width, 350);
      flex: 0 0 auto;
    }
    .popup-component-preview {
      // .popup-overlay {
      //   background: rgba(0, 0, 0, 0.8) none repeat scroll 0% 0% !important;
      // }
    }

    .referral-block-picture {
      @include vw(padding, 20);
      @extend .util-image-container;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      @include vw(height, 242);
      @include vw(margin-bottom, 30);
      @include breakpoint(mobile) {
        @include vwMobile(padding, 20);
        @include vwMobile(height, 242);
      }
      .placeholder {
        position: relative;
        @extend .util-image-placeholder;
        @include buildCorners(20, 0);
        border-bottom-width: 0;

        @include breakpoint(mobile) {
          @include vwMobile(border-top-width, 20);
          @include vwMobile(border-left-width, 20);
          @include vwMobile(border-right-width, 20);
        }
        .zoom-box {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: none;
          margin: auto;
          background-image: url('../../../../../assets/images/icons/Icon-Zoom.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          @include vw(height, 40);
          @include vw(width, 40);
          @include breakpoint(mobile) {
            @include vwMobile(height, 40);
            @include vwMobile(width, 40);
          }
        }
        .obtained-notice {
          @include vw(height, 50);
          @include vw(width, 184);
          background-color: $yellowIg;
          position: absolute;
          @include vw(top, -65);
          right: 0;
          left: 0;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            @include vw(font-size, 16);
            line-height: 2.5;
            text-transform: uppercase;
            font-weight: bold;
          }
          div {
            background-image: url('../../../../../assets/images/icons/no-border/Icon-Validate-Black.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include vw(height, 15);
            @include vw(width, 19);
            @include vw(margin-right, 9);
          }
        }
      }
    }
    .referral-block-picture:hover .zoom-box {
      display: flex;
    }
    .referral-block-reward-content {
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      h4 {
        text-align: center;
        text-transform: uppercase;
        @include vw(margin-bottom, 22);
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 22);
        }
      }

      ul {
        padding-left: 0;

        li {
          border-bottom: $gray-dark;
          border-bottom-style: solid;
          color: $gray-dark;

          list-style: none;
          @include vw(border-bottom-width, 1);
          @include vw(padding-bottom, 13);
          @include vw(margin-left, 10);
          @include vw(margin-right, 10);
          @include vw(margin-bottom, 22);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 15);
            @include vwMobile(border-bottom-width, 1);
            @include vwMobile(padding-bottom, 13);
            @include vwMobile(margin-left, 10);
            @include vwMobile(margin-right, 10);
            @include vwMobile(margin-bottom, 22);
            line-height: 1.4;
          }
          span {
            color: $white;
            text-transform: uppercase;
          }
        }
      }
      // Border related stuff
      @include vw(padding, 20);
      @include breakpoint(mobile) {
        @include vwMobile(padding-bottom, 20);
        @include vwMobile(padding-left, 20);
        @include vwMobile(padding-right, 20);
      }
      .referral-block-reward-footer {
        // Border related stuff
        flex-grow: 1;
        @include buildCorners(20, 0);
        border-top-width: 0;
        @include breakpoint(mobile) {
          @include vwMobile(border-bottom-width, 20);
          @include vwMobile(border-left-width, 20);
          @include vwMobile(border-right-width, 20);
        }
      }
    }
  }
}
