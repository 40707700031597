.tutorials-details-page {
  $side-spacing: 160;
  @include vw(padding-right, $side-spacing);
  @include vw(padding-left, $side-spacing);
  @include breakpoint(mobile) {
    @include vwMobile(padding-right, 20);
    @include vwMobile(padding-left, 20);
  }
  .image-intro {
    @include vw(height, 600);
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .description-text {
    @include vw(margin-top, 70);
    * {
      @include vw(font-size, 26);
      line-height: 1.54;
    }
    @include vw(padding-bottom, 70);
    border-bottom: 1px solid $grayButton;
  }
  .content-text {
    @include vw(margin-top, 70);

    img,
    iframe {
      width: 100%;
    }
    iframe {
      @include vw(height, 600);
      @include vw(margin-bottom, 30);
      @include breakpoint(mobile) {
        @include vwMobile(height, 300);
      }
    }
    p,
    em,
    strong {
      @include vw(font-size, 20);
      @include vw(margin-bottom, 30);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        line-height: 2.5;
      }
    }
    @include breakpoint(mobile) {
      width: 100%;
      margin-left: 0;
    }
    ul {
      padding-left: 0;
      li {
        @include vw(padding-left, 0);
        @include vw(margin-left, 28);
        @include vw(font-size, 20);
        margin-bottom: 0.2vw;
        line-height: 2;
        list-style-type: none;
        strong {
          @include vw(font-size, 20);
          @include breakpoint(mobile) {
            @include vwMobile(font-size, 16);
          }
        }
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
        &::before {
          display: inline-block;
          color: $yellowIg;
          content: '+';
          font-weight: normal;
          @include vw(width, 20);
          @include vw(margin-left, -33);
          @include vw(margin-right, 20);
          @include breakpoint(mobile) {
            @include vwMobile(width, 20);
            @include vwMobile(margin-left, -33);
            @include vwMobile(margin-right, 10);
          }
        }
      }
    }
    h1,
    h2,
    h3 {
      @include vw(margin-bottom, 20);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 20);
      }
    }
    h1 {
      @include vw(font-size, 35);
      line-height: 1.4;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 25);
      }
    }
    h2 {
      @include vw(font-size, 28);
      line-height: 1.43;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
    h3 {
      @include vw(font-size, 24);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
  }
  .tutorials-search {
    margin-top: 0;
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 65);
    }
  }

  .tutorials-about {
    @include vw(margin-top, 100);
    @include vw(margin-bottom, 0);
  }

  .container {
    display: flex;
    flex-direction: row;
    @include vw(padding-top, 120);
    .left {
      @include vw(width, 1052);
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
    .right {
      @include vw(width, 500);
      @include vw(margin-left, 60);
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }
  .tutorials-bg {
    @include vw(height, 500);
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .social {
    @include vw(margin-top, 40);
    @include vw(margin-bottom, 60);
    @include vw(padding-bottom, 60);

    border: 0;
    border-style: solid;
    border-color: $white01;
    @include vw(border-bottom-width, 1);

    .link {
      position: relative;
      z-index: 3;
      display: inline-block;
      height: 100%;

      &.iconLogoLink {
        @include vw(width, 60);
        @include vw(height, 60);
        @include vw(margin-right, 60);

        @include breakpoint(mobile) {
          @include vwMobile(width, 40);
          @include vwMobile(height, 40);
          @include vwMobile(margin-right, 40);
        }
      }
      .iconLogo {
        @include vw(width, 60);
        @include vw(height, 60);
        display: inline-block;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(mobile) {
          @include vwMobile(width, 40);
          @include vwMobile(height, 40);
        }
      }
    }
    span {
      @extend .iconLogo;
      @include vw(width, 40);
      @include vw(height, 40);
      @include vw(margin-right, 40);

      position: relative;
      z-index: 3;
      &:hover {
        filter: $white-to-yellow-filter;
      }

      &.iconLogoTwitter {
        background-image: url('../../../assets/images/icons/Twitter.svg');
      }
      &.iconLogoFacebook {
        background-image: url('../../../assets/images/icons/Facebook.svg');
      }
      &.iconLogoCopy {
        border: 1px solid $white;
        background-image: url('../../../assets/images/icons/copy.svg');
        @include vw(background-size, 35);
      }
      &.iconLogoEmail {
        background-image: url('../../../assets/images/icons/Icon\ -\ Email.svg');
      }
    }
  }
  .video {
    width: 100%;
  }
  iframe {
    @include breakpoint(mobile) {
      @include vwMobile(height, 260);
    }
  }
  h1 {
    @include vw(font-size, 35);
    color: $white;
    line-height: 1;
    @include vw(margin-bottom, 40);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 25);
      @include vwMobile(margin-bottom, 40);
    }
  }
  h2 {
    @include vw(font-size, 22);
    color: $yellowIg;
    line-height: 1;
    @include vw(margin-top, 40);
    @include vw(margin-bottom, 20);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 18);
      @include vwMobile(margin-bottom, 20);
    }
  }
  h6 {
    @include vw(font-size, 26);
    border-top-style: solid;
    border-top-color: $white01;
    color: $yellowIg;
    text-transform: uppercase;
    @include vw(margin-bottom, 60);
    @include vw(border-top-width, 1);
    @include vw(padding-top, 60);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 18);
      @include vwMobile(margin-bottom, 20);
    }
  }
  .next-tutorials {
    padding: 0;
    margin: 0;
    @include vw(margin-bottom, 100);

    li {
      display: block;
      margin: 0;
      @include vw(margin-top, 40);
      @include vw(margin-bottom, 40);
    }
    a {
      h1 {
        display: inline;
        color: $gray-medium;
        font-weight: normal;
        text-transform: unset;
        @include vw(font-size, 20);
        @include vw(margin-bottom, 40);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          @include vwMobile(margin-bottom, 20);
          @include vwMobile(margin-left, 20);
        }
      }
      &::before {
        color: $white;
        content: '+';
        @include vw(padding-right, 20);
      }
      &:hover {
        h1 {
          color: $white;
          &::before {
            color: $yellowIg;
          }
        }
      }
    }
  }

  .top-menu {
    position: sticky;
    @include vw(top, 80);
    z-index: 5;
    display: flex;
    // width: 100%;
    align-items: center;
    justify-content: space-between;
    border: 0;
    border-style: solid;
    border-color: $white02;
    background-color: $almostBlack;
    @include vw(height, 65);
    @include vw(margin-left, -$side-spacing);
    @include vw(margin-right, -$side-spacing);
    @include vw(border-bottom-width, 1);
    @include breakpoint(mobile) {
      display: none;
    }
    .item {
      @include vw(font-size, 18);
      color: $white;
      // line-height: 3;
    }
    .main {
      font-weight: bold;
      text-transform: uppercase;
    }
    .currently {
      text-decoration: underline;
    }
    .left {
      @include vw(padding-left, $side-spacing);
      .item {
        @include vw(margin-right, 40);
      }
    }
    .right {
      @include vw(padding-right, $side-spacing);
      .item {
        @include vw(padding-left, 40);
        &:hover {
          color: $yellowIg;
        }
      }
    }
  }
  .list-social-media {
    @include vw(margin-left, -20);
    @include vw(margin-bottom, 60);
    @include vw(margin-top, 40);
  }
}
