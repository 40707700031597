@mixin image-display() {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.block-big-content {
  // position: absolute;
  // @include vw(left, 20);
  width: 70%;
  height: 90%;

  @include breakpoint(mobile) {
    width: 100%;
    @include vwMobile(padding-left, 15);
    @include vwMobile(padding-right, 15);
  }
  p.type {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: $blueLight;
    text-transform: uppercase;
    @include vw(margin-top, 44);
    @include vw(margin-left, 100);
    @include vw(height, 41);
    @include vw(width, 169);
    @include vw(font-size, 16);
    @include breakpoint(mobile) {
      @include vwMobile(height, 41);
      @include vwMobile(width, 169);
      @include vwMobile(margin-top, 44);
      margin-right: auto;
      margin-left: auto;
      @include vwMobile(font-size, 18);
    }
  }
  .news-content-detail-big {
    position: absolute;
    bottom: 0;
    width: 26%;
    @include vw(margin-left, 100);
    @include vw(padding-left, 0);
    @include vw(padding-right, 0);
    @include breakpoint(mobile) {
      width: 100%;
    }

    @include breakpoint(mobile) {
      @include vwMobile(margin-left, 0);
    }
  }
  h2 {
    @include vw(margin-top, 95);
    @include vw(margin-left, 100);
    @include vw(margin-bottom, 45);
    line-height: normal;
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 30);
      @include vwMobile(margin-top, 80);
      @include vwMobile(margin-left, 0);
      @include vwMobile(margin-bottom, 20);
    }
  }
  button.read {
    border: solid 1px $gray-home;
    background-color: $blueLight;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    @include vw(margin-left, 100);
    @include vw(height, 65);
    @include vw(width, 206);
    @include breakpoint(mobile) {
      @include vwMobile(height, 65);
      @include vwMobile(width, 206);
      margin-right: auto;
      // @include vwMobile(margin-left, 0);
      margin-left: auto;
    }
    span {
      color: $white;
      line-height: 1.38;
      @include vw(font-size, 16);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
      &::before {
        color: $gray-dark;
        content: '>';
        @include vw(font-size, 18);
        @include vw(margin-right, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 18);
          @include vwMobile(margin-right, 20);
        }
      }
    }
  }
}
