.account-forgot-container {
  @include vw(height, 1000);
  display: flex;
  @include breakpoint(mobile) {
    flex-direction: column-reverse;
    height: auto;
  }
  .message-content {
    position: relative;
    @include vw(width, 1080);
    @include vw(padding-left, 155);
    @include vw(padding-top, 80);
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    @include breakpoint(mobile) {
      @include vwMobile(padding-left, 20);
      @include vwMobile(padding-right, 20);
      @include vwMobile(margin-top, -90);
      width: 100%;
    }
    .message-title {
      * {
        @include vw(font-size, 24);
        color: $yellowIg;
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        text-transform: uppercase;
        line-height: 1;
        margin: 0;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 14);
        }
      }
    }
    .message-desc {
      @include vw(margin-bottom, 20);
      * {
        @include vw(font-size, 70);
        font-weight: 900;
        line-height: 1;
        margin: 0;
        text-transform: uppercase;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 55);
          text-align: left;
        }
      }
      @include breakpoint(mobile) {
        width: 100%;
        @include vwMobile(margin-bottom, 20);
      }
    }
    .message-info {
      @include vw(margin-bottom, 40);
      @include vw(padding-right, 141);
      * {
        color: #a8a8a8;
        line-height: 2;
        @include vw(font-size, 20);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          text-align: left;
        }
      }
      @include breakpoint(mobile) {
        padding-right: 0;
        @include vwMobile(margin-bottom, 30);
      }
    }
    .secondpage {
      p:nth-child(1) {
        @include vw(margin-bottom, -20);
      }
    }
    .message-image-success {
      @include vw(height, 130);
      @include vw(width, 130);
      background-image: url('../../../../assets/images/icons/validate-border-green.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include vw(margin-bottom, 80);
      @include breakpoint(mobile) {
        @include vwMobile(height, 80);
        @include vwMobile(width, 80);
        @include vwMobile(margin-bottom, 40);
        @include vwMobile(margin-top, 30);
      }
    }
    .message-image-failure {
      @extend .message-image-success;
      background-image: url('../../../../assets/images/icons/error-border-red.svg');
    }
    .message-image-reset {
      @extend .message-image-success;
      background-image: url('../../../../assets/images/icons/Icon\ -\ Password.svg');
    }
    .white-text {
      @include vw(height, 71);
      @include vw(width, 600);
      text-align: center;
      word-break: break-all;
      @include breakpoint(mobile) {
        @include vwMobile(height, 105);
        @include vwMobile(width, 374);
        @include vwMobile(font-size, 20);
        line-height: 2;
      }
    }
    .green-text {
      display: block;
      margin-right: auto;
      margin-left: auto;
      color: $green;
      text-align: center;
      word-break: break-all;
      @include vw(height, 64);
      @include vw(width, 600);
      @include vw(margin-top, 30);
      @include breakpoint(mobile) {
        @include vwMobile(height, 105);
        @include vwMobile(width, 374);
        @include vwMobile(font-size, 20);
        @include vwMobile(margin-top, 35);
        line-height: 2;
      }
    }
    h2 {
      @include vw(font-size, 50);
      line-height: 1.4;
      text-align: center;
      @include vw(margin-bottom, 60);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 35);
        @include vwMobile(margin-bottom, 40);
      }
    }
    .line {
      position: absolute;
      @include vw(bottom, 60);
      height: 1px;
      background-color: $white02;
      width: 100%;
      @include vw(width, 749);
      @include vw(margin-bottom, 25);
      @include breakpoint(mobile) {
        display: none;
      }
    }
    .section-back {
      display: flex;
      align-items: center;
      position: absolute;
      @include vw(bottom, 34);
      @include breakpoint(mobile) {
        display: none;
      }
      div {
        @include vw(height, 16);
        @include vw(width, 16);
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-White.svg');
        background-position: center;
        background-repeat: no-repeat;
        transform: rotate(180deg);
        @include vw(background-size, 8);
        @include vw(margin-right, 10);
      }
      .goback {
        color: $white;
        text-transform: uppercase;
        @include vw(font-size, 13);
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
      }
    }
    .section-back:hover {
      div {
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
      }
      .goback {
        color: $yellowIg;
      }
    }

    p {
      &.resetNotification {
        color: $red;
        text-align: center;
        @include vw(padding-top, 20);
        @include vw(font-size, 20);
      }
      &.successNotification {
        color: $green;
        text-align: center;
        @include vw(padding-top, 20);
        @include vw(font-size, 20);
      }
    }
    .g-recaptcha {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @include breakpoint(mobile) {
        @include vwMobile(margin-top, 30);
        @include vwMobile(margin-bottom, 40);
      }
      .textRecaptcha {
        color: $yellowMild;
        text-align: right;
        text-transform: capitalize;
        @include vw(font-size, 16);
        @include vw(margin-top, 8);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
    }
    button {
      &.theme-button-forget {
        @include vwDimensions(260, 65);
        @include vw(font-size, 16);
        cursor: pointer;
        font-weight: bold;
        @include vw(margin-top, 40);
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
          @include vwDimensionsMobile(182, 50);
          @include vwMobile(margin-top, 20);
        }
      }
    }
  }
  .message-image {
    @include vw(width, 859);
    height: 100%;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .image-mobile {
    display: none;
    @include breakpoint(mobile) {
      display: flex;
      @include vwMobile(height, 275);
      width: 100%;
    }
  }
  .input-container {
    @include breakpoint(mobile) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
    }
  }
  .input-row {
    display: flex;
    justify-content: space-between;
    @include vw(margin-top, 12);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 10);
      @include vwMobile(width, 380);
    }
  }

  .row {
    display: flex;
    height: auto;
    width: 100%;
    justify-content: space-between;
    @include breakpoint(mobile) {
      flex-direction: column;
      @include vwMobile(margin-bottom, 0);
    }
    a {
      color: $yellowMild;
    }
  }
  .small {
    width: 83%;
  }
  .d-none {
    display: none;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .col {
    position: relative;
    .label {
      position: absolute;
      @include vw(font-size, 20);
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
        line-height: 2;
      }
      &::after {
        content: '*';
      }
      &.none {
        &::after {
          content: '';
        }
      }
    }
    input:invalid {
      box-shadow: none;
      outline: none;
    }
    input {
      width: 100%;
      background-color: transparent;
      color: $gray;
      line-height: 1.38;
      outline: none;
      border: none;
      @include vw(font-size, 16);
      @include vw(height, 66);
      @include vw(border-left, 0);
      @include vw(border-right, 0);
      @include breakpoint(mobile) {
        @include vwMobile(height, 65);
        @include vwMobile(font-size, 16);
      }
    }
    .strenght-psw-container {
      @include vw(margin-top, 15);
      .strenght-psw-label {
        @include breakpoint(mobile) {
          @include vwMobile(margin-top, 15);
          @include vwMobile(font-size, 16);
        }
      }
    }
  }
  .tooltip-box {
    position: absolute;
    right: 0;
  }
  label {
    @include breakpoint(mobile) {
      width: 86%;
      word-break: break-word;
    }
  }
  .col-6 {
    width: 48%;
    @include breakpoint(mobile) {
      width: 100%;
    }
  }
  .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    flex-direction: column;
    @include vw(margin-bottom, 40);
    @include vw(margin-top, 0);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 15);
      @include vwMobile(margin-top, 5);
    }
  }
  .input-container__row:hover {
    border-color: $white;
  }
  .input-container__row {
    display: flex;
    width: 100%;
    flex-direction: row;
    position: relative;
    border: 1px solid $gray-home;
    @include vw(margin-top, 48);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 30);
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $gray-home;
    background: $almostBlack;
    color: $white;
    text-align: center;
    @include vw(border-right, 0);
    @include vw(min-width, 50);
  }
  .icon-type {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include vw(width, 20);
    @include vw(height, 20);
    @include breakpoint(mobile) {
      @include vwMobile(width, 18);
      @include vwMobile(height, 18);
    }
  }
  .icon-psw {
    @extend .icon-type;
    background-image: url('../../../../assets/images/icons/no-border/Icon-Password.svg');
  }
  .icon-loading {
    @extend .icon-type;
    background-image: url('../../../../assets/images/icons/no-border/Icon-Loading.svg');
  }
  .icon-validate {
    @extend .icon-type;
    background-image: url('../../../../assets/images/icons/no-border/Icon-Validate-Green.svg');
  }
  .icon-fail {
    @extend .icon-type;
    background-image: url('../../../../assets/images/icons/no-border/Icon-Close-Red.svg');
  }
  .icon-email {
    @extend .icon-type;
    background-image: url('../../../../assets/images/icons/no-border/Icon-Email.svg');
  }
  .iconWapper {
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: center;
    border-right: 0;
    @include vw(width, 75);
    @include breakpoint(mobile) {
      @include vwMobile(width, 65);
    }
  }
  .arrow {
    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    @include vw(bottom, -10);
    @include vw(height, 12);
    @include vw(width, 26);
    @include vw(border-left-width, 26);
    @include vw(border-right-width, 26);
    @include vw(border-bottom-width, 26);
    border-right-style: solid; /* stylelint-disable-line */
    border-right-color: transparent; /* stylelint-disable-line */
    border-bottom-style: solid; /* stylelint-disable-line */
    border-bottom-color: $yellowIg; /* stylelint-disable-line */
    border-left-style: solid; /* stylelint-disable-line */
    border-left-color: transparent; /* stylelint-disable-line */

    @include breakpoint(mobile) {
      @include vwMobile(bottom, -10);
      @include vwMobile(height, 14);
      @include vwMobile(width, 32);
      @include vwMobile(border-left, 26);
      @include vwMobile(border-right, 26);
      @include vwMobile(border-bottom, 26);
      border-right-style: solid;
      border-right-color: transparent;
      border-bottom-style: solid;
      border-bottom-color: $yellowIg;
      border-left-style: solid;
      border-left-color: transparent;
    }
  }
  .yellow-notice {
    position: absolute;
    right: 0;
    left: 0;
    @include vw(bottom, -40);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    background-color: $yellowIg;
    background-image: none;
    //@include vw(bottom, -10);
    @include vw(height, 48);
    @include vw(width, 315);
    z-index: 1;
    @include breakpoint(mobile) {
      @include vwMobile(width, 315);
      @include vwMobile(min-height, 52);
      @include vwMobile(bottom, -50);
    }
    p {
      @include vw(font-size, 16);
      font-weight: normal;
      color: $almostBlack;
      line-height: 1.38;
      text-transform: lowercase;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    p::first-letter {
      text-transform: uppercase;
    }
  }

  .util-input:not(.error):hover {
    border-color: $white;
  }
  .error {
    border: $reddish 1px solid;
  }
  .theme-button {
    @include vw(width, 204);
    @include vw(margin-top, 45);
    .theme-button-container {
      div.send {
        background-image: url('../../../../assets/images/icons-buttons/Icon-Continue-Black.svg');
        background-size: contain;
        @include vw(height, 10);
        @include vw(width, 10);
        @include vw(margin-right, 15);
      }
      span {
        @include vw(font-size, 13);
        font-family: eurostile-extended, sans-serif;
        font-weight: 900;
        font-style: normal;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 13);
        }
      }
    }
    @include breakpoint(mobile) {
      display: none;
      @include vwMobile(height, 65);
      @include vwMobile(width, 60);
      span {
        display: none;
      }
      .theme-button-container {
        div.send {
          background-image: url('../../../../assets/images/icons/icon-send-black.svg');
          background-size: contain;
          @include vwMobile(height, 40);
          @include vwMobile(width, 35);
          background-size: cover;
        }
      }
    }
  }
  .second-page {
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 55);
      @include vwMobile(margin-bottom, 80);
      display: block;
      width: 100%;
      span {
        display: flex;
      }
    }
  }
  .button-mobile {
    display: none;
    @include breakpoint(mobile) {
      display: block;
    }
  }
  label {
    width: 70%;
    color: $yellowIg;
    line-height: 1.1;
    @include vw(font-size, 20);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 20);
      width: 100%;
    }
  }
  .checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //  @include vwAll(padding, 20, 40, 30, 40);
    @include breakpoint(mobile) {
    }
  }
  .checkboxcontainer {
    color: $white;
    @include vw(margin-top, 15);
    @include vw(margin-bottom, 5);
    input[type='checkbox'] {
      box-sizing: border-box;
      border-style: solid;
      border-color: $white;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      background-color: $blueDeep;
      cursor: pointer;
      outline: none;
      @include vw(width, 20);
      @include vw(height, 20);
      @include vwAll(margin, 0, 20, 0, 0);
      @include vw(border-width, 1);
      @include breakpoint(mobile) {
        @include vwMobile(margin-right, 30);
        @include vwMobile(width, 25);
        @include vwMobile(height, 25);
      }
    }
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 15);
    }
    input[type='checkbox']:checked {
      background-clip: content-box;
      background-color: $yellowIg;
      @include vwAll(padding, 5, 5, 5, 5);
      @include breakpoint(mobile) {
        @include vwMobileAll(padding, 7, 7, 7, 7);
      }
    }
    p {
      @include vw(font-size, 20);
      color: #a8a8a8;
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 20);
      }
    }
  }
}
