$color-txt-button: #000;

$color-bg-button: #fff;
$color-disabled: #85898a;
$color-positive: #3dac79;
$color-negative: #d13e3e;

$size-height-button: 65;

$size-corners: 12;
$size-corners-margin: 5;
$size-corners-margin-focus: 5 * 2;
$size-corners-margin-px: 5px;

$size-icons-button: 20;

$animations-duration: 100ms;

.theme-button-util-cancel-animations {
  @include buildCornersPlaceholder($size-corners, 0);
  height: calc(100% - ((2 * #{$size-corners-margin-px})));

  span {
    @include vw(margin-right, $size-corners-margin-focus);
  }

  div {
    &:nth-child(1) {
      @include vw(margin-left, $size-corners-margin-focus);
    }

    &:nth-child(3) {
      @include vw(margin-right, $size-corners-margin-focus);
    }
  }
}

.border-tertiary {
  border: 0;
  @include vw(border-bottom-width, 1);
  @include vw(margin-bottom, -1);
  border-bottom-style: solid;
  border-bottom-color: $color-disabled;
  @include vw(margin-left, 10);
  @include vw(margin-right, 10);
}

.border-base {
  border-width: 1px;
  border-style: solid;
}

.theme-test {
  .theme-button,
  .theme-button-secondary {
    @include vw(margin, 5);
  }
}

.theme-button {
  @include vw(height, $size-height-button);
  @include vw(min-width, 200);
  padding: 0;
  border: 0;
  background-image: linear-gradient(to right, $color-bg-button 50%, $yellowIg 50%);
  background-size: 200% 100%;
  border-radius: 0;
  cursor: pointer;
  transition: background-position $animations-duration;

  //main block - corners
  .theme-button-container {
    display: flex;
    height: calc(100% - ((2 * #{$size-corners-margin-px})));
    flex-direction: row;
    transition-duration: 0ms;
    @extend .flex-center-all;
    @include buildCornersPlaceholder($size-corners, 0);

    // text of the button
    span {
      color: $color-txt-button;
      font-weight: bold;
      text-transform: uppercase;
      //margin: auto;
      @include vw(margin-right, $size-corners-margin-focus);
      @include vw(height, 25);

      @extend .paragraph_base;
      @extend .flex-center-all;
    }

    // image block / icon
    div {
      @include vw(background-size, 17);
      @include vw(width, $size-icons-button);
      @include vw(height, 25);
      border-width: 0;
      // Replace with whatever you want
      background-image: url('../../assets/images/icons-buttons/Icon-Continue-Black.svg');
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      @extend .flex-center-all;

      &:nth-child(1) {
        @include vw(margin-right, 20);
        @include vw(margin-left, $size-corners-margin-focus);
      }

      &:nth-child(3) {
        @include vw(margin-right, $size-corners-margin-focus);
        @include vw(margin-left, 20);
      }
    }
  }

  &:hover {
    background-position: -100% 0;

    .theme-button-container {
      height: calc(100% - ((2 * #{$size-corners-margin-px})));
      transition-duration: $animations-duration;
      @include buildCornersImage($size-corners, $size-corners-margin, 'border-image-corners-black');
      @include breakpoint(mobile) {
        @include buildCornersMobileBlack($size-corners, $size-corners-margin);
      }
      span {
        @include vw(margin-right, $size-corners-margin);
      }

      div {
        &:nth-child(1) {
          @include vw(margin-left, $size-corners-margin);
        }

        &:nth-child(3) {
          @include vw(margin-right, $size-corners-margin);
        }
      }
    }
  }

  &:focus {
    background-position: -100% 0;
    outline: none;

    .theme-button-container {
      height: calc(100% - ((4 * #{$size-corners-margin-px})));
      // @include buildCornersImage($size-corners, $size-corners-margin-focus, 'border-image-corners-black');
      transition-duration: $animations-duration;

      span {
        @include vw(margin-right, 0);
      }

      div {
        &:nth-child(1) {
          @include vw(margin-left, 0);
        }

        &:nth-child(3) {
          @include vw(margin-right, 0);
        }
      }
    }
  }

  &:disabled,
  &[aria-pressed='true'] {
    transition-duration: 0ms;
    // invert everything from hover here to avoid any animations when disabled
    &:hover,
    &:focus {
      .theme-button-container {
        @extend .theme-button-util-cancel-animations;
      }
    }
  }

  //disabled button
  &:disabled {
    background-color: $color-disabled;
    background-image: none;
  }

  // aria focus
  &[aria-pressed='true'] {
    @include vwBoxShadow(0, 0, 20, 0, $color-bg-button);

    &:hover,
    &:focus {
      background-color: $color-bg-button;
      background-image: none;
    }
  }
}

.theme-button-secondary {
  @extend .theme-button;
  border-style: solid;
  border-color: $color-disabled;
  background-color: transparent;

  background-image: none;
  @include vw(border-width, 1);

  .theme-button-container {
    div {
      background-image: url('../../assets/images/icons-buttons/Icon-Continue-Disabled.svg');
    }

    span {
      color: $color-disabled;
    }
  }

  &:hover {
    border-color: $yellowIg;

    .theme-button-container {
      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
      }

      span {
        color: $yellowIg;
      }

      @include buildCornersImage($size-corners, $size-corners-margin, 'border-image-corners-yellow');
    }
  }

  &:focus {
    border-color: $yellowIg;

    .theme-button-container {
      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
      }

      span {
        color: $yellowIg;
      }

      @include buildCornersImage($size-corners, $size-corners-margin-focus, 'border-image-corners-yellow');
    }
  }

  &:disabled {
    border-color: $color-disabled;
    background-color: transparent;

    .theme-button-container {
      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-Disabled.svg');
      }

      span {
        color: $color-disabled;
      }
    }
  }

  // aria focus
  &[aria-pressed='true'] {
    @include vwBoxShadowInsetOutset(0, 0, 10, 0, $color-bg-button, 0, 0, 10, 0, $color-bg-button);
    border-color: $color-bg-button;

    .theme-button-container {
      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-White.svg');
      }

      span {
        color: $color-bg-button;
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.theme-button-tertiary {
  @extend .theme-button;

  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  background-image: none;

  .theme-button-container {
    box-sizing: border-box;

    border: 0;
    border-bottom-style: solid;
    border-bottom-color: $color-disabled;
    border-image: none;
    @include vw(border-bottom-width, 1);
    @include vw(margin-bottom, 0);
    @include vw(margin-left, 10);
    @include vw(margin-right, 10);

    div {
      background-image: url('../../assets/images/icons-buttons/Icon-Continue-Disabled.svg');
    }

    span {
      color: $color-disabled;
    }
  }

  &:hover {
    border-color: transparent;

    .theme-button-container {
      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
      }

      span {
        color: $yellowIg;
      }

      @include buildCornersImage($size-corners, $size-corners-margin, 'border-image-corners-yellow');
    }
  }

  &:focus {
    border-color: transparent;

    .theme-button-container {
      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-Yellow.svg');
      }

      span {
        color: $yellowIg;
      }

      @include buildCornersImage($size-corners, $size-corners-margin-focus, 'border-image-corners-yellow');
    }
  }

  &:disabled {
    border-color: transparent;
    background-color: transparent;

    .theme-button-container {
      @extend .border-tertiary;

      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-Disabled.svg');
      }

      span {
        color: $color-disabled;
      }
    }

    &:hover,
    &:focus {
      .theme-button-container {
        @extend .border-tertiary;
      }

      background-color: transparent;
    }
  }

  // aria focus
  &[aria-pressed='true'] {
    @include vwBoxShadowInsetOutset(0, 0, 0, 0, $color-bg-button, 0, 0, 0, 0, $color-bg-button);
    border-color: transparent;

    .theme-button-container {
      @extend .border-tertiary;

      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-White.svg');
      }

      span {
        color: $color-bg-button;
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;

      .theme-button-container {
        @extend .border-tertiary;
      }
    }
  }
}

// Little Staggering when on focus --> have to check it out
.theme-button-medium {
  @extend .theme-button;
  @include vw(height, 50);
  border-width: 1px;

  border-style: solid;
  border-color: $color-disabled;
  background-color: transparent;
  background-image: none;
  background-size: revert;
  transition: none;

  .theme-button-container {
    span {
      color: $color-bg-button;
    }

    // image block / icon
    div {
      background-image: url('../../assets/images/icons-buttons/Icon-Continue-White.svg');
    }
  }

  &:hover {
    background-position: initial;
    transition: none;

    .theme-button-container {
    }
  }

  &:focus {
    background-position: initial;
    outline: none;
    transition: none;
  }

  &:disabled,
  &[aria-pressed='true'] {
    transition: none;
    // invert everything from hover here to avoid any animations when disabled
    &:hover,
    &:focus {
      .theme-button-container {
        @extend .theme-button-util-cancel-animations;
      }
    }
  }

  //disabled button
  &:disabled {
    border-color: $color-disabled;
    background-color: transparent;
    background-image: none;

    .theme-button-container {
      span {
        color: $color-disabled;
      }

      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-Disabled.svg');
      }
    }
  }

  // aria focus
  &[aria-pressed='true'] {
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: transparent;
      background-image: none;
    }
  }
}

.theme-button-small {
  @extend .theme-button-medium;
  @include vw(height, 40);
}

.theme-button-spec-positive {
  @extend .theme-button;
  @extend .border-base;
  border-color: $color-positive;
  background-color: $color-positive;
  background-image: linear-gradient(to right, $color-positive 50%, transparent 50%);
  cursor: pointer;
  .theme-button-container {
    span {
      color: $color-bg-button;
    }

    div {
      background-image: url('../../assets/images/icons-buttons/Icon-Continue-White.svg');
    }
  }

  &:hover {
    border-color: $color-positive;
    background-color: transparent;
    @extend .border-base;
    .theme-button-container {
      span {
        color: $color-positive;
      }

      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-Positive.svg');
      }

      // @include buildCornersImage($size-corners, $size-corners-margin, 'border-image-corners-positive');
    }
  }

  &:focus {
    .theme-button-container {
      // @include buildCornersImage($size-corners, $size-corners-margin-focus, 'border-image-corners-positive');
    }
  }

  //disabled button
  &:disabled {
    border-color: $color-positive;
    background-color: $color-positive;
    opacity: 0.5;
    background-image: none;
    @extend .border-base;
    .theme-button-container {
      span {
        color: $white;
      }

      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-Disabled.svg');
      }
    }
  }

  // aria focus
  &[aria-pressed='true'] {
    @include vwBoxShadow(0, 0, 20, 0, $color-positive);
    &:hover,
    &:focus {
      background-color: $color-positive;
      background-image: none;
      .theme-button-container {
        span {
          color: $color-bg-button;
        }
        div {
          background-image: url('../../assets/images/icons-buttons/Icon-Continue-White.svg');
        }
      }
    }
  }
}

.theme-button-spec-negative {
  @extend .theme-button;
  @extend .border-base;
  border-color: $color-negative;
  background-color: $color-negative;
  background-image: linear-gradient(to right, $color-negative 50%, transparent 50%);

  .theme-button-container {
    span {
      color: $color-bg-button;
    }

    div {
      background-image: url('../../assets/images/icons-buttons/Icon-Continue-White.svg');
    }
  }

  &:hover {
    border-color: $color-negative;
    background-color: transparent;
    @extend .border-base;
    .theme-button-container {
      span {
        color: $color-negative;
      }

      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-Negative.svg');
      }

      @include buildCornersImage($size-corners, $size-corners-margin, 'border-image-corners-negative');
    }
  }

  &:focus {
    .theme-button-container {
      @include buildCornersImage($size-corners, $size-corners-margin-focus, 'border-image-corners-negative');
    }
  }

  //disabled button
  &:disabled {
    border-color: $color-negative;
    background-color: transparent;
    background-image: none;
    @extend .border-base;
    .theme-button-container {
      span {
        color: $color-disabled;
      }

      div {
        background-image: url('../../assets/images/icons-buttons/Icon-Continue-Disabled.svg');
      }
    }
  }

  // aria focus
  &[aria-pressed='true'] {
    @include vwBoxShadow(0, 0, 20, 0, $color-negative);
    &:hover,
    &:focus {
      background-color: $color-negative;
      background-image: none;
      .theme-button-container {
        span {
          color: $color-bg-button;
        }
        div {
          background-image: url('../../assets/images/icons-buttons/Icon-Continue-White.svg');
        }
      }
    }
  }
}
